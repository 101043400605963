import { FaMoneyBill } from "react-icons/fa";
import { HiCreditCard } from "react-icons/hi";
import { FcMoneyTransfer, FcShop } from "react-icons/fc";
import { MdOutlineCreditScore, MdPix } from "react-icons/md";

import SimpleCard from "../../../components/SimpleCard/SimpleCard";

import formatCurrency from "../../../utils/format-currency";

export default function CardsSection({ soldByPaymentType, sales }) {
    return (
        <section className="table-responsive w-fit max-w-full mx-auto py-2 mb-3 flex gap-4">
            <SimpleCard
                title={
                    <div className="flex flex-col md:flex-row items-center gap-1">
                        <FcShop className="w-5 h-5" />
                        <span>Nº de vendas hoje</span>
                    </div>
                }
                content={sales?.data?.totalSales ?? "..."}
                custom={"whitespace-nowrap"}
            />
            <SimpleCard
                title={
                    <div className="flex flex-col md:flex-row items-center gap-1">
                        <FcMoneyTransfer className="w-5 h-5" />
                        <span>Valor total vendido hoje</span>
                    </div>
                }
                content={`R$ ${formatCurrency(sales?.data?.totalSold)}` ?? "..."}
                custom={"whitespace-nowrap"}
            />
            <SimpleCard
                title={
                    <div className="flex flex-col md:flex-row items-center gap-1">
                        <FaMoneyBill className="w-5 h-5 text-secondaryGreen" />
                        <span>Dinheiro</span>
                    </div>
                }
                content={`R$ ${soldByPaymentType.dinheiro.toFixed(
                    2
                )}`}
                custom="w-52"
            />
            <SimpleCard
                title={
                    <div className="flex flex-col md:flex-row items-center gap-1">
                        <MdPix className="w-5 h-5 text-blue-500" />
                        <span>PIX</span>
                    </div>
                }
                content={`R$ ${soldByPaymentType.pix.toFixed(
                    2
                )}`}
                custom="w-52 whitespace-nowrap"
            />
            <SimpleCard
                title={
                    <div className="flex flex-col md:flex-row items-center gap-1">
                        <HiCreditCard className="w-5 h-5 text-amber-500" />
                        <span>Crédito</span>
                    </div>
                }
                content={`R$ ${soldByPaymentType.credito.toFixed(
                    2
                )}`}
                custom="w-52"
            />
            <SimpleCard
                title={
                    <div className="flex items-center gap-1">
                        <MdOutlineCreditScore className="w-5 h-5 text-green-600" />
                        <span>Débito</span>
                    </div>
                }
                content={`R$ ${soldByPaymentType.debito.toFixed(
                    2
                )}`}
                custom="w-52"
            />
        </section>
    )
}