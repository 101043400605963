import React from 'react';
import ReactECharts from 'echarts-for-react';
import './style.scss'

export default function Graph({ max, prevMonth, actMonth, colorAct, colorPrev }) {

    const options = {
        polar: {
            radius: [80, '80%']
        },
        angleAxis: {
            max: max,
            startAngle: 75,
            show: false
        },
        radiusAxis: {
            type: 'category',
            data: ['', ''],
            show: false
        },
        tooltip: {},
        series: {
            type: 'bar',
            data: [{
                value: prevMonth,
                itemStyle: {
                    color: colorPrev
                }
            }, {
                value: actMonth,
                itemStyle: {
                    color: colorAct,
                    radius: ['20%']
                }
            }],
            coordinateSystem: 'polar',
            label: {
                show: false,
                position: 'middle',
            }
        }
    };

    return (
        <>
            {
                max !== 0 ? (
                    <ReactECharts option={options} className='polarGraphContainer' />
                ) : (
                    <div className='md:h-auto w-full md:w-3/5 m-auto flex justify-center items-center'>
                        <span className='text-green-700 text-center'>Quando você fizer mais vendas, um gráfico será exibido aqui 😉</span>
                    </div>
                )
            }

        </>

    );
}