import useLink from "../../../../hooks/useLink"
import lucreiImg from "../../../../assets/logoAmarelaCircular.png"

export default function PromoCard({ photo, title, isActive, id }) {

    const history = useLink()

    return (
        <article className="bg-white shadow-md rounded-xl p-3 flex flex-col gap-3 items-center justify-center">
            <div className="w-64 h-64 bg-zinc-100">
                <img src={photo || lucreiImg} alt="banner da promoção" className="h-full w-full object-cover" />
            </div>
            <h2 className="text-2xl font-semibold text-center">{title}</h2>
            <span className={isActive ? 'text-secondaryGreen' : 'text-red-600'}>
                {isActive ? 'Ativa' : 'Inativa'}
            </span>
            <button
                className="bg-secondaryGreen text-white px-5 py-2 rounded-xl shadow-md hover:scale-105 duration-150"
                onClick={() => history.push(`/empresa/detalhes-da-promocao/${id}/${title}`)}
            >
                Ver Detalhes
            </button>
        </article>
    )
}