import { useState, useEffect } from "react";
import "./style.scss";

//import Footer from "../../components/Footer/footer";
import Menu from "../../components/Menu/Menu";

import { getToken, logout } from "../../services/auth";
import api from "../../services/api";

import Pagination from "../../components/Pagination";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardIcon from "../../assets/svg/clipboard-data.svg";

import MinhasVendas from "../../assets/GeneralLogo/Icones/Minhas vendas.png";
import IconeComissoes from "../../assets/GeneralLogo/Icones/Icones SVG/10.svg";
import StoreCategoriesList from "../../components/StoreCategoriesList/StoreCategoriesList";
import EditCategoryModal from "../../components/Modals/EditCategoryModal/EditCategoryModal";
import DefaultRoot from "../../components/DeafultRoot";
import MenuEmpresa from "../../components/MenuEmpresa";
import BannerTool from "../../components/BannerTool";

export default function SalesScreen() {
    const [categories, setCategories] = useState([]);
    const token = getToken();
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);

    // New Category
    const [category, setCategory] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");

    // Edit Category
    const [categoryToUpdate, setCategoryToUpdate] = useState({
        _id: "",
        name: "",
    });
    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");

    async function getCategories() {
        try {
            const response = await api.get(
                "api/v1/stores/me/paginated-categories",
                { headers: { Authorization: token } }
            );
            setCategories(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            const { status } = error.response;
            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    }

    useEffect(() => {
        getCategories();
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        async function getCategories() {
            try {
                setLoading(true);
                const response = await api.get(
                    `api/v1/stores/me/paginated-categories?pageNumber=${currentPage}`,
                    { headers: { Authorization: token } }
                );
                setLoading(false);
                setCategories(response.data.data);
            } catch (error) {
                setLoading(false);
                const { status } = error.response;
                console.log("error", status);
                if (status === 401) {
                    logout();
                    history.push("/login");
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }
            }
        }

        getCategories();
        /* eslint-disable-next-line */
    }, [currentPage]);

    const handleNewCategorySubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await api.post(
                "api/v1/categories",
                { name: category, description: categoryDescription },
                {
                    headers: { Authorization: token },
                }
            );
            setLoading(false);
            setCategory("");
            setCategoryDescription("");
            getCategories();
            toast.success("Nova categoria cadastrada com sucesso!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            const { status } = error.response;
            const { message } = error?.response?.data;
            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 400) {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } finally {
            setLoading(false);
            setCategory("");
        }
    };

    const handleInputCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleEditCategoryClick = (category) => {
        setCategoryToUpdate(category);
        setShowEditCategoryModal(!showEditCategoryModal);
    };

    const handleEditCategorySubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await api.patch(
                `api/v1/categories/${categoryToUpdate._id}`,
                { name: newCategoryName },
                {
                    headers: { Authorization: token },
                }
            );
            setLoading(false);
            setCategoryToUpdate({ _id: "", name: "" });
            setNewCategoryName("");
            setShowEditCategoryModal(false);
            getCategories();
            toast.success("Categoria atualizada com sucesso!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            const { status } = error.response;
            const { message } = error?.response?.data;
            console.log({ error });
            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 400) {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
    };

    const handleOnCloseEditCategoryModal = async () => {
        setShowEditCategoryModal(false);
        setCategoryToUpdate({ _id: "", name: "" });
        setNewCategoryName("");
        setShowEditCategoryModal(false);
    };

    return (
        <DefaultRoot>

            <MenuEmpresa />

            <BannerTool
                title={"Categorias"}
            />


            <div className="containerMenuEList StoreCategoriesListContainer">

                <div>
                    <div className="NewCategoryFormWrapper !mt-3">
                        <h2>Criar nova categoria</h2>
                        <form onSubmit={handleNewCategorySubmit}>
                            <div className="input-wrapper">
                                <label htmlFor="categoryName">
                                    Nome da categoria:
                                </label>
                                <input
                                    id="categoryName"
                                    type="text"
                                    placeholder="Digite aqui o nome da categoria..."
                                    className="new-category-input"
                                    onChange={handleInputCategoryChange}
                                    value={category}
                                />
                            </div>

                            <div className="input-wrapper">
                                <label htmlFor="categoryName">
                                    Descrição da categoria:
                                </label>
                                <textarea
                                    onChange={({ target }) =>
                                        setCategoryDescription(target.value)
                                    }
                                    value={categoryDescription}
                                    // maxLength={300}
                                    className="description-category-textarea"
                                    rows={5}
                                    placeholder="Caso queira, digite uma breve descrição para a categoria criada..."
                                ></textarea>
                            </div>

                            <button
                                type="submit"
                                className="btn-new-category"
                                disabled={category?.length < 2}
                            >
                                Criar nova categoria
                            </button>
                        </form>
                    </div>
                    {categories && (
                        <StoreCategoriesList
                            handleEditCategoryClick={handleEditCategoryClick}
                            categories={categories}
                            loading={loading}
                            nameTable={"Minhas categorias"}
                            stylee="Table Mt"
                            vendas={true}
                        />
                    )}
                    {categories?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={setCurrentPage}
                        />
                    )}
                    {showEditCategoryModal && (
                        <EditCategoryModal
                            category={categoryToUpdate}
                            handleEditCategorySubmit={handleEditCategorySubmit}
                            newCategoryName={newCategoryName}
                            setNewCategoryName={setNewCategoryName}
                            handleOnCloseEditCategoryModal={
                                handleOnCloseEditCategoryModal
                            }
                        />
                    )}
                </div>
            </div>
        </DefaultRoot>
    );
}
