import React, { useState } from "react";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DefaultLogo from "../,,/../../assets/default-logo.png";

import addWhite from "../../assets/addWhite.svg";
import "./style.scss";
import StoreModal from "../StoreModal";

export const StoreCard = ({ store }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="StoreCard">
            <div className="StoreCard__image">
                <img
                    src={`${store.avatar ? store.avatar : DefaultLogo}`}
                    alt="Logo Store A"
                />
            </div>
            <div className="StoreCard__info">
                <h5>{store.name}</h5>
                <ul className="segments">
                    {store.marketSegments.map((item, index) => {
                        if (index === store.marketSegments.length - 1)
                            return <li key={index}>{item}</li>;
                        else return <li key={index}>{item}</li>;
                    })}
                </ul>
                {/* <p className="address">{store.city}</p> */}
                <div className="more-info-wrapper">
                    <button
                        className="btn-more-info"
                        onClick={() => setOpenModal((prev) => !prev)}
                    >
                        <img src={addWhite} />
                    </button>
                    {store.whatsapp && (
                        <a
                            className="btn-whatsapp"
                            rel="noreferrer"
                            href={`https://api.whatsapp.com/send?phone=55${store.whatsapp}&text=Olá! Conheci seu estabelecimento através da Lucrei.`}
                            target="_blank"
                        >
                            {" "}
                            WhatsApp{" "}
                        </a>
                    )}
                </div>
            </div>
            {openModal && (
                <StoreModal store={store} setOpenModal={setOpenModal} />
            )}
        </div>
    );
};
