export default function ConfigForm({ formik }) {
    return (
        <div className="bg-white rounded-xl shadow-lg p-4 w-11/12 max-w-screen-md mx-3 flex flex-col gap-3">
            <h2 className="text-2xl font-semibold text-zinc-500 mb-3">
                Configurar Promoção
            </h2>
            <div>
                <label htmlFor="salesNumber">Número de Compras</label>
                <input
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.salesNumber}
                    type="number"
                    maxLength={50}
                    name="salesNumber"
                    placeholder="Quantas compras para completar o Cartão Fidelidade?"
                    required
                />
            </div>
            <div>
                <label htmlFor="minValueSale">Valor mínimo da compra</label>
                <input
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.minValueSale}
                    type="number"
                    maxLength={50}
                    name="minValueSale"
                    placeholder="Qual o valor mínimo da compra?"
                    required
                />
            </div>
            <div className="flex gap-3 items-center">
                <input
                    className="w-4 h-4"
                    onChange={formik.handleChange}
                    value={formik.values.notifyCustomers}
                    type="checkbox"
                    name="notifyCustomers"
                />
                <label className="form-check-label" htmlFor="notifyCustomers">
                    Notificar Clientes?
                </label>
            </div>
        </div>
    );
}
