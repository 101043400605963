import { FcCancel, FcOk } from 'react-icons/fc'
import DefaultButton from '../../../../components/DefaultButton'

export default function ValidateModal({ message, valid, control }) {
    return (

        <>
            <div className="fixed top-0 backdrop-brightness-50 w-screen h-max min-h-screen overflow-y-auto"></div>
            <div className="absolute w-screen flex justify-center">
                <div className="bg-white rounded-xl p-12 flex flex-col justify-center items-center gap-5">
                    {valid ? <FcOk fontSize={"5rem"} /> : <FcCancel fontSize={"5rem"} />}
                    {message}
                    <DefaultButton content={"Fechar"} className={"bg-primaryPurple text-white"} onClick={() => control(false)} />
                </div>
            </div>

        </>

    )
}