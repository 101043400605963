import { useEffect } from "react";
import { Fragment, useContext, useRef, useState } from "react";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import CustomSelect from "../../../components/CustomSelect";

import { TransactionsContext } from "../context/context";

import api from "../../../services/api";

export default function NewTransaction({
    updateTransactions,
    updateStats,
    setCategoryModalOpen,
    categories,
    isLoading,
    updateCategories,
}) {
    const cancelButtonRef = useRef(null);
    const { transactionModalOpen, setTransactionModalOpen, setRegistCallback } =
        useContext(TransactionsContext);
    const [type, setType] = useState({ id: 1, name: "ENTRADA/RECEITA" });
    const [category, setCategory] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            value: "",
            type: { id: 1, name: "ENTRADA/RECEITA" },
            category: 1,
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await api.post("/api/v1/transactions", {
                value: formik.values.value
                    .split(",")
                    .join(".")
                    .replace(/[a-zA-Z\s]/g, ""),
                type: type.name,
                category: category,
            });
            toast.success("Movimentação registrada com sucesso!", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            updateTransactions();
            updateStats();

            formik.resetForm();
            setType({ id: 1, name: "ENTRADA/RECEITA" });
            setCategory(categories?.data[0]?.name);

            setTransactionModalOpen(false);
        } catch (error) {
            const { data } = error.response;

            console.log("error", { error });

            toast.error(data?.message, {
                position: "top-right",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (!isLoading && categories?.data?.length > 0)
            setCategory(categories.data[0].name);
    }, [categories, isLoading]);

    return (
        <Transition.Root show={transactionModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setTransactionModalOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg w-full bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() =>
                                            setTransactionModalOpen(false)
                                        }
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>

                                <div className="flex">
                                    <div className="mt-3 text-center w-full">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Nova Movimentação
                                        </Dialog.Title>
                                        <form
                                            className="w-full mx-auto my-8 grid gap-4"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="flex flex-col items-start">
                                                <label htmlFor="type">
                                                    Tipo
                                                </label>
                                                <div
                                                    className="form-control"
                                                    name="type"
                                                >
                                                    <CustomSelect
                                                        options={[
                                                            {
                                                                id: 1,
                                                                name: "ENTRADA/RECEITA",
                                                            },
                                                            {
                                                                id: 2,
                                                                name: "SAIDA/DESPESA",
                                                            },
                                                        ]}
                                                        active={
                                                            formik.values.type
                                                                .id
                                                        }
                                                        control={(value) =>
                                                            setType(value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col items-start">
                                                <label htmlFor="category">
                                                    Categoria
                                                </label>
                                                <div className="flex gap-2 w-full">
                                                    <div
                                                        className="form-control"
                                                        name="category"
                                                    >
                                                        {!isLoading &&
                                                            category !=
                                                            null && (
                                                                <CustomSelect
                                                                    options={
                                                                        categories?.data
                                                                    }
                                                                    active={
                                                                        formik
                                                                            .values
                                                                            .category
                                                                    }
                                                                    control={(
                                                                        value
                                                                    ) =>
                                                                        setCategory(
                                                                            value.name
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center gap-x-1.5 rounded-md bg-secondaryGreen p-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                                                        onClick={() => {
                                                            setRegistCallback(
                                                                true
                                                            );
                                                            setTransactionModalOpen(
                                                                false
                                                            );
                                                            setCategoryModalOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <PlusIcon
                                                            className="h-7 w-7"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="flex flex-col items-start">
                                                <label htmlFor="value">
                                                    Valor em R$
                                                </label>
                                                <input
                                                    className="form-control"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.value}
                                                    type="text"
                                                    name="value"
                                                    placeholder="Ex: 100,00"
                                                    required
                                                />
                                            </div>

                                            {!isLoading &&
                                                category === null && (
                                                    <span className="text-red-500">
                                                        Você precisa cadastrar
                                                        pelo menos uma categoria
                                                        antes de registrar uma
                                                        movimentação.
                                                    </span>
                                                )}

                                            <div className="mt-3 flex gap-2 justify-end">
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto"
                                                    onClick={() =>
                                                        setTransactionModalOpen(
                                                            false
                                                        )
                                                    }
                                                    ref={cancelButtonRef}
                                                >
                                                    Cancelar
                                                </button>

                                                <button
                                                    type="submit"
                                                    className={`mt-3 inline-flex w-full justify-center rounded-md ${!isLoading &&
                                                        category === null &&
                                                        "opacity-60"
                                                        } bg-secondaryGreen px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto`}
                                                    disabled={
                                                        !isLoading &&
                                                        category === null
                                                    }
                                                >
                                                    {submitting
                                                        ? "Aguarde..."
                                                        : "Concluir"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
