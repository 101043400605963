import React from "react";

export default function StatisticCard(props) {
    return (
        <article className="flex items-center gap-x-12">
            <div className="p-3 bg-green-100 w-20 h-20 d-flex rounded-xl">
                <img src={props.icon} alt="icone" />
            </div>
            <div>
                <h3 className="text-gray-500 font-semibold text-lg">{props.title}</h3>

                <span className="font-semibold text-lg">{props.totalInfo}</span>

                <div className="flex gap-4">
                    <div className="d-flex align-items-center gap-2">
                        <span className="w-3 h-3 rounded-full bg-primaryGreen"></span>
                        <span>{props.ordinaryInfo}</span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <span className="w-3 h-3 rounded-full bg-primaryPurple"></span>
                        <span>{props.separatedInfo}</span>
                    </div>
                </div>

            </div>
        </article>
    )
}