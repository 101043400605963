import api from "../../../services/api"

export default async function statisticsDash(sellType) {

    const date = new Date()
    const currentMonth = date.getMonth() + 1

    const newClients = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/me/customers/${sellType}/new-customers`)

        return response.data.totalMostRecentCustomers
    }

    const purchasesOnce = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/${sellType}/total-customer-purchases-once`)

        return response.data.totalCustomersPurchasesOnce
    }

    const purchasesMany = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/${sellType}/total-customer-many-purchases`)

        return response.data.totalCustomersPurchasesMoreThanOne
    }

    const averageTicket = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/me/${sellType}/average-ticket`)

        return response.data.averageTicket
    }

    const totalSoldCurrentMonth = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/${sellType}/total-sold-current-month`)

        return response.data.totalSoldInTheCurrentMonth
    }

    const totalSalesCurrentMonth = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/${sellType}/total-sales-month?month=${currentMonth}`)

        return response.data.totalSalesByMonth
    }

    const totalSalesPreviousMonth = async (sellType) => {
        const response = await api.get(`api/v1/dashboard/stores/${sellType}/total-sales-previous-month`)

        return response.data.totalSalesPreviousMonth
    }


    const response = {
        newClients: await newClients(sellType),
        purchasesOnce: await purchasesOnce(sellType),
        purchasesMany: await purchasesMany(sellType),
        averageTicket: await averageTicket(sellType),
        totalSoldCurrentMonth: await totalSoldCurrentMonth(sellType),
        totalSalesCurrentMonth: await totalSalesCurrentMonth(sellType),
        totalSalesPreviousMonth: await totalSalesPreviousMonth(sellType),
    }

    return response
}