import { useState, useEffect } from "react"

import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import Pagination from "../../components/Pagination"
import MenuCliente from "../../components/MenuCliente"
import PurchasesTable from "../../components/PurchasesTable"

import "./style.scss"

import api from "../../services/api"
import { getToken, logout } from "../../services/auth"
import useSWR from "swr"
import fetcher from "../../services/fetcher"
import NewTable from "../../components/NewTable/NewTable"
import DefaultRoot from "../../components/DeafultRoot"
import MainContent from "../../components/MainContent"
import BannerTool from "../../components/BannerTool"

export default function PurchasesScreen() {

    const { data, isLoading } = useSWR(
        "api/v1/customers/me/purchases?pageSize=10&sortBy=-createdAt",
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )


    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [purchases, setPurchases] = useState([])
    const [loading, setLoading] = useState(false)
    const token = getToken()

    useEffect(() => {
        async function LoadPuchases() {
            try {
                setLoading(true)
                const response = await api.get(
                    "api/v1/customers/me/purchases?pageSize=10&sortBy=-createdAt",
                    { headers: { Authorization: token } }
                )
                setLoading(false)
                setPurchases(response.data.data)
                setTotalPages(response.data.pages)
            } catch (error) {
                const { status } = error.response
                console.log("error", status)
                if (status === 401) {
                    history.push("/login")
                    logout()
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    )
                }
            }
        }

        LoadPuchases()
        /* eslint-disable-next-line */
    }, [])

    useEffect(() => {
        async function LoadPuchases() {
            try {
                const response = await api.get(
                    `api/v1/customers/me/purchases?sortBy=-createdAt&pageNumber=${currentPage}`,
                    { headers: { Authorization: token } }
                )
                setPurchases(response.data.data)
            } catch (error) {
                const { status } = error.response
                console.log("error", status)
                if (status === 401) {
                    history.push("/login")
                    logout()
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    )
                }

                if (error.response.status === 429) {
                    history.push("/login")
                    logout()
                    toast.info(
                        "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                        {
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    )
                    return
                }
            }
        }

        LoadPuchases()
        /* eslint-disable-next-line */
    }, [currentPage])

    const history = useHistory()
    return (
        <DefaultRoot>
            <MenuCliente />
            <BannerTool
                title={"Minhas últimas compras"}
            />

            {/*  <PurchasesTable purchases={purchases} loading={loading} /> */}
            <MainContent>
                {!isLoading && (
                    <NewTable
                        data={data}
                        name={""}
                        fields={[
                            {
                                type: "date",
                                title: "Data da compra",
                                name: "createdAt"
                            },
                            {
                                type: "default",
                                title: "Estabelecimento",
                                name: "storeName"
                            },
                            {
                                type: "money",
                                title: "Valor",
                                name: "value"
                            },
                            {
                                type: "money",
                                title: "Cashback",
                                name: "cashback"
                            }
                        ]}
                    />
                )}

            </MainContent>

            {/*  <NewTable
                        data={data}
                        name={"Minhas  últimas compras"}
                        fields={[
                            {
                                type: "date",
                                title: "Data da compra",
                                name: "createdAt"
                            },
                            {
                                type: "default",
                                title: "Estabelecimento",
                                name: "storeName"
                            },
                            {
                                type: "money",
                                title: "Valor",
                                name: "value"
                            },
                            {
                                type: "money",
                                title: "Cashback",
                                name: "cashback"
                            }
                        ]}
                    /> */}
            {purchases.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            )}
        </DefaultRoot>
    )
}
