import Logotipo from "../../assets/LogotipoBranco.png";

import "./footer.scss";

function Footer() {
  return (
    <div className="flex-shrink-0">
      <footer>
        <div id="main_boxTwo">
          <div className="container_footerTwo">
            <div className="boxAlingOne">
              <h3>Sobre nós</h3>
              <br></br>
              <p id="textPrinc">
                Nossa plataforma funciona no modelo de
                marketplace entre clientes e empresas e tem como
                objetivo fazer gerenciamento de vendas para
                clientes que compram em empresas parceiras{" "}
              </p>
            </div>
            <div className="boxAlingTwo">
              <h3 className="textAlingc">Coluna Lucrei!</h3>
              <br></br>
              <div className="textAling textAlingc">
                <p>Conectando clientes e empresas.</p>
                <p>Você lucrando com a gente.</p>
                <p>Compre em nossos parceiros</p>
                <p>
                  A melhor forma de ganhar dinheiro comprando.
                </p>
              </div>
            </div>
            <div className="boxAlingOne">
              <h3>Precisa da gente?</h3>
              <br></br>
              <div className="textAling w_100">
                <p>Endereço: Capistrano-CE</p>
                <p>Email: lucreicashback@gmail.com</p>
                <p>Suporte: (85) 99194-9020</p>
                <p>Setor de vendas: (85) 8217-0523</p>
              </div>
            </div>
          </div>
        </div>
        <div id="main_boxThree">
          <div className="container_footerThree">
            <div className="boxs_footerThree w_25">
              <img src={Logotipo} alt="logo da lucrei" id="logoL"></img>
            </div>
            <div className="boxs_footerThree">
              <div className="box_textDireitos w_50">
                <h2>
                  Lucrei © 2023 Todos os direitos reservados
                </h2>
              </div>
            </div>
            <div className="boxs_footerThree w_25">
              <div className="box_textTrhee">
                <p>Vem lucrar com a gente!</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
