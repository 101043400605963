import { useState } from 'react';
import './style.scss'


import TextSupport from '../../pages/CashbackNaNota/components/TextSupport';
import Forget from '../forgetPass/index'
import Termos from '../terms/index'

import Close from '../../assets/X.svg'
import SenhaReset from '../../assets/emailEnv.svg'
import EmailEnv from '../../assets/emailEnv.svg'


function Modal({ visible = () => { }, text, Title, Component, subText }) {
    const [imgAtual, setimgAtual] = useState(SenhaReset)
    const [TextAtual, setTextAtual] = useState(text)

    return (
        <div className={"show modalContainer fade"} id="exemplomodal" tabindex="-1" role="dialog" aria- labelledby="myLargeModalLabel">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2>{Title}</h2>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={visible}><span
                            aria-hidden="true"><img src={Close}></img></span></button>
                    </div>
                    <div class="modal-body d-flex">
                        <h2 id='none'>{TextAtual}</h2>
                        {TextAtual == text && subText == true ?
                            <p id='none'>Sem problemas, a gente te ajuda a recuperar :)</p>
                            : null}
                        {Component == 'Forget' ?
                            <div class="modal-left">
                                <img src={imgAtual}></img>
                            </div> : null}
                        {Component == 'Forget' ? (
                            <div class={'modal-right'}>
                                {Component == 'Forget' ?
                                    <Forget alterImg={() => setimgAtual(EmailEnv)} alterText={() => setTextAtual('Email Enviado')} />
                                    : null}
                            </div>
                        ) : null}
                        {Component == 'Termos' ? (
                            <div className='modal-right w100'>
                                <Termos />
                            </div>
                        ) : null}
                        {Component == 'Support' ? (
                            <div className='modal-right w100'>
                                <TextSupport />
                            </div>
                        ) : null}
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;