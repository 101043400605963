import React, { useState, useEffect } from "react";

import MenuEmpresa from "../../../../components/MenuEmpresa";
import useLink from "../../../../hooks/useLink";
import BannerTool from "../../../../components/BannerTool/index";
import useSWR from "swr";
import Pagination from "../../../../components/Pagination";
import Loading from "../../../../components/Loading";
import NewTable from "../../../../components/NewTable/NewTable";
import genCouponsAdapter from "./genCouponsAdapter";
import DefaultRoot from "../../../../components/DeafultRoot";
import { MdSearch } from "react-icons/md"
import api from "../../../../services/api";
import DefaultButton from './../../../../components/DefaultButton/index';
import LoadingForButton from "../../../../components/LoadingForButton";
import StatusFilter from "../../components/StatusFilter";

export default function GeneratedCoupons() {

    const history = useLink()
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [adaptedData, setAdaptedData] = useState()
    const [status, setStatus] = useState("")
    const endpoint = `api/v1/stores/me/customer-coupons?pageNumber=${currentPage}`
    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState(`${endpoint}`)
    const [search, setSearch] = useState("")
    const [error, setError] = useState(false)

    /* Fetch */
    const fetcher = async (source) => {
        try {
            const response = await api.get(source)
            setError(false)
            return response.data

        } catch (error) {
            setError(true)
        }

    }

    const { data: genCoupons, isLoading: loadingGenCoupons } = useSWR(url,
        fetcher, {
        revalidateOnFocus: false,
    })


    /* Search by code function */
    const HandleSearch = (e) => {
        e.preventDefault()
        const searchValue = search
        if (search.length > 0)
            setUrl(`api/v1/customer-coupons/code/${searchValue}`)
        else
            setUrl(`${endpoint}?pageNumber=${currentPage}${status}`)
    }


    /* Effects */
    useEffect(() => { // calls the function that adapts the api response to facilitate accessing the relevant data

        if (!loadingGenCoupons && genCoupons !== undefined) {
            setLoading(true)
            setAdaptedData(genCouponsAdapter(genCoupons))
            setTotalPages(genCoupons.pages)
            setLoading(false)
        }

    }, [genCoupons, loadingGenCoupons])

    useEffect(() => { // apply status filter on url
        setUrl(`${endpoint}${status}`)
    }, [endpoint, status])


    return (
        <DefaultRoot>

            <MenuEmpresa />

            <BannerTool
                title="Cupons Gerados"
                action={() => history.push("/empresa/meus-cupons")}
                actionTitle="Ver Meus Cupons"
            />

            <main className="max-w-screen-2xl mx-auto px-2">



                {/* Search by Code */}
                <form
                    action=""
                    className="flex flex-col justify-center items-center gap-3 bg-white shadow-lg rounded-xl p-3 pb-3 w-fit mx-auto mt-3 mb-5"
                    onSubmit={(e) => HandleSearch(e)}>
                    <div className="flex items-center justify-center gap-2">
                        <input
                            type="text"
                            placeholder="Pesquisar código"
                            className="bg-transparent border-2 border-black p-2 rounded-xl"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button type="submit" className="bg-secondaryGreen text-white rounded-xl hover:scale-105 duration-100 py-2 px-4">
                            {loadingGenCoupons && search.length > 0 ? <LoadingForButton /> : <MdSearch fontSize={"1.6rem"} />}
                        </button>
                    </div>

                    {search.length !== 0 && (
                        <DefaultButton
                            className={"bg-primaryPurple text-white"}
                            content={"Restaurar Itens"}
                            onClick={() => { setSearch(''); setUrl(endpoint) }}
                        />
                    )}

                </form>

                {/* Status filter */}
                {/* It's only displayed if the search is not active, as the search route does not allow the status parameter */}
                {!search.length > 0 && <StatusFilter setStatus={setStatus} generated={true} />}

                {/* Table */}
                <div className="salesTable px-3">
                    {loading ? (
                        <Loading />
                    ) : (
                        error ? (
                            <span className="text-red-500 font-semibold text-lg flex justify-center mt-5">Cupom inexistente!</span>
                        ) : (
                            <NewTable
                                data={adaptedData}
                                name={`Cupons Gerados Pelos Clientes`}
                                fields={[
                                    {
                                        type: "button",
                                        title: "",
                                        name: "Validar",
                                        disabled: (item) => item.status !== "VÁLIDO",
                                        action: (item) => history.push(`/empresa/validar-cupons/${item.code}`)
                                    },
                                    {
                                        type: "default",
                                        title: "Status",
                                        name: "status",
                                    },
                                    {
                                        type: "default",
                                        title: "Cod. Cupom",
                                        name: "code",
                                    },
                                    {
                                        type: "default",
                                        title: "Cliente",
                                        name: "customerName",
                                    },
                                    {
                                        type: "default",
                                        title: "Produto",
                                        name: "description",
                                    },
                                    {
                                        type: "date",
                                        title: "Gerado Em",
                                        name: "createdAt",
                                    },
                                    {
                                        type: "date",
                                        title: "Usado Em",
                                        name: "statusChangedAt",
                                    },
                                    {
                                        type: "date",
                                        title: "Válido Até",
                                        name: "expirationDate",
                                    },
                                ]}
                            />
                        )

                    )}
                </div>

                {/* Pagination */}
                {!loadingGenCoupons && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages || 1}
                        paginate={setCurrentPage}
                    />
                )}

            </main>

        </DefaultRoot>

    )
}