import moment from "moment/moment"


export default function DateField({ field, item, defaultTd }) {
  return (
    <td
      key={field.name}
      className={defaultTd}
    >
      {moment(item[field.name]).format("DD/MM/YYYY - HH:mm")}
    </td>
  )
}
