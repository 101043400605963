//import moment from "moment/moment";
import CouponRoot from "./CouponRoot";
//import useLink from "../../../hooks/useLink";

export default function EmpresaCoupon({
    store,
    avatar,
    discount,
    description,
    expiration,
    originalPrice,
    id,
    status,
}) {
    //const history = useLink()

    return (
        <CouponRoot /* action={id ? () => history.push(`/empresa/cupom/${id}`) : null} */
        >
            <div className="flex flex-col text-center justify-center items-center gap-2">
                <span className="text-black text-sm w-[100px]">{store}</span>
                <img
                    src={avatar}
                    alt="logo"
                    width={100}
                    className="rounded-md"
                />
            </div>

            <span className="border-dashed border-zinc-950 border-1 mx-3"></span>

            <div className="grid gap-2 text-center w-full items-center">
                <div className="flex justify-center items-center gap-2">
                    <span
                        className={`font-semibold ${status === "ATIVO"
                            ? "text-secondaryGreen"
                            : "text-red-600"
                            }`}
                    >{`Status: ${status}`}</span>
                </div>

                <h2 className="text-base font-semibold">{`${discount}% de Desconto`}</h2>
                {originalPrice > 0 && (
                    <div className="text-base font-semibold flex flex-wrap gap-2 justify-center">
                        <span>
                            De
                        </span>
                        <span className="line-through text-red-600">{`R$ ${originalPrice}`}</span>
                        <span>por</span>
                        <span className="text-secondaryGreen font-bold text-lg">
                            {`R$ ${(originalPrice - (originalPrice * discount) / 100).toFixed(2)}`}
                        </span>
                    </div>
                )}

                <p className="flex justify-center items-center text-sm w-full h-20 overflow-auto">
                    {description}
                </p>

                <span className="text-sm text-secondaryGreen font-semibold">{`Válido até ${new Date(
                    expiration.slice(0, 10)
                ).toLocaleDateString(undefined, {
                    timeZone: "UTC",
                })}`}</span>
            </div>
        </CouponRoot>
    );
}
