import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {function} props.setOpenModal
 * @param {React.ReactNode} props.children
 * @param {string} [props.title]
 * @param {string} [props.subtitle]
 * @param {boolean} [props.unlimitedSize]
 * @param {boolean} [props.noScroll]
 */

export default function Modal(props) {
  return (
    <div>
      <Transition.Root show={props.openModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.setOpenModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-zinc-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-fit max-w-[100vw] mx-auto overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className={twMerge("relative max-h-[85vh] overflow-y-auto transform overflow-hidden rounded-lg text-left transition-all w-full pt-12 pb-5 px-5 bg-white shadow-xl", props.unlimitedSize ? "" : "max-w-xl")}>

                  <div className="absolute right-0 top-0 pr-4 pt-4">
                    <button
                      type="button"
                      className="text-gray-600 bg-white hover:bg-gray-200 p-0.5 rounded-full hover:text-gray-700 focus:outline-none"
                      onClick={() => props.setOpenModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="w-full max-h-[70vh] overflow-y-auto flex flex-col items-center mt-3">

                    {props.title && (
                      <div className="text-center">
                        <h2 className="font-semibold text-green-600">
                          {props.title}
                        </h2>
                      </div>
                    )}

                    {props.subtitle && (
                      <span className="text-center mx-auto w-full">
                        {props.subtitle}
                      </span>
                    )}

                    {props.children}

                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
