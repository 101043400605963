import useLink from '../../hooks/useLink'
import './style.scss'
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid"

export default function BannerTool({ title, action, actionTitle }) {

    const history = useLink()

    return (
        <div className='flex flex-col px-3 -mt-8 bg-gradient-to-r rounded-t-none from-green-600 from-0% via-secondaryGreen via-50% to-green-600 to-95% py-3 mb-3'>
            <button
                className='flex items-center justify-center gap-2 p-2 md:p-3 mb-3 md:mb-0 w-fit bg-white text-black rounded-xl cursor-pointer hover:scale-105 duration-150 shadow-md'
                onClick={() => history.goBack()}>
                <ArrowUturnLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Voltar
            </button>
            <div className="flex justify-content-center">

                <div className="flex flex-col justify-content-center align-items-center mt-2 mb-4 rounded-3xl">
                    <div className="bannerText text-center m-2 text-white">
                        <h1>
                            {title}
                        </h1>
                    </div>

                    {action && (
                        <button
                            className='p-3 bg-white text-black rounded-xl cursor-pointer hover:scale-105 duration-150 shadow-md'
                            onClick={() => action()}>
                            {actionTitle}
                        </button>
                    )}

                </div>
            </div>
        </div>

    )
}