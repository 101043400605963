import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import InitialPage from "../pages/InitialPage/pages/index";
import Logon from "../pages/Logon/index";
import CadastroCliente from "../pages/Cadastro_Cliente/index";

import ManagerCustomer from "../pages/ClientManagement/index";

import Dash_customer from "../pages/Dashboard/Dash_Cliente/index";

import Withdraw_Main from "../components/WithdrawCustomer/Main/index";
import Withdraw_secondary from "../components/WithdrawCustomer/Secondary/index";
import Withdraw_third from "../components/WithdrawCustomer/third/index";

import DashboardEmpresa from "../pages/NewDashEmpresa/index";
import NewPerfil from "../pages/PerfilClientes/clientes";

import CashbackNaNota from "../pages/CashbackNaNota/index";

import PurchasesScreen from "../pages/PurchasesScreen";

import SalesScreen from "../pages/SalesScreen";
import StoresScreen from "../pages/StoresScreen";

import Profile from "../pages/ProfileUsers/index";
import PerfilClienteAvulso from "../pages/PerfilClienteAvulso";

import MyWithdraw from "../pages/Withdrawls/index";
import MyDeposits from "../pages/Deposits/index";
import CreateAvatar from "../pages/CreateAvatar";
import NotFound from "../pages/NotFound";
import EstatisticasEmpresa from "../pages/Estatisticas_Empresa";

// código de verificação de usuário
import VerifyUser from "../pages/VerificationUserPage";

// central de ajuda

import ResetPassword from "../pages/ResetPassword";
// import QRCodeReader from "../pages/QRCodeReader"
import ReceiptsScreen from "../pages/ReceiptsScreen";
import NewReceiptInfo from "../pages/NewReceiptInfo";
import NewReceiptInfoClone from "../pages/NewReceiptInfoClone";
import StoreCategoriesList from "../pages/StoreCategoriesList";
import ProductsRanking from "../pages/ProductsRanking";
import SalesByCategoryList from "../pages/SalesByCategoryList";
import SingleSalesList from "../pages/SingleSalesList";
import ListCoupons from "../pages/Coupons/Customer/ListCoupons";
import CustomerCupons from "../pages/Coupons/Customer/CustomerCoupons";
import CouponPage from "../pages/Coupons/Customer/CouponPage";
import EmpresaCoupons from "../pages/Coupons/Empresa/EmpresaCoupons";
import GeneratedCoupons from "../pages/Coupons/Empresa/GeneratedCoupons";
import ValidateCoupons from "../pages/Coupons/Empresa/ValidateCoupons";
import CreatePromo from "../pages/LoyaltyCards/Store/CreatePromo";
import PromoList from "../pages/LoyaltyCards/Store/PromoList";
import RegistSell from "./../pages/LoyaltyCards/Store/RegistSell/index";
import PromoDetails from "../pages/LoyaltyCards/Store/PromoDetails";
import CustomerPurchases from "../pages/LoyaltyCards/Store/CustomerPurchases";
import PurchasesHistory from "../pages/LoyaltyCards/Store/PurchasesHistory";
import CustomerCards from "../pages/LoyaltyCards/Customer/CustomerCards";
import CardDetails from "../pages/LoyaltyCards/Customer/CardDetails";
import PromoCards from "../pages/LoyaltyCards/Customer/PromoCards";
import BlackFriday from "../pages/BlackFriday";
import Transactions from "../pages/Transactions";
import ParticipatingUsers from "../pages/LoyaltyCards/Store/ParticipatingUsers";
// import { getModules, getRole } from "../services/Data_User";
// import NotAllowed from "../pages/NotAllowed";
import { isAuthenticated } from "../services/auth";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Products from "../pages/Products/Empresa";
import ClientProductsRanking from "../pages/ClientProductsRanking";

export default function Routes() {
    const PrivateRoute = ({ component: Component, module, ...params }) => (
        /*Para ativar os módulos, remova esse Route e descomente o código a seguir */
        <Route
            {...params}
            render={(props) =>
                isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{ pathname: "/", state: { from: props.location } }}
                    />
                )
            }
        />

        /*   <Route
              {...params}
              render={(props) => {

                   if (!module || getModules().includes(module)) {
                      return <Component {...props} />
                  }

                  if (module && !getModules().includes(module)) {
                      return <NotAllowed />
                  }

                  if (getRole() === "customer" || getRole() === "admin") {
                      return <Component {...props} />
                  }
                  else {
                      return (
                          <Redirect
                              to={{ pathname: "/", state: { from: props.location } }}
                          />
                      )
                  }

              }}
          /> */
    );

    return (
        <BrowserRouter>
            <Switch>
                {/* <Route path="*" exact component={Maintenance} /> */}
                <Route path="/blackfriday" exact component={BlackFriday} />
                <Route path="/login" exact component={Logon} />
                <Route path="/" exact component={InitialPage} />
                <Route path="/recuperar-senha" component={ForgotPassword} />
                <Route
                    path="/auth/reset-password/:token"
                    exact
                    component={ResetPassword}
                />
                {/**Cliente*/}
                <Route path="/cliente/cadastro" component={CadastroCliente} />
                <Route
                    path="/cliente/verificar/:phone"
                    component={VerifyUser}
                />
                {/* <Route path="/vasconha" component={QRCodeReader} /> */}
                <PrivateRoute path="/profile" component={Profile} />{" "}
                <PrivateRoute
                    path="/cliente/dashboard"
                    component={Dash_customer}
                />
                <PrivateRoute
                    path="/cliente/compras"
                    component={PurchasesScreen}
                />
                <PrivateRoute
                    path="/cliente/cashbackNota"
                    component={CashbackNaNota}
                />
                <PrivateRoute
                    path="/cliente/notas-fiscais"
                    component={ReceiptsScreen}
                />
                <PrivateRoute
                    path="/cliente/nota-fiscal-info"
                    component={NewReceiptInfo}
                />
                <PrivateRoute
                    path="/cliente/vasconha"
                    component={NewReceiptInfoClone}
                />
                {/* <PrivateRoute
                    path="/cliente/NewDashCliente"
                    component={NewDashCliente}
                /> */}
                <PrivateRoute
                    path="/cliente/solicitar/saque"
                    component={Withdraw_Main}
                />
                <PrivateRoute
                    path="/cliente/solicitar/dados"
                    component={Withdraw_secondary}
                />
                <PrivateRoute
                    path="/cliente/solicitar/confirmar"
                    component={Withdraw_third}
                />
                <PrivateRoute path="/cliente/saques" component={MyWithdraw} />{" "}
                <PrivateRoute
                    path="/cliente/onde-comprar"
                    component={StoresScreen}
                />
                <PrivateRoute path="/cliente/cupons" component={ListCoupons} />
                <PrivateRoute
                    path="/cliente/meus-cupons"
                    component={CustomerCupons}
                />
                <PrivateRoute
                    path="/cliente/cupom/:id"
                    component={CouponPage}
                />
                <PrivateRoute
                    path="/cliente/cartoes-fidelidade"
                    component={CustomerCards}
                />
                <PrivateRoute
                    path="/cliente/detalhes-do-cartao/:cardCode/:cardId"
                    component={CardDetails}
                />
                <PrivateRoute
                    path="/cliente/cartoes-disponiveis"
                    component={PromoCards}
                />
                {/**Empresa*/}
                <PrivateRoute
                    path="/empresa/dashboard"
                    component={DashboardEmpresa}
                />
                <PrivateRoute
                    path="/empresa/estatisticas"
                    component={EstatisticasEmpresa}
                    module={"cashback" || "venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/clientes"
                    component={ManagerCustomer}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/cliente/:customerId/perfil"
                    component={NewPerfil}
                />
                <PrivateRoute
                    path="/empresa/profile/avatar"
                    component={CreateAvatar}
                />
                <PrivateRoute
                    path="/empresa/depositos"
                    component={MyDeposits}
                    module={"cashback"}
                />
                <PrivateRoute
                    path="/empresa/vendas"
                    component={SalesScreen}
                    module={"cashback"}
                />
                <PrivateRoute
                    path="/empresa/produtos-mais-vendidos"
                    component={ProductsRanking}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/lista-vendas-avulsas"
                    component={SingleSalesList}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/vendas-por-categoria/:category"
                    component={SalesByCategoryList}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/clientes-avulsos/:customerPhone/perfil"
                    component={PerfilClienteAvulso}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/produtos-por-cliente/mais-vendidos/:customerId/:customerName"
                    component={ClientProductsRanking}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/minhas-categorias"
                    component={StoreCategoriesList}
                    module={"venda-avulsa"}
                />
                <PrivateRoute
                    path="/empresa/meus-cupons"
                    component={EmpresaCoupons}
                    module={"cupom-desconto"}
                />
                <PrivateRoute
                    path="/empresa/cupons-gerados"
                    component={GeneratedCoupons}
                    module={"cupom-desconto"}
                />
                <PrivateRoute
                    path="/empresa/validar-cupons/:code?"
                    component={ValidateCoupons}
                    module={"cupom-desconto"}
                />
                <PrivateRoute
                    path="/empresa/criar-cartao"
                    component={CreatePromo}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/nova-venda-promocional"
                    component={RegistSell}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/meus-cartoes"
                    component={PromoList}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/detalhes-da-promocao/:promotionId/:promotionName"
                    component={PromoDetails}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/usuarios-participantes/:promotionId/:promotionName"
                    component={ParticipatingUsers}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/historico-por-promocao/:promotionId/:promotionName/:customerName/:customerId/:loyaltyCardId"
                    component={CustomerPurchases}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/historico-por-cliente/:promotionId/:customerName/:customerId"
                    component={PurchasesHistory}
                    module={"cartao-fidelidade"}
                />
                <PrivateRoute
                    path="/empresa/controle-financeiro"
                    component={Transactions}
                    module={"financeiro"}
                />
                <PrivateRoute
                    path="/empresa/produtos"
                    component={Products}
                    module={"vendas-avulsas"}
                />
                <Route path="*" exact component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
}
