/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { getToken } from "../../../services/auth";
import Loading from "../../../components/Loading/index";
import InputMask from "react-input-mask";
import "./styleHistorico.scss";
//import axios from "axios";
//import calen from "./calen.svg";
//import seta from "../img/setatwo.svg";
import setaS from "../img/setas.svg";
import bag from "../img/shopping-bag.svg";
import smile from "../img/smile.svg";
import ticket from "../img/ticket.svg";
import calendar from "../img/calendar.svg";
import perfil from "../img/perfil.svg";
import api from "../../../services/api";
import SimpleCard from "../../../components/SimpleCard/SimpleCard";

function HistoricoCompr(props) {
    // const [user, setUser] = useState({});
    const token = getToken();
    const history = useHistory();

    const customerId = props.customer;
    const customerPhone = props.phone
    const [historyPurchases, setHistoryPurchases] = useState([]);
    const [dateFilter, setDateFilter] = useState();
    const [visibilityButton, setVisibilityButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [averageTicket, setAverageTicket] = useState(0);
    const [PurchasesPerMonth, setPurchasesPerMonth] = useState(0);
    const [comprasPorCategoria, setComprasPorCategoria] = useState([]);

    const mockResponse = {
        data: [
            {
                categoria: "Material Escolar",
                total: 12,
            },
            {
                categoria: "Bebidas",
                total: 8,
            },
            {
                categoria: "Roupas",
                total: 15,
            },
            {
                categoria: "Bijuteria",
                total: 20,
            },
            {
                categoria: "Alimentos",
                total: 48,
            },
        ],
    };

    const getHistory = async () => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerId}/purchases`,
            {
                headers: { Authorization: token },
            }
        );

        setHistoryPurchases(response.data.purchases);
        setIsLoading(false);
    };

    const getPurchasesPerMonth = async () => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerId}/purchases?month=${new Date().getMonth() + 1
            }&year=${new Date().getFullYear()}`,
            {
                headers: { Authorization: token },
            }
        );

        setPurchasesPerMonth(response.data.totalPurchases);
    };

    const getAverageTicket = async () => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerId}/average-ticket`,
            {
                headers: { Authorization: token },
            }
        );

        setAverageTicket(response.data[0].averageTicket.toFixed(2));
    };

    const getHistoryWithFilter = async (month, year) => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerId}/purchases?month=${month}&year=${year}`,
            {
                headers: { Authorization: token },
            }
        );
        setIsLoading(false);
        setHistoryPurchases(response.data.purchases);
    };

    const goBack = useCallback(() => {
        history.push("/empresa/clientes");
    }, [history]);

    const getNumeroDeComprasPorCategoria = async () => {
        try {
            const response = await api.get(
                `api/v1/dashboard/stores/me/customers/${customerPhone}/total-separate-sales-by-category`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log("getTotalSeparateSalesByCategoryForCustomer", response);
            setComprasPorCategoria(response?.data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const validateDateFilter = () => {
        setIsLoading(true);
        const today = new Date();
        const currentYear = today.getFullYear();

        function isValidMonthYear(input) {
            const [month, year] = input.split("/").map((num) => parseInt(num));
            return month >= 1 && month <= 12 && year <= currentYear;
        }

        const pattern = new RegExp("^(0[1-9]|1[0-2])/(\\d{4})$");
        if (pattern.test(dateFilter) && isValidMonthYear(dateFilter)) {
            const month =
                dateFilter[0] !== 0
                    ? dateFilter.substring(0, 2)
                    : dateFilter.substring(1, 2);
            const year = dateFilter.substring(3);

            getHistoryWithFilter(month, year);
        } else {
            setHistoryPurchases([]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getHistory();
        getAverageTicket();
        getPurchasesPerMonth();
        getNumeroDeComprasPorCategoria();
    }, []);

    return (
        <div className="agroupHistoric">
            <div className="boxLeft">
                <div className="containerOne dNone">
                    <div className="boxPerfil">
                        <img width="40px" src={perfil} alt="perfil" />
                        <h2>Perfil</h2>
                    </div>
                    <button className="button" onClick={goBack}>
                        Voltar
                    </button>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "500px",
                        overflowY: "auto",
                    }}
                >
                    <div className="boxOnee mb-3">
                        <article className="efect">
                            <h3>Histórico de Compras</h3>
                        </article>

                        <div className="table-responsive mx-auto py-2 flex gap-4 my-4">
                            {comprasPorCategoria?.map((item) => {
                                return (
                                    <SimpleCard
                                        key={item.categoryId}
                                        content={`${item._id}: ${item.totalSeparateSales}`}
                                        custom={"w-48"}
                                    />
                                );
                            })}
                        </div>

                        <div className="groupData m-3">
                            <div className="form-group">
                                <label htmlFor="dateFilter">
                                    Filtrar por mês/ano
                                </label>
                                <div className="d-flex gap-2">
                                    <InputMask
                                        mask="99/9999"
                                        placeholder="01/2023"
                                        onChange={(e) =>
                                            setDateFilter(e.target.value)
                                        }
                                        id="dateFilter"
                                        className="form-control"
                                    />
                                    {/*  <input className="form-control" onChange={setDateFilterHandler} type="date" id="dateFilter" /> */}
                                    <button
                                        onClick={() => {
                                            validateDateFilter();
                                            setVisibilityButton(true);
                                        }}
                                        className="btn btn-success text-light"
                                    >
                                        Aplicar Filtro
                                    </button>
                                </div>
                                {visibilityButton && (
                                    <button
                                        className="btn btn-success text-light"
                                        style={{ margin: "10px 0" }}
                                        onClick={() => {
                                            getHistory();
                                            setVisibilityButton(false);
                                        }}
                                    >
                                        Retornar Valores
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {isLoading ? <Loading /> : null}
                    {!(isLoading && historyPurchases.length !== 0)
                        ? historyPurchases.map((purchase) => {
                            const dateInfo = purchase.date_timezone;
                            const datetime = `${dateInfo.day}/${dateInfo.month}/${dateInfo.year}`;
                            return (
                                <div
                                    key={purchase._id}
                                    className="historyBox"
                                >
                                    <div className="contHistory">
                                        <img
                                            id="set"
                                            src={setaS}
                                            height="30px"
                                            alt="icone"
                                        />
                                        <h3>R$ {purchase.value}</h3>
                                    </div>
                                    <p>{datetime}</p>
                                </div>
                            );
                        })
                        : null}
                    {historyPurchases.length === 0 ? (
                        <span>
                            Nenhuma compra encontrada no período informado!
                        </span>
                    ) : null}
                </div>
            </div>
            <div className="boxRight">
                <div className="boxApont">
                    <div className="ImageeCircle">
                        <img height="40px" width="40px" src={bag} alt="icone" />
                    </div>
                    <div className="groupArticle">
                        <h3>
                            {historyPurchases.length
                                ? historyPurchases.length
                                : 0}
                        </h3>
                        <p>Compras Efetuadas</p>
                    </div>
                </div>
                <div className="boxApont">
                    <div className="ImageeCircle">
                        <img
                            height="40px"
                            width="40px"
                            src={calendar}
                            alt="icone"
                        />
                    </div>
                    <div className="groupArticle">
                        <h3>{PurchasesPerMonth}</h3>
                        <p>Compras no mês atual</p>
                    </div>
                </div>
                <div className="boxApont">
                    <div className="ImageeCircle">
                        <img
                            height="40px"
                            width="40px"
                            src={smile}
                            alt="icone"
                        />
                    </div>
                    <div className="groupArticle">
                        <h3>Cliente Lucrei</h3>
                        <p>Perfil do cliente</p>
                    </div>
                </div>
                <div className="boxApont">
                    <div className="ImageeCircle">
                        <img
                            height="40px"
                            width="40px"
                            src={ticket}
                            alt="icone"
                        />
                    </div>
                    <div className="groupArticle">
                        <h3>{averageTicket}</h3>
                        <p>Ticket Médio</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HistoricoCompr;
