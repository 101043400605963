import React from "react";
import { StoreCard } from "../StoreCard";

import "./style.scss";

const StoreGrid = ({ data }) => {
  if (data.length > 0)
    return (
      <div className="StoreGrid">
        {data.map((store) => (
          <StoreCard key={store._id} store={store} />
        ))}
      </div>
    );

  return null;
};

export default StoreGrid;
