import { useEffect, useState } from "react";

import moment from "moment";
import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import BannerTool from "../../components/BannerTool";
import MenuEmpresa from "../../components/MenuEmpresa";
import DefaultRoot from "../../components/DeafultRoot";
import MainContent from "../../components/MainContent";
import NewTable from "../../components/NewTable/NewTable";

import api from "../../services/api";


export default function ProductsRanking() {
    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState()
    const [filter, setFilter] = useState({ year: moment().format("YYYY"), month: moment().format("MM") })
    const history = useHistory()

    const chartData = async () => {
        const response = await api.get(`api/v1/separate-sales/best-selling-products?month=${filter.month}&year=${filter.year}`)
        console.log(response.data.data)
        setProducts(response.data.data)
        setIsLoading(false)
    }

    useEffect(() => {
        chartData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    return (
        <DefaultRoot>
            <MenuEmpresa />
            <BannerTool
                title="Produtos mais vendidos"
                action={() => history.push("/empresa/lista-vendas-avulsas")}
                actionTitle={"Ver todas as Vendas Avulsas"}
            />

            <MainContent>

                <section className="salesByCategoryInfo d-flex flex-column align-items-center justify-content-center mt-5">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <NewTable
                            data={{ data: products }}
                            name={`Ranking de produtos`}
                            filter={filter}
                            setFilter={setFilter}
                            fields={[
                                {
                                    type: "default",
                                    title: "Produto",
                                    name: "_id",
                                },
                                {
                                    type: "default",
                                    title: "Número de vendas",
                                    name: "totalQuantity",
                                    altName: "leadCustomerName",
                                },
                                {
                                    type: "money",
                                    title: "Total vendido",
                                    name: "totalSold",
                                },
                                {
                                    type: "date",
                                    title: "Última venda",
                                    name: "latestPurchaseDate",
                                }
                            ]}
                        />
                        // <CardsRender data={products} />
                    )}
                </section>

            </MainContent>
        </DefaultRoot>

    )
}
