import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";

import api from "../../../services/api";

import visivelOne from "../CadVenda/IconsCadVenda/visivel1.png";
import visivelTwo from "../CadVenda/IconsCadVenda/visivel2.png";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import formatCurrency from "../../../utils/format-currency";
import CloseIcon from "@material-ui/icons/Close";
import { logout } from "../../../services/auth";
import x from "../CadVenda/IconsCadVenda/x.svg";

toast.configure();

const EditCategoryModal = ({
    token,
    setShowDepositModal,
    showDepositModal,
    updateUnableToSell,
    handleOnCloseEditCategoryModal,
    category,
    handleEditCategorySubmit,
    newCategoryName,
    setNewCategoryName,
}) => {
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({});
    const history = useHistory();

    useEffect(() => {
        async function getLoggedUser() {
            try {
                const response = await api.get("api/v1/auth/me", {
                    headers: { Authorization: token },
                });

                const { email, name, role, unableToSell } = response.data;

                setUser({ email, name, role, unableToSell });
            } catch (error) {
                const { status } = error.response;
                console.log("error", status);
                if (status === 401) {
                    logout();
                    history.push("/login");
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }
            }
        }
        getLoggedUser();
    }, []);

    const ID_EMPRESA = "id";
    const id = localStorage.getItem(ID_EMPRESA);

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && showDepositModal) {
                setShowDepositModal(false);
            }
        },
        [showDepositModal, setShowDepositModal]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);

        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);

    return (
        <div className="EditCategoryModal">
            <div className="ModalWrapper">
                <button
                    className="closeButton"
                    onClick={handleOnCloseEditCategoryModal}
                >
                    <img src={x} width="20px" />
                </button>
                <div className="headerModal">
                    <div className="CadInfo">
                        <h2>Editar Categoria:</h2>
                        <p>{category.name}</p>
                    </div>
                </div>
                <form
                    onSubmit={handleEditCategorySubmit}
                    className="EditCategoryForm"
                >
                    <div className="groupForms">
                        <div className="group">
                            <label>
                                <span>Digite o novo nome da categoria:</span>
                                <div className="agroupInput">
                                    <input
                                        className="formInput"
                                        placeholder="Novo nome da categoria..."
                                        value={newCategoryName}
                                        onChange={({ target }) =>
                                            setNewCategoryName(target.value)
                                        }
                                    />
                                </div>
                            </label>
                        </div>

                        <div className="buttonGroup">
                            <button
                                type="submit"
                                className="buttonConfirm"
                                disabled={newCategoryName?.length < 2}
                            >
                                Atualizar categoria
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditCategoryModal;
