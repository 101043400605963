export const responseAdapter = (data) => {
    const response = {
        balance: 0,
        expenses: 0,
        incomes: 0,
    }

    if (Array.isArray(data?.incomesOverview)) {
        let counter = 0
        data.incomesOverview.forEach((i) => {
            counter = counter + i.total
        })
        response.incomes = counter
    }

    if (Array.isArray(data?.expensesOverview)) {
        let counter = 0
        data.expensesOverview.forEach((i) => {
            counter = counter + i.total
        })
        response.expenses = counter
    }

    response.balance = response.incomes - response.expenses

    return response
}