export const isInvalidValue = (item, field) => {

  const checkIIfValueIsValid = (value) => [undefined, null].includes(value);

  if (
    checkIIfValueIsValid(item[field.name]) &&
    checkIIfValueIsValid(item[field.altName]) &&
    field.type !== "button" &&
    typeof field.name !== "function"
  )
    return true

  else return false
}