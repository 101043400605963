import React, { useEffect, useState, useCallback } from "react";

import "./style.scss";

import visivelOne from "./IconsCadVenda/visivel1.png";
import visivelTwo from "./IconsCadVenda/visivel2.png";

import api from "../../../services/api";
import CurrencyInput from "react-currency-masked-input";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Step1 from "./IconsCadVenda/primeiro.png";
import Step2_Gray from "./IconsCadVenda/segundo.png";
import Step2_Green from "./IconsCadVenda/two.png";
import { MdKeyboardBackspace } from "react-icons/md";

import x from "./IconsCadVenda/x.svg";

import CircularProgress from "@material-ui/core/CircularProgress";
import { logout } from "../../../services/auth";

toast.configure();

const RegisterSale = ({
    token,
    setShowRegisterSale,
    showRegisterSale,
    updateDebit,
    updateSales,
}) => {
    //states
    const [customerCpf, setCustomerCpf] = useState("");
    const [value, setValue] = useState();
    const [password, setPassword] = useState();
    const [lucreiCode, setLucreiCode] = useState("");

    const [labelClient, setLabelClient] = useState();
    const [step, setStep] = useState(1);
    const [labelCad, setLabelCad] = useState("Insira os dados da venda");

    const [CheckType, setCheckType] = useState("password");
    //const [showhidetext, setshowhidetext] = useState("show")
    const [Control, setControl] = useState(0);
    const [imgShow, setimgShow] = useState(visivelOne);

    const handleShowHide = (e) => {
        if (Control === 0) {
            setCheckType("text");
            setControl(1);
            setimgShow(visivelTwo);
        } else {
            setCheckType("password");
            setControl(0);
            setimgShow(visivelOne);
        }
    };

    //usuario
    const [customer, setCustomer] = useState({});

    //const [user, setUser] = useState({});

    //cashback
    const [labelCashback, setLabelCashback] = useState();
    const cashback = labelCashback / 100;
    const KOBE_TAX = 0;
    const kobeTax1 = localStorage.getItem(KOBE_TAX);

    const [loading, setLoading] = useState(false);

    //validação de dados.
    const [valueError, setValueError] = useState(false);

    const [saleValue, setSaleValue] = useState(null);

    var request = {
        customerCpf,
        lucreiCode,
        value,
        cashback,
        password,
    };

    const history = useHistory();

    async function SaleClient(labelClient) {
        if (labelClient.length === 11 || labelClient.length === 14) {
            let cpf = labelClient.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
            );
            setCustomerCpf(cpf);

            let URL_CPF = `/api/v1/customers/${labelClient}`;
            handleUser(URL_CPF);
        } else if (labelClient.length === 6) {
            setLucreiCode(labelClient);
            let URL_CODE = `/api/v1/customers/${labelClient}?queryBy=lucreiCode`;
            handleUser(URL_CODE);
        } else {
            toast.error("CPF OU CODIGO LUCREI INVALIDO!", {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    async function handleUser(URL) {
        setLoading(true);

        try {
            const response = await api.get(URL, {
                headers: { Authorization: token },
            });

            const { name, city, dateOfBirth } = response.data;
            setCustomer({ name, city, dateOfBirth });
            setSaleValue(value);
            setStep(2);
            setLabelCad("Confirme os dados da venda");
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 404) {
                toast.error("O Cliente não existe no banco de dados", {
                    position: "bottom-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }

            if (status === 403) {
                history.push("/login");
                logout();
                toast.error(
                    "Sessão expirada! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    }
    const [wasSubmitted, setWasSubmitted] = useState(false);

    async function handleRegisterSale(e) {
        e.preventDefault();

        if (!wasSubmitted) {
            setWasSubmitted(true);
            setLoading(true);
            try {
                const response = await api.post("api/v1/sales", request, {
                    headers: { Authorization: token },
                });

                setCustomerCpf("");
                setPassword("");
                setValue("");
                setLabelCashback("");

                setShowRegisterSale((prev) => !prev);

                toast.success("Compra cadastrada com sucesso.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                });

                setShowRegisterSale(false);

                setLoading(false);
                updateDebit();
                updateSales();
            } catch (error) {
                //const { data } = error?.response?;
                setLoading(false);
                setWasSubmitted(false);

                if (
                    error?.response?.data.status === 400 &&
                    error?.response?.data.message === "Customer not found!"
                ) {
                    toast.error("O Cliente não existe no banco de dados", {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                if (!window.navigator.onLine) {
                    toast.error(
                        "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }
                if (error?.response?.data.status === "fail") {
                    toast.error(
                        "Senha incorreta! Verifique seus dados e tente novamente.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }

                if (error?.response?.data.status === 500) {
                    toast.error(
                        "Erro de servidor! Entre em contato com a Kobe",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }
            }
        }
    }

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && showRegisterSale) {
                setShowRegisterSale(false);
            }
        },
        [showRegisterSale, setShowRegisterSale]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);

        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);

    const handleCurrencyInputBlur = ({ target }) => {
        if (target.value === "" || target.value === "0.00") {
            setValueError("Preencha um valor.");
            console.log(valueError);
        }
    };

    const onChangeCurrencyValue = ({ target }) => {
        const d = target.value.replace(/\D/, "");
        setValueError(false);
        console.log(valueError);
        const e = d / 100;
        if (saleValue) setSaleValue(null);
        setValue(e);
    };

    const handleStepBack = () => {
        setSaleValue(saleValue);
        setStep(1);
    };

    const cashbackReais = (labelCashback / 100) * value;
    const comissaoTotal = kobeTax1 * cashbackReais + cashbackReais;
    const kobeTaxa = kobeTax1 * cashbackReais;

    const taxaAdm = parseFloat(kobeTaxa.toFixed(2)).toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 },
        { style: "currency", currency: "BRL" }
    );
    const cashbackConvertido = parseFloat(
        cashbackReais.toFixed(2)
    ).toLocaleString(
        "pt-br",
        { minimumFractionDigits: 2 },
        { style: "currency", currency: "BRL" }
    );

    const labelAlert = `O cashback atribuído é no valor de R$${cashbackConvertido} reais.`;

    const checkTokenValidation = async () => {
        try {
            const response = await api.post(`/api/v1/auth/token-validate`, {
                headers: { Authorization: token },
            });
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 403) {
                history.push("/login");
                logout();
                toast.error(
                    "Sessão expirada! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    useEffect(() => {
        checkTokenValidation();
    }, []);

    return (
        <div className="ModalCadastro">
            <div className="ModalWrapper">
                <div className="relativePositionButton">
                    <button
                        className="closeButton"
                        onClick={() => setShowRegisterSale(false)}
                    >
                        <img src={x} width="20px" alt="close" />
                    </button>
                </div>

                <div className="headerModal">
                    <div className="CadInfo">
                        <h2>Cadastrar Venda:</h2>
                        <p className="labelCad">{labelCad}</p>
                    </div>
                </div>
                <form className="Salesform" onSubmit={handleRegisterSale}>
                    {step === 1 && (
                        <div>
                            <div className="StepTracker">
                                <div className="Step1">
                                    <img width="25" src={Step1} alt="" />
                                    <p>1º PASSO</p>
                                </div>
                                <div className="Step2Gray">
                                    <img width="25" src={Step2_Gray} alt="" />
                                    <p>2º PASSO</p>
                                </div>
                            </div>
                            <div className="groupHeader">
                                <label>
                                    <span>CPF ou CÓDIGO LUCREI:</span>
                                </label>
                                <input
                                    type="text"
                                    className="formInput"
                                    required
                                    minlength="6"
                                    maxlength="14"
                                    onChange={(e) =>
                                        setLabelClient(e.target.value)
                                    }
                                    value={labelClient}
                                />
                            </div>
                            <div className="row">
                                <div className="group">
                                    <p>Valor da compra:</p>
                                    <CurrencyInput
                                        className="formInputValue"
                                        onChange={onChangeCurrencyValue}
                                        onBlur={handleCurrencyInputBlur}
                                        type="number"
                                        value={
                                            saleValue !== null
                                                ? saleValue
                                                : null
                                        }
                                    />
                                </div>
                                <div className="group">
                                    <p>% de cashback:</p>
                                    <input
                                        className="formInputValue"
                                        required
                                        onChange={(e) =>
                                            setLabelCashback(e.target.value)
                                        }
                                        value={labelCashback}
                                    />
                                </div>
                            </div>

                            {labelCashback > 0 && (
                                <div className="AlertKobe">
                                    <p>
                                        O cashback atribuído é no valor de{" "}
                                        <strong id="strong">
                                            R$ {cashbackConvertido}
                                        </strong>{" "}
                                        reais.
                                    </p>
                                </div>
                            )}

                            <div className="buttonGroup">
                                <button
                                    type="button"
                                    onClick={() => {
                                        if (labelCashback?.includes("%")) {
                                            toast.error(
                                                "Remova o caracter % do valor de cashback!",
                                                {
                                                    position: "top-right",
                                                    autoClose: 4000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                }
                                            );
                                        } else if (labelCashback <= 100) {
                                            SaleClient(labelClient);
                                        } else {
                                            toast.error(
                                                "Valor de cashback invalido!!",
                                                {
                                                    position: "top-right",
                                                    autoClose: 8000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                }
                                            );
                                        }
                                    }}
                                    className="p-3 mx-auto mt-4 text-base font-semibold rounded-3xl bg-primaryGreen w-4/5 hover:scale-105 duration-300"
                                >
                                    {loading ? (
                                        <CircularProgress
                                            color="primary"
                                            size={21}
                                        />
                                    ) : (
                                        "Avançar"
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <div className="StepTracker">
                                <div className="Step1">
                                    <img width="25" src={Step1} alt="" />
                                    <p>1º PASSO</p>
                                </div>
                                <div className="Step2Green">
                                    <img width="25" src={Step2_Green} alt="" />
                                    <p>2º PASSO</p>
                                </div>
                            </div>
                            <div className="clienteCard">
                                <div className="clienteInfo">
                                    <label>
                                        {customer.name ||
                                            "Preencha os dados da venda "}
                                    </label>
                                    <label>{customerCpf || " "}</label>
                                </div>
                                <div className="compraInfo">
                                    <div className="colEsq text-base">
                                        <label>Valor:</label>
                                        <label>% de Cashback:</label>
                                    </div>
                                    <div className="colDir text-base">
                                        <label>{`R$ ${value || 0}`}</label>
                                        <label>{labelCashback || 0}%</label>
                                    </div>
                                </div>
                                {/* <label>Total:</label> */}
                                <div className="totalInfo text-base">
                                    <div className="colEsq">
                                        <label>Cashback:</label>
                                    </div>
                                    <div className="colDir">
                                        <label className="cashback-value">
                                            {" "}
                                            R$
                                            {` ` +
                                                parseFloat(
                                                    cashbackReais.toFixed(2)
                                                ).toLocaleString(
                                                    "pt-br",
                                                    {
                                                        minimumFractionDigits: 2,
                                                    },
                                                    {
                                                        style: "currency",
                                                        currency: "BRL",
                                                    }
                                                )}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="senhaInput">
                                <label>Senha:</label>
                                <div className="inputShowPass text-base">
                                    <input
                                        type={CheckType}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder="Digite sua senha"
                                        value={password}
                                    />
                                    <img
                                        onClick={() => handleShowHide()}
                                        src={imgShow}
                                        height="27px"
                                    ></img>
                                </div>
                            </div>

                            <div className="buttonGroup gap-2">
                                <button
                                    type="submit"
                                    className="p-3 mx-auto text-base font-semibold rounded-3xl bg-primaryGreen w-4/5 hover:scale-105 duration-300"
                                >
                                    {loading ? (
                                        <CircularProgress
                                            color="primary"
                                            size={21}
                                        />
                                    ) : (
                                        "Concluir"
                                    )}
                                </button>
                                <button
                                    onClick={handleStepBack}
                                    className="buttonCancel"
                                >
                                    <MdKeyboardBackspace />
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default RegisterSale;
