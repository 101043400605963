import useSWR from "swr"
import Loading from "../../../../components/Loading"
import NewTable from "../../../../components/NewTable/NewTable"
import fetcher from "../../../../services/fetcher"

export default function SalesTable() {

    const { data: sales, isLoading: loadingSales } = useSWR(
        "api/v1/stores/me/sales?pageSize=5&sortBy=-createdAt", fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    return (
        loadingSales ? <Loading /> : (
            <NewTable
                data={sales}
                name={"Compras Recentes"}
                fields={[
                    {
                        type: "default",
                        title: "CodLucrei",
                        name: "lucreiCode"
                    },
                    {
                        type: "default",
                        title: "Cliente",
                        name: "customerName",
                        altName: "leadCustomerName"
                    },
                    {
                        type: "phone",
                        title: "Telefone",
                        name: "customerPhone"
                    },
                    {
                        type: "date",
                        title: "Data da Compra",
                        name: "createdAt",
                    },
                    {
                        type: "money",
                        title: "Valor da Compra",
                        name: "value"
                    },
                    {
                        type: "money",
                        title: "Cashback",
                        name: "cashback"
                    }
                ]}
            />
        )
    )
}