export default function MoneyField({ field, item, defaultTd }) {
  return (
    <td
      key={field.name}
      className={defaultTd}
    >
      {
        item[field.name]
        && `R$ ${item[field.name].toFixed(2)}`
      }
    </td>
  )
}