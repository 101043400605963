import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../../../../services/api";
import CreateForm from "./CreateForm";
import ConfigForm from "./ConfigForm";
import LoadingForButton from "../../../../../components/LoadingForButton";

export default function Form() {
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState({});

    const formik = useFormik({
        initialValues: {
            title: "",
            description: "",
            salesNumber: "",
            minValueSale: "",
            /* endDate: "", */
            notifyCustomers: false,
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const formData = new FormData();
        formData.append("photo", image.raw);
        formData.append("title", formik.values.title);
        formData.append("description", formik.values.description);
        formData.append("minValueSale", formik.values.minValueSale);
        formData.append("salesNumber", formik.values.salesNumber);
        formData.append("notifyCustomers", formik.values.notifyCustomers);

        try {
            //console.log(dataToSubmit)
            await api.post("/api/v1/loyalty-card-promotions", formData);

            formik.resetForm();
            setImage({});

            toast.success(
                "Sua Promoção de Cartão Fidelidade foi criada com sucesso!",
                {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );

            setLoading(false);
        } catch (error) {
            const { status, data } = error.response;

            console.log("error", { error });
            if (status === 400) {
                toast.error(data?.message, {
                    position: "top-right",
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
    };
    return (
        <form
            className="flex flex-col items-center justify-center gap-3 w-screen"
            onSubmit={handleSubmit}
        >
            <CreateForm formik={formik} image={image} setImage={setImage} />
            <ConfigForm formik={formik} />

            <button
                className="
                        px-5 py-3 mx-auto
                        text-xl font-semibold
                        rounded-xl bg-primaryGreen
                        w-fit
                        hover:scale-105 duration-300"
                type="submit"
            >
                {loading ? <LoadingForButton /> : "Cadastrar"}
            </button>
        </form>
    );
}
