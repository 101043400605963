import moment from "moment/moment";
import CouponRoot from "./CouponRoot";
import QRCode from "react-qr-code";
import useLink from "../../../hooks/useLink";

export default function CustomerCoupon({
    status,
    code,
    store,
    avatar,
    discount,
    description,
    expiration,
    originalPrice,
    id,
    statusChangedAt,
    action,
    loading,
    update
}) {
    const history = useLink();

    return (
        <CouponRoot
            action={id ? () => history.push(`/cliente/cupom/${id}`) : null}
        >
            <div className="flex flex-col text-center justify-center items-center gap-3">
                <QRCode
                    size={200}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={code}
                    viewBox={`0 0 256 256`}
                />
                <span className="font-semibold">ID: {code}</span>
            </div>

            <span className="border-dashed border-zinc-950 border-1 mx-3"></span>

            <div className="grid gap-2 text-center w-full items-center">
                <div className="flex justify-center items-center gap-2">
                    <span className="text-black text-sm w-[100px]">
                        {store}
                    </span>
                    <img
                        src={avatar}
                        alt="logo"
                        width={50}
                        className="rounded-md"
                    />
                </div>

                <h2 className="text-base font-semibold">{`${discount}% de Desconto`}</h2>
                {originalPrice > 0 && (
                    <div className="text-base font-semibold flex flex-wrap gap-2 justify-center">
                        <span>
                            De
                        </span>
                        <span className="line-through text-red-600">{`R$ ${originalPrice}`}</span>
                        <span>por</span>
                        <span className="text-secondaryGreen font-bold text-lg">
                            {`R$ ${(originalPrice - (originalPrice * discount) / 100).toFixed(2)}`}
                        </span>
                    </div>
                )}

                <p className="flex justify-center items-center text-sm w-full h-20 overflow-auto">
                    {description}
                </p>

                <span
                    className={`font-semibold ${status === "VÁLIDO"
                        ? "text-secondaryGreen"
                        : "text-red-600"
                        }`}
                >{`Status: ${status}`}</span>

                {statusChangedAt && (
                    <span className="text-sm text-red-600 font-semibold">{`Usado em ${moment(
                        statusChangedAt
                    ).format("DD/MM/YYYY")}`}</span>
                )}

                <span className="text-sm text-primaryPurple font-semibold">{`Válido até ${new Date(
                    expiration?.slice(0, 10)
                ).toLocaleDateString(undefined, {
                    timeZone: "UTC",
                })}`}</span>

                {status === "VÁLIDO" && (
                    id && (
                        <button
                            className="bg-red-600 text-white p-2 hover:scale-105 duration-150 rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                action(id)
                                update()
                            }}
                            disabled={loading}
                        >
                            {loading === id ? (
                                <div
                                    className="spinner-border text-white"
                                    role="status"
                                >
                                    <span className="sr-only"></span>
                                </div>
                            ) : (
                                "Excluir Cupom"
                            )}
                        </button>
                    )
                )}
            </div>
        </CouponRoot>
    );
}
