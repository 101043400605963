const formatCurrency = (value) => {
  if (value != undefined) {
    const formatedValue = value.toLocaleString(
      "pt-br",
      {
        minimunFractionDigits: 2
      },
      {
        style: "currency",
        currency: "BRL"
      }
    )

    return formatedValue
  }

  return "..."

};

export default formatCurrency;