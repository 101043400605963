import { useState } from "react";
import "./style.scss";
import api from "../../services/api";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useForm from "../../hooks/useForm";
import Logo from "../../assets/logo-green.png";

function ForgotPassword(props) {
    const [token, setToken] = useState(null);
    const inputCredential = useForm("email");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!inputCredential.validate()) return;

            setIsLoading(true);
            const response = await api.post(
                "api/v1/auth/forgot-password",
                {
                    credential: inputCredential.value,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            );

            if (response.status === 200) {
                history.push("/login");
                toast.success(
                    "Email enviado! Verifique sua caixa de entrada ou spam.",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    },
                );
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="ForgotPasswordPage">
            <main className="content">
                <img
                    className="logo"
                    src={Logo}
                    alt="Logo da Lucrei Cashback"
                />

                <h1>Redefinir senha</h1>
                <p>
                    Nós te enviaremos um email com instruções de como resetar a
                    sua senha.
                </p>
                <form onSubmit={handleSubmit} className="form-forgot-password">
                    <div className="form-group">
                        <label for="credential">
                            Meu email usado na Lucrei:
                        </label>
                        <input
                            className="campo"
                            onChange={inputCredential.onChange}
                            value={inputCredential.value}
                            onBlur={inputCredential.onBlur}
                            id="credential"
                            placeholder="josealmeida10@gmail.com"
                            required
                            type="text"
                        />

                        <div className="form-group">
                            <button
                                className="btn-update-password forgot-password"
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? "Enviando..." : "Enviar email"}
                            </button>
                        </div>
                    </div>

                    <div className="toggle-password-wrapper">
                        <Link to="https://api.whatsapp.com/send?phone=5585991949020&text=Olá, Suporte! Não lembro meu email, pode me ajudar a recuperar a minha senha?">
                            Não lembro o meu email
                        </Link>
                    </div>
                </form>
            </main>
        </div>
    );
}

export default ForgotPassword;
