import { FaWhatsapp } from "react-icons/fa"

export default function PhoneField({ field, item, defaultTd }) {
  return (
    <td
      key={field.title}
      className={`${defaultTd}`}
    >
      <a
        className="flex gap-1 text-secondaryGreen text-center hover:text-primaryPurple"
        target="_blank"
        rel="noreferrer"
        href={`https://api.whatsapp.com/send?phone=55${item[field.name] || item[field.altName]}`}
      >
        <FaWhatsapp className="h-5 w-5 cursor-pointer text-green-500 " />
        {item[field.name] || item[field.altName]}
      </a>
    </td>
  )
}