import { useContext, useEffect, useState } from "react";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/20/solid";

import Loading from "../../../components/Loading/index";
import Pagination from "../../../components/Pagination";
import NewTable from "../../../components/NewTable/NewTable";

import { TransactionsContext } from "../context/context";

import ConfirmDelete from "./ConfirmDelete";
import NewTransaction from "./NewTransaction";
import TransactionEdit from "./TransactionEdit";
import TransactionTypeFilter from "./TransactionTypeFilter";
import NewCategory from "./NewCategory";

import fetcher from "../../../services/fetcher";

export default function TransactionsStatement({
    updateStats,
    setCategoryModalOpen,
}) {
    /* Filters */
    const { filter } = useContext(TransactionsContext); // Period Filter
    const [typeFilter, setTypeFilter] = useState(false); // Type Filter

    /* Modals Controls */
    const [confirmDelOpen, setConfirmDelOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    /* Pagination Controls */
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [itemToDelete, setItemToDelete] = useState(null); // Stores the id of the transaction to be deleted
    const [itemToEdit, setItemToEdit] = useState({
        type: "",
        category: "",
        value: "",
    }); // Stores the object of the transaction to be deleted

    // Data fetching with SWR
    const { data, isLoading } = useSWR(
        `api/v1/transactions?month=${filter.month}&year=${filter.year
        }&page=${currentPage}${typeFilter && "&type=" + typeFilter}`,
        fetcher,
        { revalidateOnFocus: false }
    );

    const { data: categories, isLoading: loadingCategories } = useSWR(
        `api/v1/transactions-categories`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    // SWR Mutation Trigger - Revalidates selected key when fired
    const { trigger: updateTransactions } = useSWRMutation(
        `api/v1/transactions?month=${filter.month}&year=${filter.year
        }&page=${currentPage}${typeFilter && "&type=" + typeFilter}`,
        fetcher
    );

    const { trigger: updateCategories } = useSWRMutation(
        `api/v1/transactions-categories`,
        fetcher
    );

    // Handle functions
    const handleDelete = (item) => {
        setItemToDelete(item._id);
        setConfirmDelOpen(true);
    };

    const handleEdit = (item) => {
        setItemToEdit(item);
        setEditOpen(true);
    };

    // Pagination Effect
    useEffect(() => {
        if (!isLoading) {
            setTotalPages(data?.pages);
        }
    }, [data, isLoading]);

    return (
        <div className="w-full">
            {/* Modals */}
            <NewTransaction
                updateTransactions={updateTransactions}
                updateStats={updateStats}
                setCategoryModalOpen={setCategoryModalOpen}
                categories={categories}
                isLoading={loadingCategories}
                updateCategories={updateCategories}
            />

            <NewCategory updateCategories={updateCategories} />

            <ConfirmDelete
                open={confirmDelOpen}
                control={setConfirmDelOpen}
                item={itemToDelete}
                updateTransactions={updateTransactions}
                updateStats={updateStats}
            />

            <TransactionEdit
                open={editOpen}
                control={setEditOpen}
                categories={categories}
                isLoading={loadingCategories}
                item={itemToEdit}
                updateTransactions={updateTransactions}
                updateStats={updateStats}
            />

            {/* Filter */}
            <TransactionTypeFilter
                filter={typeFilter}
                control={setTypeFilter}
            />

            {/* Table */}
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <NewTable
                        data={data}
                        name={`Minhas Transações`}
                        fields={[
                            {
                                type: "default",
                                title: "Tipo",
                                name: "type",
                                className: (item) =>
                                    item.type === "ENTRADA/RECEITA"
                                        ? "text-secondaryGreen"
                                        : "text-red-500",
                            },
                            {
                                type: "default",
                                title: "Categoria",
                                name: "category",
                            },
                            {
                                type: "money",
                                title: "Valor",
                                name: "value",
                            },
                            {
                                type: "date",
                                title: "Data",
                                name: "createdAt",
                            },
                            {
                                type: "button",
                                title: "Editar",
                                name: (
                                    <PencilSquareIcon
                                        className="h-6 w-6 text-secondaryGreen"
                                        aria-hidden="true"
                                    />
                                ),
                                disabled: (item) =>
                                    item.category === "VENDA AVULSA" ||
                                    item.category === "VENDA COM CASHBACK",
                                action: (item) => item && handleEdit(item),
                                className: "bg-transparent",
                            },
                            {
                                type: "button",
                                title: "Excluir",
                                name: (
                                    <TrashIcon
                                        className="h-6 w-6 text-red-500"
                                        aria-hidden="true"
                                    />
                                ),
                                disabled: (item) =>
                                    item.category === "VENDA AVULSA" ||
                                    item.category === "VENDA COM CASHBACK",
                                action: (item) => item && handleDelete(item),
                                className: "bg-transparent",
                            },
                        ]}
                    />
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={setCurrentPage}
                    />
                </>
            )}
        </div>
    );
}
