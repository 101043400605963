import { HeartIcon } from "@heroicons/react/24/solid"
import React from "react"
import { FcLike } from "react-icons/fc"

export default function LucreiCode({ userName, lucreiCode }) {
  return (
    <section className="w-full flex flex-col gap-3 rounded-lg shadow-md ring-1 ring-primaryGreen p-3 justify-between items-center text-center bg-gradient-to-l from-emerald-50 from-5% via-white via-40% to-purple-50 ">

      <div className="flex items-center justify-center gap-2 mb-2">

        {userName ? (
          <React.Fragment>
            <HeartIcon className="w-7 h-7 text-secondaryGreen" />
            <h2 className="text-xl font-normal m-0">{`Olá, ${userName}!`}</h2>
          </React.Fragment>
        ) : (
          <div className="bg-zinc-200 animate-pulse rounded-md w-24 h-7"></div>
        )}

      </div>

      <div className="flex items-center justify-center gap-2">
        {lucreiCode ? (
          <React.Fragment>
            <p className="m-0 sm:text-sm md:text-base">Seu código Lucrei:</p>
            <h3 className="text-xl font-semibold m-0 text-white bg-secondaryGreen px-1 rounded-sm">{lucreiCode}</h3>
          </React.Fragment>
        ) : (
          <div className="bg-zinc-200 animate-pulse rounded-md w-36 h-7"></div>
        )}

      </div>

    </section>
  )
}