import useSWR from "swr";
import fetcher from "../../../services/fetcher";
import { useState, useEffect } from "react";
import NewTable from "../../../components/NewTable/NewTable";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination";

export default function SingleSalesTable({ modal }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data: sales, isLoading: loadingSales } = useSWR(
        `api/v1/separate-sales?page=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        sales && setTotalPages(sales.pages);
    }, [sales]);

    return (
        <>
            {loadingSales ? (
                <Loading />
            ) : (
                <NewTable
                    data={sales}
                    name={`Vendas Avulsas`}
                    fields={[
                        {
                            type: "button",
                            title: "",
                            name: "Excluir",
                            disabled: (item) => item.cashback > 0,
                            action: (item) => modal(true, item),
                            className: "bg-red-500",
                        },
                        {
                            type: "date",
                            title: "Data da Venda",
                            name: "createdAt",
                        },
                        {
                            type: "array",
                            title: "Produtos(s)",
                            name: "products",
                        },
                        {
                            type: "money",
                            title: "Valor",
                            name: "value",
                        },
                        {
                            type: "default",
                            title: "Cliente",
                            name: "customerName",
                            altName: "leadCustomerName",
                        },
                        {
                            type: "phone",
                            title: "Telefone",
                            name: "customerPhone",
                        },
                        {
                            type: "default",
                            title: "Descrição",
                            name: "description",
                        },
                        {
                            type: "default",
                            title: "Tipo de Pagamento",
                            name: "paymentType",
                        },
                        {
                            type: "money",
                            title: "Cashback",
                            name: "cashback",
                        },
                    ]}
                />
            )}

            {/* Pagination */}
            {!loadingSales && (
                <table className="mx-auto mt-5">
                    <tbody>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages || 1}
                            paginate={setCurrentPage}
                        />
                    </tbody>
                </table>
            )}
        </>
    );
}
