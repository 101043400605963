import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import ctl from "@netlify/classnames-template-literals";
import "./CpfUpdate.scss";

import LoadingForButton from "../../../components/LoadingForButton";

import api from "../../../services/api";
import useForm from "../../../hooks/useForm";
import { useState } from "react";

export default function CpfUpdate() {
    const inputCpf = useForm("cpf");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            await api.patch("/api/v1/auth/customers/me/basic-info/cpf", {
                cpf: inputCpf.value,
            });
            toast.success("CPF atualizado com sucesso!", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            const { status, data } = error.response;

            console.log("error", { error });
            if (status === 400) {
                toast.error(data?.message, {
                    position: "top-right",
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } finally {
            setIsLoading(false);
            inputCpf.setValue("");
        }
    };

    return (
        <section className="rounded-xl drop-shadow-md bg-white p-8 flex flex-col justify-center items-center">
            <h2 className="font-semibold text-2xl w-full text-start">
                Atualize seus dados cadastrais
            </h2>
            <p className="w-full text-start text-zinc-600">
                Aproveite todos os benefícios da Lucrei (Cashback, Dinheiro na
                nota, cupons de desconto e muito mais) ao completar o seu
                cadastro.
            </p>
            <form onSubmit={handleSubmit} className="w-full flex flex-col">
                <label class="form-label">CPF</label>
                <InputMask
                    className="form-control"
                    onChange={inputCpf.onChange}
                    value={inputCpf.value}
                    onBlur={inputCpf.onBlur}
                    mask="999.999.999-99"
                    id="cpf"
                    type="text"
                    name="cpf"
                    placeholder="Ex: 000.000.000-00"
                    required
                />
                {inputCpf.error && (
                    <span className="error">{inputCpf.error}</span>
                )}

                {/**
               * <InputMask
                                    className="form-control"
                                    onChange={inputCpf.onChange}
                                    value={inputCpf.value}
                                    onBlur={inputCpf.onBlur}
                                    mask="999.999.999-99"
                                    id="campo_CPF"
                                    maxLength="16"
                                    type="text"
                                    name="cpf"
                                    placeholder="Ex: 000.000.000-00"
                                    required
                                />
               *
               */}

                <div className="flex justify-end w-full">
                    <button
                        disabled={inputCpf.error || isLoading}
                        className={ctl(`
            px-3 py-2.5 mt-4
            text-base text-white font-semibold
            rounded-xl bg-secondaryGreen
            hover:scale-105 duration-300 btn-atualiza-cpf`)}
                        type="submit"
                    >
                        {isLoading ? <LoadingForButton /> : "Atualizar meu CPF"}
                    </button>
                </div>
            </form>
        </section>
    );
}
