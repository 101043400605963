import React, { useState } from "react";
import api from "../../services/api";
import logoVerde from '../../assets/logoCircular.png';
import "./style.css"

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";


export default function VerificacaoCliente(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [inputCode, setInputCode] = useState();
    
    
    const verifySMSCode = async(e) => {
        setLoading(true);
        e.preventDefault();
        const confirmPhone = props.match.params.phone;
        // console.log(confirmPhone);

        // const verifyTypeCode = document.getElementById("confirmationCode").getAttribute("value")
        // Number(verifyTypeCode)
        // console.log(verifyTypeCode)

        const data = {
            phone: confirmPhone,
            code: inputCode,
        }

        try {
            const response = await api.post(
                "api/v1/auth/confirm-phone", data
            );

            
            if (response.status === 200) {
                toast.success("Número de telefone confirmado com sucesso!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            setLoading(false);
            history.push('/login')

        } catch (error) {
            const { status, data } = error.response;
            const { message } = data;

            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if(status && status === 400){
                toast.error(
                    "Código inválido! Verifique novamente ou entre em contato com o suporte da Lucrei.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if(status && status === 404){
                toast.error(
                    "Código inválido! Verifique novamente ou entre em contato com o suporte da Lucrei.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
            setLoading(false);
        }

    }

    const resendSMSCode = async() =>{
        setLoading(true);
        // e.preventDefault();
        const confirmPhone = props.match.params.phone;

        const data = {
            phone: confirmPhone
        }

        try{
            const response = await api.post("api/v1/auth/resend-code", data)

            if (response.status === 200) {
                toast.success("Código reenviado, cheque sua caixa de mensagens!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            setLoading(false);

        }catch(error){
            const { status, data } = error.response;
            const { message } = data;

            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if(status && status === 404){
                toast.error(
                    "Número de telefone não foi encontrado!",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if(status && status === 400){
                toast.error(
                    "Número de telefone inválido!",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
            setLoading(false);
        }
    }
    
    
    return (
        <>
        <div className="VerificacaoWrapper">
            <div className="VerificacaoBox">
                <div className="LogoWrapper">
                    <img src={logoVerde} alt="Logo da Lucrei" />
                </div>
                <div className="SmsCodeWrapper">
                    <p>Digite o código de 4 dígitos enviado por SMS para o celular informado, para confirmar seu número de contato</p>
                    <form onSubmit={verifySMSCode}>    
                        <input 
                        id="confirmationCode" 
                        className="campo" 
                        type="text"
                        minLength={4}
                        maxLength={4}
                        onChange={(e) => {
                            setInputCode(e.target.value);
                        }}
                        value={inputCode}
                        required
                        /> 
                    
                        <div className="ButtonGroup">
                            <button type="submit" disabled={loading ? (true) : (false)} className="btnSendCode">
                                {loading 
                                ? (<CircularProgress color="primary" size={21}/>)
                                : ("Enviar Código")
                                }
                            </button>
                        </div>
                    </form>
                    <div className="ButtonGroup">
                        <button className='btnResendCode' disabled={loading ? (true) : (false)} onClick={() => resendSMSCode()}>Reenviar Código</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
