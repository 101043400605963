import "./styleText.scss";

function TextSupport() {
    return (
        <div className="containerText">
            <div className="groupTextModal">
                <h2>
                    1. Como faço para ganhar cashback com minha nota fiscal?
                </h2>
                <p>
                    É muito simples, basta você após comprar em um
                    estabelecimento que lhe entregue uma nota fiscal, você deve
                    clicar no botão “Envie a sua nota!” acima, escolher uma das
                    opções de envio e nos enviar a sua nota fiscal. Após isso, a
                    sua nota passará por uma análise, é feita a validação da
                    nota, e o valor vai cair diretamente na sua conta Lucrei.
                </p>
            </div>
            <div className="groupTextModal">
                <h2>2. Quais empresas eu posso cadastrar minhas notas?</h2>
                <p>
                    Você poderá cadastrar suas compras em qualquer empresa que
                    no ato de sua compra lhe entregue um cupom fiscal. A única
                    regra é que essa empresa precisa estar situada em uma das
                    cidades disponíveis no nosso cadastro de usuário no sistema.
                </p>
            </div>
            <div className="groupTextModal">
                <h2>3. Posso cadastrar várias notas todos os dias?</h2>
                <p>
                    Não, o limite de notas enviadas é de 1 nota por dia, por
                    usuário.
                </p>
            </div>
            <div className="groupTextModal ">
                <h2>
                    4. Quais motivos podem levar minha nota a não ser validada?
                </h2>
                <p>
                    Vamos lá, existem alguns motivos que podem levar a sua nota
                    a não ser aceita para que você não receba seu cashback, são
                    eles:
                </p>
                <ul>
                    <li>
                        Compra efetuada em cidades que não pertencem às cidades
                        de cadastro da Lucrei;
                    </li>
                    <li>
                        Limite diário de notas enviadas pelo usuário excedido (3
                        notas por dia, por usuário);
                    </li>
                    <li>Código da nota fiscal inválido no envio;</li>
                    <li>Foto da nota fiscal com informações ilegíveis.</li>
                </ul>
            </div>
        </div>
    );
}

export default TextSupport;
