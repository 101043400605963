import { FcShop } from "react-icons/fc"
import { ShoppingCartIcon } from "@heroicons/react/24/outline"
import useLink from "../../../../hooks/useLink"

export default function Stores() {

    const history = useLink()

    return (
        <section className="flex flex-col justify-between items-center gap-3 text-center p-3 rounded-lg shadow-md bg-white">
            <div className="flex items-center justify-center gap-3 mb-2">
                <FcShop className="w-7 h-7" />
                <h2 className="text-xl font-normal m-0">Empresas Parceiras</h2>
            </div>

            <p>Para ganhar mais dinheiro e aumentar o saldo, é só comprar nos nossos parceiros!</p>

            <button
                type="button"
                className="inline-flex items-center gap-x-2 w-fit rounded-md bg-secondaryGreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                onClick={() => history.push("/cliente/onde-comprar")}
            >
                <ShoppingCartIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Verificar Parcerias
            </button>
        </section>
    )
}