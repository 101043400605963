import React, { useState, useEffect } from "react";
import "./style.scss";

import Modal from "../../components/Modal/Modal";

import carouselOne from "./assets/imgleft.svg";
import Arroba from "./assets/inputEmail.svg";
import logoRight from "./assets/LOGO.svg";
import caixa from "./assets/IMAGEM - CAIXA.svg";
import cell from "./assets/cell.svg";
import mob from "./assets/mobImage.png";
import lucreiMob from "./assets/LUCREI.svg";
import { useHistory, Link } from "react-router-dom";

import loginToken from "../../services/auth";
import { loginData } from "../../services/Data_User";
import api from "../../services/api";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateCredential } from "../../utils/credential-validate";
import useForm from "../../hooks/useForm";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function Login(props) {
    const confirmationEmail = props.location.search.split("?")[1];
    const resetPasswordSuccess = props.location.search.split("?")[1];

    const history = useHistory();

    const [credential, setCredential] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const inputCredential = useForm("credential");
    const inputPassword = useForm("password");

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOnBlur = () => {
        if (credential && credential.length === 0) return;
        if (!validateCredential(credential))
            setError("Email ou telefone inválido.");
        else setError(null);
    };

    //função de conexao com API"store"

    async function HandleSignIn(e) {
        setLoading(true);
        e.preventDefault();
        const data = {
            credential: inputCredential.value.trim(),
            password: inputPassword.value,
        };

        try {
            const response = await api.post("/api/v1/auth/login", data);

            loginToken(response.data.token);

            if (response.data.account.role === "store") {
                history.push("empresa/dashboard");
            }
            if (response.data.account.role === "customer") {
                history.push("cliente/dashboard");
            }
            if (response.data.account.role === "admin") {
                history.push("/adm/dash");
            }
            loginData(
                response.data.account.user.name,
                response.data.account.email,
                response.data.account.phone,
                response.data.account?.user?.lucreiCode || "",
                response.data.account.user?.avatar || "",
                response.data.account?.user?.modules || [],
                response.data.account?.role
            );

            setLoading(true);
            toast.success("Seja Bem-Vindo", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } catch (error) {
            if (error?.response?.status === undefined) {
                console.log("ERROR UNDEFINED STATUS", error);
                toast.error(
                    "Não foi possível conectar ao sistema da Lucrei. Tente novamente mais tarde.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            console.log("ERROR", error?.response?.status);
            const { status, data } = error?.response;
            const { message } = data;

            if (
                status === 400 &&
                message === "Unable to login! Check your credentials."
            )
                toast.error(
                    "Não foi possível fazer login! Cheque os seus dados e tente novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );

            if (status === 429)
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (confirmationEmail === "confirmation=success") {
            toast.success("E-mail confirmado com sucesso!.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }, [confirmationEmail]);

    useEffect(() => {
        if (resetPasswordSuccess === "resetPassword=success") {
            toast.success("A senha foi alterada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }, [resetPasswordSuccess]);

    useEffect(() => {
        const lucreiError = sessionStorage.getItem("@LucreiErrorToast");

        if (lucreiError !== null) {
            toast.error(lucreiError, {
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            sessionStorage.clear();
        }
    }, []);

    return (
        <div>
            <div className="logoLoad">{loading ? <LinearProgress /> : ""}</div>
            <div id="box">
                <div id="box-left">
                    {/*Lado esquerdo*/}
                    {/* Lado bem vindo */}
                    <div
                        id="carouselExampleIndicators"
                        data-interval="5000"
                        className="carousel slide"
                        data-ride="carousel"
                    >
                        <ol className="carousel-indicators">
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="0"
                                className="active"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="1"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="2"
                            ></li>
                        </ol>
                        <div className="carousel-inner" data-interval="5000">
                            <div className="carousel-item active w-100">
                                <div className="d-flex w-100 containerCarousel">
                                    <img
                                        className="d-block"
                                        src={carouselOne}
                                        alt="First slide"
                                    ></img>
                                    <div className="textCarousel">
                                        <h5>
                                            Receba dinheiro por toda compra
                                            realizada
                                        </h5>
                                        <p>
                                            Compre em nossas lojas parceiras e
                                            obtenha o cashback em sua carteira
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item w-100 containerCarousel">
                                <div className="d-flex w-100 containerCarousel">
                                    <img
                                        className="d-block"
                                        src={caixa}
                                        alt="second slide"
                                    ></img>
                                    <div className="textCarousel">
                                        <h5>
                                            Faça o saque para sua conta SEM
                                            burocracia
                                        </h5>
                                        <p>
                                            Com o CASHBACK em sua carteira,
                                            agora é só sacar para qualquer
                                            conta!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item w-100 containerCarousel">
                                <div className="d-flex w-100 containerCarousel">
                                    <img
                                        className="d-block"
                                        id="imgL"
                                        src={cell}
                                        alt="First slide"
                                    ></img>
                                    <div className="textCarousel">
                                        <h5>
                                            Você conectado em qualquer lugar
                                        </h5>
                                        <p>
                                            Ofertas exclusivas e sorteios
                                            mensais para assinantes e membros
                                            ativos!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="box-right">
                    {/*Lado direito*/}
                    {/* Lado do formulário */}
                    <form onSubmit={HandleSignIn} className="LoginForm">
                        {}
                        <div className="d-flex">
                            <img src={logoRight} className="Dn"></img>
                            <img src={mob} className="DnTwo cell"></img>
                            <img
                                src={lucreiMob}
                                className="DnTwo LucreiM"
                            ></img>
                            <h3 id="textCompr" className="Dn">
                                Comprou, ganhou!
                            </h3>
                            <div className="textCenter mt-3">
                                <h5 id="alingT">DIGITE SUAS INFORMAÇÕES</h5>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="credencial" id="fontLabel">
                                Telefone ou E-mail:
                            </label>
                            <Input
                                id="credencial"
                                className="campo"
                                onChange={inputCredential.onChange}
                                value={inputCredential.value}
                                type="text"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton className="ButtonIn">
                                            <img src={Arroba}></img>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                name="credencial"
                                placeholder="85991234567 | joao@gmail.com"
                                required
                                onBlur={inputCredential.onBlur}
                            />

                            {inputCredential.error && (
                                <span className="error-message">
                                    {inputCredential.error}
                                </span>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="credencial" id="fontLabel">
                                Senha:
                            </label>
                            <Input
                                className="campo"
                                onChange={inputPassword.onChange}
                                value={inputPassword.value}
                                onBlur={inputPassword.onBlur}
                                type={values.showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                                handleMouseDownPassword
                                            }
                                        >
                                            {values.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                name="senha"
                                placeholder="Senha"
                                required
                                minLength="6"
                                maxLength="50"
                            />

                            {inputPassword.error && (
                                <span className="error-message">
                                    {inputPassword.error}
                                </span>
                            )}
                            <Link
                                to="/recuperar-senha"
                                className="esquecerSenha"
                            >
                                Esqueci a senha
                            </Link>
                            {/* <a
                                className="esquecerSenha"
                                href="https://api.whatsapp.com/send?phone=5585991949020&text=Olá, Suporte! Pode me ajudar a recuperar a minha senha?"
                            >
                                Esqueceu sua senha?
                            </a> */}
                        </div>
                        <div className="form-group formm">
                            <button
                                id="button_Entrar"
                                className="button_Entrar"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress
                                        color="primary"
                                        size={21}
                                    />
                                ) : (
                                    "Entrar"
                                )}
                            </button>
                            <p
                                className="cadastrar"
                                onClick={() =>
                                    history.push("/cliente/cadastro")
                                }
                            >
                                Não tem uma conta? Cadastre-se aqui
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
