import React from "react";
import InputMask from "react-input-mask";
import "./style.scss";
const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const MaskedInput = ({ value, onChange, name, mask, onBlur }) => {
  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: onlyNumbers(event.target.value),
      },
    });
  }

  return (
    <InputMask
      className="campo"
      placeholder="(85) 99408-4544"
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default MaskedInput;
