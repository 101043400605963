import Arrayfield from "../ArrayField"
import ButtonField from "../ButtonField"
import DateField from "../DateField"
import MoneyField from "../MoneyField"
import PhoneField from "../PhoneField"
import TextField from "../TextField"

export const fieldsType = [
  {
    type: "array",
    field: Arrayfield
  },
  {
    type: "button",
    field: ButtonField
  },
  {
    type: "date",
    field: DateField
  },
  {
    type: "money",
    field: MoneyField
  },
  {
    type: "phone",
    field: PhoneField
  },
  {
    type: "default",
    field: TextField
  }
]