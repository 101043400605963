import { MdOutlineKeyboardArrowDown } from "react-icons/md"

export default function StatusFilter({ setStatus, generated }) {
    return (
        <div className="flex justify-center relative w-fit mx-auto items-center mb-5 mt-3">
            <MdOutlineKeyboardArrowDown fontSize={"1.5rem"} className="absolute right-3 text-white" />
            <select
                name="statusSelect"
                className="bg-secondaryGreen rounded-xl py-2 ps-2 pe-5 text-start text-white cursor-pointer m-auto appearance-none"
                onChange={(e) => setStatus(e.target.value)}
            >
                <option value="">Todos os Cupons</option>
                {!generated && (
                    <>
                        <option value="&status=ATIVO">Apenas Cupons Ativos</option>
                        <option value="&status=INATIVO">Apenas Cupons Inativos</option>
                    </>
                )}

                {generated && (
                    <>
                        <option value="&status=VÁLIDO">Apenas Cupons Válidos</option>
                        <option value="&status=USADO">Cupons Usados</option>
                    </>
                )}
            </select>


        </div>
    )
}