import { useEffect, useState } from "react"

import useSWR from "swr"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom"

import Loading from "../../../../components/Loading"
import BannerTool from "../../../../components/BannerTool"
import DefaultRoot from "../../../../components/DeafultRoot"
import MenuEmpresa from "../../../../components/MenuEmpresa"
import SimpleCard from "../../../../components/SimpleCard/SimpleCard"

import useLink from "../../../../hooks/useLink"

import api from "../../../../services/api"
import fetcher from "../../../../services/fetcher"
import useSWRMutation from "swr/mutation"
import NewTable from "../../../../components/NewTable/NewTable"

export default function PromoDetails() {
    const history = useLink()
    const params = useParams()

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [loadingRedeem, setLoadingRedeem] = useState(false)

    const { data: details, isLoading: loadingDetails } = useSWR(
        `api/v1/loyalty-card-promotions/${params.promotionId}/stats?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const { trigger: updateDetails } = useSWRMutation(`api/v1/loyalty-card-promotions/${params.promotionId}/stats?pageNumber=${currentPage}`, fetcher)


    const HandleRedeem = async (idCard) => {
        setLoadingRedeem(true)
        try {
            const response = await api.patch(`api/v1/loyalty-cards/${idCard}/redeem`, {})

            toast.success(response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })

        }
        catch (error) {
            const { message } = error?.response?.data
            toast.error(
                message,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            )
        }
        finally {
            setLoadingRedeem(false)
            updateDetails()
        }
    }

    /* Effects */
    useEffect(() => {
        if (!loadingDetails) {
            setTotalPages(details.pages)
        }
    }, [details, loadingDetails])


    return (
        <DefaultRoot>
            <MenuEmpresa />

            <main>
                <BannerTool
                    title="Detalhes da Promoção"
                    action={() =>
                        history.push("/empresa/nova-venda-promocional")
                    }
                    actionTitle="Cadastrar Nova Venda em Cartão Fidelidade"
                />

                {loadingDetails ? (
                    <Loading />
                ) : (
                    <div className="flex flex-col justify-center items-center max-w-screen-2xl mx-auto px-2">
                        <h2 className="my-4 text-2xl font-semibold text-center">{params.promotionName}</h2>
                        <section className="table-responsive mx-auto px-2 py-2 flex gap-4 md:justify-center">
                            <SimpleCard
                                title={"Nº de usuários participantes"}
                                content={details?.numberOfCardOwners || "-"}
                            />
                            <SimpleCard
                                title={"Nº de cartões gerados"}
                                content={details?.numberOfGeneratedCards || "-"}
                            />
                            <SimpleCard
                                title={"Total Vendido"}
                                content={`R$ ${details?.totalSoldByPromotion?.totalSold.toFixed(
                                    2
                                ) || "-"
                                    }`}
                            />
                            <SimpleCard
                                title={"Nº de Vendas"}
                                content={`${details?.totalSoldByPromotion?.totalSales ||
                                    "-"
                                    }`}
                            />
                        </section>

                        <div className="flex w-full justify-end mt-5">
                            <button
                                className="rounded-lg px-3 py-2 bg-secondaryGreen text-white duration-100 hover:scale-105"
                                onClick={() => history.push(`/empresa/usuarios-participantes/${params.promotionId}/${params.promotionName}`)}
                            >
                                Ver usuários participantes
                            </button>
                        </div>

                        <section className="flex w-full gap-4">

                            <NewTable
                                data={{ data: details?.generatedCards }}
                                altData={details?.salesNumberPromotion}
                                name={"Cartões Fidelidade Gerados"}
                                fields={[
                                    {
                                        type: "default",
                                        title: "Código",
                                        name: "code",
                                    },
                                    {
                                        type: "default",
                                        title: "Cliente",
                                        name: "customerName",
                                        altName: "leadCustomerName",
                                    },
                                    {
                                        type: "default",
                                        title: "Nº de Compras",
                                        name: "totalPurchasesDone",
                                    },
                                    {
                                        type: "default",
                                        title: "Status",
                                        name: (item) => item.isComplete ? "COMPLETO" : "-",
                                    },
                                    {
                                        type: "button",
                                        title: "Resgatou Brinde",
                                        rule: "isRedeemed",
                                        name: (item) => loadingRedeem ? "aguarde..." : item.isRedeemed ? "JÁ RESGATADO" : "Resgatar",
                                        disabled: (item) => !item.isComplete || item.isRedeemed,
                                        action: (item) => HandleRedeem(item._id)
                                    },
                                    {
                                        type: "button",
                                        title: "Detalhes",
                                        name: "Ver Compras",
                                        disabled: (item) =>
                                            !item.customerId &&
                                            !item.leadCustomerId,
                                        action: (item) =>
                                            history.push(
                                                `/empresa/historico-por-promocao/${params.promotionId}/${params.promotionName}/${item.customerName || item.leadCustomerName}/${item.customerId || item.leadCustomerId}/${item._id}`
                                            ),
                                    },
                                ]}
                            />

                        </section>
                    </div>
                )}
            </main>
        </DefaultRoot>
    )
}
