import { useState, useEffect, useCallback } from "react"

import { toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"

import Girl from "./assets/GirlCash.svg"
import arrow from "./assets/cashArrow.svg"

import Modal from "../../components/Modal/Modal"
import MenuCliente from "../../components/MenuCliente"
import NewReceiptModal from "../../components/NewReceiptModal"

import "./style.scss"

import api from "../../services/api"
import { logout, getToken } from "../../services/auth"

import CashNotas from "./components/CashNotas"
import CollapseElement from "./components/collapseElement"

export default function CashbackNaNota() {
    const history = useHistory()
    const token = getToken()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalSupportOpen, setIsModalSupportOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({})

    const checkTokenValidation = async () => {
        try {
            const response = await api.post(`/api/v1/auth/token-validate`, {
                headers: { Authorization: token },
            })
        } catch (error) {
            const { status } = error.response

            if (status === 401) {
                logout()
                history.push("/login")
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
            }

            if (status === 403) {
                logout()
                history.push("/login")
                toast.error(
                    "Sessão expirada! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
            }
        }
    }

    const checkDailyLimitExpirationDate = async () => {
        try {
            console.log("checkDailyLimitExpirationDate")
            const response = await api.get(`/api/v1/auth/me`, {
                headers: { Authorization: token },
            })

            setUserInfo(response.data)
        } catch (error) {
            const { status } = error.response

            if (status === 401) {
                logout()
                history.push("/login")
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
            }

            if (status === 429) {
                history.push("/login")
                logout()
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
                return
            }
        }
    }

    const toggleModal = useCallback(() => {
        setIsModalOpen((prevState) => !prevState)
    }, [])

    const showDailyLimitMessage = useCallback(() => {
        toast.info(
            "Você atingiu o limite diário de 1 cupom fiscal enviado. Tente novamente a partir de amanhã.",
            {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }
        )
    }, [])

    useEffect(() => {
        checkTokenValidation()
        checkDailyLimitExpirationDate();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="cash-container">
            <MenuCliente />
            <section className="cash-section">
                <div className="cash-sectionLeft">
                    <article>
                        <h2>
                            <span>Dinheiro</span> na Nota
                        </h2>
                        <p>
                            Receba dinheiro com suas notas fiscais e garanta seu
                            cashback
                        </p>
                    </article>
                    <img src={Girl} />
                </div>
                <div className="cash-sectionRigth">
                    <section className="cash-groupboxCash">
                        <Link to={"/cliente/notas-fiscais"}>
                            <CashNotas
                                NoteSearc={true}
                                handleClick={() => setIsModalOpen(true)}
                            />
                        </Link>
                        <CashNotas
                            NoteSearc={false}
                            handleClick={() => setIsModalOpen(true)}
                            limit={showDailyLimitMessage}
                            userInfo={userInfo}
                        />
                    </section>
                    <section className="cash-sectionSuport">
                        <h2>ENTENDA O FUNCIONAMENTO</h2>
                        <div className="cash-groupTextSupport">
                            <h3>Suporte</h3>
                            <img
                                onClick={() => setIsModalSupportOpen(true)}
                                src={arrow}
                            />
                        </div>
                        <p>
                            Nós ajudamos você a entender como ganhar dinheiro
                            com suas notas fiscais. Caso ainda tenha alguma
                            dúvida sobre o processo, fale conosco!
                        </p>
                        <a
                            href="https://api.whatsapp.com/send?phone=5585991949020"
                            target="_blank"
                        >
                            <button type="button">Receber Suporte</button>
                        </a>
                    </section>
                </div>
            </section>
            <h2 id="textCollapse">Como Funciona?</h2>
            <section className="collapseGroup">
                <CollapseElement
                    titleNumber={0}
                    textNumber={0}
                    nameId={"collapse1"}
                />
                <CollapseElement
                    titleNumber={1}
                    textNumber={1}
                    nameId={"collapse2"}
                />
                <CollapseElement
                    titleNumber={2}
                    textNumber={2}
                    nameId={"collapse3"}
                />
                <CollapseElement titleNumber={3} nameId={"collapse4"} />
            </section>
            {isModalOpen && <NewReceiptModal setIsModalOpen={setIsModalOpen} />}
            {isModalSupportOpen && (
                <Modal
                    Component={"Support"}
                    Title={"Como Funciona?"}
                    visible={() => setIsModalSupportOpen(false)}
                />
            )}
        </div>
    );
}
