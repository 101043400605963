import './StyleInfor.scss'
import ticket from '../img/ticket.svg'
import calendar from '../img/calendar.svg'
import user from "../img/user.svg"
import phone from "../img/phone.svg"
import email from "../img/mail.svg"
import home from "../img/home.svg"
import editar from '../img/editar.svg'

function Infor(props) {
    const customerInfo = props.customer
    console.log(customerInfo)

    return (
        <section className='containerInfor'>
            <div className='subcontainer'>
                <div className='agroupboxInfor'>
                    <div className='inforCentro'>
                        <h2>Nome</h2>
                        <div className='boxInfor'>
                            <div className='d-flex'>
                                <img
                                    width='25px'
                                    src={user}
                                />
                                <p className='infor'>{customerInfo.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className='inforCentro'>
                        <h2>Contato</h2>
                        <div className='boxInfor'>
                            <div className='d-flex'>
                                <img
                                    width='25px'
                                    src={phone}
                                />
                                <p className='infor'>{customerInfo.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className='inforCentro'>
                        <h2>Email</h2>
                        <div className='boxInfor'>
                            <div className='d-flex'>
                                <img
                                    width='25px'
                                    src={email}
                                />
                                <p className='infor'>{customerInfo.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='inforEndereco'>
                    {/*<h2>Endereço</h2>
                    <div className='boxInfor'>
                        <div className='d-flex'>
                            <img
                                height='25px'
                                src={home}
                            />
                           <p className='infor'>Aracoiaba, R. Oliveira Peixoto</p>
                        </div>
                        <img
                            height='20px'
                            src={editar}
                        />
                    </div>
                    */}
                </div>
            </div>
        </section>
    )
}

export default Infor