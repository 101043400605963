import React from 'react';
import "./Style.scss";
import { Link } from "react-router-dom";

export default function WithdrawSecondary(){
  return(
    <div className="tudoSaqueC">
        <div className="containerSaqueC">
          <h1>Confirmar Senha:</h1>
          <form className="formSaque">
            <div className="group">
              <label>Confirme sua senha:</label>
              <input type="password" className="campoText" placeholder="********"/>
            </div>
            <div className="group">
              <Link to="/customer/dashboard"><button type="submit" className="buttonConfirm">Confirmar</button></Link>
              <Link to="/customer/dashboard/withdraw/bankdata"><button className="buttonCancel">Cancelar</button></Link>
            </div>
          </form>
        </div>
    </div>
  )
};