export const TOKEN_KEY = "@Cashback-Token";
export const EMAIL_USER = "EmailUser";
export const PHONE_USER = "PhoneUser";
export const NAME_USER = "NameUser";
export const LUCREI_CODE = "code";

export const isAuthenticated = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token !== null) {
        return true;
    } else {
        return false;
    }
};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const loginToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    localStorage.clear();
};

export default loginToken;
