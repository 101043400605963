import { useEffect, useState } from "react"

import useSWR from "swr"
import { HiSearch } from "react-icons/hi"
import { useParams } from "react-router-dom"
import { MdOutlineClose } from "react-icons/md"

import Loading from "../../../../components/Loading"
import BannerTool from "../../../../components/BannerTool"
import DefaultRoot from "../../../../components/DeafultRoot"
import MenuEmpresa from "../../../../components/MenuEmpresa"
import NewTable from "../../../../components/NewTable/NewTable"

import useLink from "../../../../hooks/useLink"

import fetcher from "../../../../services/fetcher"

export default function ParticipatingUsers() {
    const history = useLink()
    const params = useParams()

    /*     const [currentPage, setCurrentPage] = useState(1)
        const [totalPages, setTotalPages] = useState(1) */
    const [filteredData, setFilteredData] = useState({
        customers: { data: [] },
    })
    const [search, setSearch] = useState("")

    /*     const { data: details, isLoading: loadingDetails } = useSWR(
            `api/v1/loyalty-card-promotions/${params.promotionId}/stats?pageNumber=${currentPage}`,
            fetcher,
            {
                revalidateOnFocus: false,
            }
        ) */

    const { data: customers, isLoading: loadingCustomers } = useSWR(
        `api/v1/loyalty-card-promotions/${params.promotionId}/customers`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const HandleSearch = (e) => {
        setSearch(e.target.value)

        const filteredCustomers = customers.data.filter((x) => {
            return x.name
                ?.toLowerCase()
                .includes(e.target.value.toLowerCase())
        })

        const updatedData = {
            customers: { data: filteredCustomers },
        }

        setFilteredData(updatedData)
    }

    const SetOriginalData = () => {
        const originalData = {
            customers: { data: customers?.data },
        }
        setFilteredData(originalData)
        setSearch("")
    }

    /* Effects */
    /*     useEffect(() => {
            if (!loadingDetails) {
                setTotalPages(details.pages)
            }
        }, [details, loadingDetails]) */

    useEffect(() => {
        SetOriginalData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers])

    return (
        <DefaultRoot>
            <MenuEmpresa />

            <main>
                <BannerTool
                    title="Usuários Participantes"
                />

                {loadingCustomers ? (
                    <Loading />
                ) : (
                    <div className="flex flex-col justify-center items-center max-w-screen-2xl mx-auto">
                        <h2 className="my-4 text-2xl font-semibold">{params.promotionName}</h2>
                        <section className="w-full max-w-xl">
                            <form
                                action=""
                                className="flex gap-2 items-center px-3 mt-3 bg-white rounded-3xl border-solid border-2 border-zinc-300 shadow-md max-w-[90%] mx-auto"
                            >
                                <HiSearch className="text-xl" />
                                <input
                                    type="text"
                                    className="border-none outline-none p-2 bg-transparent w-full ring-transparent focus:ring-transparent"
                                    placeholder="Pesquisar Clientes"
                                    value={search}
                                    onChange={HandleSearch}
                                />
                                {search.length > 0 && (
                                    <MdOutlineClose
                                        className="text-red-600 text-xl cursor-pointer"
                                        onClick={SetOriginalData}
                                    />
                                )}
                            </form>
                        </section>

                        <section className="mt-5 w-full px-2">
                            <NewTable
                                data={filteredData.customers}
                                name={"Clientes Participantes"}
                                fields={[
                                    {
                                        type: "default",
                                        title: "Nome",
                                        name: "name",
                                    },
                                    {
                                        type: "default",
                                        title: "Nº de Cartões Gerados",
                                        name: "totalCards",
                                    },
                                    {
                                        type: "date",
                                        title: "Última Compra",
                                        name: "updatedAt",
                                    },
                                    {
                                        type: "button",
                                        title: "Detalhes",
                                        name: "Ver Compras",
                                        disabled: (item) => !item._id,
                                        action: (item) => history.push(
                                            `/empresa/historico-por-cliente/${params.promotionId}/${item.name}/${item._id}`
                                        )
                                    }
                                ]}
                            />
                        </section>


                        {/* <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                paginate={setCurrentPage}
                            /> */}

                    </div>
                )}
            </main>
        </DefaultRoot>
    )
}
