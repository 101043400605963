import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
/* const links = [
    { href: '/account-settings', label: 'Account settings' },
    { href: '/support', label: 'Support' },
    { href: '/license', label: 'License' },
    { href: '/sign-out', label: 'Sign out' },
] */

export default function DropDownCard({ title, color, links }) {
    return (
        links.length > 0 && (
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button
                    className={`${color} inline-flex w-full justify-center gap-x-1.5 rounded-xl p-6 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-300`}
                >
                    <span className="drop-shadow-md text-2xl font-semibold">{title}</span>
                </Menu.Button>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >

                    <Menu.Items className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                        {links.map((link) => (
                            /* Use the `active` state to conditionally style the active item. */
                            <Menu.Item key={link.action} as={Fragment}>
                                {({ isactive }) => (
                                    <button
                                        onClick={() => link.action()}
                                        className={classNames(
                                            isactive ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block px-4 py-2 w-full text-center text-md font-medium no-underline hover:no-underline hover:bg-primaryPurple hover:text-white'
                                        )}
                                    >
                                        {link.label}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        )
    )
}