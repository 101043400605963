import React from 'react'
import './style.scss'

export const Message = (props) => {
    const responseLines = props.content.split('\n')


    return (
        <>
            {props.isBot && <div className='BotMessageContainer p-3 mb-2 animate__animated animate__fadeInLeftBig'>
                {responseLines.map((response, index) => (
                    <div className='textResponse mb-2' key={index}>{response}</div>
                ))}
                <span>{props.isBot}</span>
            </div>}

            {!props.isBot && <div className='UserMessageContainer p-3 mb-2 animate__animated animate__fadeInRightBig'>
                {responseLines.map((response, index) => (
                    <div className='textResponse mb-2' key={index}>{response}</div>
                ))}
                <span>{props.isBot}</span>
            </div>}

        </>

    )
}
