import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../../services/api";
import LoadingForButton from "../../../components/LoadingForButton";
import ctl from "@netlify/classnames-template-literals";
import { useState } from "react";


export default function PasswordUpdate() {
  const [confirmPass, setConfirmPass] = useState("")
  const [errors, setErrors] = useState()

  const formik = useFormik({
    initialValues: {
      password: ""
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formik.values.password !== confirmPass) {
      setErrors("As senhas devem ser iguais")
      return
    }

    if (formik.values.password === confirmPass) {
      setErrors()
    }

    try {
      await api.patch(
        "/api/v1/auth/customers/me/basic-info/password",
        formik.values
      );
      toast.success("Senha atualizada com sucesso!", {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      formik.resetForm()
      setConfirmPass("")

    } catch (error) {
      const { status, data } = error.response;

      console.log("error", { error });
      if (status === 400) {
        toast.error(data?.message, {
          position: "top-right",
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  }

  return (
    <section className="rounded-xl drop-shadow-md bg-white p-8 flex flex-col justify-center items-center mt-4">
      <h2 className="font-semibold text-2xl w-full text-start">Altere a sua senha padrão</h2>
      <p className="w-full text-start text-zinc-600">Proteja sua conta Lucrei ao criar uma senha mais segura.</p>
      <form onSubmit={handleSubmit} className="w-full grid grid-cols-1 lg:grid-cols-5 gap-3 items-center">
        <div className="w-full lg:col-span-2">
          <label class="form-label">Nova senha</label>
          <input
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            id="password"
            minLength={6}
            maxLength={20}
            type="password"
            name="password"
            required
          />
          <span className="text-red-600">
            {errors}
          </span>
        </div>

        <div className="w-full lg:col-span-2">
          <label class="form-label">Confirmar nova senha</label>
          <input
            className="form-control"
            onChange={(e) => setConfirmPass(e.target.value)}
            value={confirmPass}
            id="confirmPass"
            minLength={6}
            maxLength={20}
            type="password"
            name="conformPass"
            required
          />
          <span className="text-red-600">
            {errors}
          </span>

        </div>

        <div className="w-full col-span-1 flex justify-end lg:block lg:-mb-5">
          <button
            className={ctl(`
            px-3 py-2.5 mt-2 lg:mt-5
            text-base text-white font-semibold
            rounded-xl bg-secondaryGreen         
            hover:scale-105 duration-300`)}
            type="submit"
          >
            {formik.isSubmitting ? <LoadingForButton /> : "Alterar senha"}
          </button>
        </div>

      </form>

    </section>
  )
}