import api from "../../../services/api";

export default async function StatisticsDataIntegration(period) {
    const NewClients = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/customers/most-recent-per-period?month=${period.month}&year=${period.year}`
        );
        console.log(
            "NEW CLIENTES",
            response.data.totalMostRecentCustomersPerPeriod
        );
        return response.data;
    };

    const CustomersPurchasesOnce = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/total-customer-purchases-once-per-period?month=${period.month}&year=${period.year}`
        );
        return response.data;
    };

    const CustomersPurchasesMany = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/total-customer-many-purchases-per-period?month=${period.month}&year=${period.year}`
        );
        return response.data;
    };

    const AverageTicket = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/average-ticket-per-period?month=${period.month}&year=${period.year}`
        );
        return response.data;
    };

    const TotalSold = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/total-sold-per-period?month=${period.month}&year=${period.year}`
        );
        return response.data;
    };

    const TotalSales = async (period) => {
        const response = await api.get(
            `api/v1/dashboard/stores/total-sales-per-period?month=${period.month}&year=${period.year}`
        );
        return response.data;
    };

    const responseData = {
        NewClients: await NewClients(period),
        CustomersPurchasesOnce: await CustomersPurchasesOnce(period),
        CustomersPurchasesMany: await CustomersPurchasesMany(period),
        AverageTicket: await AverageTicket(period),
        TotalSold: await TotalSold(period),
        TotalSales: await TotalSales(period),
    };

    return responseData;
}
