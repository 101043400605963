import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import "./style.scss";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import { getToken, logout } from "../../services/auth";
import useForm from "../../hooks/useForm";
import QrReader from "react-qr-scanner";
import ReceiptCodeInput from "../ReceiptCodeInput";

const NewReceiptModal = ({
    balance,
    setShowWithdrawModal,
    setIsModalOpen,
    user,
    checkDailyLimitExpirationDate,
}) => {
    const [loading, setLoading] = useState(false);
    const [createMode, setCreateMode] = useState("");
    const [modalToastOpen, setModalToastOpen] = useState(false);
    const storeInput = useForm();
    const valueInput = useForm();
    const codeInput = useForm("code");
    const history = useHistory();
    const token = getToken();
    const [scanResultWebCam, setScanResultWebCam] = useState("");
    const [receiptImage, setReceiptImage] = useState({
        image: null,
        raw: {},
    });
    const [fileInputKey, setFileInputKey] = useState("");

    const checkTokenValidation = async () => {
        try {
            const response = await api.post(`/api/v1/auth/token-validate`, {
                headers: { Authorization: token },
            });
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (error.response.status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (status === 403) {
                history.push("/login");
                logout();
                toast.error(
                    "Sessão expirada! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const handleCreateModeChange = ({ target }) => {
        setCreateMode(target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            !valueInput.validate() ||
            !storeInput.validate() ||
            !codeInput.validate()
        ) {
            return;
        }

        const bodyData = {
            code: codeInput.value,
            store: storeInput.value,
            value: valueInput.value,
        };
        try {
            const response = await api.post("api/v1/receipts", bodyData, {
                headers: { Authorization: token },
            });

            setLoading(true);
            const { status } = response;
            if (status === 201) {
                toast.success("Sua nota fiscal foi cadastrada com sucesso!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
                setIsModalOpen(false);
                checkDailyLimitExpirationDate();
            }
        } catch (error) {
            if (error?.response?.data?.message.includes("Limite diário")) {
                toast.error(
                    "Limite diário de envio de cupons fiscais atingido! Tente novamente no dia seguinte.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    }
                );
                setIsModalOpen(false);
                checkDailyLimitExpirationDate();
            }

            console.log({ error });

            if (error?.response?.status === 500) {
                toast.error(
                    "Sistema em manutenção! Por favor, tente novamente quando voltarmos.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    }
                );
                setIsModalOpen(false);
                history.push("/login");
                logout();
            }

            if (
                error?.response?.data?.message ===
                "Erro! Nota fiscal já existente no banco de dados!"
            ) {
                toast.error(
                    "Erro! Nota fiscal já existente no banco de dados!",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    }
                );
            }
            if (error.response.status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
            if (error.response.status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitPhotoUpload = async (e) => {
        e.preventDefault();

        if (!storeInput.validate() || !valueInput.validate()) {
            return;
        }

        // PADRONIZAR CAPITALIZE STORE NAME
        const capitalizedStoreName = storeInput.value.replace(
            /\b[\p{L}\u00C0-\u00FF]{3,}/gu,
            function (txt) {
                return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
            }
        );

        const trimmedStoreName = capitalizedStoreName.trim();

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("store", trimmedStoreName);
            formData.append("value", valueInput.value);
            formData.append("receipt-photo", receiptImage.raw);

            const response = await api.post(
                "/api/v1/receipts/upload",
                formData,
                {
                    headers: { Authorization: token },
                }
            );

            setLoading(false);
            const { status } = response;
            if (status === 201) {
                setModalToastOpen(true);
                /*  toast.success("Sua nota fiscal foi cadastrada com sucesso!", {
                     position: toast.POSITION.BOTTOM_RIGHT,
                     autoClose: 3000,
                 });
                 */
            }
        } catch (error) {
            setLoading(false);
            console.log({ error });
            if (
                error &&
                error.response.data.message.includes("Inaccessible host")
            ) {
                toast.error(
                    "Falha no envio! Verifique a sua conexão de internet ou tente novamente mais tarde!",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    }
                );
            }

            if (error?.response?.status === 500) {
                toast.error(
                    "Sistema em manutenção! Por favor, tente novamente quando voltarmos.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    }
                );
                setIsModalOpen(false);
                history.push("/login");
                logout();
            }

            if (error?.response?.data?.message.includes("Limite diário")) {
                toast.error(
                    "Limite diário de envio de cupons fiscais atingido! Tente novamente no dia seguinte.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 3000,
                    }
                );
                setIsModalOpen(false);
                checkDailyLimitExpirationDate();
            }
            if (error.response.status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (error.response.status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    const handleImageChange = ({ target }) => {
        if (target.files.length === 0) return;
        const validFormats = ["jpg", "png", "jpeg"];
        const sizeInMB = target.files[0].size / 1024 / 1024;
        const format = target.files[0].type.split("/")[1];
        if (!validFormats.includes(format)) {
            toast.error(
                "Por favor, selecione um arquivo nos formatos .jpg, .png ou .jpeg",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return false;
        }
        if (sizeInMB > 6) {
            toast.error(
                "Por favor, selecione um arquivo de imagem menor ou igual a 6MB.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return false;
        }

        setReceiptImage({
            url: URL.createObjectURL(target.files[0]),
            raw: target.files[0],
        });
    };

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleRemovePhoto = useCallback(() => {
        setReceiptImage({});
        setFileInputKey(null);
    }, []);

    useEffect(() => {
        checkTokenValidation();
        // eslint-disable-next-line
    }, []);

    const previewStyle = {
        maxWidth: "100%",
    };

    return (
        <div className="NewReceiptModal">
            {modalToastOpen ? (
                <div className="ToastModalContainer modalWrapper">
                    <p className="toastText">
                        Pronto! A sua nota foi enviada com sucesso! Agora, a
                        nossa equipe irá validar a sua nota em até 48h úteis e o
                        seu cashback logo estará disponível!
                    </p>
                    <button
                        className="btn btn-success"
                        onClick={() => {
                            window.location.reload();
                            setIsModalOpen(false);
                            setModalToastOpen(false);
                            checkDailyLimitExpirationDate();
                        }}
                    >
                        OK
                    </button>
                </div>
            ) : (
                <div className="FormWrapper modalWrapper">
                    <button className="closeButton" onClick={handleCloseModal}>
                        <CloseIcon />
                    </button>
                    {/* <h2>Como você deseja enviar sua nota fiscal?</h2>
                    <select
                        name="create"
                        id="create"
                        className="creation-select campo"
                        onChange={handleCreateModeChange}
                        defaultValue=""
                    >
                        <option value="" disabled>
                            Selecione...
                        </option>
                        <option value="upload-photo">
                            Enviar foto da nota fiscal
                        </option>
                    </select> */}
                    {/* Forms Container */}
                    {/* NEW RECEIPT MANUAL */}
                    {/* {createMode === "manual" && (
     <form onSubmit={handleSubmit} className="NewReceiptForm">
         <div className="form-group">
             <label htmlFor="store">Estabelecimento:</label>
             <input
                 id="store"
                 className="campo"
                 onChange={storeInput.onChange}
                 value={storeInput.value}
                 name="name"
                 placeholder="Nome do estabelecimento que comprou"
                 required
                 onBlur={storeInput.onBlur}
             />
             {storeInput.error && (
                 <span className="error-message">
                     {storeInput.error}
                 </span>
             )}
         </div>
         <div className="form-group">
             <label htmlFor="value">Valor na nota fiscal:</label>
             <input
                 id="value"
                 className="campo"
                 onChange={valueInput.onChange}
                 value={valueInput.value}
                 placeholder="Valor na nota fiscal"
                 required
                 min="1"
                 step="0.01"
                 type="number"
                 onBlur={valueInput.onBlur}
             />
             {valueInput.error && (
                 <span className="error-message">
                     {valueInput.error}
                 </span>
             )}
         </div>
         <div className="form-group">
             <label htmlFor="code">Código:</label>
             <ReceiptCodeInput
                 id="code"
                 required
                 className="campo"
                 mask="receipt"
                 placeholder="Digite o código completo"
                 onChange={codeInput.onChange}
                 onBlur={codeInput.onBlur}
                 maxLength={54}
                 value={codeInput.value}
             />
             {codeInput.error && (
                 <span className="error-message">
                     {codeInput.error}
                 </span>
             )}
             { <input
                 id="code"
                 className="campo"
                 onChange={codeInput.onChange}
                 value={codeInput.value}
                 placeholder="Digite o código"
                 required
                 maxLength={44}
                 onBlur={codeInput.onBlur}
             />
             {codeInput.error && (
                 <span className="error-message">
                     {codeInput.error}
                 </span>
             )} }
             <span className="help-find-key">
                 <a href="https://lucrei.nyc3.digitaloceanspaces.com/receipts/uploads/1666750973250.jpeg">
                     Onde encontro o código?
                 </a>
             </span>
         </div>
         <button type="submit" className="btn-submit">
             {loading ? "Enviando..." : "Cadastrar Nota Fiscal"}
         </button>
     </form>
 )} */}

                    {/* NEW RECEIPT UPLOAD PHOTO */}
                    {/*  {createMode === "upload-photo" && ( */}
                    <form
                        onSubmit={handleSubmitPhotoUpload}
                        className="NewReceiptForm"
                    >
                        <div className="form-group">
                            <label htmlFor="store">Estabelecimento:</label>
                            <input
                                id="store"
                                className="campo"
                                onChange={storeInput.onChange}
                                value={storeInput.value}
                                name="store"
                                placeholder="Farmácia ABC"
                                required
                                onBlur={storeInput.onBlur}
                            />
                            {storeInput.error && (
                                <span className="error-message">
                                    {storeInput.error}
                                </span>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="store">Valor:</label>
                            <input
                                id="value"
                                className="campo"
                                onChange={valueInput.onChange}
                                value={valueInput.value}
                                name="store"
                                placeholder="Valor da nota fiscal"
                                required
                                min="1"
                                step="0.01"
                                type="number"
                                onBlur={valueInput.onBlur}
                            />
                            {valueInput.error && (
                                <span className="error-message">
                                    {valueInput.error}
                                </span>
                            )}
                        </div>

                        <div className="form-group photo-upload-wrapper">
                            {!receiptImage.url && (
                                <label
                                    className="upload-photo-label"
                                    htmlFor="receipt-photo"
                                >
                                    <AddPhotoIcon />
                                    <span>ENVIAR FOTO</span>
                                </label>
                            )}
                            <input
                                type="file"
                                id="receipt-photo"
                                name="receipt-photo"
                                accept=".jpg,.png,.jpeg"
                                onChange={handleImageChange}
                                required
                                key={fileInputKey}
                            />
                        </div>
                        {receiptImage.url && (
                            <div className="image-preview-wrapper">
                                <div
                                    className="receipt-preview"
                                    style={{
                                        backgroundImage: `url('${receiptImage.url}')`,
                                    }}
                                ></div>
                                {receiptImage.url && (
                                    <button
                                        type="button"
                                        className="btn-delete-photo"
                                        disabled={loading}
                                        onClick={handleRemovePhoto}
                                    >
                                        EXCLUIR FOTO
                                    </button>
                                )}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="btn-submit"
                            disabled={!storeInput.value || loading}
                        >
                            {loading
                                ? "Enviando foto..."
                                : "Cadastrar Nota Fiscal"}
                        </button>
                    </form>
                    {/* )} */}
                </div>
            )}
        </div>
    );
};

export default NewReceiptModal;
