import NewTable from "../../../components/NewTable/NewTable";
import formatCurrency from "../../../utils/format-currency";
import formatDateToBrazilianFormat from "../../../utils/format-date-and-time";

export default function PurchaseDetail({
  customerName,
  createdAt,
  value,
  products
}) {
  console.log(products)
  return (
    <div className="flex flex-col gap-3 m-3 max-w-full">
      <h2>Detalhes da compra</h2>
      <div className="flex flex-col">
        <span>Cliente: {customerName}</span>
        <span>Data: {formatDateToBrazilianFormat(createdAt)}</span>
        <span>Valor: R$ {formatCurrency(value)}</span>
      </div>

      <NewTable
        data={{ data: products }}
        name={`Produtos`}
        fields={[
          {
            type: "default",
            title: "Produto",
            name: "name",
          },
          {
            type: "money",
            title: "Valor",
            name: "price",
          },
          {
            type: "default",
            title: "Quantidade",
            name: "quantity",
          }
        ]}
      />
    </div>
  )
}