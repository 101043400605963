export function checkMaxYear(date) {
    const currentYear = new Date().getFullYear();
    const year = date.split("-")[0] * 1;

    if (year > currentYear - 16) return false;
}

export function getDateLimit() {
    const now = new Date();
    const lastYear = new Date("10/1/2005");
    const d = now - lastYear;
    const x = Math.floor(d / 31536000000);
    const month =
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
    const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
    return `${now.getFullYear() - 16}-${month}-${day}`;
}

export function checkMinimumAge(date) {
    const formatedDate = date.replace(/\//g, "-");
    const sd = formatedDate.split("-");

    const stringDate = `${sd[1]}-${sd[0]}-${sd[2]}`;

    const birthDate = new Date(stringDate);

    if (birthDate.getFullYear() < 1900) {
        return false;
    }

    const today = new Date();
    if (
        today >=
        new Date(
            birthDate.getFullYear() + 16,
            birthDate.getMonth(),
            birthDate.getDate() + 1
        )
    ) {
        return true;
    } else {
        //cai aqui se usuário menor que 16 anos
        return false;
    }
}

export function convertDateToMongoDB(date) {
    //converts to year-month-day
    // vem dia - mes - ano
    const numbers = date.split("/");
    const dateString = `${numbers[2]}-${numbers[1]}-${numbers[0]}`;
    return dateString;
}
