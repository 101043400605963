import React from "react";
import CustomerProfileInfoCard from "../CustomerProfileInfoCard";
import bag from "../../assets/svg/shopping-bag.svg";
import smile from "../../assets/svg/smile.svg";
import ticket from "../../assets/svg/ticket.svg";
import calendar from "../../assets/svg/calendar.svg";

function CustomerInfoCards({
    totalPurchases,
    totalPurchasesFromThisMonth,
    averageTicket,
}) {
    return (
        <div className="boxRight">
            <CustomerProfileInfoCard
                icon={bag}
                title="Compras efetuadas"
                data={totalPurchases}
            />

            <CustomerProfileInfoCard
                icon={calendar}
                title="Compras no mês atual"
                data={totalPurchasesFromThisMonth}
            />

            <CustomerProfileInfoCard
                icon={smile}
                title="Cliente Lucrei"
                data="Perfil do cliente"
            />

            <CustomerProfileInfoCard
                icon={ticket}
                title="Ticket médio"
                data={averageTicket}
            />
        </div>
    );
}

export default CustomerInfoCards;
