import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import "./style.scss";

import { Link, useHistory } from "react-router-dom";

import api from "../../services/api";
import { logout } from "../../services/auth";
import NewReceiptModal from "../../components/NewReceiptModal";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DescriptionIcon from "@material-ui/icons/Description";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const NewReceiptInfo = ({
    balance,
    token,
    setShowWithdrawModal,
    showWithdrawModal,
    user,
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const checkTokenValidation = async () => {
        try {
            const response = await api.post(`/api/v1/auth/token-validate`, {
                headers: { Authorization: token },
            });
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 403) {
                logout();
                history.push("/login");
                toast.error(
                    "Sessão expirada! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const checkDailyLimitExpirationDate = async () => {
        try {
            console.log("checkDailyLimitExpirationDate");
            const response = await api.get(`/api/v1/auth/me`, {
                headers: { Authorization: token },
            });

            setUserInfo(response.data);
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    const toggleModal = useCallback(() => {
        setIsModalOpen((prevState) => !prevState);
    }, []);

    const showDailyLimitMessage = useCallback(() => {
        toast.info(
            "Você atingiu o limite diário (1 nota fiscal) de notas fiscais enviadas. Tente novamente a partir de amanhã.",
            {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }
        );
    }, []);

    useEffect(() => {
        checkTokenValidation();
        checkDailyLimitExpirationDate();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="NewReceiptInfo">
            <div className="container">
                <Link to="/cliente/dashboard" className="btn-go-back">
                    <ArrowBackIosIcon /> Voltar
                </Link>
                <div className="buttons-wrapper">
                    <Link
                        className="NewReceiptInfo-card"
                        to="/cliente/notas-fiscais"
                    >
                        <div className="card-icon view-receipts-icon">
                            <FileCopyIcon />
                        </div>
                        <p>Acompanhe suas notas</p>
                    </Link>
                    {userInfo?.ableToSendReceipts === false ? (
                        <div
                            className="NewReceiptInfo-card"
                            role="button"
                            style={{ opacity: ".4" }}
                            onClick={showDailyLimitMessage}
                        >
                            <div className="card-icon new-receipt-icon">
                                <DescriptionIcon />
                            </div>
                            <span style={{ color: "red" }}>
                                Limite diário atingido!
                            </span>
                        </div>
                    ) : (
                        <div
                            className="NewReceiptInfo-card"
                            role="button"
                            onClick={toggleModal}
                        >
                            <div className="card-icon new-receipt-icon">
                                <DescriptionIcon />
                            </div>
                            <p>Envie a sua nota!</p>
                        </div>
                    )}
                </div>

                <div className="faq-wrapper">
                    <h2>Como funciona?</h2>
                    <ul className="faq-list">
                        <div className="faq">
                            <h5>
                                Como faço para ganhar cashback com minha nota
                                fiscal?
                            </h5>
                            <p>
                                É muito simples, basta você após comprar em um
                                estabelecimento que lhe entregue uma nota
                                fiscal, você deve clicar no botão{" "}
                                <strong>“Envie a sua nota!” </strong>
                                acima, escolher uma das opções de envio e nos
                                enviar a sua nota fiscal. Após isso, a sua nota
                                passará por uma análise, é feita a validação da
                                nota, e o valor vai cair diretamente na sua
                                conta Lucrei.
                            </p>
                        </div>
                        <div className="faq">
                            <h5>
                                Quais empresas eu posso cadastrar minhas notas?
                            </h5>
                            <p>
                                Você poderá cadastrar suas compras em qualquer
                                empresa que no ato de sua compra lhe entregue um
                                cupom fiscal. A única regra é que essa empresa
                                precisa estar situada em uma das cidades
                                disponíveis no nosso cadastro de usuário no
                                sistema.
                            </p>
                        </div>
                        <div className="faq">
                            <h5>Posso cadastrar várias notas todos os dias?</h5>
                            <p>
                                Não, o limite de notas enviadas é de{" "}
                                <strong>1 nota por dia, por usuário</strong>.
                            </p>
                        </div>
                        <div className="faq">
                            <h5>
                                Quais motivos podem levar minha nota a não ser
                                validada?
                            </h5>
                            <p>
                                Vamos lá, existem alguns motivos que podem levar
                                a sua nota a não ser aceita para que você não
                                receba seu cashback, são eles:{" "}
                                <ul>
                                    <li>
                                        Compra efetuada em cidades que não
                                        pertencem às cidades de cadastro da
                                        Lucrei;
                                    </li>
                                    <li>
                                        Limite diário de notas enviadas pelo
                                        usuário excedido (3 notas por dia, por
                                        usuário);
                                    </li>
                                    <li>
                                        Código da nota fiscal inválido no envio;
                                    </li>
                                    <li>
                                        Foto da nota fiscal com informações
                                        ilegíveis.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </ul>
                    <p>
                        Qualquer dúvida você poderá está enviando mensagem para
                        o nosso suporte para que possamos lhe ajudar a
                        esclarecê-la!
                    </p>
                </div>
            </div>

            {isModalOpen && (
                <NewReceiptModal
                    setIsModalOpen={setIsModalOpen}
                    checkDailyLimitExpirationDate={
                        checkDailyLimitExpirationDate
                    }
                />
            )}
        </div>
    );
};

export default NewReceiptInfo;
