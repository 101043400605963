export default function links(
    user,
    history,
    unableToSellError,
    setShowDeposit,
    setShowRegisterSale,
    setShowRegisterSaleTwo,
    setShowRegistClient,
    setShowAddProduct
) {
    const items = [
        {
            title: "Vendas",
            color: "bg-primaryGreen",
            links: [
                {
                    action: () => {
                        if (user.unableToSell) {
                            unableToSellError();
                        } else setShowRegisterSale(true);
                    },
                    label: "Cadastrar Venda",
                    module: "cashback",
                },
                {
                    action: () => history.push("/empresa/vendas"),
                    label: "Minhas Vendas",
                    module: "cashback",
                },
                {
                    action: () => {
                        if (user.unableToSell) {
                            unableToSellError();
                        } else setShowRegisterSaleTwo(true);
                    },
                    label: "Cadastrar Venda Avulsa",
                    module: "venda-avulsa",
                },
                {
                    action: () => history.push("/empresa/lista-vendas-avulsas"),
                    label: "Minhas Vendas Avulsas",
                    module: "venda-avulsa",
                },
                {
                    action: () => setShowDeposit(true),
                    label: "Depositar Comissões",
                    module: "cashback",
                },
                {
                    action: () => history.push("/empresa/estatisticas"),
                    label: "Minhas Estatísticas",
                    module: "cashback" || "venda-avulsa",
                },
            ],
        },
        {
            title: "Produtos",
            color: "bg-sky-600",
            links: [
                {
                    action: () => setShowAddProduct(true),
                    label: "Cadastrar Produto",
                    module: "venda-avulsa",
                },
                {
                    action: () => history.push("/empresa/produtos"),
                    label: "Meus Produtos",
                    module: "venda-avulsa",
                },
            ],
        },
        {
            title: "Clientes",
            color: "bg-primaryYellow",
            links: [
                {
                    action: () => setShowRegistClient(true),
                    label: "Cadastrar Cliente",
                    module: "venda-avulsa",
                },
                {
                    action: () => history.push("/empresa/clientes"),
                    label: "Meus Clientes",
                    module: "venda-avulsa",
                },
            ],
        },
        {
            title: "Promoções",
            color: "bg-primaryPurple",
            links: [
                {
                    action: () => history.push("/empresa/validar-cupons/"),
                    label: "Validar Cupom",
                    module: "cupom-desconto",
                },
                {
                    action: () => history.push("/empresa/meus-cupons"),
                    label: "Meus Cupons de Desconto",
                    module: "cupom-desconto",
                },
                {
                    action: () => history.push("/empresa/criar-cartao"),
                    label: "Cadastrar Promoção de Cartão Fidelidade",
                    module: "cartao-fidelidade",
                },
                {
                    action: () =>
                        history.push("/empresa/nova-venda-promocional"),
                    label: "Cadastrar Venda em Cartão Fidelidade",
                    module: "cartao-fidelidade",
                },
                {
                    action: () => history.push("/empresa/meus-cartoes"),
                    label: "Minhas Promoções de Cartão Fidelidade",
                    module: "cartao-fidelidade",
                },
            ],
        },
        {
            title: "Financeiro",
            color: "bg-secondaryGreen",
            links: [
                {
                    action: () => history.push("/empresa/controle-financeiro"),
                    label: "Controle Financeiro",
                    module: "financeiro",
                },
            ],
        },
    ];

    return items;
}
