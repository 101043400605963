import { useEffect, useState, useCallback, useRef } from "react";

import useSWR from "swr";
import { toast } from "react-toastify";
import { HiSearch } from "react-icons/hi";
import { BsPrinter } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import CurrencyInput from "react-currency-masked-input";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MdKeyboardBackspace, MdOutlineClose } from "react-icons/md";

import Pagination from "../../Pagination";

import Modal from "../../Tailwind/Modal";

import add from "../../../assets/addWhite.svg";

import "./style.scss";

import api from "../../../services/api";
import { logout } from "../../../services/auth";
import fetcher from "../../../services/fetcher";

import formatDateToBrazilianFormat from "../../../utils/format-date-and-time";
import { twMerge } from "tailwind-merge";
import useSWRMutation from 'swr/mutation'

toast.configure();

const RegisterSale = ({
    token,
    setShowRegisterSale,
    showRegisterSale,
    updateSales,
    setLucreiView,
}) => {
    const [value, setValue] = useState();
    const [modalCategory, setModalCategory] = useState(false);
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState("");
    const [requestPost, setRequestPost] = useState({});
    const [paymentType, setPaymentType] = useState("");
    const [description, setDescription] = useState("");
    const [customersResult, setCustomersResult] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [showCustomersResult, setShowCustomersResult] = useState(false);
    const [searchProducts, setSearchProducts] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productsError, setProductsError] = useState(false)

    const printableDivRef = useRef();

    const { data: products, isLoading: loadingProducts } = useSWR(
        `api/v1/stores/me/products?page=${currentPage}&pageSize=10&${searchProducts && "searchBy=" + searchProducts
        }`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const { trigger } = useSWRMutation(`api/v1/stores/me/products?page=${currentPage}&pageSize=10&${searchProducts && "searchBy=" + searchProducts}`, fetcher)

    const productsList = products?.data ? products.data : products;

    // refs
    const searchDebouncerTimeout = useRef(null);

    function searchForCustomers(e) {
        const value = e.target.value;
        if (searchLoading) {
            return;
        }
        clearTimeout(searchDebouncerTimeout.current);

        setShowCustomersResult(false);
        setSearch(value);
        setCustomersResult([]);
        if (value.length >= 3) {
            searchDebouncerTimeout.current = setTimeout(async () => {
                setSearchLoading(true);
                setShowCustomersResult(true);
                const searchResponse = await api.get(
                    `api/v1/dashboard/stores/me/customers-by-name?name=${value}`
                );

                setSearchLoading(false);

                const results = searchResponse.data.result;

                setCustomersResult(
                    results.filter((result) =>
                        result.name.toLowerCase().includes(value.toLowerCase())
                    )
                );
            }, 500);
        } else {
            setSearchLoading(false);
        }
    }

    const [loading, setLoading] = useState(false);

    const [saleValue, setSaleValue] = useState(null);
    const [cashbackPercentage, setCashbackPercentage] = useState();
    const [customerInfo, setCustomerInfo] = useState("");

    async function getCustomerInfoByPhone(phone) {
        try {
            const response = await api.get(
                `api/v1/dashboard/stores/me/any-customers/${phone}/info`,
                {
                    headers: { Authorization: token },
                }
            );
            const customerInfo = response?.data?.data;

            return customerInfo;
        } catch (error) {
            console.log(error);
        }
    }

    async function verification() {
        /** CHECA PHONE E CASHBACK */
        const onlyPhoneNumbers = phone.replace(/[^0-9]/g, "") + "";
        console.log(
            "onlyPhone",
            onlyPhoneNumbers,
            typeof onlyPhoneNumbers,
            cashbackPercentage,
            onlyPhoneNumbers.length
        );
        if (cashbackPercentage && onlyPhoneNumbers.length !== 11) {
            toast.error(
                "Por favor, identifique corretamente o usuário pelo nº de telefone para ele receber o cashback.",
                {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            return;
        }

        /**VAI PROCURAR OS DADOS DO CLIENTE NO BACKEND */

        let objectValue = {};
        let sexo = document.querySelector('input[name="sexo"]:checked')
            ? document.querySelector('input[name="sexo"]:checked').value
            : "INDEFINIDO";

        if (value !== undefined && value > 0) {
            objectValue["value"] = value;
            if (phone.replace(/[^0-9]/g, "").length === 11) {
                objectValue["phone"] = phone.replace(/[^0-9]/g, "");

                const infoCliente = await getCustomerInfoByPhone(
                    objectValue.phone
                );
                setCustomerInfo(infoCliente);
                console.log("INFO CLIENTE =>", infoCliente);
            }
            if (sexo !== "INDEFINIDO") {
                objectValue["gender"] = sexo;
            }
            objectValue["description"] = description?.trim();

            setRequestPost(objectValue);
            setStep(2);
            setModalCategory(false);
        } else {
            toast.error("Preencha os dados obrigatórios (Categoria e Valor).", {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }

    const history = useHistory();

    const [wasSubmitted, setWasSubmitted] = useState(false);

    function isInvalid(value) {
        return (
            value === undefined ||
            value === null ||
            (typeof value === "string" && value.trim() === "") ||
            (typeof value === "number" && isNaN(value))
        );
    }

    function removeInvalidKeys(obj) {
        const cleanedObj = {};

        Object.keys(obj).forEach((key) => {
            if (!isInvalid(obj[key])) {
                cleanedObj[key] = obj[key];
            }
        });

        return cleanedObj;
    }

    async function handleRegisterSale(e) {
        e.preventDefault();

        if (!wasSubmitted) {
            setWasSubmitted(true);
            setLoading(true);

            const requestBody = {
                ...requestPost,
                products: selectedProducts,
                paymentType: paymentType,
                cashbackPercentage: cashbackPercentage / 100,
            };

            try {
                const response = await api.post(
                    "/api/v1/separate-sales/pdv",
                    removeInvalidKeys(requestBody)
                );

                setShowRegisterSale((prev) => !prev);

                if (response.status === 201) {
                    toast.success("Compra cadastrada com sucesso.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 6000,
                    });
                }
                if (response.status === 201) {
                    setShowRegisterSale(false);
                }

                trigger();
                setValue();
                setModalCategory(false);
                setStep(1);
                setPhone("");
                setRequestPost({});
                setPaymentType("");
                setDescription("");
                setCustomersResult([]);
                setSearch([]);
                setSearchLoading(false);
                setShowCustomersResult(false);
                setSearchProducts("");
                setCurrentPage(1);
                setTotalPages(1);
                setSelectedProducts([]);
                setProductsError(false);
                setWasSubmitted(false);
                setSaleValue(null);
                setCashbackPercentage();
                setCustomerInfo("");

                setLoading(false);
                //updateDebitValue();
                updateSales();
                setLucreiView(false);
            } catch (error) {
                if (error.response) {
                    const { status, data } = error.response;
                    setLoading(false);
                    setWasSubmitted(false);
                    //TODO! TRATAR OS ERROS 400
                    if (status === 401) {
                        history.push("/login");
                        logout();
                        toast.error(
                            "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                            {
                                position: "top-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            }
                        );
                    }

                    if (status === 400) {
                        toast.error(data?.message || "ERRO!", {
                            position: "top-right",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }

                    if (!window.navigator.onLine) {
                        toast.error(
                            "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                            {
                                position: "top-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            }
                        );
                        return;
                    }
                    if (status === 403) {
                        toast.error(
                            "Senha incorreta! Verifique seus dados e tente novamente.",
                            {
                                position: "top-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            }
                        );
                    }

                    if (status === 500) {
                        toast.error(
                            "Erro de servidor! Entre em contato com a Kobe",
                            {
                                position: "top-right",
                                autoClose: 6000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            }
                        );
                    }
                }
            }
        }
    }

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && showRegisterSale) {
                setShowRegisterSale(false);
            }
        },
        [showRegisterSale, setShowRegisterSale]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);

        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);

    // const onChangeCurrencyValue = ({ target }) => {
    //     const d = target.value.replace(/\D/, "");
    //     setValueError(false);
    //     const e = d / 100;
    //     console.log("E =>", e);
    //     if (saleValue) setSaleValue(null);
    //     setValue(e);
    // };

    const handleStepBack = () => {
        setSaleValue(saleValue);
        setStep(1);
        setCustomerInfo("");
    };

    // const handlePrint = (elementId) => {
    //     // Armazena uma referência ao elemento a ser impresso
    //     const printElement = document.getElementById(elementId);

    //     // Adiciona a classe 'printable' ao elemento a ser impresso
    //     printElement.classList.add('printable');

    //     // Chama a função de impressão
    //     window.print();

    //     // Remove a classe 'printable' após a impressão
    //     printElement.classList.remove('printable');
    // };

    // const handlePrint = () => {
    //     html2canvas(printableDivRef.current).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const printWindow = window.open('', '_blank');
    //         printWindow.document.write(`<img src="${imgData}" onload="window.print()" />`);
    //         // printWindow.document.close();
    //     });
    // };

    const handlePrint = useReactToPrint({
        removeAfterPrint: true,
        documentTitle: " ",
    });

    const cashbackToCustomer = (cashbackPercentage / 100) * (value || 0);

    function updateOrAddProduct(_id, name, price, quantity) {
        const refProduct = productsList.find((product) => product._id === _id)
        if (refProduct.inStock < quantity) setProductsError(_id)
        else setProductsError(undefined)
        setSelectedProducts((prevProducts) => {
            const productIndex = prevProducts.findIndex(
                (product) => product.name === name
            );

            if (productIndex !== -1) {
                if (Number(quantity) > 0) {
                    // Atualiza a quantidade do produto existente
                    const updatedProducts = [...prevProducts];
                    updatedProducts[productIndex] = {
                        ...updatedProducts[productIndex],
                        quantity: quantity,
                    };
                    return updatedProducts;
                } else {
                    // Remove o produto do array se a quantidade não for maior que zero
                    return prevProducts.filter(
                        (product) => product.name !== name
                    );
                }
            } else if (Number(quantity) > 0) {
                // Adiciona um novo produto ao array
                return [
                    ...prevProducts,
                    { _id: _id, name: name, price: price, quantity: quantity },
                ];
            } else {
                // Caso a quantidade não seja maior que zero e o produto não exista, retorna o estado atual
                return prevProducts;
            }
        });
    }
    function getProductKey(name, key) {
        let product = selectedProducts.find((product) => product.name === name);

        if (product && key in product) return product[key];
    }

    useEffect(() => {
        setValue(
            selectedProducts
                .reduce(
                    (total, product) =>
                        total + product.price * Number(product.quantity),
                    0
                )
                .toFixed(2)
        );
    }, [selectedProducts]);

    useEffect(() => {
        if (!loadingProducts && products.pages) setTotalPages(products.pages);
        else setTotalPages(1);
    }, [loadingProducts, products]);

    return (
        <Modal
            openModal={showRegisterSale}
            setOpenModal={setShowRegisterSale}
            title="Cadastrar Venda Avulsa"
            subtitle="Informe os dados da venda"
        >
            <div className="my-3" ref={printableDivRef}>
                <div
                    className="modalProducts"
                    style={{ right: modalCategory ? "0px" : "" }}
                >
                    <div className="w-full flex justify-start">
                        {/* <img
                            src={X}
                            onClick={() => {
                                setModalCategory(false);
                            }}
                            alt="close"
                        /> */}
                        <button
                            type="submit"
                            className={twMerge("w-fit rounded-3xl bg-secondaryGreen text-white px-3 py-2 text-base font-semibold duration-300 hover:scale-105", productsError && "opacity-50")}
                            onClick={() => setModalCategory(false)}
                            disabled={productsError !== undefined}
                        >
                            Concluir
                        </button>
                    </div>
                    <div>
                        <section className="w-full max-w-xl">
                            <form
                                action=""
                                onSubmit={() => null}
                                className="flex gap-2 items-center px-3 mt-3 bg-white rounded-3xl border-solid border-2 border-zinc-300 shadow-md mx-auto"
                            >
                                <HiSearch className="text-2xl" />
                                <input
                                    type="text"
                                    className="border-none outline-none p-2 bg-transparent w-full ring-transparent focus:ring-transparent"
                                    placeholder="Pesquisar Produtos"
                                    value={searchProducts}
                                    onChange={(e) =>
                                        setSearchProducts(e.target.value)
                                    }
                                />
                                {search.length > 0 && (
                                    <MdOutlineClose
                                        className="text-red-600 text-2xl cursor-pointer"
                                        onClick={() => {
                                            setSearchProducts("");
                                        }}
                                    />
                                )}
                            </form>

                            {selectedProducts.length > 0 && (
                                <div className={twMerge("divide-y border-2 p-2 rounded-lg mt-3", productsError ? "border-red-600" : "border-secondaryGreen")}>
                                    {selectedProducts.map((product) => {
                                        return (
                                            <div className="flex justify-between gap-4 items-center py-3">
                                                <span className="w-full">
                                                    {product.name} - R${" "}
                                                    {product.price}
                                                </span>

                                                <div>
                                                    <label htmlFor="name">
                                                        Quantidade
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            updateOrAddProduct(
                                                                product._id,
                                                                product.name,
                                                                product.price,
                                                                e.target.value
                                                            )
                                                        }
                                                        value={getProductKey(
                                                            product.name,
                                                            "quantity"
                                                        )}
                                                        type="number"
                                                        min={0}
                                                        maxLength={50}
                                                        name="name"
                                                        placeholder="0"
                                                        required
                                                    />
                                                    {productsError === product._id && (
                                                        <span className="text-red-600 text-sm">A quantidade selecionada é maior que o estoque</span>
                                                    )}
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                            )}

                            <div
                                className="divide-y border border-zinc-300 p-2 rounded-lg mt-3"
                                key={products}
                            >
                                {productsList?.map((product) => {
                                    const isSelected =
                                        selectedProducts.findIndex(
                                            (productSelected) =>
                                                productSelected.name ===
                                                product.name
                                        );
                                    return (
                                        isSelected === -1 && (
                                            <div className="flex justify-between gap-4 items-center py-3">
                                                <span className="w-full">
                                                    {product.name} - R${" "}
                                                    {product.price}
                                                </span>

                                                <div>
                                                    <label htmlFor="name">
                                                        Quantidade
                                                    </label>
                                                    <span className="text-xs text-zinc-700">Disponível: {product.inStock}</span>
                                                    <input
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            updateOrAddProduct(
                                                                product._id,
                                                                product.name,
                                                                product.price,
                                                                e.target.value
                                                            )
                                                        }
                                                        value={getProductKey(
                                                            product.name,
                                                            "quantity"
                                                        )}
                                                        type="number"
                                                        min={0}
                                                        maxLength={50}
                                                        name="name"
                                                        placeholder="0"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        )
                                    );
                                })}
                                {loadingProducts && <span>Buscando...</span>}
                            </div>

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                paginate={setCurrentPage}
                            />
                        </section>
                    </div>
                    {/* <h2>Categorias</h2>
                <section className="sectionBoxCategorys">
                    {categories &&
                        categories.map((item, index) => (
                            <div className="boxCategory">
                                <label
                                    htmlFor={item.id}
                                    onClick={() => {
                                        selectCategories();
                                    }}
                                >
                                    {item.name}
                                </label>
                                <input
                                    type="checkbox"
                                    className="categories"
                                    id={item.id}
                                    onClick={() => {
                                        selectCategories();
                                    }}
                                    value={item.id}
                                    name={item.name}
                                />
                            </div>
                        ))}
                </section> */}
                </div>
                <div className="ModalCadastroVendaAvulsa">
                    <div className="ModalWrapperTwo">
                        {/* <div className="headerModal">
                            <div className="CadInfo">
                                <h2>Cadastrar Venda Avulsa:</h2>
                                <p className="labelCad">{labelCad}</p>
                            </div>
                        </div> */}
                        <form
                            className="Salesform"
                            onSubmit={handleRegisterSale}
                        >
                            {step === 1 && (
                                <div>
                                    {/* <div className="StepTracker">
                                        <div className="Step1">
                                            <img
                                                width="25"
                                                src={Step1}
                                                alt="Step One"
                                            />
                                            <p>1º PASSO</p>
                                        </div>
                                        <div className="Step2Gray">
                                            <img
                                                width="25"
                                                src={Step2_Gray}
                                                alt="Step two"
                                            />
                                            <p>2º PASSO</p>
                                        </div>
                                    </div> */}

                                    <div className="groupHeader">
                                        <div className="group">
                                            <p>Valor da compra: *</p>
                                            <CurrencyInput
                                                className="form-control Value"
                                                onChange={(_e, maskedValue) =>
                                                    setValue(maskedValue)
                                                }
                                                value={value}
                                                type="number"
                                                placeholder="Valor"
                                                required
                                            />
                                            <p>{saleValue}</p>
                                        </div>
                                        <label>Cliente: (Opcional)</label>
                                        <div className="relative w-full">
                                            <input
                                                type="text"
                                                name=""
                                                id=""
                                                onChange={searchForCustomers}
                                                className="form-control w-full"
                                                placeholder="Buscar por cliente..."
                                                value={search}
                                            />
                                            {showCustomersResult && (
                                                <div className="absolute left-0 top-full z-10 mt-2 w-full overflow-hidden rounded border border-black bg-white shadow">
                                                    {searchLoading && (
                                                        <div className="py-3">
                                                            <CircularProgress color="primary" />
                                                        </div>
                                                    )}
                                                    {customersResult.length ? (
                                                        <ul className="m-0 flex max-h-[50vh] flex-col overflow-y-auto p-0">
                                                            {customersResult.map(
                                                                (client) => (
                                                                    <li
                                                                        key={
                                                                            client.phone
                                                                        }
                                                                        onClick={() => {
                                                                            setSearch(
                                                                                client.name
                                                                            );
                                                                            setCustomerInfo(
                                                                                client
                                                                            );
                                                                            setPhone(
                                                                                client.phone
                                                                            );
                                                                            setShowCustomersResult(
                                                                                false
                                                                            );
                                                                        }}
                                                                        className="block cursor-pointer p-3 text-base text-black no-underline hover:bg-black/5"
                                                                    >
                                                                        {
                                                                            client.name
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <>
                                                            {search &&
                                                                !searchLoading ? (
                                                                <p className="m-0 py-3 text-center font-normal">
                                                                    Nenhum
                                                                    cliente
                                                                    encontrado
                                                                    para{" "}
                                                                    <strong>
                                                                        {search}
                                                                    </strong>
                                                                    .
                                                                </p>
                                                            ) : (
                                                                <p></p>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="groupHeader">
                                        <label>
                                            Tipo de pagamento: (Opcional)
                                        </label>
                                        <select
                                            className="creation-select campo"
                                            onChange={({ target }) =>
                                                setPaymentType(target.value)
                                            }
                                        >
                                            <option>Escolha uma opção</option>
                                            <option value={"DINHEIRO FÍSICO"}>
                                                Dinheiro Físico
                                            </option>
                                            <option value="PIX">PIX</option>
                                            <option value="CART CRÉDITO">
                                                Cartão de Crédito
                                            </option>
                                            <option value="CART DÉBITO">
                                                Cartão de Débito
                                            </option>
                                        </select>
                                    </div>

                                    <div className="row">
                                        <div className="group">
                                            <p>% de Cashback (Opcional):</p>
                                            <input
                                                className="form-control Value"
                                                required
                                                onChange={(e) =>
                                                    setCashbackPercentage(
                                                        e.target.value
                                                    )
                                                }
                                                value={cashbackPercentage}
                                            />
                                            {cashbackPercentage && value && (
                                                <p className="total-cashback-message">
                                                    O cashback atribuído é no
                                                    valor de{" "}
                                                    <strong>
                                                        R${" "}
                                                        {cashbackToCustomer?.toLocaleString(
                                                            "pt-br",
                                                            {
                                                                minimumFractionDigits: 2,
                                                            },
                                                            {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }
                                                        )}
                                                    </strong>
                                                </p>
                                            )}
                                        </div>

                                        <div className="group">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                    marginBottom: "1rem",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "14px",
                                                        margin: "0",
                                                    }}
                                                >
                                                    Produtos: (Opcional)
                                                </p>
                                                <div
                                                    className="boxArrowAdd"
                                                    onClick={() => {
                                                        setModalCategory(true);
                                                    }}
                                                >
                                                    <img
                                                        src={add}
                                                        alt="adicionar produtos"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="groupCategoriesList"> */}
                                            {selectedProducts.length > 0 && (
                                                <section className="divide-y border border-zinc-300 p-2 rounded-lg">
                                                    {selectedProducts.map(
                                                        (product) => (
                                                            <article className="flex justify-between py-2">
                                                                <span>
                                                                    {
                                                                        product.quantity
                                                                    }
                                                                    x{" "}
                                                                    {
                                                                        product.name
                                                                    }
                                                                </span>
                                                                <span>
                                                                    R${" "}
                                                                    {
                                                                        product.price
                                                                    }
                                                                </span>
                                                            </article>
                                                        )
                                                    )}
                                                </section>
                                            )}
                                            {/* </div> */}
                                        </div>
                                        <div className="group">
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Sexo: (Opcional)
                                            </p>
                                            <div className="grupInputRadio">
                                                <input
                                                    type="radio"
                                                    id="MASC"
                                                    name="sexo"
                                                    value={"M"}
                                                />
                                                <label htmlFor="MASC">
                                                    Masculino
                                                </label>
                                            </div>
                                            <div className="grupInputRadio">
                                                <input
                                                    type="radio"
                                                    id="FEM"
                                                    name="sexo"
                                                    value={"F"}
                                                />
                                                <label htmlFor="FEM">
                                                    Feminino
                                                </label>
                                            </div>
                                            <div className="grupInputRadio">
                                                <input
                                                    type="radio"
                                                    id="OUT"
                                                    name="sexo"
                                                    value={"OUTRO"}
                                                />
                                                <label htmlFor="OUT">
                                                    Outro
                                                </label>
                                            </div>
                                        </div>
                                        <div className="group">
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Descrição: (Opcional)
                                            </p>

                                            <textarea
                                                value={description}
                                                onChange={({ target }) =>
                                                    setDescription(target.value)
                                                }
                                                rows="3"
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="buttonGroup mb-3 gap-2">
                                        <button
                                            type="button"
                                            onClick={() => verification()}
                                            className="mx-auto mt-3 w-4/5 rounded-3xl bg-secondaryGreen text-white p-3 text-base font-semibold duration-300 hover:scale-105"
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    color="primary"
                                                    size={21}
                                                />
                                            ) : (
                                                "Avançar"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {step === 2 && (
                                <div>
                                    <div className="StepTracker">
                                        {/* <div className="Step1">
                                            <img width="25" src={Step1} alt="" />
                                            <p>1º PASSO</p>
                                        </div>
                                        <div className="Step2Green">
                                            <img
                                                width="25"
                                                src={Step2_Green}
                                                alt=""
                                            />
                                            <p>2º PASSO</p>
                                        </div> */}
                                    </div>
                                    <div
                                        className="clienteCard"
                                        id="printableLucreiDiv"
                                    >
                                        <div style={{ width: "100%" }}>
                                            <label
                                                style={{
                                                    alignSelf: "center",
                                                    width: "100%",
                                                    fontSize: "1.3em",
                                                }}
                                            >
                                                Dados da Venda
                                            </label>
                                        </div>
                                        <div className="groupInforSale">
                                            <div className="boxInforSale print-estabelecimento">
                                                <div className="storeName">
                                                    <label>
                                                        Estabelecimento:
                                                    </label>
                                                    <span>
                                                        {localStorage.getItem(
                                                            "NameUser"
                                                        ) || "-"}
                                                    </span>
                                                </div>
                                                {localStorage.getItem(
                                                    "storeAvatar"
                                                ) && (
                                                        <img
                                                            width="80px"
                                                            alt="Logo da empresa"
                                                            src={localStorage.getItem(
                                                                "storeAvatar"
                                                            )}
                                                        />
                                                    )}
                                            </div>
                                            <div className="boxInforSale print-dataCompra">
                                                <label>Data da compra:</label>
                                                <span>
                                                    {formatDateToBrazilianFormat(
                                                        new Date()
                                                    )}
                                                </span>
                                            </div>

                                            <div className="boxInforSale">
                                                <label>Valor da Compra:</label>
                                                <span>
                                                    R${" "}
                                                    {value?.toLocaleString(
                                                        "pt-br",
                                                        {
                                                            minimumFractionDigits: 2,
                                                        },
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                                </span>
                                            </div>
                                            {cashbackPercentage && (
                                                <div className="boxInforSale">
                                                    <label>
                                                        Cashback dado ao
                                                        cliente:
                                                    </label>
                                                    <span>
                                                        R${" "}
                                                        {cashbackToCustomer?.toLocaleString(
                                                            "pt-br",
                                                            {
                                                                minimumFractionDigits: 2,
                                                            },
                                                            {
                                                                style: "currency",
                                                                currency: "BRL",
                                                            }
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            <div className="boxInforSale">
                                                <label>Produtos:</label>
                                                {selectedProducts.length >
                                                    0 && (
                                                        <section className="divide-y border border-zinc-300 p-2 rounded-lg w-full">
                                                            {selectedProducts.map(
                                                                (product) => (
                                                                    <article className="flex justify-between py-2">
                                                                        <span>
                                                                            {
                                                                                product.quantity
                                                                            }
                                                                            x{" "}
                                                                            {
                                                                                product.name
                                                                            }
                                                                        </span>
                                                                        <span>
                                                                            R${" "}
                                                                            {
                                                                                product.price
                                                                            }
                                                                        </span>
                                                                    </article>
                                                                )
                                                            )}
                                                        </section>
                                                    )}
                                            </div>
                                            <div className="boxInforSale">
                                                <label>
                                                    Tipo de Pagamento:
                                                </label>
                                                <span>
                                                    {paymentType
                                                        ? paymentType
                                                        : "Não informado"}
                                                </span>
                                            </div>
                                            <div className="boxInforSale">
                                                <label>Telefone:</label>
                                                <span>
                                                    {customerInfo.phone
                                                        ? customerInfo.phone
                                                        : "Não informado"}
                                                </span>
                                            </div>
                                            <div className="boxInforSale">
                                                <label>Cliente:</label>
                                                {customerInfo?.name ? (
                                                    <span>
                                                        {customerInfo?.name
                                                            ? customerInfo.name
                                                            : ""}
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            borderBottom:
                                                                "2px dashed black",
                                                            marginTop: "30px",
                                                        }}
                                                    ></span>
                                                )}
                                            </div>
                                            <div className="boxInforSale">
                                                <label>CPF:</label>
                                                <span>
                                                    {customerInfo?.cpf
                                                        ? customerInfo.cpf
                                                        : "-"}
                                                </span>
                                            </div>
                                            <div className="boxInforSale">
                                                <label>Descrição:</label>
                                                <span>
                                                    {requestPost.description
                                                        ? requestPost.description
                                                        : "Não informada"}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="compraInfo">
                                        <div className="colEsq">
                                            <label>Valor:</label>
                                            {phone.replace(/[^0-9]/g, "").length == 11 && <label>Telefone:</label>}

                                        </div>
                                        <div className="colDir">
                                            <label>{`R$ ${value || 0}`}</label>
                                        </div>
                                    </div> */}
                                        {/* <label>Total:</label> */}
                                        <div className="totalInfo">
                                            <div className="colDir">
                                                {/* <label className="cashback-value">
                                                {" "}
                                                R$
                                                {` ` +
                                                    parseFloat(
                                                        cashbackReais.toFixed(2)

                                                        ).toLocaleString(
                                                        "pt-br",
                                                        {
                                                            minimumFractionDigits: 2,
                                                        },
                                                        {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }
                                                    )}
                                            </label> */}
                                            </div>
                                        </div>
                                        {/* PRINT MEDIA QUERY ELEMENTS AREA */}
                                        <div className="assinatura-wrapper">
                                            <span>Assinatura</span>
                                        </div>
                                    </div>

                                    <div className="buttonGroup buttonGroupWithPrint mt-4 gap-2">
                                        {!loading && (
                                            <button
                                                type="button"
                                                className="flex w-full items-center justify-center gap-x-2 rounded-3xl bg-violet-950 p-3 text-base font-semibold text-white duration-300 hover:scale-105  "
                                                onClick={() =>
                                                    handlePrint(
                                                        null,
                                                        () =>
                                                            printableDivRef.current
                                                    )
                                                }
                                            >
                                                <BsPrinter size={20} />{" "}
                                                <span>Imprimir</span>
                                            </button>
                                        )}
                                        <div>
                                            <button
                                                onClick={handleStepBack}
                                                className="buttonCancel"
                                            >
                                                <MdKeyboardBackspace />
                                            </button>
                                            <button
                                                type="submit"
                                                className="mx-auto w-4/5 rounded-3xl bg-primaryGreen p-3 text-base font-semibold duration-300 hover:scale-105"
                                            >
                                                {loading ? (
                                                    <CircularProgress
                                                        color="primary"
                                                        size={21}
                                                    />
                                                ) : (
                                                    "Concluir"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RegisterSale;
