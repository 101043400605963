import BannerTool from "../../../../components/BannerTool";
import DefaultRoot from "../../../../components/DeafultRoot";
import MenuEmpresa from "../../../../components/MenuEmpresa";
import useLink from "../../../../hooks/useLink";
import Form from "./Form";

export default function CreatePromo() {
    const history = useLink();

    return (
        <DefaultRoot>
            <MenuEmpresa />

            <main>
                <BannerTool
                    title="Nova Promoção de Cartão Fidelidade"
                    action={() => history.push("/empresa/meus-cartoes")}
                    actionTitle={"Ver Minhas Promoções"}
                />

                <Form />
            </main>
        </DefaultRoot>
    );
}
