export default function Arrayfield({ field, item, defaultTd }) {
  if (item[field.name].length !== 0)
    return (
      <td key={field.name} className={defaultTd}>
        {
          item[field.name].map((category) => (
            <div key={category.name}>
              {category.name}
            </div>
          )
          )}
      </td>
    )
  else
    return (
      <td key={field.name} className={defaultTd}>
        Não Informado
      </td>
    )

}