import React from "react";
import './style.scss'

export default function Card(props) {
    return (
        <article className="cardHowWorks gap-3">
            <span className="index text-start">
                {props.index}
            </span>
            <img src={props.img} alt="vetor" />
            <span className="description">{props.description}</span>
        </article>
    )
}