import { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import Loading from "../../components/Loading"
import BannerTool from "../../components/BannerTool"
import MenuEmpresa from "../../components/MenuEmpresa"
import DefaultRoot from "../../components/DeafultRoot"
import MainContent from "../../components/MainContent"

import api from "../../services/api"

import NewTable from "../../components/NewTable/NewTable"
import { useParams } from "react-router-dom/cjs/react-router-dom"

export default function ClientProductsRanking() {
    const [isLoading, setIsLoading] = useState(true)
    const [products, setProducts] = useState()
    const history = useHistory()
    const params = useParams()
    const customerId = params?.customerId
    const customerName = params?.customerName

    const chartData = async () => {
        const response = await api.get(`api/v1/separate-sales/customers/${customerId}/best-selling-products`)
        console.log(response.data.data)
        setProducts(response.data.data)
        setIsLoading(false)
    }

    useEffect(() => {
        chartData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DefaultRoot>
            <MenuEmpresa />
            <BannerTool
                title={`Produtos mais vendidos ao cliente ${customerName}`}
                action={() => history.push("/empresa/lista-vendas-avulsas")}
                actionTitle={"Ver todas as Vendas Avulsas"}
            />

            <MainContent>

                <section className="salesByCategoryInfo d-flex flex-column align-items-center justify-content-center mt-5">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <NewTable
                            data={{ data: products }}
                            name={`Ranking de produtos`}
                            fields={[
                                {
                                    type: "default",
                                    title: "Produto",
                                    name: "_id",
                                },
                                {
                                    type: "default",
                                    title: "Número de vendas",
                                    name: "totalQuantity",
                                    altName: "leadCustomerName",
                                },
                                {
                                    type: "money",
                                    title: "Total vendido",
                                    name: "totalSold",
                                },
                                {
                                    type: "date",
                                    title: "Última venda",
                                    name: "latestPurchaseDate",
                                }
                            ]}
                        />
                        // <CardsRender data={products} />
                    )}
                </section>

            </MainContent>
        </DefaultRoot>

    )
}
