import lucreiImg from "../../../../assets/logoAmarelaCircular.png"

export default function PromoCard({ store, avatar, photo, title, promoPurchases }) {

    return (
        <article className="bg-white shadow-md rounded-xl p-3 flex flex-col gap-3 items-center justify-between">

            <div className="w-64 h-64 bg-zinc-100">
                <img src={photo || lucreiImg} alt="banner da promoção" className="h-full w-full object-cover" />
            </div>
            <h2 className="text-xl font-semibold text-center">{title}</h2>

            <div className="flex flex-col justify-center items-center gap-3">
                <span className="text-secondaryGreen mt-2 font-semibold">
                    COMPRE EM
                </span>
                <div className="flex text-center justify-center items-center gap-2">
                    <img
                        src={avatar}
                        alt="logo"
                        width={80}
                        className="rounded-md"
                    />
                    <span className="text-black text-sm w-[100px]">{store}</span>
                </div>
            </div>

            <span className="text-primaryPurple text-center">
                {`Faça ${promoPurchases} compras nesta empresa para completar este cartão.`}
            </span>
        </article>
    )
}