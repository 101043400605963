import { useState } from "react";
import arrowC from "../arrow.svg"

export default function CollapseElement({ titleNumber, textNumber, nameId }) {

    const title = [
        "Como faço para ganhar cashback com minha nota fiscal?",
        "Quais empresas eu posso cadastrar minhas notas?",
        "Posso cadastrar várias notas todos os dias?",
        "Quais motivos podem levar minha nota a não ser validada?"
    ]

    const texts = [
        "É muito simples, basta você após comprar em um estabelecimento que lhe entregue uma nota fiscal, você deve clicar no botão “Envie a sua nota!” acima, escolher uma das opções de envio e nos enviar a sua nota fiscal. Após isso, a sua nota passará por uma análise, é feita a validação da nota, e o valor vai cair diretamente na sua conta Lucrei.",
        "Você poderá cadastrar suas compras em qualquer empresa que no ato de sua compra lhe entregue um cupom fiscal. A única regra é que essa empresa precisa estar situada em uma das cidades disponíveis no nosso cadastro de usuário no sistema.",
        "Não, o limite de notas enviadas é de 1 nota por dia, por usuário.",
    ]

    const [Controle, setControle] = useState(false)

    return (
        <div className="collapseElement">
            <div className="headCollapse" onClick={() => setControle(!Controle)}>
                <h2>{title[titleNumber]}</h2>
                <img
                    style={{ transform: `${Controle ? 'rotate(-450deg)' : ''} ` }}
                    src={arrowC}
                />
            </div>
            <div className="inforElement">
                {titleNumber < 3 ? (
                    <p id={nameId} style={{ height: `${Controle ? `${window.document.getElementById(nameId).scrollHeight}px` : ''} `, marginTop: `${Controle ? `10px` : ''} ` }}>
                        {texts[textNumber]}
                    </p>

                ) : (
                    <>
                        <p id={nameId} style={{ height: `${Controle ? `${window.document.getElementById(nameId).scrollHeight}px` : ''} `, marginTop: `${Controle ? `10px` : ''} ` }}>
                            Vamos lá, existem alguns motivos que podem levar a sua nota a não ser aceita para que você não receba seu cashback, são eles:
                            <ul>
                                <li>Compra efetuada em cidades que não pertencem às cidades de cadastro da Lucrei;</li>
                                <li>Limite diário de notas enviadas pelo usuário excedido (1 nota por dia, por usuário);</li>
                                <li> Código da nota fiscal inválido no envio;</li>
                                <li>Foto da nota fiscal com informações ilegíveis.</li>
                            </ul>
                        </p>
                    </>
                )}
            </div>
        </div>
    )
}