import React, { useState, useCallback, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory } from "react-router-dom";
import { getToken, logout } from "../../services/auth";
import Menu from "../../components/Menu/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import IconeComprasRealizadas from "../../assets/GeneralLogo/Icones/minhas compra.png";
import IconeSaquesRealizados from "../../assets/GeneralLogo/Icones/Meus depositos.png";
import api from "../../services/api";
import cropImage from "./cropImage";
import "./style.scss";
import { ImageCropModal } from "./ImageCropModal";
import { toast } from "react-toastify";

const CreateAvatar = () => {
    const [crop, setCrop] = useState({
        aspect: 1 / 1,
        width: 300,
        height: 300,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [image, setImage] = useState({
        image: null,
        raw: {},
    });
    const [showModal, setShowModal] = useState(null);
    const token = getToken();
    const history = useHistory();
    const ref = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("avatar", image.raw);

            await api.post("/api/v1/stores/avatar", formData, {
                headers: { Authorization: token },
            });
            toast.success("Foto atualizada com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } catch (error) {
            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            toast.error("Erro ao fazer o upload da foto!", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };

    const validateImageDimensionsOnLoad = (image) => {
        if (image.width < 300 || image.height < 300) {
            setShowModal(false);
            handleCancelCrop();
            toast.error(
                "Por favor, selecione um arquivo de imagem com largura e altura maiores que 200 pixels.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return false;
        }
    };

    const handleImageChange = ({ target }) => {
        // REFACTOR THIS TO FUNCTIONS
        const validFormats = ["jpg", "png", "jpeg"];
        const sizeInMB = target.files[0].size / 1024 / 1024;
        const format = target.files[0].type.split("/")[1];
        if (!validFormats.includes(format)) {
            toast.error(
                "Por favor, selecione um arquivo nos formatos .jpg, .png ou .jpeg",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            setShowModal(false);
            handleCancelCrop();
            return false;
        }
        if (sizeInMB >= 1) {
            toast.error(
                "Por favor, selecione um arquivo de imagem menor que 1MB.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            setShowModal(false);
            handleCancelCrop();
            return false;
        }

        setImage({
            url: URL.createObjectURL(target.files[0]),
            raw: target.files[0],
        });

        setShowModal(true);
    };

    const handleCancelCrop = () => {
        setImage({});
        setShowModal(false);
        ref.current.value = "";
    };

    const onUploadCrop = useCallback(async () => {
        const blob = await cropImage(image.url, image.raw, crop);
        const ext = blob.name.split(".")[1];
        const file = new File([blob], `upload.${ext}`, {
            type: blob.type,
        });
        setImage({ raw: file, url: URL.createObjectURL(file) });
        setShowModal(false);
    }, [image.url, crop, image.raw]);

    return (
        <div className="containerCliente PurchaseScreen CreateAvatar">
            <div className="containerMenuEList">
                <div className="menuClient">
                    <Menu
                        title1="Dashboard"
                        icon1={<DashboardIcon />}
                        onClick1={() => history.push("/cliente/dashboard")}
                        title2="Minhas Compras"
                        icon2={
                            <img
                                src={IconeComprasRealizadas}
                                alt=""
                                with="25"
                                height="25"
                            />
                        }
                        onClick2={() => history.push("/cliente/compras")}
                        title3="Meus Saques"
                        icon3={
                            <img
                                src={IconeSaquesRealizados}
                                alt=""
                                with="25"
                                height="25"
                            />
                        }
                        onClick3={() => history.push("/cliente/saques")}
                        logo="/cliente/dashboard"
                        profile="/cliente/profile"
                    />
                </div>
                <div className="main-content">
                    <h4>Adicione uma nova foto de perfil</h4>
                    <div>
                        {showModal === false && image.url && (
                            <img
                                width={240}
                                height={240}
                                src={image.url}
                                alt="Avatar"
                                onLoad={validateImageDimensionsOnLoad}
                            />
                        )}
                        <form onSubmit={handleSubmit}>
                            <input
                                type="file"
                                name="avatar"
                                accept="image/*"
                                onChange={handleImageChange}
                                ref={ref}
                            />
                            <button
                                className="btn-upload"
                                type="submit"
                                disabled={!image.url}
                            >
                                Atualizar Foto
                            </button>
                        </form>

                        {showModal && (
                            <ImageCropModal>
                                <>
                                    <ReactCrop
                                        src={image.url}
                                        crop={crop}
                                        onChange={(newCrop) => setCrop(newCrop)}
                                        onImageLoaded={(image) =>
                                            validateImageDimensionsOnLoad(image)
                                        }
                                        minWidth={300}
                                        minHeight={300}
                                        locked={true}
                                        onComplete={(c) =>
                                            setCompletedCrop(crop)
                                        }
                                    />
                                    {completedCrop && (
                                        <div className="group-button">
                                            <button
                                                className="btn-cancel"
                                                onClick={handleCancelCrop}
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                className="btn-crop"
                                                onClick={() => onUploadCrop()}
                                            >
                                                Recortar
                                            </button>
                                        </div>
                                    )}
                                </>
                            </ImageCropModal>
                        )}
                    </div>
                </div>
            </div>
            <div className="rodapeCliente_Purchases"></div>
        </div>
    );
};

export default CreateAvatar;
