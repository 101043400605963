import React, { useState } from "react";

import MenuEmpresa from "../../../../components/MenuEmpresa";
import BannerTool from "../../../../components/BannerTool/index";

import DefaultButton from "../../../../components/DefaultButton";
import QrCouponReader from "./QrCouponReader";
import api from "../../../../services/api";
import DefaultRoot from "../../../../components/DeafultRoot";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ValidateModal from "./ValidateModal";
import { useEffect } from "react";

export default function ValidateCoupons() {

    const params = useParams()
    const [code, setCode] = useState(params.code)
    const [lucreiPass, setLucreiPass] = useState()
    const [qrReaderOpen, setQrReaderOpen] = useState(false)
    const [valid, setValid] = useState()
    const [message, setMessage] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [validating, setValidating] = useState(false)


    const submitHandler = async (e) => {
        e.preventDefault()
        setValidating(true)
        try {
            await api.patch(`api/v1/customer-coupons/${code}/validate`, { password: lucreiPass })

            setValid(true)
            setMessage("Cupom Validado com sucesso!")
        }
        catch (error) {
            setValid(false)
            setMessage(error.response.data.message)
        }
        finally {
            setModalOpen(true)
            setValidating(false)
        }

    }

    useEffect(() => {
        setCode(params.code)
    }, [params])


    return (
        <DefaultRoot>

            <MenuEmpresa />

            <main className="flex flex-col justify-center">

                {!qrReaderOpen && <BannerTool title="Validar Cupons" />}

                {qrReaderOpen && <QrCouponReader qrReaderOpen={qrReaderOpen} setQrReaderOpen={setQrReaderOpen} action={setCode} />}


                {!qrReaderOpen && (
                    <div className="w-fit text-center m-auto flex flex-col justify-center gap-4">

                        <DefaultButton
                            content={"Ler QR Code"}
                            className={"bg-primaryPurple text-white font-semibold"}
                            onClick={() => setQrReaderOpen(true)}
                        />

                        <span className="font-bold text-lg">OU</span>


                        <form onSubmit={submitHandler}>
                            <div className="flex flex-col justify-start text-start bg-white shadow-md rounded-xl p-3">
                                <label htmlFor="couponCode" className="font-semibold text-sm">Digite o Código:</label>
                                <input
                                    type="number"
                                    name="couponCode"
                                    placeholder="Código do Cupon"
                                    className="bg-transparent border-2 border-black p-2 rounded-xl mb-3"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    required
                                />
                                <label htmlFor="password" className="font-semibold text-sm">Digite sua senha da Lucrei:</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="bg-transparent border-2 border-black p-2 rounded-xl mb-3"
                                    placeholder="Sua Senha"
                                    value={lucreiPass}
                                    onChange={(e) => setLucreiPass(e.target.value)}
                                    required
                                />
                                <DefaultButton
                                    content={validating ? (
                                        <div className="spinner-border text-success" role="status">
                                            <span className="sr-only"></span>
                                        </div>) : "Validar"}
                                    className={"bg-secondaryGreen text-white font-semibold "}
                                    type="submit"
                                />
                            </div>
                        </form>


                    </div>
                )}

                {modalOpen && <ValidateModal valid={valid} message={message} control={setModalOpen} />}


            </main>

        </DefaultRoot>


    )
}