import BannerTool from "../../../../components/BannerTool";
import DefaultRoot from "../../../../components/DeafultRoot";
import MenuEmpresa from "../../../../components/MenuEmpresa";
import useLink from "../../../../hooks/useLink";
import RegistSellForm from "./RegistSellForm";
import MainContent from "../../../../components/MainContent"

export default function RegistSell() {
    const history = useLink();

    return (
        <DefaultRoot>
            <MenuEmpresa />
            <BannerTool
                title="Cadastrar Nova Venda Em Cartão Fidelidade"
                action={() => history.push("/empresa/meus-cartoes")}
                actionTitle={"Minhas Promoções de Cartão Fidelidade"}
            />

            <MainContent>
                <RegistSellForm />
            </MainContent>
        </DefaultRoot>
    );
}
