import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import NewPass from "../../components/NewPassword/index";

import api from "../../services/api";
import { getToken, logout } from "../../services/auth";

import ProfileEmpresa from "../../components/Profile/Store/dados";
import ProfileCliente from "../../components/Profile/Customer/dados";
//import "./style.scss";
import { toast } from "react-toastify";

import MenuEmpresa from "../../components/MenuEmpresa";
import MenuCliente from "../../components/MenuCliente";
import CustomerRegistProfile from "./CustomerRegistProfile";
//import ProfileCliente from '../../components/Profile/'

export default function Profile() {
    const history = useHistory();
    const token = getToken();
    const [user, setUser] = useState({});
    console.log(user)
    useEffect(() => {
        async function getLoggedUser() {
            try {
                const response = await api.get("api/v1/auth/me");

                const { email, name, role } = response.data;

                setUser(response.data);
            } catch (error) {
                const { status } = error.response;
                console.log("error", status);
                if (status === 401) {
                    history.push("/login");
                    logout();
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }
            }
        }
        getLoggedUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (user.role === "store")
        return (
            <div>
                <MenuEmpresa />
                <div className="containerProfile" id="Store">
                    <div className="container">
                        <div className="dadosEmpresa">
                            <ProfileEmpresa token={token} />
                        </div>

                        <div className="NovaSenha">
                            <NewPass />
                        </div>
                    </div>
                </div>
            </div>
        );
    else if (user.cpf === "000.000.000-00")
        return <CustomerRegistProfile user={user} />

    else
        return (
            <div className="bg-zinc-100 h-screen">
                <MenuCliente />
                <div className="flex flex-col justify-center max-w-screen-xl mx-auto px-2">
                    <div className="dadosEmpresa">
                        <ProfileCliente token={token} />
                    </div>

                    <div className="NovaSenha">
                        <NewPass />
                    </div>
                </div>
            </div>
        );
}
