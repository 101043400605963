import ctl from "@netlify/classnames-template-literals"

export default function ButtonField({ field, item, defaultTd }) {
  const isDisabled = field.disabled(item)
  return (
    <td
      key={field.title}
      className={`${defaultTd} ${field.type === "button" && "text-center"}`}
    >
      <button
        className={ctl(
          `${field.className || "bg-secondaryGreen"}
            text-white py-1 px-3 rounded-md
            ${!isDisabled && "duration-100 hover:scale-105"}
            ${isDisabled && "opacity-50"}`
        )}
        onClick={() => field.action(item)}
        disabled={isDisabled}
      >
        {
          typeof field.name === 'function' ? (
            field.name(item)
          ) : (
            field.name
          )
        }
      </button>
    </td>
  )
}