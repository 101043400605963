const tabs = [
    { id: false, name: 'Todas' },
    { id: "ENTRADA/RECEITA", name: 'Entradas' },
    { id: "SAIDA/DESPESA", name: 'Saídas' },
]

export default function TransactionTypeFilter({ filter, control }) {

    return (
        <div className={`flex w-full justify-start md:justify-end md:mb-0`}>
            {
                tabs.map((tab, index) => {
                    return (
                        <button
                            className={`rounded-md py-2 px-3 ${tab.id === filter ? 'bg-secondaryGreen text-white' : 'bg-transparent text-gray-800'}`}
                            onClick={() => control(tabs[index].id)}
                            key={tab.id}
                        >
                            {tab.name}
                        </button>
                    )
                })
            }

        </div>
    )
}
