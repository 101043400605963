import LoadingPulse from "../../../components/LoadingPulse"

export default function HeroBanner(props) {
    return (
        <section className="
        bg-[url('./assets/img/heroDash.png')] bg-cover bg-black bg-opacity-90
        w-screen pt-6 pb-2 px-4 md:px-14 -mt-10
        text-white
        ">
            <h3 className="flex font-bold text-3xl">Olá, {props.loading ? <LoadingPulse /> : props.user.name}!</h3>

            <p className="font-semibold text-xl text-justify">
                Aqui estão todas as informações financeiras da sua
                empresa.
            </p>
        </section>
    )
}
