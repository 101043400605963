import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa"
import { useEffect } from "react";

export default function CreateForm({ formik, image, setImage }) {

    const imageInput = useRef(null)

    const handleImageChange = ({ target }) => {
        if (target.files.length === 0) return;
        const validFormats = ["jpg", "png", "jpeg"];
        const sizeInMB = target.files[0].size / 1024 / 1024;
        const format = target.files[0].type.split("/")[1];
        if (!validFormats.includes(format)) {
            toast.error(
                "Por favor, selecione um arquivo nos formatos .jpg, .png ou .jpeg",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return false;
        }
        if (sizeInMB > 2) {
            toast.error(
                "Por favor, selecione um arquivo de imagem menor ou igual a 2MB.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            setImage({})
            return false;
        }

        setImage({
            url: URL.createObjectURL(target.files[0]),
            raw: target.files[0],
        });
    };

    useEffect(() => {
        if (Object.keys(image).length === 0)
            imageInput.current.value = ""
    }, [image])

    return (
        <div className="bg-white rounded-xl shadow-lg p-4 w-11/12 max-w-screen-md mx-3 flex flex-col gap-3">
            <h2 className="text-2xl font-semibold text-zinc-500 mb-3">Criar Promoção de Cartão Fidelidade</h2>
            <div>
                <label htmlFor="title">Título da Promoção</label>
                <input
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    type="text"
                    maxLength={50}
                    name="title"
                    placeholder="Título do Cartão"
                    required
                />
            </div>
            <div>
                <label htmlFor="description">Descrição da Promoção</label>
                <textarea
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    name="description"
                    placeholder="Descrição do Cartão"
                />
            </div>


            <div>
                <label htmlFor="photo">Imagem</label>

                <div className="flex items-center gap-2">
                    <input
                        ref={imageInput}
                        className="form-control"
                        type="file"
                        id="receipt-photo"
                        name="receipt-photo"
                        accept=".jpg,.png,.jpeg"
                        onChange={handleImageChange}
                    />
                    {Object.keys(image).length > 0 && (
                        <FaTrashAlt
                            className="text-red-600 cursor-pointer"
                            onClick={() => {
                                setImage({})
                            }}
                        />
                    )}

                </div>

            </div>

        </div>
    )
}