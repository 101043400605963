import { useState } from 'react'
import { Listbox } from '@headlessui/react'
import Pagination from '../../../../components/Pagination'
import useSWR from 'swr';
import fetcher from '../../../../services/fetcher';
import { useEffect } from 'react';

export default function PromoSelect({ selectedPromo, setSelectedPromo }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data: promos, isLoading: loadingPromos } = useSWR(
        `api/v1/stores/me/loyalty-card-promotions?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        if (!loadingPromos) {
            setTotalPages(promos.pages);
        }
    }, [promos, loadingPromos]);


    return (

        <Listbox value={selectedPromo} onChange={setSelectedPromo} as="div" className="form-select relative">
            <Listbox.Button className={"w-full text-start"}>{selectedPromo.title}</Listbox.Button>
            <Listbox.Options className="absolute bg-white rounded-lg shadow-md w-full p-3 left-0 top-full">
                {loadingPromos ? <span>Aguarde...</span> : (
                    promos.data.map((promo) => (
                        <Listbox.Option
                            key={promo._id}
                            value={promo}
                            disabled={!promo.isActive}
                            className={"cursor-pointer hover:bg-green-100"}
                        >
                            {promo?.title}
                        </Listbox.Option>
                    ))
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </Listbox.Options>
        </Listbox>
    )
}