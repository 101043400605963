import "./style.scss"

/* Hooks */
import { useEffect, useState } from "react"
import useLink from "../../hooks/useLink"
import useSWRImmutable from "swr/immutable"
import useSWR from "swr"

/* Components */
import MenuEmpresa from "../../components/MenuEmpresa"
import Chart from "./components/Chart/Chart.jsx"
import Deposit from "../../components/Modals/Deposito/index"
import RegisterSaleModal from "../../components/Modals/CadVenda/index"
import RegisterSaleModalTwo from "../../components/Modals/CadVendaAvulsa/index"
import { ChatBot } from "../../components/ChatBot"
import HeroBanner from "./components/HeroBanner"
import Footer from "../../components/Footer/index"

/* Utilities */
import { toast } from "react-toastify"
//import Error401 from "../../excessoes"
//import Error401 from "../../excessoes"
import api from "../../services/api"
//import { logout } from "../../services/auth"
import RegistClient from "../../components/Modals/registClient"
import Loading from "../../components/Loading"
import SalesTable from "./components/LastSellTable/SalesTable"
import SeparateSalesTable from "./components/LastSellTable/SeparateSalesTable"
import useSWRMutation from "swr/mutation"
import DropDownCard from "./components/DropDownCard"
import { FcInfo } from "react-icons/fc"

import links from "./data/links.js"
import UpdateToastModal from "./components/UpdateToastModal.jsx"
import BirthdayCustomers from "./components/BirthdayCustomers/index.jsx"
// import NewSeparateSale from "../../components/Modals/NewSeparateSale/index.jsx"
import AddProduct from "../../components/Modals/Product/Add/index.jsx"

export default function Dash() {
    const fetcher = async (url) => {
        const response = await api.get(url)
        return response.data
    }

    const history = useLink()
    const [showDeposit, setShowDeposit] = useState(false)
    const [showRegistClient, setShowRegistClient] = useState(false)
    const [showRegisterSale, setShowRegisterSale] = useState(false)
    const [showRegisterSaleTwo, setShowRegisterSaleTwo] = useState(false)
    const [showAddProduct, setShowAddProduct] = useState(false)
    const [sales, setSales] = useState()
    const [lucreiView, setLucreiView] = useState(false)
    const [cache, setCache] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [updateToastModal, setUpdateToastModal] = useState(false)

    /* Data fetching with SWR. Docs: https://swr.vercel.app/pt-BR/docs/getting-started */
    const {
        data: user,
        isLoading: loadingUser,
        mutate: updateUser,
    } = useSWRImmutable("api/v1/auth/me", fetcher)

    const { data: debit, mutate: updateDebit } = useSWR(
        "api/v1/stores/me/debit",
        fetcher,
        { revalidateOnFocus: false }
    )

    const { data: secondarySales, mutate: fetchSecondary } = useSWR(
        "api/v1/stores/me/sales?pageSize=5&sortBy=-createdAt",
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: false,
        }
    )

    const {
        data: defaultSales,
        isLoading: loadingDefaultSales,
        mutate: updateDefaultSales,
    } = useSWR("api/v1/separate-sales?limit=5&sortBy=-createdAt", fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    const { trigger: updateSeparate } = useSWRMutation(
        "api/v1/separate-sales?limit=5&sortBy=-createdAt",
        fetcher
    )

    if (!loadingUser) {
        const KOBE_TAX = 0
        localStorage.setItem(KOBE_TAX, user?.kobeTax)
    }

    const DEPOSIT = "@DEPOSIT"

    /* Error Dispatch */
    const unableToSellError = () => {
        toast.error(
            "Caro usuário, a funcionalidade de 'Cadastrar Venda' só estará disponível após o depósito solicitado por você ter sido concluído.",
            {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }
        )
    }

    /* Effects */

    useEffect(() => {
        if (debit) localStorage.setItem(DEPOSIT, JSON.stringify(debit))
    }, [debit])

    useEffect(() => {
        if (!lucreiView) setSales(defaultSales)

        if (lucreiView) setSales(secondarySales)

        if (!cache) {
            if (!lucreiView) {
                setIsLoading(loadingDefaultSales)
                setSales(defaultSales)
            } else {
                const fetch = async () => {
                    setIsLoading(true)
                    await fetchSecondary()
                    setIsLoading(false)
                    setCache(true)
                }
                fetch()
            }
        }
    }, [
        lucreiView,
        cache,
        fetchSecondary,
        loadingDefaultSales,
        secondarySales,
        defaultSales,
    ])

    useEffect(() => {
        if (lucreiView) {
            setSales(secondarySales)
        }
    }, [secondarySales, lucreiView])

    const features = links(
        user,
        history,
        unableToSellError,
        setShowDeposit,
        setShowRegisterSale,
        setShowRegisterSaleTwo,
        setShowRegistClient,
        setShowAddProduct
    )

    return (
        <div className="containerDash">
            <ChatBot />
            {/* Modals */}
            {showRegisterSale && (
                <RegisterSaleModal
                    setShowRegisterSale={setShowRegisterSale}
                    showRegisterSale={showRegisterSale}
                    updateDebitValue={updateDebit}
                    updateSales={updateDefaultSales}
                />
            )}

            <RegisterSaleModalTwo
                setShowRegisterSale={setShowRegisterSaleTwo}
                showRegisterSale={showRegisterSaleTwo}
                updateDebitValue={updateDebit}
                updateSales={updateSeparate}
                setLucreiView={setLucreiView}
            />

            <AddProduct
                openModal={showAddProduct}
                setOpenModal={setShowAddProduct}
                onAdd={updateSeparate}
            />

            {/* <NewSeparateSale
                openModal={showRegisterSaleTwo}
                setOpenModal={setShowRegisterSaleTwo}
                onAdd={updateSeparate}
            /> */}

            {showDeposit && (
                <Deposit
                    setShowDepositModal={setShowDeposit}
                    showDepositModal={showDeposit}
                    updateUser={updateUser}
                />
            )}

            <RegistClient
                setShowRegistClient={setShowRegistClient}
                showRegistClient={showRegistClient}
            />

            <UpdateToastModal
                updateToastModal={updateToastModal}
                setUpdateToastModal={setUpdateToastModal}
            />

            {/* Page's code */}
            <MenuEmpresa />

            <HeroBanner loading={loadingUser} user={user} />

            <main className="max-w-screen-2xl mx-auto flex flex-col">
                <div className="grid-containerr">
                    <section className="CotainerTwo px-4">
                        <div className="d-flex agroupItens">
                            <div className="BoxDashLeft">
                                <Chart user={user} />

                                <div className="flex gap-2 mt-8 items-center justify-evenly mx-auto w-11/12 bg-slate-50 p-3 rounded-xl shadow-md">
                                    <FcInfo className="text-7xl md:text-4xl" />
                                    <div className="text-center">
                                        <span>
                                            Novidade! Agora as nossas
                                            ferramentas estão organizadas por
                                            categoria. Clique nas categorias
                                            para ver as ferramentas.{" "}
                                        </span>
                                        <span
                                            className="underline text-secondaryGreen cursor-pointer"
                                            onClick={() =>
                                                setUpdateToastModal(true)
                                            }
                                        >
                                            Saiba Mais
                                        </span>
                                    </div>
                                </div>
                                <section className="grid md:grid-cols-2 px-4 my-8 gap-6 w-full">
                                    {!loadingUser &&
                                        features.map((featureGroup) => {
                                            return (
                                                /* Para ativar os módulos, basta excluir esse primeiro Dropdown
                                                e descomentar o próximo.
                                                */
                                                <DropDownCard
                                                    title={featureGroup.title}
                                                    color={featureGroup.color}
                                                    links={featureGroup.links}
                                                />

                                                /*  <DropDownCard
                                                     title={featureGroup.title}
                                                     color={featureGroup.color}
                                                     links={featureGroup.links.filter(
                                                         (link) =>
                                                             !link.module ||
                                                             user?.modules.includes(
                                                                 link.module
                                                             )
                                                     )}
                                                 /> */
                                            )
                                        })}
                                </section>
                            </div>

                            <BirthdayCustomers />

                            {/* <Ranking ranking={ranking} isLoading={loadingRanking} /> */}
                        </div>
                    </section>
                </div>

                <div className="flex rounded-xl overflow-hidden bg-white w-max mt-5 mx-auto flex-col md:flex-row">
                    <button
                        className={`p-3 duration-500 ${!lucreiView && "bg-primaryPurple text-white"
                            }`}
                        onClick={() => setLucreiView(false)}
                    >
                        Vendas Avulsas
                    </button>
                    <button
                        className={`p-3 duration-500 ${lucreiView && "bg-primaryGreen"
                            }`}
                        onClick={() => setLucreiView(true)}
                    >
                        Vendas com Cashback
                    </button>
                </div>

                <div className="px-3 mx-auto w-full">
                    {isLoading ? (
                        <Loading />
                    ) : lucreiView ? (
                        <SalesTable />
                    ) : (
                        <SeparateSalesTable />
                    )}
                </div>
            </main>

            <div className="rodape mt-5">
                <Footer />
            </div>
        </div>
    )
}
