import { useState } from "react"

import moment from "moment"
import useSWRMutation from "swr/mutation"

import BannerTool from "../../components/BannerTool"
import DefaultRoot from "../../components/DeafultRoot"
import MenuEmpresa from "../../components/MenuEmpresa"
import CustomSelect from "../../components/CustomSelect"

import { TransactionsContext } from "./context/context"

import fetcher from "../../services/fetcher"

import { yearsMap } from "../../utils/years"
import { monthsMap } from "../../utils/months"

import Stats from "./components/Stats"
import RegistButtons from "./components/RegistButtons"
import TransactionsStatement from "./components/TransactionsStatement"


export default function Transactions() {

    const [filter, setFilter] = useState({ year: moment().format("YYYY"), month: moment().format("MM") })

    const [transactionModalOpen, setTransactionModalOpen] = useState(false)
    const [categoryModalOpen, setCategoryModalOpen] = useState(false)

    const [registCallback, setRegistCallback] = useState(false)
    /* If the user chooses to add a new category while the transaction registration modal 
    is open, this state will be activated as true to open the category registration 
    modal and when closing it triggers a callback that will return to the transaction registration 
    modal automatically */

    // SWR Mutation Trigger - Revalidates selected key when fired
    const { trigger: stats } = useSWRMutation(`api/v1/transactions/overview?month=${filter.month}&year=${filter.year}`, fetcher)


    /* Filter controls */
    const controlMonth = (value) => {
        setFilter({ year: filter.year, month: value.id })
    }

    const controlYear = (value) => {
        setFilter({ year: value.id, month: filter.month })
    }


    return (
        <DefaultRoot>

            <MenuEmpresa />

            <main>

                <BannerTool
                    title="Controle Financeiro"
                />

                <TransactionsContext.Provider value={{ filter, setFilter, transactionModalOpen, setTransactionModalOpen, categoryModalOpen, setCategoryModalOpen, registCallback, setRegistCallback }}>
                    <div className="flex flex-col gap-4 md:gap-5 justify-center items-center w-[95%] 2xl:w-full max-w-screen-2xl mx-auto">

                        {/* Period Controls */}
                        <section className="flex mx-2 w-full justify-start gap-2">
                            <CustomSelect options={monthsMap()} active={moment().format("MM")} control={controlMonth} />
                            <CustomSelect options={yearsMap()} active={yearsMap().length} control={controlYear} />
                        </section>

                        {/* Overview Cards */}
                        <Stats />

                        {/* Add new regist and add new category buttons */}
                        <RegistButtons transaction={setTransactionModalOpen} category={setCategoryModalOpen} />

                        {/* Table and transactions add and category add modals */}
                        <TransactionsStatement
                            updateStats={stats}
                            setCategoryModalOpen={setCategoryModalOpen}
                        />

                    </div>
                </TransactionsContext.Provider>

            </main>
        </DefaultRoot>
    )
}