import React from 'react';
import "./Style.scss";
import MaskedInputCpf from "../../../utils/maskInput_cpf";
import { Link } from "react-router-dom";

export default function WithdrawMain() {
  const CASHBACK = '@cashback';
  const cashback = localStorage.getItem(CASHBACK)
  return (
    <div className="tudoSaque">
      <div className="containerSaque">
        <h1>Solicitar Saque:</h1>
        <form className="formSaque">
          <div className="group">
            <label>Informe seu CPF:</label>
            <MaskedInputCpf
              className="campoText"
              /*onChange={e => setCpf(e.target.value)}
              value={cpf} */
              mask="999.999.999-99"
              id="campo_CPF"
              maxLength="16"
              type="text"
              name='cpf'
              placeholder="Ex: 000.000.000-00"
              required />
          </div>
          <div className="group">
            <label>Informe o Valor:</label>
            <input type="text" className="campoText" placeholder="R$:" />
          </div>
          <div className="group">
            <h1  >{`R$ ${cashback}`}</h1>
            <span>Saldo Disponível:</span>
          </div>
          <div className="group">
            <Link to="/customer/dashboard/withdraw/bankdata"><button type="submit" className="buttonConfirm">Confirmar</button></Link>
            <Link to="/customer/dashboard"><button className="buttonCancel">Cancelar</button></Link>
          </div>
        </form>
      </div>
    </div>
  )
};