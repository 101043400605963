import { useEffect, useState } from "react";

import useSWR from "swr";
import { toast } from "react-toastify";
import { HiSearch } from "react-icons/hi";
import useSWRMutation from "swr/mutation";
import { MdOutlineClose } from "react-icons/md";

import Loading from "../../../components/Loading";
import BannerTool from "../../../components/BannerTool";
import Pagination from "../../../components/Pagination";
import DefaultRoot from "../../../components/DeafultRoot";
import MenuEmpresa from "../../../components/MenuEmpresa";
import NewTable from "../../../components/NewTable/NewTable";
import AddProduct from "../../../components/Modals/Product/Add";
import DeleteProduct from "../../../components/Modals/Product/Delete";

import api from "../../../services/api";
import fetcher from "../../../services/fetcher";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import EditProduct from "../../../components/Modals/Product/Edit";


export default function ProductsEmpresa() {

  const [search, setSearch] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [targetProduct, setTargetProduct] = useState()
  const [loadingDelete, setLoadingDelete] = useState(false)


  const { data: products, isLoading: loadingProducts } = useSWR(
    `api/v1/stores/me/products?page=${currentPage}&pageSize=10&${search && 'searchBy=' + search}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const { trigger: updateProductsList } = useSWRMutation(`api/v1/stores/me/products?page=${currentPage}&pageSize=10&${search && 'searchBy=' + search}`, fetcher)

  const HandleSearch = (e) => {
    e.preventDefault()
    setSearchQuery(search)
    console.log(e.target.value)
    //setSearch(e.target.value)
  }

  const handleDeleteProduct = async (id) => {
    try {
      setLoadingDelete(true)

      await api.delete(`api/v1/products/${id}`)

      toast.success(
        "Produto excluído com sucesso!",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        }
      )
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      })
    } finally {
      setLoadingDelete(false)
      setOpenDeleteModal(false)
      updateProductsList()
    }
  }

  /* Effects */
  useEffect(() => {
    if (!loadingProducts && products.pages)
      setTotalPages(products.pages)
    else
      setTotalPages(1)
  }, [loadingProducts, products])

  return (
    <DefaultRoot>
      <MenuEmpresa />

      <main>
        <BannerTool
          title="Produtos Cadastrados"
          action={() => setOpenAddModal(true)}
          actionTitle={"Cadastrar Novo Produto"}
        />

        {/* Modals */}

        <AddProduct
          openModal={openAddModal}
          setOpenModal={setOpenAddModal}
          onAdd={updateProductsList}
        />

        <EditProduct
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          onAdd={updateProductsList}
          product={targetProduct}
        />

        <DeleteProduct
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          item={targetProduct}
          handleDelete={handleDeleteProduct}
          loading={loadingDelete}
        />

        {/* Content */}

        <section className="w-full flex justify-center">
          <form
            action=""
            onSubmit={HandleSearch}
            className="flex gap-2 items-center px-3 mt-3 bg-white rounded-3xl border-solid border-2 border-zinc-300 shadow-md w-[700px] max-w-[90%] mx-auto"
          >
            <HiSearch className="text-2xl" />
            <input
              type="text"
              className="border-none outline-none p-2 bg-transparent w-full ring-transparent focus:ring-transparent"
              placeholder="Pesquisar Produtos"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search.length > 0 && (
              <MdOutlineClose
                className="text-red-600 text-2xl cursor-pointer"
                onClick={() => {
                  setSearch("")
                  // setSearchQuery("")
                }}
              />
            )}
            {/* <button
                  className="min-h-full flex justify-center items-center bg-secondaryGreen px-4 py-2 rounded-3xl text-white -me-4 hover:scale-105 duration-100"
                  type="submit"
                >
                  Buscar
                </button> */}
          </form>
        </section>

        {loadingProducts ? (
          <Loading />
        ) : (
          <div className="flex flex-col justify-center items-center max-w-screen-2xl mx-auto">
            <section className="mt-3 w-full px-2">
              <NewTable
                data={products.data ? products : { data: products }}
                name={""}
                fields={[
                  {
                    type: "button",
                    title: "",
                    name: (
                      <PencilSquareIcon
                        className="h-6 w-6 text-secondaryGreen"
                        aria-hidden="true"
                      />
                    ),
                    disabled: (item) => item.cashback > 0,
                    action: (item) => {
                      setTargetProduct(item)
                      setOpenEditModal(true)
                    },
                    className: "bg-transparent",
                  },
                  {
                    type: "button",
                    title: "",
                    name: (
                      <TrashIcon
                        className="h-6 w-6 text-red-500"
                        aria-hidden="true"
                      />
                    ),
                    disabled: (item) => item.cashback > 0,
                    action: (item) => {
                      setTargetProduct(item.id)
                      setOpenDeleteModal(true)
                    },
                    className: "bg-transparent",
                  },
                  {
                    type: "default",
                    title: "Código",
                    name: "code",
                  },
                  {
                    type: "default",
                    title: "Produto",
                    name: "name",
                  },
                  {
                    type: "money",
                    title: "Preço",
                    name: "price",
                  },
                  {
                    type: "default",
                    title: "Estoque",
                    name: "inStock",
                  }
                ]}
              />
            </section>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              paginate={setCurrentPage}
            />

          </div>
        )}
      </main>
    </DefaultRoot>
  )
}
