import React from "react";
import InputMask from "react-input-mask";
import "./style.scss";

const MaskedInput = ({ value, name, onChange, mask, onBlur }) => {
  return (
    <InputMask
      className="campo"
      placeholder="Ex: 000.000.000-00"
      name={name}
      mask={mask}
      value={value}
      onChange={onChange}
      required
      onBlur={onBlur}
    />
  );
};

export default MaskedInput;
