import Menu from "../../components/Menu/Menu";

import {
    BanknotesIcon,
    HomeIcon,
    ShoppingBagIcon,
    UsersIcon,
} from "@heroicons/react/24/outline";

export default function MenuEmpresa() {
    return (
        <Menu
            home="/empresa/dashboard"
            navigation={[
                {
                    name: "Dashboard",
                    href: "/empresa/dashboard",
                    icon: HomeIcon,
                },
                {
                    name: "Minhas Vendas",
                    href: "/empresa/vendas",
                    icon: ShoppingBagIcon,
                },
                {
                    name: "Minhas Vendas Avulsas",
                    href: "/empresa/lista-vendas-avulsas",
                    icon: ShoppingBagIcon,
                },
                {
                    name: "Meus clientes",
                    href: "/empresa/clientes",
                    icon: UsersIcon,
                },
                {
                    name: "Meus Depósitos",
                    href: "/empresa/depositos",
                    icon: BanknotesIcon,
                },
            ]}
            /*  title1="Dashboard Lucrei"
         icon1={DashboardIcon}
         onClick1={() => {
             history.push("/empresa/dashboard");
         }}
         title2="Minhas Vendas"
         icon2={MinhasVendas}
         onClick2={() => { history.push("/empresa/vendas") }}
         title3="Vendas Avulsas"
         icon3={ChartIcon}
         onClick3={() => history.push("/empresa/vendas-avulsas")}
         title4="Meus Depositos"
         icon4={IconeComissoes}
         onClick4={() => history.push("/empresa/depositos")}
         title5="Meus Clientes"
         icon5={ClientsIcon}
         onClick5={() => history.push("/empresa/clientes")}
         title6="Minhas Estatísticas"
         icon6={DashboardIcon}
         onClick6={() => history.push("/empresa/estatisticas")}
         logo="/empresa/dashboard" */
        />
    );
}
