import React, { useState, useEffect } from "react";
import "./style.scss";
import Footer from "../../components/Footer/index";
import Menu from "../../components/Menu/Menu";
import DashboardIcon from "../../assets/svg/clipboard-data.svg";
import IconeComprasRealizadas from "../../assets/GeneralLogo/Icones/minhas compra.png";
import IconeSaquesRealizados from "../../assets/GeneralLogo/Icones/Meus depositos.png";
import X from "../../assets/X.svg";
import { TbAdjustmentsHorizontal } from "react-icons/tb";

import search from "../../assets/search.svg";
import config from "../../assets/config.svg";

import { getToken, logout } from "../../services/auth";
import api from "../../services/api";
import StoreGrid from "../../components/StoreGrid";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MenuCliente from "../../components/MenuCliente";

let controlSearch = "";

export default function StoresScreen() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [stores, setStores] = useState(null);
    const [c, setC] = useState(null);
    const [city, setCity] = useState("");
    const [segment, setSegment] = useState("");
    const [Search, Setsearch] = useState("");

    const [segments, setSegments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noStoreSegment, setNoStoreSegment] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const token = getToken();
    const history = useHistory();

    const getUser = async () => {
        try {
            const response = await api.get(`api/v1/auth/me`, {
                headers: { Authorization: token },
            });

            setCity(response.data.city);
        } catch (error) {
            const { status } = error.response;
            console.log("error", status);
            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    function toClean() {
        let elements = document.getElementsByName("segment");

        for (let index = 0; index < elements.length; index++) {
            document.getElementsByName("segment")[index].checked = false;
        }

        setSegment("");
    }

    // MUDAR PARA loadStores E ALTERAR EM TODO CANTO
    const loadStores = async () => {
        try {
            setLoading(true);

            const URL = segment
                ? `api/v1/stores?sortBy=name&city=${city}&segment=${segment}&pageSize=6&pageNumber=${currentPage}`
                : `api/v1/stores?sortBy=name&city=${city}&pageSize=6&pageNumber=${currentPage}`;
            const response = await api.get(URL, {
                headers: { Authorization: token },
            });
            setLoading(false);
            setStores(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            setLoading(false);
            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const loadSegments = async () => {
        try {
            setLoading(true);

            const response = await api.get(`api/v1/segments?sortBy=name`, {
                headers: { Authorization: token },
            });

            setLoading(false);
            setSegments(response.data);
        } catch (error) {
            setLoading(false);
            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const loadCities = async () => {
        try {
            setLoading(true);

            const response = await api.get(`api/v1/cities?sortBy=name`, {
                headers: { Authorization: token },
            });

            setC(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const filterStoreBySegment = async () => {
        try {
            setLoading(true);
            setCurrentPage(1);
            const URL = segment
                ? `api/v1/stores?sortBy=name&city=${city}&segment=${segment}&pageSize=6&pageNumber=1`
                : `api/v1/stores?sortBy=name&city=${city}&pageSize=6&pageNumber=1`;

            const response = await api.get(URL, {
                headers: { Authorization: token },
            });

            setLoading(false);
            if (response.data.data.length < 1 && segment)
                setNoStoreSegment(true);

            setStores(response.data.data);
            setTotalPages(response.data.pages);
        } catch (error) {
            const { status } = error.response;
            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    useEffect(() => {
        getUser();
        loadStores(city);
        loadCities();
        loadSegments();
        /* eslint-disable-next-line */
    }, []);

    useEffect(() => {
        filterStoreBySegment();
        Setsearch("");
        controlSearch = "";
        /* eslint-disable-next-line */
    }, [segment]);

    useEffect(() => {
        setNoStoreSegment(false);
        loadStores();
        Setsearch("");
        controlSearch = "";
        /* eslint-disable-next-line */
    }, [city]);

    const paginate = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber);
            const URL = segment
                ? `api/v1/stores?sortBy=name&city=${city}&segment=${segment}&pageSize=6&pageNumber=${pageNumber}`
                : `api/v1/stores?sortBy=name&city=${city}&pageSize=6&pageNumber=${pageNumber}`;

            const response = await api.get(URL, {
                headers: { Authorization: token },
            });
            setLoading(false);
            setStores(response.data.data);
        } catch (error) {
            console.log(error);
            const { status } = error.response;
            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const handleChange = ({ target }) => {
        setCurrentPage(1);
        setNoStoreSegment(false);
        setCity(target.value);
    };

    async function handleSearch() {
        const URL = `api/v1/stores/all?search=${Search}`;
        const response = await api.get(URL, {
            headers: { Authorization: token },
        });
        controlSearch = Search;
        setStores(response.data.data);
        setTotalPages(response.data.pages);
    }

    const handleChangeSegmentFilter = (target) => {
        setSegment(target);
    };

    return (
        <div className="containerCliente StoresScreen">
            <div className="containerMenuEList">
                <div className="menuClient">
                    <MenuCliente />
                </div>

                <div className="main-content">
                    <div className="inputSearch">
                        <div className="leftInput">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSearch();
                                    toClean();
                                }}
                            >
                                <img
                                    src={search}
                                    onClick={() => {
                                        toClean();
                                        handleSearch();
                                    }}
                                />
                                <input
                                    type={"text"}
                                    value={Search}
                                    placeholder={"Buscar"}
                                    onChange={(e) => Setsearch(e.target.value)}
                                />
                            </form>
                        </div>
                        <button
                            className="buttonRight"
                            onClick={() => setModalFilter(true)}
                        >
                            <img src={config} />
                        </button>
                    </div>
                    <div
                        className="modalFilter"
                        style={{ right: modalFilter ? 0 : "" }}
                    >
                        <div className="wrapper">
                            <div className="headerModal">
                                <p
                                    className="clear-filter"
                                    role="button"
                                    onClick={toClean}
                                >
                                    Limpar
                                </p>
                                <img
                                    onClick={() => setModalFilter(false)}
                                    src={X}
                                />
                            </div>
                            <h2>Filtro</h2>
                            <div
                                className="select-wrapper"
                                style={{ padding: "0px 12px" }}
                            >
                                {c && (
                                    <select
                                        className="filter filter--city"
                                        value={city}
                                        onChange={handleChange}
                                    >
                                        {c.map((city) => (
                                            <option
                                                key={city._id}
                                                value={city.name}
                                            >
                                                {city.name}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div className="select-wrapper">
                                <div className="segmentsBox">
                                    <label htmlFor="Todos">Todos</label>
                                    <input
                                        type="radio"
                                        id={"Todos"}
                                        name="segment"
                                        className="inputRadio"
                                        onChange={() => {
                                            if (segment == "") {
                                                filterStoreBySegment();
                                            } else {
                                                handleChangeSegmentFilter("");
                                            }
                                        }}
                                        value={"segment"}
                                    />
                                </div>
                                {segments &&
                                    segments.map((item, index) => (
                                        <div
                                            key={item._id}
                                            className="segmentsBox"
                                            style={{
                                                borderBottom:
                                                    segments.length - 1 == index
                                                        ? "none"
                                                        : "",
                                            }}
                                        >
                                            <label htmlFor={item.name}>
                                                {item.name}
                                            </label>
                                            <input
                                                type="radio"
                                                id={item.name}
                                                name="segment"
                                                onChange={() =>
                                                    handleChangeSegmentFilter(
                                                        item.name
                                                    )
                                                }
                                                value={"segment"}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    {modalFilter && (
                        <div
                            className="effectModal"
                            onClick={() => setModalFilter(false)}
                        />
                    )}
                    {stores && <StoreGrid data={stores} />}

                    {/* === TROCAR CITIES POR STORES === */}
                    {loading ||
                        (stores && stores.length < 1 && (
                            <div className="no-stores">
                                {loading && <p>carregando...</p>}
                                {!loading &&
                                    stores &&
                                    stores.length < 1 &&
                                    !noStoreSegment &&
                                    controlSearch == "" && (
                                        <>
                                            <p className="no-store-message">
                                                Infelizmente, {city} ainda não
                                                possui estabelecimentos com a
                                                Lucrei
                                            </p>
                                            <p>
                                                Tente procurar nossos parceiros
                                                mais próximos a você usando a
                                                ferramenta de{" "}
                                                <span
                                                    style={{ color: "#00ee00" }}
                                                >
                                                    Filtros
                                                </span>{" "}
                                                <span>
                                                    <TbAdjustmentsHorizontal color="#00ee00" />
                                                </span>{" "}
                                                no canto superior direito.
                                            </p>
                                        </>
                                    )}
                                {!loading &&
                                    stores &&
                                    stores.length < 1 &&
                                    noStoreSegment &&
                                    controlSearch == "" && (
                                        <>
                                            <p className="no-store-message">
                                                Infelizmente, em {city} ainda
                                                não há estabelecimentos desse
                                                gênero usando a Lucrei
                                            </p>
                                            <p>
                                                Tente procurar nossos parceiros
                                                mais próximos a você usando a
                                                ferramenta de{" "}
                                                <span
                                                    style={{ color: "#00ee00" }}
                                                >
                                                    Filtros
                                                </span>{" "}
                                                <span>
                                                    <TbAdjustmentsHorizontal color="#00ee00" />
                                                </span>{" "}
                                                no canto superior direito.
                                            </p>
                                        </>
                                    )}
                                {!loading &&
                                    stores &&
                                    stores.length < 1 &&
                                    !noStoreSegment &&
                                    controlSearch != "" && (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <p className="no-store-message">
                                                Infelizmente,{" "}
                                                <span
                                                    style={{
                                                        fontWeight: "700",
                                                    }}
                                                >
                                                    '{controlSearch}'
                                                </span>{" "}
                                                não faz parte da Lucrei
                                            </p>
                                            <p>
                                                Tente procurar nossos parceiros
                                                mais próximos a você usando a
                                                ferramenta de{" "}
                                                <span
                                                    style={{ color: "#00ee00" }}
                                                >
                                                    Filtros
                                                </span>{" "}
                                                <span>
                                                    <TbAdjustmentsHorizontal color="#00ee00" />
                                                </span>{" "}
                                                no canto superior direito.
                                            </p>
                                            <p>
                                                Para retornar aos valores
                                                iniciais, vá até o filtro e
                                                clique na caixinha que possui
                                                "Todos"
                                            </p>
                                        </div>
                                    )}
                            </div>
                        ))}
                    {!loading && totalPages > 1 && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={paginate}
                        />
                    )}
                </div>
            </div>
            {/* <div className="rodapeCliente">
                <Footer />
            </div> */}
        </div>
    );
}
