import fetcher from "../../../../services/fetcher";
import BannerTool from "../../../../components/BannerTool";
import useSWR from "swr";

import { useState, useEffect } from "react";
import Pagination from "../../../../components/Pagination";
import Loading from "../../../../components/Loading";

import DefaultRoot from "../../../../components/DeafultRoot";
import CustomerCard from "../components/CustomerCard";
import MenuCliente from "../../../../components/MenuCliente";

export default function CustomerCards() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data: cards, isLoading: loadingCards } = useSWR(
        `api/v1/customers/me/loyalty-cards?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        if (!loadingCards) {
            setTotalPages(cards.pages);
        }
    }, [cards, loadingCards]);

    return (
        <DefaultRoot>
            <MenuCliente />

            <main>
                <BannerTool title="Meus Cartões Fidelidade" />

                {loadingCards ? (
                    <Loading />
                ) : cards.data.totalItems > 0 ? (
                    <section className="w-11/12 mx-auto grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                        {cards.data.data.map((card) => {
                            return (
                                <CustomerCard
                                    store={card.store.name}
                                    avatar={card.store.avatar}
                                    photo={card.promotion.photo}
                                    title={card.promotion.title}
                                    isComplete={card.isComplete}
                                    isRedeemed={card.isRedeemed}
                                    totalPurchases={card.totalPurchasesDone}
                                    promoPurchases={card.promotion.salesNumber}
                                    code={card.code}
                                    id={card.id}
                                    key={card.id}
                                    object={card}
                                />
                            );
                        })}
                    </section>
                ) : (
                    <h2 className="text-2xl text-center max-w-2xl mx-auto mt-5">
                        Você ainda não possui cartões de fidelidade.
                    </h2>
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </main>
        </DefaultRoot>
    );
}