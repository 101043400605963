import ctl from "@netlify/classnames-template-literals"

export default function CouponsGrid({ children, totalItems }) {
    return (
        <section
            className=
            {ctl(`
                 max-w-[90%] 
                 xl:max-w-screen-lg 2xl:max-w-[1550px] 
                 ${totalItems > 3 && "xl:justify-between"}  
                 mx-auto 
                 flex justify-center flex-wrap gap-4`
            )}>

            {children}

        </section>
    )
}