import { isInvalidValue } from "./functions/isInvalidValue"
import { fieldsType } from "./Fields/data/fieldsType"
import UninformedField from "./Fields/UninformedField"


const defaultTd = "ring-1 ring-gray-200 ring-opacity-50 whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"

export default function TableRows({ data, fields }) {
  return (
    <tbody className="bg-white">

      {data?.map((item, index) => (

        <tr key={index} className={`${index % 2 && 'bg-zinc-100'}`}>

          {fields.map((field, index) => {

            if (isInvalidValue(item, field))
              return <UninformedField field={field} defaultTd={defaultTd} />


            const currentType = fieldsType.filter((fieldType) => fieldType.type === field.type)[0]

            const props = { field, item, defaultTd };

            return <currentType.field {...props} key={index} />

          })}

        </tr>

      ))}

    </tbody>
  )
}