import api from "../../../services/api"

export const SoldByPaymentType = async () => {
    const response = await api.get(
        `api/v1/separate-sales/total-sold-by-payment-type`)

    const responseAdapter = (field) => {
        const adapted = response.data.data.find((element) => element._id === field)

        if (adapted === undefined)
            return 0

        else return adapted.totalSold
    }


    const responseAdapted = {
        dinheiro: responseAdapter('DINHEIRO FÍSICO'),
        pix: responseAdapter('PIX'),
        credito: responseAdapter('CART CRÉDITO'),
        debito: responseAdapter('CART DÉBITO')
    }

    return responseAdapted
}