import { useState, useEffect } from "react"

import useSWR from "swr"

import Loading from "../../components/Loading"
import Pagination from "../../components/Pagination"
import BannerTool from "../../components/BannerTool"
import MenuEmpresa from "../../components/MenuEmpresa"
import DefaultRoot from "../../components/DeafultRoot"
import MainContent from "../../components/MainContent"
import NewTable from "../../components/NewTable/NewTable"

import fetcher from "../../services/fetcher"

export default function Deposits() {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { data, isLoading } = useSWR(
        `api/v1/stores/me/deposits?sortBy=-createdAt&pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    useEffect(() => {
        if (!isLoading) {
            setTotalPages(data?.pages)
        }
    }, [data, isLoading])

    return (
        <DefaultRoot>

            <MenuEmpresa />

            <BannerTool
                title="Meus Depósitos"
            />

            <MainContent>
                {isLoading ? <Loading />
                    : (
                        <NewTable
                            data={data}
                            name={""}
                            fields={[
                                {
                                    type: "date",
                                    title: "Data da Solicitação",
                                    name: "createdAt",
                                },
                                {
                                    type: "money",
                                    title: "Valor do Depósito",
                                    name: "value",
                                },
                                {
                                    type: "default",
                                    title: "Status do Depósito",
                                    name: "status",
                                }
                            ]}
                        />
                    )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </MainContent>
        </DefaultRoot>

    )
}
