import moment from "moment"

export const months = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril',
    'Maio', 'Junho', 'Julho', 'Agosto',
    'Setembro', 'Outubro', 'Novembro', 'Dezembro'
]

export const monthsMap = () => {

    const yearMonths = []

    months.forEach((monthOption, index) => {
        if (index + 1 <= moment().format("MM"))
            yearMonths.push({ id: index + 1, name: monthOption })
    })

    return yearMonths
}