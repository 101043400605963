import { FcOnlineSupport } from "react-icons/fc"
import { FaWhatsapp } from "react-icons/fa"

export default function Support() {
    return (
        <section className="w-full flex flex-col gap-3 rounded-lg shadow-md p-3 justify-between items-center text-center bg-white">

            <div className="flex items-center justify-center gap-3 mb-2">
                <FcOnlineSupport className="w-7 h-7" />
                <h2 className="text-xl font-normal m-0">Precisa de ajuda?</h2>
            </div>

            <h3 className="text-xl font-semibold">Entre em contato conosco!</h3>

            <p>O nosso suporte está disponível no WhatsApp</p>
            <button
                type="button"
                className="inline-flex items-center gap-x-2 w-fit rounded-md bg-secondaryGreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                onClick={() => window.open("https://api.whatsapp.com/send?phone=5585991949020", "_blank")}
            >
                <FaWhatsapp className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Envie uma mensagem
            </button>

        </section>
    )
}