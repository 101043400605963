import moment from "moment"
import useLink from "../../../../hooks/useLink"
import { RiWhatsappFill } from "react-icons/ri"

export default function CustomerCard({ day, name, phone, id }) {

  const history = useLink()

  return (
    <article className="bg-white shadow-md rounded-lg p-3 flex justify-between items-center w-full">
      <div className="flex items-center gap-3">
        <div className="rounded-full h-14 w-14 flex justify-center bg-primaryPurple p-3 text-white font-semibold text-lg">
          <span>
            {day}
          </span>
        </div>

        <div className="flex items-start flex-col">
          <p className="font-semibold m-0 text-xl text-gray-600">
            {name}
            {day == moment().format("DD") && <span>🎂🎉</span>}
          </p>

          <button
            className="text-primaryPurple hover:text-purple-600 text-sm"
            onClick={() => {
              localStorage.setItem(
                "customerKey",
                JSON.stringify(id)
              );
              history.push(`/empresa/cliente/${id}/perfil`)
            }}
          >
            Ver detalhes do cliente
          </button>
        </div>
      </div>

      <RiWhatsappFill
        className="text-secondaryGreen drop-shadow-sm h-10 w-10 hover:scale-105 duration-150 cursor-pointer"
        onClick={() => window.open(`https://api.whatsapp.com/send?phone=55${phone}`, '_blank')}
      />

    </article>
  )
}