import moment from "moment"

export default function momentconfig() {
  moment.locale("pt-br")

  moment.updateLocale('pt-br', {
    months: [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
      "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
  })
}