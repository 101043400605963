import React, { useState, useEffect } from "react"

import { toast } from "react-toastify"

import Loading from "../../components/Loading"
import BannerTool from "../../components/BannerTool"
import MenuEmpresa from "../../components/MenuEmpresa"
import DefaultRoot from "../../components/DeafultRoot"
import MainContent from "../../components/MainContent"

import useLink from "../../hooks/useLink"

import { SoldByPaymentType } from "./integration/SingleSalesListIntegration"

import api from "../../services/api"

import DeleteModal from "./components/DeleteModal"
import CardsSection from "./components/CardsSection"
import SingleSalesTable from "./components/SingleSalesTable"
import useSWR from "swr"
import fetcher from "../../services/fetcher"
import moment from "moment"


export default function SingleSalesList() {
    const [soldByPaymentType, setSoldByPaymentType] = useState()
    const [loading, setLoading] = useState(true)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [itemId, setItemId] = useState()
    const [loadingAction, setLoadingAction] = useState(false)

    const { data: sales } = useSWR(
        `api/v1/dashboard/stores/me/separate-sales/daily?day=${moment().format("DD")}&month=${moment().format("MM")}&year=${moment().format("YYYY")}
        `,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const history = useLink()

    const handleDeleteSale = async (id) => {
        try {
            setLoadingAction(true)

            await api.delete(`api/v1/separate-sales/${id}`)

            toast.success(
                "Venda excluída com sucesso!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            )
        } catch (error) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } finally {
            setLoadingAction(false)
            setDeleteModalOpen(false)
        }
    }

    useEffect(() => {
        const getData = async () => {
            const byPaymentType = await SoldByPaymentType()
            setSoldByPaymentType(byPaymentType)
            setLoading(false)
        }
        getData()
    }, [])


    return (
        <DefaultRoot>
            <MenuEmpresa />

            <BannerTool
                title="Informações de Vendas Avulsas"
                action={() => history.push("/empresa/produtos-mais-vendidos")}
                actionTitle={"Ver Produtos mais Vendidos"}
            />

            {deleteModalOpen && (
                <DeleteModal
                    control={setDeleteModalOpen}
                    item={itemId}
                    handleDelete={handleDeleteSale}
                    loading={loadingAction}
                />
            )}

            <MainContent>

                {!deleteModalOpen && (
                    loading ? (
                        <Loading />
                    ) : (
                        <div>
                            {soldByPaymentType && (<CardsSection soldByPaymentType={soldByPaymentType} sales={sales} />)}

                            <SingleSalesTable
                                modal={(state, item) => {
                                    setItemId(item._id)
                                    setDeleteModalOpen(state)
                                }}
                            />

                        </div>
                    )
                )}

            </MainContent>
        </DefaultRoot>
    )
}
