import React from "react";

import MenuEmpresa from "../../../../components/MenuEmpresa";
import useLink from "../../../../hooks/useLink";
//import fetcher from "../../../services/fetcher";
import BannerTool from "../../../../components/BannerTool/index";
import useSWR from "swr";
import CouponsGrid from "../../components/CouponsGrid";
import { useState, useEffect } from "react";
import Pagination from "../../../../components/Pagination";
import Loading from "../../../../components/Loading";
import api from "../../../../services/api";
import EmpresaCoupon from "../../components/EmpresaCoupon";
import DefaultRoot from "../../../../components/DeafultRoot";
import StatusFilter from "../../components/StatusFilter";

export default function EmpresaCoupons() {
    const history = useLink();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [status, setStatus] = useState("");

    /* Fetch */
    async function fetcher(url) {
        const response = await api.get(url);
        return response.data;
    }

    const { data: empresaCoupons, isLoading: loadingEmpresaCoupons } = useSWR(
        `api/v1/stores/me/coupons?pageNumber=${currentPage}${status}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        if (!loadingEmpresaCoupons) {
            setTotalPages(empresaCoupons.pages);
        }
    }, [empresaCoupons, loadingEmpresaCoupons]);

    return (
        <DefaultRoot>

            <MenuEmpresa />

            <main>
                <BannerTool
                    title="Meus Cupons"
                    action={() => history.push("/empresa/cupons-gerados")}
                    actionTitle="Ver Cupons Gerados"
                />

                <StatusFilter setStatus={setStatus} generated={false} />

                {loadingEmpresaCoupons ? (
                    <Loading />
                ) : (
                    <CouponsGrid totalItems={empresaCoupons.totalItems}>
                        {empresaCoupons.totalItems !== 0 ? (
                            empresaCoupons.data.map((coupon) => {
                                return (
                                    <EmpresaCoupon
                                        key={coupon._id}
                                        store={coupon.store.name}
                                        avatar={coupon.store.avatar}
                                        discount={Number(coupon.discountPercentage * 100).toFixed(0)}
                                        description={coupon.description}
                                        expiration={coupon.expirationDate}
                                        originalPrice={Number(coupon?.originalPrice).toFixed(2)}
                                        status={coupon.status}
                                        id={coupon._id}
                                    />
                                );
                            })
                        ) : (
                            <span className="mt-40 text-center text-xl w-full">
                                Não temos cupons disponíveis no momento.
                            </span>
                        )}
                    </CouponsGrid>
                )}

                {!loadingEmpresaCoupons && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={setCurrentPage}
                    />
                )}
            </main>
        </DefaultRoot>
    );
}
