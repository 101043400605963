import { useEffect, useState } from "react"

import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"

import Pagination from "../../components/Pagination"
import MenuCliente from "../../components/MenuCliente"
import WithdrawTable from "../../components/MyWithdraw/index"

import "./style.scss"

import api from "../../services/api"
import { getToken, logout } from "../../services/auth"
import NewTable from "../../components/NewTable/NewTable"
import MainContent from "../../components/MainContent"
import DefaultRoot from "../../components/DeafultRoot"
import BannerTool from "../../components/BannerTool"

export default function SimpleTabs() {
    const history = useHistory()

    const token = getToken()
    const [withdrawals, setWithdrawals] = useState([])
    const [currentPageWithdrawals, setCurrentPageWithdrawals] = useState(1)
    const [totalPagesWithdrawals, setTotalPagesWithdrawals] = useState(1)
    const [loading, setLoading] = useState(false)

    const loadWithdrawals = async () => {
        setLoading(true)
        const response = await api.get(
            `api/v1/customers/me/withdrawals?sortBy=-createdAt&pageNumber=${currentPageWithdrawals}`,
            {
                headers: { Authorization: token },
            }
        )
        setLoading(false)
        setWithdrawals(response.data)
        setTotalPagesWithdrawals(response.data.pages)
    }

    useEffect(() => {
        loadWithdrawals().catch((error) => {
            const { status } = error.response
            console.log("error", status)
            if (status === 401) {
                logout()
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
                history.push("/")
            }
        })

        /* eslint-disable-next-line */
    }, [])

    useEffect(() => {
        loadWithdrawals().catch((error) => {
            const { status } = error.response
            console.log("error", status)
            if (status === 401) {
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                )
                history.push("/")
            }
        })

        /* eslint-disable-next-line */
    }, [currentPageWithdrawals])

    return (
        <DefaultRoot className="containerSaques">
            <MenuCliente />

            <BannerTool
                title={"Solicitações de Saque"}
            />
            <MainContent>
                {withdrawals.data && (
                    <NewTable
                        data={withdrawals}
                        name={""}
                        fields={[
                            {
                                type: "date",
                                title: "Data da Solicitação",
                                name: "createdAt",
                            },
                            {
                                type: "money",
                                title: "Valor do Saque",
                                name: "value",
                            },
                            {
                                type: "default",
                                title: "Chave PIX",
                                name: "pix",
                            },
                            {
                                type: "default",
                                title: "Status",
                                name: "status",
                            }
                        ]}
                    />
                )}
                <Pagination
                    currentPage={currentPageWithdrawals}
                    totalPages={totalPagesWithdrawals}
                    paginate={setCurrentPageWithdrawals}
                />
            </MainContent>
        </DefaultRoot>
    )
}
