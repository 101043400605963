import { Fragment, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import api from '../../../services/api';

export default function ConfirmDelete({ open, control, item, updateTransactions, updateStats }) {

    const cancelButtonRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)


    const deleteItem = async () => {
        setSubmitting(true)
        try {
            await api.delete(`/api/v1/transactions/${item}`);
            toast.success("Movimentação excluída com sucesso!", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            updateTransactions()
            updateStats()

            control(false);
        } catch (error) {
            const { data } = error.response;

            console.log("error", { error });

            toast.error(data?.message, {
                position: "top-right",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={control}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg w-full bg-white px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => control(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>

                                <div className="flex">

                                    <div className="mt-3 text-center w-full">

                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 mb-3">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>

                                        <p>
                                            Tem certeza que deseja excluir essa transação? Essa ação não pode ser desfeita.
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-3 flex gap-2 justify-end">

                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-primaryPurple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto"
                                        onClick={() => control(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancelar
                                    </button>

                                    <button
                                        type="button"
                                        className={`mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto`}
                                        onClick={() => deleteItem()}
                                    >
                                        {submitting ? 'Aguarde...' : 'Excluir'}
                                    </button>

                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
