export default function genCouponsAdapter(items) {


    const adapter = (item) => {
        return {
            code: item?.code,
            status: item?.status,
            customerName: item?.customerName,
            createdAt: item?.createdAt,
            statusChangedAt: item?.statusChangedAt,
            expirationDate: item?.parentCoupon?.expirationDate,
            description: item?.parentCoupon?.description
        }
    }

    if (Array.isArray(items.data)) {
        const itemsAdapted = items.data.map((item) => {
            return adapter(item)
        })

        return {
            data: itemsAdapted
        }
    } else {
        return {
            data: [adapter(items.data)]
        }
    }

}