import CustomerCard from "./CustomerCard"
import useSWR from "swr"
import fetcher from "../../../../services/fetcher"
import moment from "moment"
import momentconfig from "../../../../utils/moment-config"

export default function BirthdayCustomers() {

  momentconfig()

  const { data } = useSWR(
    `api/v1/dashboard/stores/me/birthday-customers?month=${moment().format("MM")}`,
    fetcher,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  )

  const hasCustomersInBirthdayThisMonth = data?.data.length > 0
  const hasCustomersInBirthdayToday = data?.data.filter((customer) => customer.date_timezone.day === moment().format("DD")).length > 0

  return (
    <div className="w-full flex flex-col gap-3 max-h-[1000px] overflow-auto">

      <h2 className="uppercase text-xl text-gray-500 font-semibold">
        clientes aniversariantes de <span className="text-gray-700 font-bold">{moment().format("MMMM")}</span>
      </h2>

      {!hasCustomersInBirthdayThisMonth && <span>📅 Você não possui clientes aniversariantes neste mês</span>}

      {hasCustomersInBirthdayToday ? (
        <section className="flex flex-col gap-3">

          <h2 className="uppercase text-xl text-gray-500 font-semibold mb-0.5">
            Hoje
          </h2>

          {data?.data.map((customer) => (
            customer.date_timezone.day === moment().format("DD") && (
              <CustomerCard
                key={customer._id}
                day={customer.date_timezone.day}
                name={customer.customerName}
                id={customer._id}
                phone={customer.whatsappNumber}
              />
            )
          ))}

        </section>
      ) : hasCustomersInBirthdayThisMonth && (
        <span>📅 Você não possui clientes aniversariando hoje</span>
      )}

      {data?.data.filter((customer) => customer.date_timezone.day > moment().format("DD")).length > 0 && (
        <section className="flex flex-col gap-3">

          <h2 className="uppercase text-xl text-gray-500 font-semibold mb-0.5 mt-4">
            Próximos aniversariantes
          </h2>

          {data?.data.map((customer) => (
            customer.date_timezone.day > moment().format("DD") && (
              <CustomerCard
                key={customer._id}
                day={customer.date_timezone.day}
                name={customer.customerName}
                id={customer._id}
                phone={customer.whatsappNumber}
              />
            )
          ))}

        </section>
      )}

    </div>
  )
}