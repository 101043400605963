import { useEffect, useState } from "react"

import useSWR from "swr"
import { FaWhatsapp } from "react-icons/fa"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import Loading from "../../../../components/Loading"
import BannerTool from "../../../../components/BannerTool"
import Pagination from "../../../../components/Pagination"
import DefaultRoot from "../../../../components/DeafultRoot"
import MenuEmpresa from "../../../../components/MenuEmpresa"
import NewTable from "../../../../components/NewTable/NewTable"

import fetcher from "../../../../services/fetcher"

import formatPhone from "../utils/formatPhone"

export default function PurchasesHistory() {

    const params = useParams()

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { data: purchases, isLoading: loadingPurchases } = useSWR(
        `api/v1/loyalty-card-promotions/${params.promotionId}/customers/${params.customerId}/purchases`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    /* Effects */
    useEffect(() => {
        if (!loadingPurchases) {
            setTotalPages(purchases.pages)
        }
    }, [purchases, loadingPurchases])


    return (
        <DefaultRoot>
            <MenuEmpresa />

            {loadingPurchases ? <Loading /> : (
                <main>
                    <BannerTool
                        title={"Histórico de Compras"}
                    />

                    <section className="flex flex-col w-full mx-auto px-3 max-w-screen-2xl gap-4 justify-center items-center mt-4">

                        <article className="p-4 flex justify-center text-center flex-col gap-2 bg-white rounded-xl shadow-xl w-full">
                            <h5>CLiente: {params.customerName}</h5>
                            <h5 className="flex items-center justify-center gap-3">Telefone p/ Contato:
                                <a
                                    className="flex gap-1 text-secondaryGreen text-center hover:text-primaryPurple"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://api.whatsapp.com/send?phone=55${purchases.purchaseHistory[0].customerPhone}`}
                                >
                                    <FaWhatsapp className="-ml-0.5 h-5 w-5 cursor-pointer text-green-500 " />
                                    {formatPhone(purchases.purchaseHistory[0].customerPhone)}
                                </a>
                            </h5>
                            <h5>Promoção: {purchases.purchaseHistory[0].description.replace(/Venda Cartão Fidelidade:/, '')}</h5>
                        </article>


                        <NewTable
                            data={{ data: purchases.purchaseHistory }}
                            name={"Histórico de Compras"}
                            fields={[
                                {
                                    type: "default",
                                    title: "Código do Cartão",
                                    name: "loyaltyCardCode"
                                },
                                {
                                    type: "money",
                                    title: "Valor",
                                    name: "value",
                                },
                                {
                                    type: "date",
                                    title: "Data",
                                    name: "createdAt"
                                }
                            ]}
                        />

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            paginate={setCurrentPage}
                        />

                    </section>
                </main>
            )}





        </DefaultRoot>
    )
}