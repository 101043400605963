import { classNames } from "../../../functions/auxiliars"

export default function TextField({ field, item, defaultTd }) {
  return (
    <td
      key={field.title}
      className={classNames(defaultTd, field.className && field.className(item))}
    >
      {item[field.name] || item[field.altName] || typeof field.name === 'function' && field.name(item)}
    </td>
  )

}