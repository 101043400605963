import { toast } from "react-toastify";
import { FcMoneyTransfer } from "react-icons/fc";
import { BanknotesIcon } from "@heroicons/react/24/outline";

import useLink from "../../../../hooks/useLink";

import formatCurrency from "../../../../utils/format-currency";

export default function Wallet({ cashback, setShowWithdrawModal, disabled }) {

    const history = useLink();

    return (
        <section className="flex flex-col w-full justify-between items-center gap-3 bg-gradient-to-b from-white from-30% via-yellow-50 via-50% to-white to-60% rounded-lg shadow-md p-3">

            <div className="flex items-center justify-center gap-3 mb-2">
                <FcMoneyTransfer className="w-7 h-7" />
                <h2 className="text-xl font-normal m-0">Saldo</h2>
            </div>

            <p className="text-4xl font-semibold text-orange-400 drop-shadow-md">{`R$ ${formatCurrency(cashback)}`}</p>

            <button
                type="button"
                className="inline-flex items-center gap-x-2 w-fit rounded-md bg-secondaryGreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                onClick={() => {
                    if (disabled) {
                        toast.error(
                            <p>Por favor, atualize seus dados cadastrais para ter acesso a essa funcionalidade
                                <br />
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:scale-105 duration-100 sm:w-auto"
                                    onClick={() => history.push("/profile")}
                                >
                                    Atualizar dados
                                </button>
                            </p>
                            ,
                            {
                                position: "top-right",
                                autoClose: 8000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            }
                        );
                    } else {
                        setShowWithdrawModal(true)
                    }
                }}
            >
                <BanknotesIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Solicitar Saque
            </button>

        </section>
    )
}