import React from "react";
import { useCallback } from "react";

const ReceiptCodeInput = ({ mask, ...props }) => {
    const handleKeyUp = useCallback(
        (e) => {
            receiptCode(e);
        },
        [mask]
    );

    return <input {...props} onKeyUp={handleKeyUp} />;
};

function receiptCode(e) {
    e.currentTarget.maxLength = 54;
    let value = e.currentTarget.value;
    if (
        !value.match(
            /^(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4}).(\d{4})$/
        )
    ) {
        value = value
            .replace(/\D/g, "")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2")
            .replace(/(\d{4})(\d)/, "$1.$2");

        e.currentTarget.value = value;
    }
    return e;
}

export default ReceiptCodeInput;
