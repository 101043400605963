import React from "react";
import trendingIcon from "../img/seta.svg";
import userIcon from "../img/user.svg";
import usersIcon from "../img/users.svg";
import ticketIcon from "../img/ticket.svg";
import moneyIcon from "../img/cifrao.png";
import TotalSalesIcon from "../img/sales-cart.svg";
import StatisticCard from "./StatisticCard";
import PeriodControls from "./PeriodControls";
import Loading from "../../../components/Loading";

export default function StatisticInfo({ data, isLoading, period, setPeriod }) {
    return (
        <section
            className="
            StatisticInfoContainer
            bg-white
            shadow-lg
            max-w-6xl rounded-3xl
            p-5"
        >
            <div className="sectionHeader flex flex-col items-center justify-center mb-14 gap-2 text-gray-500">
                <PeriodControls
                    isLoading={isLoading}
                    period={period}
                    setPeriod={setPeriod}
                />

                <article className="flex items-center justify-center gap-2 mt-7">
                    <span className="w-3 h-3 rounded-full bg-primaryGreen"></span>
                    <span className="me-2">Vendas com Cashback</span>
                    <span className="w-3 h-3 rounded-full bg-primaryPurple"></span>
                    <span>Vendas Avulsas</span>
                </article>
            </div>

            {isLoading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-x-24 gap-y-12 md:grid md:grid-cols-2">
                    <StatisticCard
                        title="Novos Clientes"
                        icon={trendingIcon}
                        totalInfo={
                            data.NewClients.totalMostRecentCustomersPerPeriod
                        }
                        ordinaryInfo={
                            data.NewClients
                                .totalMostRecentCustomersOrdinarySalesPerPeriod
                        }
                        separatedInfo={
                            data.NewClients
                                .totalMostRecentCustomersSeparateSalesPerPeriod
                        }
                    />
                    <StatisticCard
                        title="Compraram Apenas 1 Vez"
                        icon={userIcon}
                        totalInfo={
                            data.CustomersPurchasesOnce
                                .totalCustomersPurchasesOncePerPeriod
                        }
                        ordinaryInfo={
                            data.CustomersPurchasesOnce
                                .totalCustomersPurchasesOnceFromOrdinarySales
                        }
                        separatedInfo={
                            data.CustomersPurchasesOnce
                                .totalCustomersPurchasesOnceFromSeparatedSales
                        }
                    />
                    <StatisticCard
                        title="Compraram Várias Vezes"
                        icon={usersIcon}
                        totalInfo={
                            data.CustomersPurchasesMany
                                .totalCustomersPurchasesManyPerPeriod
                        }
                        ordinaryInfo={
                            data.CustomersPurchasesMany
                                .totalCustomersPurchasesMoreThanOneOrd
                        }
                        separatedInfo={
                            data.CustomersPurchasesMany
                                .totalCustomersPurchasesMoreThanOneSep
                        }
                    />
                    <StatisticCard
                        title="Ticket Médio"
                        icon={ticketIcon}
                        totalInfo={`R$ ${data.AverageTicket.averageTicket?.toFixed(
                            2
                        )}`}
                        ordinaryInfo={`R$ ${data.AverageTicket.averageTicketFromOrdinarySales?.toFixed(
                            2
                        )}`}
                        separatedInfo={`R$ ${data.AverageTicket.averageTicketFromSeparateSales?.toFixed(
                            2
                        )}`}
                    />
                    <StatisticCard
                        title="Total Vendido"
                        icon={moneyIcon}
                        totalInfo={`R$ ${data.TotalSold.totalSoldPerPeriod?.toFixed(
                            2
                        )}`}
                        ordinaryInfo={`R$ ${data.TotalSold.totalSoldFromOrdinarySalesPerPeriod?.toFixed(
                            2
                        )}`}
                        separatedInfo={`R$ ${data.TotalSold.totalSoldFromSeparateSalesPerPeriod?.toFixed(
                            2
                        )}`}
                    />
                    <StatisticCard
                        title="Vendas No Mês"
                        icon={TotalSalesIcon}
                        totalInfo={data.TotalSales.totalSalesPerPeriod}
                        ordinaryInfo={
                            data.TotalSales.totalOrdinarySalesPerPeriod
                        }
                        separatedInfo={
                            data.TotalSales.totalSeparateSalesPerPeriod
                        }
                    />
                </div>
            )}
        </section>
    );
}
