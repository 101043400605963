import React, { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MenuEmpresa from "../../components/MenuEmpresa";
import "./index.scss";
import { useState } from "react";
import Loading from "../../components/Loading";
import NewTable from "../../components/NewTable/NewTable";
import useSWR from "swr";
import Pagination from "../../components/Pagination";
import fetcher from "../../services/fetcher";
import MainContent from "../../components/MainContent";
import DefaultRoot from "../../components/DeafultRoot";

export default function SalesByCategoryList() {
    const params = useParams();
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { data: sales, isLoading: loadingSales } = useSWR(`api/v1/separate-sales?category=${params.category}&page=${currentPage}`,
        fetcher, {
        revalidateOnFocus: false,
    })


    /* Effects */
    useEffect(() => {
        if (!loadingSales) {
            setTotalPages(sales.pages)
        }

    }, [sales, loadingSales])


    return (
        <DefaultRoot>
            <MenuEmpresa />

            <MainContent>

                {loadingSales ? (
                    <Loading />
                ) : (
                    <>
                        {sales.length === 0 ? (
                            <p className="text-center">
                                Nenhuma venda cadastrada nessa categoria!
                            </p>
                        ) : (
                            <NewTable
                                data={sales}
                                name={`Vendas da Categoria ${params.category}`}
                                fields={[
                                    {
                                        type: "date",
                                        title: "Data da Venda",
                                        name: "createdAt",
                                    },
                                    {
                                        type: "money",
                                        title: "Valor",
                                        name: "value",
                                    },
                                    {
                                        type: "default",
                                        title: "Cliente",
                                        name: "customerName",
                                        altName: "leadCustomerName",
                                    },
                                    {
                                        type: "default",
                                        title: "Telefone",
                                        name: "customerPhone",
                                    },
                                    {
                                        type: "default",
                                        title: "Pagamento",
                                        name: "paymentType",
                                    },
                                    {
                                        type: "default",
                                        title: "Sexo",
                                        name: "customerGender",
                                    },
                                ]}
                            />
                        )}

                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                paginate={setCurrentPage}
                            />
                        )}
                    </>
                )}
            </MainContent>
        </DefaultRoot>
    );
}

