//import { FcCancel, FcOk } from 'react-icons/fc'
import DefaultButton from "../../../../components/DefaultButton"
import LoadingForButton from "../../../../components/LoadingForButton"
import Modal from "../../../Tailwind/Modal"

export default function DeleteProduct({ openModal, setOpenModal, item, handleDelete, loading }) {
  return (

    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <div className="bg-white rounded-xl flex flex-col justify-center items-center gap-5 max-w-xl">
        <h5 className="text-center">Tem certeza que deseja excluir esse produto? Essa ação não pode ser desfeita.</h5>
        <div className="flex gap-3">
          <DefaultButton
            content={"Cancelar"}
            className={"bg-zinc-300 text-zinc-950"}
            onClick={() => setOpenModal(false)}
          />

          <DefaultButton
            content={loading ? <LoadingForButton /> : "Excluir"}
            className={"bg-red-600 text-white"}
            onClick={() => handleDelete(item)}
          />
        </div>
      </div>
    </Modal>
  )
}