import React, { useState, useEffect } from "react";
import "./style.scss";

import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function NotFound() {
    const history = useHistory();

    return (
        <div className="NotFound">
            <div className="container">
                <h1>404 - Página não encontrada! :(</h1>
                <p>A página que você solicitou não existe.</p>
                <Link to="/login">Voltar para a página de login</Link>
            </div>
        </div>
    );
}
