/* eslint-disable jsx-a11y/alt-text */

import swipe from "../../assets/swipe_.gif";
import React, { useState } from "react";
import "./styleTabela.scss";
import { CircularProgress } from "@material-ui/core";
import useSortedData from "../../hooks/useSortedData";
import TrPontos from "./imgs/3 PONTOS.svg";
import filtrar from "./imgs/filtrar.png";
import { BiEdit } from "react-icons/bi";
import { MdQueryStats } from "react-icons/md";
import { Link } from "react-router-dom";

function StoreCategoriesList({
    categories,
    nameTable,
    stylee,
    vendas,
    handleEditCategoryClick,
}) {
    if (vendas === true) {
        return (
            <section className={`${stylee} NewCategoryWrapper !rounded-xl`}>
                {categories && categories.length > 0 ? (
                    <img src={swipe} width={"60px"} className="swipe"></img>
                ) : null}

                <div className="AlingOneTable">
                    <h2>{nameTable}</h2>

                    <div className="cont">
                        <img height="13px" src={TrPontos} />
                    </div>
                </div>

                {categories?.length > 0 && (
                    <div className="AlingTwoTable bold StoreCategoriesTableHeader">
                        <h2>Nome</h2>
                        <h2>
                            {" "}
                            <BiEdit size={28} />
                        </h2>
                        <h2>
                            <MdQueryStats size={28} />
                        </h2>
                    </div>
                )}
                {categories &&
                    categories.length > 0 &&
                    categories.map((item) => (
                        <div className="AlingTwoTable" key={item._id}>
                            <h2 className="category-name">{item.name}</h2>
                            <button
                                className="btn-editar-vendas-avulsas"
                                onClick={() => handleEditCategoryClick(item)}
                            >
                                Editar categoria
                            </button>
                            <Link
                                to={`/empresa/vendas-por-categoria/${item.name}`}
                                className="btn-visualizar-vendas-avulsas"
                            >
                                Visualizar vendas
                            </Link>
                        </div>
                    ))}
                {categories?.length === 0 && (
                    <>
                        <p className="text-center">
                            Você ainda não possui nenhuma categoria cadastrada.
                        </p>
                    </>
                )}
            </section>
        );
    }
}

export default StoreCategoriesList;
