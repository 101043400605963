import "./style.scss";
import MockImage from "../,,/../../assets/logo_b.jpg";
import closeButton from '../../assets/X.svg'
import map from '../../assets/map.png'
import tell from '../../assets/phone.png'
import { Link } from 'react-router-dom'

const maskPhone = (phone) => {
  return phone
    .replace(/\D/g, "")
    .replace(/(\d{2})/, "($1) ")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
};

const StoreModal = ({ store, setOpenModal }) => {
  return (
    <div className="StoreModal">
      <div className="StoreInfo">
        <button className="closeButton" onClick={() => setOpenModal(false)}>
          <img src={closeButton} alt="" />
        </button>
        <h4 className="StoreInfo__name">{store.name}</h4>
        <div className="StoreInfo__img">
          <img
            src={`${store.avatar ? store.avatar : MockImage}`}
            alt="Imagem da loja"
          />
        </div>
        {/* <ul className="StoreInfo__segments">
          {store.marketSegments.map((item, index) => {
            if (index === store.marketSegments.length - 1)
              return <li key={index}>{item}</li>;
            else return <li key={index}>{item}, </li>;
          })}
        </ul> */}
        <div className="StoreInfo__address">
          <label>Endereço</label>
          <div className="address_wrapper">
            <p>{`${store.address.street}, ${store.address.placeNumber}.`}<br />{`${store.address.district}, ${store.city} - ${store.address.state}`}</p>
            <span className="see-map-link">
              <Link
                to={`https://www.google.com/maps/search/?api=1&query=${store.name}+${store.address.street}+${store.address.placeNumber}+${store.city}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={map}
                  alt=""
                />
              </Link>
            </span>
          </div>
        </div>
        <div className="StoreInfo__contact">
          {store.account && store.account.phone && (
            <div className="contactContainer">
              <label>Contato</label>
              <span className="phone">
                <Link to={`tel:+55${store.account.phone}`}>
                  {maskPhone(store.account.phone)}
                </Link>
                <img
                  src={tell}
                  style={{ width: 'auto' }}
                  height='25px'
                  alt=""
                />
              </span>
            </div>
          )}
          {store.whatsapp && (
            <Link
              className="btn-whatsapp"
              rel="noreferrer"
              to={`https://api.whatsapp.com/send?phone=55${store.whatsapp}&text=Olá! Conheci seu estabelecimento através da Lucrei.`}
              target="_blank"
            >
              WhatsApp
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreModal;
