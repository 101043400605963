//import { FcCancel, FcOk } from 'react-icons/fc'
import DefaultButton from "../../../components/DefaultButton"
import LoadingForButton from "../../../components/LoadingForButton"

export default function DeleteModal({ control, item, handleDelete, loading }) {
    return (

        <>
            <div className="fixed top-0 backdrop-brightness-50 w-screen h-max min-h-screen overflow-y-auto"></div>
            <div className="absolute w-screen flex justify-center">
                <div className="bg-white rounded-xl p-12 flex flex-col justify-center items-center gap-5 max-w-xl">
                    <h5 className="text-center">Tem certeza que deseja excluir essa venda? Essa ação não pode ser desfeita.</h5>
                    <div className="flex gap-3">
                        <DefaultButton content={"Cancelar"} className={"bg-zinc-300 text-zinc-950"} onClick={() => control(false)} />
                        <DefaultButton
                            content={loading ? <LoadingForButton /> : "Excluir"}
                            className={"bg-red-600 text-white"}
                            onClick={() => handleDelete(item)}
                        />
                    </div>

                </div>
            </div>

        </>

    )
}