import axios from 'axios';
import { getToken, logout } from './auth'



const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status === 401) {
      sessionStorage.setItem("@LucreiErrorToast", "Seu tempo de sessão expirou! Por favor, faça login novamente.")

      logout()
      window.location.replace("/login")
    }
    /*   if (status === 403) {
        sessionStorage.setItem("@LucreiErrorToast", "Credenciais inválidas! Por favor, tente fazer login novamente.")
  
        logout()
        window.location.replace("/login")
      } */

    return Promise.reject(error)
  }
);

export default api;