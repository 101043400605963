import { useHistory } from 'react-router-dom';

export default function useLink() {
    const history = useHistory();

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const push = (path, state) => {
        scrollToTop();
        history.push(path, state);
    };

    const replace = (path, state) => {
        scrollToTop();
        history.replace(path, state);
    };

    return {
        ...history,
        push,
        replace
    };
}