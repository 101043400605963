import { useContext } from 'react';

import useSWR from 'swr';
import { CurrencyDollarIcon, BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline';

import { TransactionsContext } from '../context/context';

import fetcher from '../../../services/fetcher';
import { responseAdapter } from '../utils/responseAdapter';


export default function Stats() {

    const { filter } = useContext(TransactionsContext)

    const { data, isLoading } = useSWR(
        `api/v1/transactions/overview?month=${filter.month}&year=${filter.year}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const overview = responseAdapter(data)


    const stats = [
        { id: 1, name: 'Em caixa', stat: overview.balance, icon: CurrencyDollarIcon, color: 'bg-primaryYellow' },
        { id: 2, name: 'Entradas/Receitas', stat: overview.incomes, icon: BarsArrowDownIcon, color: 'bg-secondaryGreen' },
        { id: 3, name: 'Saídas/Despesas', stat: overview.expenses, icon: BarsArrowUpIcon, color: 'bg-red-500' },
    ]


    return (
        <section className="flex justify-center w-full">
            <dl className="grid grid-cols-1 gap-4 md:gap-5 sm:grid-cols-2 lg:grid-cols-3 w-full m-0">
                {stats.map((item) => (
                    <div
                        key={item.id}
                        className="flex flex-col gap-3 overflow-hidden rounded-lg bg-white px-4 py-4 shadow sm:px-6 sm:pt-6"
                    >

                        <dt className='flex w-full justify-start items-center gap-3'>

                            <div className={`rounded-lg p-2 ${item.color}`}>
                                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>

                            <p className="truncate text-xl font-medium text-gray-500 m-0">{item.name}</p>

                        </dt>
                        <dd className="ml-20 flex items-baseline">

                            {!isLoading ? (
                                <p className={`text-2xl font-semibold text-gray-900 m-0 ${item.stat < 0 ? 'text-red-500' : 'text-gray-900'}`}>
                                    {`R$ ${item.stat.toFixed(2)}`}
                                </p>
                            ) : <span>...</span>}


                        </dd>

                    </div>
                ))}
            </dl>
        </section>
    )
}
