import { ShoppingBagIcon, BanknotesIcon, UserIcon } from "@heroicons/react/24/outline"
import { FcPlus } from "react-icons/fc"
import useLink from "../../../../hooks/useLink"

export default function MoreActions() {

    const history = useLink()

    const links = [
        {
            name: "Minhas Compras",
            icon: ShoppingBagIcon,
            href: "/cliente/compras"
        },
        {
            name: "Meus Saques",
            icon: BanknotesIcon,
            href: "/cliente/saques"
        },
        {
            name: "Meu Perfil",
            icon: UserIcon,
            href: "/profile"
        }
    ]

    return (
        <section className="flex flex-col gap-3 w-full justify-center items-center rounded-lg bg-gradient-to-l from-emerald-50 from-5% via-white via-40% to-purple-50 shadow-md p-3">

            <div className="flex items-center justify-center gap-3 mb-3">
                <FcPlus className="w-7 h-7" />
                <h2 className="text-xl font-normal m-0">Mais Opções</h2>
            </div>

            <div className="flex flex-wrap justify-center items-center gap-5 gap-y-4">
                {
                    links.map((link) => {
                        return (
                            <article className="flex flex-col items-center justify-center gap-2 hover:scale-105 duration-150" key={link.name}>

                                <button
                                    type="button"
                                    className="rounded-full bg-white border border-zinc-300 p-2 text-white shadow-md"
                                    onClick={() => history.push(link.href)}
                                >
                                    <link.icon className="h-7 w-7 md:h-10 md:w-10 text-secondaryGreen" aria-hidden="true" />
                                </button>

                                <p className="m-0 text-xs md:text-sm">{link.name}</p>

                            </article>
                        )
                    })
                }
            </div>


        </section>
    )
}