import moment from "moment"

export const yearsMap = () => {
    const years = []

    for (let i = 2022; i <= moment().format("YYYY"); i++) {
        years.push({ id: i, name: i })
    }

    return years
}