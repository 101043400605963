export default function TableHeaders({ fields }) {
  return (
    <thead className="bg-secondaryGreen text-zinc-50">
      <tr>
        {fields.map((field) => (
          <th
            key={field.title}
            scope="col"
            className={`${field.type !== "button"
              ? "pl-4 pr-3 py-3.5 text-left text-sm font-semibold"
              : "whitespace-nowrap text-center py-2 px-3 sm:pr-6"
              } ${field.type === "button" && "text-right"}`}
          >
            {field.title}
          </th>
        ))}
      </tr>
    </thead>
  )
}