export default function CouponRoot({ children, action }) {
    return (
        <article
            className={`bg-white rounded-xl p-3 w-[500px] flex ${action !== null && "cursor-pointer hover:scale-105 duration-100"}`}
            onClick={action !== null && action ? () => action() : null}
        >
            <div className="flex justify-center w-full">

                {children}

            </div>
        </article>
    )
}