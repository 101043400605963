import React, { useState, useEffect } from "react";
import "./style.scss";
import api from "../../services/api";
import { logout } from "../../services/auth";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useForm from "../../hooks/useForm";
import Logo from "../../assets/logo-green.png";

function ResetPassword(props) {
    const [isValidToken, setIsValidToken] = useState(null);
    const [token, setToken] = useState(null);
    const inputPassword = useForm("password");
    const inputConfirmPassword = useForm("password");
    const [togglePasswords, setTogglePasswords] = React.useState(false);
    const [error, setError] = useState("");
    const history = useHistory();

    const verifyToken = async () => {
        const resetToken = props.match.params.token;

        try {
            const response = await api.get(
                `api/v1/auth/forgot-password/${resetToken}`
            );

            if (response.status === 200) {
                setIsValidToken(true);
                localStorage.removeItem("@Cashback-Token");

                setToken(response.data.token);
            }
        } catch (error) {}
    };

    useEffect(() => {
        verifyToken();
    }, []);

    const handleOnBlurConfirmPassword = () => {
        validatePasswords();
    };

    const validatePasswords = () => {
        const { value } = inputConfirmPassword;

        if (value.length === 0) {
            setError("Preencha um valor.");
            return false;
        }

        if (value !== inputPassword.value) {
            setError("As senhas devem ser iguais.");
            return false;
        }

        setError(null);
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!inputPassword.validate()) return;

            if (!validatePasswords()) return;

            const response = await api.patch(
                "api/v1/auth/reset-password",
                {
                    password: inputPassword.value,
                    confirmPassword: inputConfirmPassword.value,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200)
                history.push("/login?resetPassword=success");
        } catch (error) {
            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    return (
        <div className="ResetPasswordPage">
            <main className="content">
                <img
                    className="logo"
                    src={Logo}
                    alt="Logo da Lucrei Cashback"
                />
                {!isValidToken ? (
                    <div className="invalid-token-message">
                        <p>
                            <strong>
                                Caro usuário, o link que você está usando para
                                redefinir a sua senha encontra-se expirado!
                            </strong>
                        </p>
                        <p>
                            Se você deseja redefinir a sua senha para ter acesso
                            à plataforma novamente:
                        </p>
                        <p>
                            Na página de <Link to="/login">login</Link>, clique
                            no botão <strong>"Esqueceu sua senha?"</strong>
                        </p>
                    </div>
                ) : (
                    <>
                        <h1>Redefinir senha</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="password">Crie uma Senha:</label>
                                <input
                                    className="campo"
                                    onChange={inputPassword.onChange}
                                    value={inputPassword.value}
                                    onBlur={inputPassword.onBlur}
                                    id="password"
                                    placeholder="Nova senha:"
                                    required
                                    type={togglePasswords ? "text" : "password"}
                                />
                                {inputPassword.error && (
                                    <span className="error-message">
                                        {inputPassword.error}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label for="confirm-password">
                                    Confirme a nova senha:
                                </label>
                                <input
                                    className="campo"
                                    onChange={inputConfirmPassword.onChange}
                                    value={inputConfirmPassword.value}
                                    onBlur={handleOnBlurConfirmPassword}
                                    id="confirm-password"
                                    placeholder="Confirme a nova senha:"
                                    required
                                    type={togglePasswords ? "text" : "password"}
                                />
                                {inputConfirmPassword.error && (
                                    <span className="error-message">
                                        {inputConfirmPassword.error}
                                    </span>
                                )}
                                {error && (
                                    <span className="error-message">
                                        {error}
                                    </span>
                                )}
                            </div>
                            <div className="toggle-password-wrapper">
                                <button
                                    type="button"
                                    className="toggle-password"
                                    onClick={() =>
                                        setTogglePasswords(!togglePasswords)
                                    }
                                >
                                    {togglePasswords
                                        ? "Esconder senhas"
                                        : "Mostrar senhas"}
                                </button>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn-update-password"
                                    type="submit"
                                >
                                    Criar senha
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </main>
        </div>
    );
}

export default ResetPassword;
