import React, { useEffect, useState } from "react";
import ChatIcon from "./chat-icon.svg";
import { RiSendPlane2Fill } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";

import { Message } from "./components/Message/index";
import { Typing } from "./components/Typing";
import axios from "axios";
import { toast } from "react-toastify";
import "./style.scss";

export const ChatBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [userMessages, setUserMessages] = useState([]);
    const [botResponse, setBotResponse] = useState("");
    const [isfetching, setIsFetching] = useState(false);
    const [firstRequest, setFirstRequest] = useState(true);

    const getUserInput = (e) => {
        setUserInput(e.target.value);
    };

    const handleKeyDown = (event) => {
        const userQuestion = userInput.trim();
        console.log(userQuestion, userQuestion.length);

        if (event.key === "Enter" && userQuestion.length > 0) {
            submitHandler(event);
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const userQuestion = userInput.trim();
        console.log(userQuestion, userQuestion.length);

        if (userQuestion === "" && userQuestion.length === 0) {
            toast.info(
                "Por favor, informe algum texto para a sua dúvida ao nosso Assistente Lucrei.",
                {
                    position: "bottom-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
            console.log('userQuestion === "" && userQuestion.length === 0');
            return;
        }

        if (firstRequest) {
            setFirstRequest(false);
        }
        const req = {
            isBot: false,
            content: userInput,
        };

        setUserMessages([...userMessages, req]);
        setUserInput("");
        setIsFetching(true);

        try {
            const response = await axios.post(
                "https://api.openai.com/v1/completions",
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: userInput,
                    max_tokens: 1000, // tamanho da resposta
                    temperature: 0.5, // criatividade na resposta
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                    },
                }
            );
            setBotResponse(response.data.choices[0].text);
            console.log(response.data.choices[0]);
        } catch (error) {
            console.log(error);
            setBotResponse(
                "Desculpe! Eu estou temporariamente indisponível :( mas retornarei assim que possível! Se você acha que isso é um erro, por favor contate o suporte da Lucrei!"
            );
        }
    };

    useEffect(() => {
        if (botResponse.length !== 0) {
            const req = {
                content: botResponse,
                isBot: true,
            };
            setIsFetching(false);
            setUserMessages([...userMessages, req]);
        }
    }, [botResponse]);

    return (
        <div
            className="chatBotContainer"
            style={{
                bottom: `${isOpen ? "0" : "1rem"}`,
                top: `${isOpen ? "0" : ""}`,
            }}
        >
            {!isOpen && (
                <div
                    className="chatBanner animate__animated animate__bounceInRight"
                    onClick={() => setIsOpen(true)}
                >
                    <h6 className="description">
                        Quer saber sobre algo? Converse comigo!
                    </h6>
                    <img src={ChatIcon} width={50} alt="chat Lucrei" />
                </div>
            )}

            {isOpen && (
                <div
                    className="chatContainer rounded animate__animated animate__bounceInUp"
                // style={{ minHeight: "100vh" }}
                >
                    <div className="chatHeader p-3 rounded">
                        <div className="headerContent">
                            <h4>Chat Lucrei</h4>
                            <span>
                                Você está conversando com o assistente virtual
                                inteligente da Lucrei!
                            </span>
                        </div>
                        <IoIosArrowForward
                            onClick={() => setIsOpen(false)}
                            style={{
                                fontSize: "1.8rem",
                                cursor: "pointer",
                                transform: "rotate(90deg)",
                            }}
                        />
                    </div>
                    <div className="chatSection px-2">
                        <div className="messages">
                            {firstRequest && (
                                <Message
                                    content='Olá! Como posso ajudar? Experimente dizer coisas como "Me dê dicas de marketing"
                        ou "O que é faturamento?".'
                                    isBot={true}
                                />
                            )}

                            {userMessages.map((msg, index) => {
                                return (
                                    <Message
                                        key={index}
                                        content={msg.content}
                                        isBot={msg.isBot}
                                    />
                                );
                            })}

                            {isfetching && <Typing />}
                        </div>
                    </div>
                    <div className="chatAction p-2">
                        <form onSubmit={submitHandler}>
                            <div className="d-flex gap-1">
                                <textarea
                                    className="form-control"
                                    type="text"
                                    placeholder="Digite uma mensagem"
                                    onChange={getUserInput}
                                    value={userInput}
                                    disabled={isfetching}
                                    onKeyDown={handleKeyDown}
                                    autoFocus
                                />
                                {/* <button className='send d-flex align-items-center' type="submit"><span className="material-symbols-outlined">
                                send
                            </span></button> */}
                                {!isfetching && (
                                    <button
                                        className="send d-flex align-items-center"
                                        type="submit"
                                        disabled={isfetching}
                                    >
                                        <RiSendPlane2Fill
                                            style={{ fontSize: "1.8rem" }}
                                        />
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
