import React from 'react';
import "./Style.scss";
import { Link } from "react-router-dom";

export default function WithdrawSecondary(){
  return(
    <div className="tudoSaque">
        <div className="containerSaque">
          <h1>Dados bancarios:</h1>
          <form className="formSaque">
            <div className="group">
              <label>Tipo de chave PIX:</label>
              <input type="text" className="campoText" placeholder="EX: CPF"/>
            </div>
            <div className="group">
              <label>Informe a chave PIX:</label>
              <input type="text" className="campoText" placeholder="000.000.000-66"/>
            </div>
            <div className="group">
              <label>Nome do titular da conta:</label>
              <input type="text" className="campoText" placeholder="João gomes da silva"/>
            </div>
            <div className="group">
              <Link to="/customer/dashboard/withdraw/confirmpassword"><button type="submit" className="buttonConfirm">Confirmar</button></Link>
              <Link to="/customer/dashboard/withdraw"><button className="buttonCancel">Cancelar</button></Link>
            </div>
          </form>
        </div>
    </div>
  )
};