import BannerTool from "../../../components/BannerTool";
import DefaultRoot from "../../../components/DeafultRoot";
import MainContent from "../../../components/MainContent";
import MenuCliente from "../../../components/MenuCliente";

import CpfUpdate from "./CpfUpdate";
import PasswordUpdate from "./PasswordUpdate";

export default function CustomerRegistProfile({ user }) {
  return (
    <DefaultRoot>

      <MenuCliente />

      <BannerTool
        title={"Atualize seus dados"}
      />

      <MainContent>
        {user?.cpf === "000.000.000-00" && (
          <CpfUpdate />
        )}
        <PasswordUpdate />
      </MainContent>
    </DefaultRoot>
  )
} 