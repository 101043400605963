import {
    DocumentTextIcon,
    ReceiptPercentIcon,
    CheckBadgeIcon,
    DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import { BoltIcon } from "@heroicons/react/20/solid";
import useLink from "../../../../hooks/useLink";
import { toast } from "react-toastify";

export default function QuickActions({ disabled }) {
    const history = useLink();

    const links = [
        {
            name: "Dinheiro na nota",
            icon: DocumentTextIcon,
            href: "/cliente/cashbackNota",
        },
        {
            name: "Cupons de desconto",
            icon: ReceiptPercentIcon,
            href: "/cliente/cupons",
        },
        {
            name: "Cartões Fidelidade",
            icon: CheckBadgeIcon,
            href: "/cliente/cartoes-fidelidade",
        },
        {
            name: "Promoções de Cartão Fidelidade",
            icon: DocumentPlusIcon,
            href: "/cliente/cartoes-disponiveis",
        },
    ];

    return (
        <section className="flex flex-col gap-3 w-full justify-center items-center rounded-lg bg-gradient-to-r from-green-600 from-0% via-secondaryGreen via-50% to-green-600 to-95% shadow-md p-3">
            <div className="flex items-center justify-center gap-3 mb-3">
                <BoltIcon
                    className="w-7 h-7 text-yellow-400"
                    aria-hidden="true"
                />
                <h2 className="text-xl font-normal m-0 text-white">
                    Ações Rápidas
                </h2>
            </div>

            <div className="grid grid-cols-2 lg:flex justify-center items-start gap-5 gap-y-4">
                {links.map((link) => {
                    return (
                        <article className="flex flex-col items-center justify-center gap-2 hover:scale-105 duration-150" key={link.name}>
                            <button
                                type="button"
                                className={`rounded-full bg-transparent border-2 p-2 shadow-md ${disabled ? "text-green-400 border-green-400" : "text-white border-zinc-200"}`}
                                onClick={() => {
                                    if (disabled) {
                                        toast.error(
                                            <p>Por favor, atualize seus dados cadastrais para ter acesso a essa funcionalidade
                                                <br />
                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:scale-105 duration-100 sm:w-auto"
                                                    onClick={() => history.push("/profile")}
                                                >
                                                    Atualizar dados
                                                </button>
                                            </p>
                                            ,
                                            {
                                                position: "top-right",
                                                autoClose: 8000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                            }
                                        );
                                    } else {
                                        history.push(link.href)
                                    }
                                }}
                            >
                                <link.icon
                                    className="h-7 w-7 md:h-10 md:w-10"
                                    aria-hidden="true"
                                />
                            </button>

                            <p className={`m-0 text-xs md:text-sm drop-shadow-md text-center ${disabled ? "text-green-400" : "text-white"}`}>
                                {link.name}
                            </p>
                        </article>
                    );
                })}
            </div>
        </section>
    );
}