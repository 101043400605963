import React from "react";
import "./style.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const InfoMessageForm = ({ user, setShowWithdrawModal }) => {
  return (
    <div className="message-info-withdrawal">
      <CheckCircleIcon className="check-icon" />
      <div className="form-group">
        {user && (
          <>
            <p className="success-message">
              Olá, {user.name}! A sua solicitação de saque foi criada com
              sucesso. Em breve estaremos fazendo o seu pix.
            </p>
            <p className="notification-contact-message">
              * Qualquer eventual dúvida com relação aos dados informados por
              você que possa aparecer, estaremos entrando em contato.
            </p>
          </>
        )}
      </div>
      <div className="form-group">
        <button
          className="btn-ok"
          type="button"
          onClick={() => setShowWithdrawModal(false)}
        >
          Entendi!
        </button>
      </div>
    </div>
  );
};

export default InfoMessageForm;
