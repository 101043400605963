/* Hooks */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

/* Components */
import DefaultRoot from "./../../../components/DeafultRoot"
import Footer from "../../../components/Footer/index"
import MenuCliente from "../../../components/MenuCliente"
import MoreActions from "./components/MoreActions"
import QuickActions from "./components/QuickActions"
import Stores from "./components/Stores"
import Support from "./components/Support"
import { toast } from "react-toastify"
import Wallet from "./components/Wallet"
import WithdrawModal from "../../../components/WithdrawModal"

/* Services */
import api from "../../../services/api"
import { getToken, logout } from "../../../services/auth"
import LucreiCode from "./components/LucreiCode"
import useSWR from "swr"
import fetcher from "../../../services/fetcher"
import UncompletedRegist from "./components/UncompleteRegistWarning"

export default function Dash() {
    const token = getToken()
    const CASHBACK = "@cashback"
    const history = useHistory()

    /*  const { data: user, isLoading } = useSWR(
         `api/v1/auth/me`,
         fetcher,
         {
             revalidateOnFocus: false,
         }
     );
  */
    const [showWithdrawModal, setShowWithdrawModal] = useState(false)
    const [user, setUser] = useState({})
    const [cashback, setCashback] = useState([])
    const [uncompletedRegist, setUncompletedRegist] = useState(false)


    useEffect(() => {
        async function LoadCashback() {
            try {
                const response = await api.get("api/v1/customers/me/balance", {
                    headers: { Authorization: token },
                })
                setCashback(response.data.balance)
                localStorage.setItem(CASHBACK, cashback)
            } catch (error) {
                const { status } = error.response
                console.log("error", status)
                if (status === 401) {
                    history.push("/login")
                    logout()
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    )
                }
            }
        }

        LoadCashback()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, cashback])

    useEffect(() => {
        async function GetUser() {
            try {
                const response = await api.get("api/v1/auth/me", {
                    headers: { Authorization: token },
                })
                setUser(response.data)
            } catch (error) {
                const { status } = error.response
                console.log("error", status)
                if (status === 401) {
                    history.push("/login")
                    logout()
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    )
                }
            }
        }

        GetUser()
    }, [])

    useEffect(() => {
        if (user?.cpf === "000.000.000-00")
            setUncompletedRegist(true)
    }, [user])

    return (

        <div>
            {showWithdrawModal && (
                <WithdrawModal
                    balance={cashback}
                    setShowWithdrawModal={setShowWithdrawModal}
                    showWithdrawModal={showWithdrawModal}
                    user={user}
                />
            )}

            {uncompletedRegist && (
                <UncompletedRegist />
            )}


            <DefaultRoot>


                <MenuCliente />

                <main className="flex flex-col gap-4 justify-center items-center px-2 max-w-screen-xl mx-auto">

                    <LucreiCode userName={user?.name} lucreiCode={user?.lucreiCode} />

                    <QuickActions disabled={uncompletedRegist} />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                        <Wallet
                            setShowWithdrawModal={setShowWithdrawModal}
                            cashback={cashback}
                            disabled={uncompletedRegist}
                        />

                        <Stores />
                    </div>

                    <Support />

                    <MoreActions />
                </main>
            </DefaultRoot>

            <Footer />
        </div>
    )
}
