import React from "react";

import TableRows from "./TableRows";
import TableHeaders from "./TableHeaders";
import CustomSelect from "../CustomSelect";
import { yearsMap } from "../../utils/years";
import { monthsMap } from "../../utils/months";
import moment from "moment"


export default function NewTable({ data, name, fields, filter, setFilter }) {

  const controlMonth = (value) => {
    if (filter && setFilter)
      setFilter({ year: filter.year, month: value.id })
  }

  const controlYear = (value) => {
    if (filter && setFilter)
      setFilter({ year: value.id, month: filter.month })
  }


  return (
    <div className="w-full">
      <div className="sm:flex sm:items-center sm:flex-row-reverse">

        {filter && setFilter && (
          <section className="flex mx-2 w-full justify-end gap-2 mb-3 sm:mb-0">
            <CustomSelect options={monthsMap()} active={moment().format("MM")} control={controlMonth} />
            <CustomSelect options={yearsMap()} active={yearsMap().length} control={controlYear} />
          </section>
        )}
        <div className="sm:flex-auto whitespace-nowrap">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{name}</h1>
        </div>
      </div>
      {
        data?.data?.length === 0 ? (
          <div className="w-full flex justify-center text-center border border-gray-200 rounded-xl shadow-md p-5 bg-white">
            Não encontramos nada aqui.
          </div>
        ) : (
          <div className="flow-root">
            <div className="mx-auto overflow-x-auto w-full">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden border border-gray-200 rounded-xl shadow-md">
                  <table className="min-w-full">
                    <TableHeaders fields={fields} />
                    <TableRows data={data?.data} fields={fields} />
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </div>
  )
}
