import { useState } from "react";
import { useEffect } from "react";

import useSWR from "swr";

import Loading from "../../components/Loading";
import BannerTool from "../../components/BannerTool";
import Pagination from "../../components/Pagination";
import DefaultRoot from "../../components/DeafultRoot";
import MenuEmpresa from "../../components/MenuEmpresa";
import NewTable from "../../components/NewTable/NewTable";

import useLink from "../../hooks/useLink";

import fetcher from "../../services/fetcher";

import calcCustomerRating from "./functions/calcCustomerRating";

export default function ClientManagement() {
    const history = useLink();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [lucreiView, setLucreiView] = useState(false);
    // const [newCustomers, setNewCustomers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const noPurchaseIntervalLimit = data?.noPurchaseIntervalLimit || 0;

    const { data: customers, isLoading: loadingCustomers } = useSWR(
        `api/v1/dashboard/stores/me/my-customers?page=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const { data: separateCustomers, isLoading: loadingSeparateCustomers } =
        useSWR(
            `api/v1/dashboard/stores/me/all-my-customers?page=${currentPage}`,
            fetcher,
            {
                revalidateOnFocus: false,
            }
        );

    // const getNewCustomers = async () => {
    //     const customersOnWeek = await api.get(
    //         "api/v1/dashboard/stores/me/customers/most-recent/last-7-days"
    //     );
    //     const customersOnMonth = await api.get(
    //         "api/v1/dashboard/stores/me/customers/most-recent/current-month"
    //     );
    //     const customersOnYear = await api.get(
    //         "api/v1/dashboard/stores/me/customers/most-recent/current-year"
    //     );

    //     const response = {
    //         onWeek: customersOnWeek.data.totalMostRecentCustomers,
    //         onMonth: customersOnMonth.data.totalMostRecentCustomers,
    //         onYear: customersOnYear.data.totalMostRecentCustomers,
    //     };
    //     setIsLoading(false);
    //     setNewCustomers(response);
    // };

    useEffect(() => {
        if (lucreiView) customers && setTotalPages(customers.pages);
        else separateCustomers && setTotalPages(separateCustomers.pages);
    }, [customers, lucreiView, separateCustomers]);

    // useEffect(() => {
    //     getNewCustomers();
    // }, []);

    useEffect(() => {
        //identifies the current view, set the data to data state and save the data in the local storage

        if (!loadingCustomers && !loadingSeparateCustomers) {
            setIsLoading(true);
            if (lucreiView) {
                setData(customers);
                localStorage.setItem(
                    "customersList",
                    JSON.stringify(customers.data)
                );
            } else {
                setData(separateCustomers);
                localStorage.setItem(
                    "customersList",
                    JSON.stringify(separateCustomers.data)
                );
            }
            setIsLoading(false);
        }
    }, [
        customers,
        loadingCustomers,
        loadingSeparateCustomers,
        lucreiView,
        separateCustomers,
    ]);

    return (
        <DefaultRoot>
            <MenuEmpresa />

            <main>
                <BannerTool
                    title={"Meus clientes"}
                    action={() => history.push("/empresa/dashboard")}
                    actionTitle={"Voltar ao Dashboard"}
                />

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="max-w-screen-2xl px-3 mx-auto">
                        {/* <div className="flex flex-wrap gap-3 m-auto max-w-screen-2xl">
                            <InfoCard
                                positive={true}
                                period="Semana"
                                newCustomers={newCustomers.onWeek}
                            />
                            <InfoCard
                                positive={true}
                                period="Mês"
                                newCustomers={newCustomers.onMonth}
                            />
                            <InfoCard
                                positive={true}
                                period="Ano"
                                newCustomers={newCustomers.onYear}
                            />
                        </div> */}

                        <div className="flex rounded-xl overflow-hidden bg-white w-max mx-auto flex-col md:flex-row mt-2 mb-3">
                            <button
                                className={`p-3 duration-500 ${!lucreiView && "bg-primaryPurple text-white"
                                    }`}
                                onClick={() => setLucreiView(false)}
                            >
                                Vendas Avulsas
                            </button>
                            <button
                                className={`p-3 duration-500 ${lucreiView && "bg-primaryGreen"
                                    }`}
                                onClick={() => setLucreiView(true)}
                            >
                                Vendas com Cashback
                            </button>
                        </div>

                        {loadingCustomers || loadingSeparateCustomers ? (
                            <Loading />
                        ) : (
                            <div className="mx-auto lg:max-w-screen-2xl">
                                <NewTable
                                    data={data}
                                    name={`Clientes que já compraram nessa modalidade: ${data.totalItems}`}
                                    fields={[
                                        {
                                            type: "default",
                                            title: "Cliente",
                                            name: `${lucreiView
                                                ? "name"
                                                : "customerName"
                                                }`,
                                            altName: "leadCustomerName",
                                        },
                                        {
                                            type: "phone",
                                            title: "Telefone",
                                            name: "phone",
                                            altName: "customerPhone",
                                        },
                                        {
                                            type: "date",
                                            title: "Última compra",
                                            name: "lastPurchase",
                                        },
                                        // {
                                        //     type: "money",
                                        //     title: "Ticket Médio",
                                        //     name: "averageTicket",
                                        // },
                                        // {
                                        //     type: "default",
                                        //     title: "Total de Compras",
                                        //     name: "totalPurchases",
                                        // },
                                        {
                                            type: "default",
                                            title: "Avaliação",
                                            className: (item) => {
                                                const rating =
                                                    calcCustomerRating(
                                                        item.lastPurchase,
                                                        noPurchaseIntervalLimit
                                                    );
                                                if (rating === "Ótima")
                                                    return "text-green-600";
                                                if (rating === "Bom")
                                                    return "text-primaryPurple";
                                                if (rating === "Ruim")
                                                    return "text-red-500";
                                            },
                                            name: (item) => {
                                                return calcCustomerRating(
                                                    item.lastPurchase,
                                                    noPurchaseIntervalLimit
                                                );
                                            },
                                        },
                                        {
                                            type: "button",
                                            title: "",
                                            name: "Ver Mais",
                                            action: (item) => {
                                                if (lucreiView)
                                                    history.push(
                                                        `/empresa/cliente/${item._id}/perfil`
                                                    );
                                                else
                                                    history.push(
                                                        `/empresa/clientes-avulsos/${item.phone ||
                                                        item.customerPhone
                                                        }/perfil`
                                                    );
                                            },
                                            disabled: () => false,
                                        },
                                    ]}
                                />

                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages || 1}
                                    paginate={setCurrentPage}
                                />
                            </div>
                        )}
                    </div>
                )}
            </main>
        </DefaultRoot>
    );
}
