import React, { useState } from "react";

import "./style.scss";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Vetor1 from "../../assets/img/vetor1.png";
/* import MaskedInputPhone from "../../utils/maskInput_phone";
import MaskedInputCpf from "../../utils/maskInput_cpf";
import MaskedInputBirthDate from "../../utils/maskInput_birthDate"; */

import api from "../../services/api";
import loginToken from "../../services/auth";
import { loginData } from "../../services/Data_User";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import { cidades } from "../../utils/optionsSelect";

import CircularProgress from "@material-ui/core/CircularProgress";
import useForm from "../../hooks/useForm";
import { convertDateToMongoDB } from "../../utils/dates";
import { Link } from "react-router-dom"

import Modal from "../../components/Modal/Modal";

export default function CadastroCliente() {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const inputEmail = useForm("email");
    const inputName = useForm();
    const inputCpf = useForm("cpf");
    const inputPhone = useForm("phone");
    const inputPassword = useForm("password");
    const inputConfirmPassword = useForm();
    const inputDateOfBirth = useForm("date");

    const [agree, setAgree] = useState(null);

    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setComfirmPassword] = useState("");
    const [city, setCity] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");

    const [error, setError] = useState("");
    const [errorCity, setErrorCity] = useState("");

    const [registerCode, setRegisterCode] = useState({});

    const handleOnBlurConfirmPassword = () => {
        validatePasswords();
    };

    const validateCity = () => {
        if (!city) return false;

        return true;
    };

    const [values, setValues] = React.useState({
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validatePasswords = () => {
        const { value } = inputConfirmPassword;

        if (value.length === 0) {
            setError("Preencha um valor.");
            return false;
        }

        if (value !== inputPassword.value) {
            setError("As senhas devem ser iguais.");
            return false;
        }

        setError(null);
        return true;
    };

    const handleOnBlurAgreement = () => {
        if (!agree) {
            setAgree(false);
            return;
        }
    };

    const handleOnBlurCity = () => {
        if (!validateCity()) {
            setErrorCity("Informe a sua cidade.");
            return;
        }

        setErrorCity("");
    };

    const handleRegisterCodeValidate = async () => {
        if (!registerCode.value) return;

        setRegisterCode(() => ({ value: registerCode.value }));

        try {
            setLoading(true);
            const response = await api.post("api/v1/promo-codes/validate", {
                code: registerCode.value.toUpperCase(),
            });

            setRegisterCode(() => ({
                ...registerCode,
                message: `Código ${registerCode.value} foi aplicado`,
                applied: true,
                error: null,
            }));
        } catch (error) {
            const { response } = error;
            const { status } = response;
            if (status === 404) {
                const { message } = response.data;
                setRegisterCode(() => ({
                    ...registerCode,
                    message: null,
                    error: message,
                    applied: false,
                }));
            }

            if (status === 400) {
                const { message } = response.data;
                setRegisterCode(() => ({
                    ...registerCode,
                    message: null,
                    error: message,
                    applied: false,
                }));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRegisterCodeInputChange = async ({ target }) => {
        setRegisterCode(() => ({
            ...registerCode,
            value: target.value,
            error: null,
        }));
    };

    const handleRemoveRegisterCode = () => {
        setRegisterCode(() => ({
            ...registerCode,
            message: "",
            value: "",
            applied: false,
            error: null,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!inputName.validate() || !inputEmail.validate()) return;

        if (!inputCpf.validate() || !inputDateOfBirth.validate()) return;

        if (!inputPhone.validate() || !inputPassword.validate()) return;

        if (!validatePasswords() || !agree) {
            setAgree(false);
            return;
        }

        if (!validateCity()) return;

        const convertedDate = convertDateToMongoDB(inputDateOfBirth.value);

        const data = {
            name: inputName.value,
            cpf: inputCpf.value,
            phone: inputPhone.value.replace(/[^0-9]/g, ""),
            email: inputEmail.value,
            password: inputPassword.value,
            confirmPassword: inputConfirmPassword.value,
            dateOfBirth: convertedDate,
            city,
            registerCode: registerCode.applied ? registerCode.value : undefined,
        };

        try {
            setLoading(true);

            const response = await api.post(
                "api/v1/auth/customers/signup",
                data
            );
            setLoading(false);

            loginToken(response.data.token);

            loginData(
                response.data.user.name,
                response.data.user.email,
                response.data.user.phone
            );

            history.push("/cliente/dashboard");

            toast.success("Seja Bem-Vindo a Lucrei :)", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            setLoading(false);

            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (error.message === "Network Error") {
                toast.error(
                    "Os servidores da Lucrei encontram-se indisponíveis no momento. Prometemos retornar o mais breve possível.",
                    {
                        position: "top-right",
                        autoClose: 7500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            const { status, data } = error?.response;
            const { message } = data;

            if (status && status === 400) {
                if (
                    message ===
                    "Duplicate field value: cpf. Please use another value!"
                ) {
                    toast.error(
                        "Este CPF já esta cadastrado! Caso queira recuperar o seu acesso entre em contato com nosso Suporte.",
                        {
                            position: "top-right",
                            autoClose: 7000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                    return;
                }

                toast.error(message, {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else if (status === 500) {
                toast.error(
                    "Servidor Indisponível, entre em contato com a Kobe!",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    async function handleCadCliente(e) {
        e.preventDefault();

        if (error) return;
        setLoading(true);

        const data = {
            name,
            cpf,
            phone,
            email,
            password,
            confirmPassword,
            dateOfBirth,
            city,
        };
        if (password !== confirmPassword) {
            setLoading(false);
            toast.error("As senhas não conferem!! ", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else {
            try {
                const response = await api.post(
                    "api/v1/auth/customers/signup",
                    data
                );

                loginToken(response.data.token);
                loginData(
                    response.data.account.name,
                    response.data.account.email,
                    response.data.account.phone
                );

                history.push("/cliente/dashboard");
                toast.success("Seja Bem-Vindo a Lucrei :)", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } catch (error) {
                setLoading(false);
                const { status, data } = error.response;
                const { message } = data;

                if (
                    status === 400 &&
                    message ===
                    "Duplicate field value: cpf. Please use another value!"
                )
                    toast.error(
                        "Este CPF ja esta cadastrado! Verifique seus dados ou realize login",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                if (
                    status === 400 &&
                    message ===
                    "Duplicate field value: email. Please use another value!"
                )
                    toast.error(
                        "Este Email ja esta cadastrado! verifique seus dados ou realize login",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );

                if (
                    status === 400 &&
                    message ===
                    "Duplicate field value: phone. Please use another value!"
                )
                    toast.error(
                        "Este Telefone ja esta cadastrado! verifique seus dados ou realize login",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );

                if (status === 500)
                    toast.error(
                        "Servidor Indisponivel, entre em contato com a kobe!!",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
            }
        }
    }

    return (
        <div className="cadastro-clientes">
            <div className="container-sm">
                <form onSubmit={handleSubmit}>
                    <div
                        className="row justify-content-center align-items-center mb-3"
                        id="responsivity-control"
                    >
                        <div className="left-form col-4">
                            <div className="topic text-center mt-2">
                                <h2 className="mb-3">Cadastre sua Conta</h2>
                                <p className="detais-text text-muted mb-5">
                                    DIGITE SUAS INFORMAÇÕES
                                </p>
                            </div>

                            <div class="mb-3">
                                <label for="name" class="form-label">
                                    Nome Completo
                                </label>
                                <input
                                    id="name"
                                    className="form-control"
                                    onChange={inputName.onChange}
                                    value={inputName.value}
                                    type="text"
                                    name="name"
                                    placeholder="Digite Aqui"
                                    onBlur={inputName.onBlur}
                                    required
                                />
                                {inputName.error && (
                                    <span>{inputName.error}</span>
                                )}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">CPF</label>
                                <InputMask
                                    className="form-control"
                                    onChange={inputCpf.onChange}
                                    value={inputCpf.value}
                                    onBlur={inputCpf.onBlur}
                                    mask="999.999.999-99"
                                    id="campo_CPF"
                                    maxLength="16"
                                    type="text"
                                    name="cpf"
                                    placeholder="Ex: 000.000.000-00"
                                    required
                                />
                                {inputCpf.error && (
                                    <span>{inputCpf.error}</span>
                                )}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Telefone</label>
                                <InputMask
                                    id="campo_phone"
                                    className="form-control"
                                    mask="(99) 99999-9999"
                                    onChange={inputPhone.onChange}
                                    value={inputPhone.value}
                                    maxLength="16"
                                    type="text"
                                    name="phone"
                                    placeholder="Ex: (99) 9 9999-9999"
                                    required
                                    onBlur={inputPhone.onBlur}
                                />
                                {inputPhone.error && (
                                    <span>{inputPhone.error}</span>
                                )}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">
                                    Data de Nascimento
                                </label>
                                <InputMask
                                    className="form-control"
                                    placeholder="Ex: 01/01/2000"
                                    name="date"
                                    mask="99/99/9999"
                                    value={inputDateOfBirth.value}
                                    onChange={inputDateOfBirth.onChange}
                                    required
                                    onBlur={inputDateOfBirth.onBlur}
                                />
                                {inputDateOfBirth.error && (
                                    <span>{inputDateOfBirth.error}</span>
                                )}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">Cidade</label>
                                <select
                                    className="form-control"
                                    onChange={({ target }) =>
                                        setCity(target.value)
                                    }
                                    value={city}
                                    name="city"
                                    id="city"
                                    placeholder="Ex: Fortaleza"
                                    onBlur={handleOnBlurCity}
                                >
                                    <option value="" disabled selected>
                                        Selecione...
                                    </option>
                                    {cidades?.map((cidades) => (
                                        <option value={cidades}>
                                            {cidades}
                                        </option>
                                    ))}
                                </select>
                                {errorCity && <span>{errorCity}</span>}
                            </div>
                            <div class="mb-3">
                                <label class="form-label">E-mail</label>
                                <input
                                    id="email"
                                    className="form-control"
                                    onChange={inputEmail.onChange}
                                    value={inputEmail.value}
                                    type="email"
                                    name="email"
                                    placeholder="Informe o Seu E-mail"
                                    required
                                    onBlur={inputEmail.onBlur}
                                />
                                {inputEmail.error && (
                                    <span>{inputEmail.error}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-4 ms-5 right-form">
                            <img
                                class="img-fluid"
                                id="lg-image"
                                src={Vetor1}
                                alt=""
                            />
                            <div class="mb-4 h-control">
                                <label class="form-label">Crie uma Senha</label>
                                <input
                                    className="form-control"
                                    onChange={inputPassword.onChange}
                                    value={inputPassword.value}
                                    onBlur={inputPassword.onBlur}
                                    type={
                                        values.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    name="password"
                                    id="password"
                                    placeholder="Digite aqui"
                                    required
                                />

                                <span
                                    class="pass-show"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? (
                                        <i className="bi bi-eye-fill"></i>
                                    ) : (
                                        <i className="bi bi-eye-slash-fill"></i>
                                    )}
                                </span>
                                {inputPassword && (
                                    <span>{inputPassword.error}</span>
                                )}
                                {error && <span>{error}</span>}
                            </div>

                            <div class="mb-4 h-control">
                                <label class="form-label">
                                    Confirme sua Senha
                                </label>
                                <input
                                    className="form-control"
                                    onChange={inputConfirmPassword.onChange}
                                    value={inputConfirmPassword.value}
                                    onBlur={handleOnBlurConfirmPassword}
                                    type={
                                        values.showPassword
                                            ? "text"
                                            : "password"
                                    }
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder="Digite aqui:"
                                    required
                                />
                                <span
                                    class="pass-show"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? (
                                        <i className="bi bi-eye-fill"></i>
                                    ) : (
                                        <i className="bi bi-eye-slash-fill"></i>
                                    )}
                                </span>
                                {inputConfirmPassword && (
                                    <span>{inputConfirmPassword.error}</span>
                                )}
                                {error && <span>{error}</span>}
                            </div>

                            <div class="mt-4-5 mb-3 border-dashed">
                                <label class="form-label">
                                    Tem um código de cadastro? (Opcional)
                                </label>
                                <input
                                    className="form-control"
                                    onChange={handleRegisterCodeInputChange}
                                    value={registerCode.value}
                                    name="registerCode"
                                    id="registerCode"
                                    disabled={loading || registerCode.applied}
                                />
                                <button
                                    type="button"
                                    className="btn btn-success mt-3 ms-2-2"
                                    disabled={
                                        loading ||
                                        !registerCode.value ||
                                        registerCode.applied
                                    }
                                    onClick={handleRegisterCodeValidate}
                                >
                                    {" "}
                                    {loading ? (
                                        <CircularProgress
                                            color="primary"
                                            size={21}
                                        />
                                    ) : (
                                        "Usar código"
                                    )}
                                </button>
                            </div>
                            {registerCode.message && (
                                <span className="success">
                                    {registerCode.message}
                                </span>
                            )}
                            {registerCode.error && (
                                <span>{registerCode.error}</span>
                            )}

                            <div class="form-check agree-confirm">
                                <input
                                    onChange={() => setAgree(!agree)}
                                    checked={agree}
                                    onBlur={handleOnBlurAgreement}
                                    type="checkbox"
                                    class="form-check-input"
                                    required
                                />
                                <label for="remember" class="form-check-label">
                                    Li e concordo com os
                                </label>
                                <button
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    {" "}
                                    Termos de Uso
                                </button>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={loading}
                                >
                                    {" "}
                                    {loading ? (
                                        <CircularProgress
                                            color="primary"
                                            size={21}
                                        />
                                    ) : (
                                        "Cadastrar"
                                    )}
                                </button>
                            </div>

                            <div class="text-center mt-3 already-have-account">
                                Já tem uma conta? Faça Login{" "}
                                <Link to="/login">aqui</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {open && (
                <Modal visible={() => setOpen(false)} Title="Termos de Uso" />
            )}
        </div>
    );
}
