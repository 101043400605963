import "./StyleInfor.scss";
import ticket from "../img/ticket.svg";
import calendar from "../img/calendar.svg";
import user from "../img/user.svg";
import phone from "../img/phone.svg";
import email from "../img/mail.svg";
import home from "../img/home.svg";
import editar from "../img/editar.svg";
import { FaWhatsapp } from "react-icons/fa";

function Infor({ customer }) {
    return (
        <section className="containerInfor">
            <div className="subcontainer">
                <div className="agroupboxInfor">
                    <div className="inforCentro">
                        <h2>Nome</h2>
                        <div className="boxInfor">
                            <div className="d-flex">
                                <img width="25px" src={user} />
                                <p className="infor">
                                    {customer?.name || "Não informado"}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="inforCentro">
                        <h2>Contato</h2>
                        <div className="boxInfor">
                            {customer?.phone ? (
                                <a
                                    className="flex gap-2 items-center	"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://api.whatsapp.com/send?phone=55${customer?.phone}`}
                                >
                                    <FaWhatsapp className="-ml-0.5 h-5 w-5 cursor-pointer text-green-500 " />
                                    {/* <img width="25px" src={phone} /> */}
                                    <p
                                        className="infor"
                                        style={{ marginBottom: 0 }}
                                    >
                                        {customer?.phone || "-"}
                                    </p>
                                </a>
                            ) : (
                                "-"
                            )}
                        </div>
                    </div>
                    <div className="inforCentro">
                        <h2>Email</h2>
                        <div className="boxInfor">
                            <div className="d-flex">
                                <img width="25px" src={email} />
                                <p className="infor">
                                    {customer?.email || "Não informado"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Infor;
