import React from "react"
import Menu from "../../components/Menu/Menu"

/*Icons Imports*/
import { BanknotesIcon, HomeIcon, ShoppingBagIcon } from "@heroicons/react/24/outline"


export default function MenuCliente() {

    return (
        <div className="menu">
            <Menu
                home="/cliente/dashboard"
                navigation={[
                    { name: 'Dashboard', href: "/cliente/dashboard", icon: HomeIcon },
                    { name: 'Minhas Compras', href: "/cliente/compras", icon: ShoppingBagIcon },
                    { name: 'Meus Saques', href: "/cliente/saques", icon: BanknotesIcon }
                ]}
            />
        </div>
    );
}
