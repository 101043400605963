import { useState } from "react"
import { useEffect } from "react"

import useSWR from "swr"

import Graph from "../../../../components/Graphic/polar/index"

import useLink from "../../../../hooks/useLink"

import User from "../../img/user.svg"
import arrow from "../../img/seta.svg"
import users from "../../img/users.svg"
import Ticket from "../../img/ticket.svg"
import Cifrao from "../../img/cifrao.png"

import statisticsDash from "../../integration/statisticsDash"

import "./styleGraf.scss"

function Chart({ user }) {
    const [lucreiView, setLucreiView] = useState(false)
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [cache, setCache] = useState(false)
    const history = useLink()

    /* Data fetching with SWR. Docs: https://swr.vercel.app/pt-BR/docs/getting-started */
    const { data: defaultData, isLoading: loadingDefault } = useSWR(
        "separate", statisticsDash, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    }
    )

    const { data: secondaryData, mutate: fetchSecondary } = useSWR(
        "ordinary", statisticsDash, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: false
    }
    )


    useEffect(() => {
        if (!lucreiView)
            setData(defaultData)

        if (lucreiView)
            setData(secondaryData)

        if (!cache) {
            if (!lucreiView) {
                setIsLoading(loadingDefault)
                setData(defaultData)
            } else {
                const fetch = async () => {
                    setIsLoading(true)
                    await fetchSecondary()
                    setIsLoading(false)
                    setCache(true)
                }
                fetch()
            }
        }
    }, [lucreiView, cache, fetchSecondary, loadingDefault, secondaryData, defaultData])

    useEffect(() => {
        if (lucreiView) {
            setData(secondaryData)
        }
    }, [secondaryData, lucreiView])

    return (
        <section className="statisticContainer">
            <div className="flex rounded-xl overflow-hidden bg-zinc-100 w-max mx-auto mb-4 flex-col md:flex-row">
                <button
                    className={`p-3 duration-500 ${!lucreiView && "bg-primaryPurple text-white"
                        }`}
                    onClick={() => setLucreiView(false)}
                >
                    Vendas Avulsas
                </button>
                <button
                    className={`p-3 duration-500 ${lucreiView && "bg-primaryGreen"
                        }`}
                    onClick={() => setLucreiView(true)}
                >
                    Vendas com Cashback
                </button>
            </div>


            <div className="statisticInfo">
                {/* <img alt='arraste para o lado' src={swipe} width={'80px'} className='swipe'></img> */}
                <div className="containerAdvances justify-center">
                    <div className="boxAdvances">
                        <div
                            className={`imgElement ${lucreiView
                                ? "imgLucreiElement"
                                : "imgAvulseElement"
                                }`}
                        >
                            <img height="28" src={arrow} alt="Arrow" />
                        </div>
                        <div className="textAdvances">
                            <p>Novos Clientes nesse mês</p>
                            <h3>
                                {!isLoading && data?.newClients}
                                {isLoading && "..."}
                            </h3>
                        </div>
                    </div>
                    <div className="boxAdvances">
                        <div
                            className={`imgElement ${lucreiView
                                ? "imgLucreiElement"
                                : "imgAvulseElement"
                                }`}
                        >
                            <img height="28" src={User} alt="icone" />
                        </div>

                        <div className="textAdvances">
                            <p>Compraram apenas 1 vez</p>
                            <h3>
                                {!isLoading && data?.purchasesOnce}
                                {isLoading && "..."}
                            </h3>
                        </div>
                    </div>
                    <div className="boxAdvances">
                        <div
                            className={`imgElement ${lucreiView
                                ? "imgLucreiElement"
                                : "imgAvulseElement"
                                }`}
                        >
                            <img height="28" src={users} alt="icone" />
                        </div>
                        <div className="textAdvances">
                            <p>Compraram várias vezes</p>
                            <h3>
                                {!isLoading && data?.purchasesMany}
                                {isLoading && "..."}
                            </h3>
                        </div>
                    </div>
                    <div className="boxAdvances">
                        <div
                            className={`imgElement ${lucreiView
                                ? "imgLucreiElement"
                                : "imgAvulseElement"
                                }`}
                        >
                            <img height="28" src={Ticket} alt="icone" />
                        </div>
                        <div className="textAdvances">
                            <p>Ticket Médio</p>
                            <h3>
                                R${" "}
                                {!isLoading && data?.averageTicket.toFixed(2)}
                                {isLoading && "..."}
                            </h3>
                        </div>
                    </div>
                    <div className="boxAdvances" id="total">
                        <div
                            className={`imgElement ${lucreiView
                                ? "imgLucreiElement"
                                : "imgAvulseElement"
                                }`}
                        >
                            <img height="28" src={Cifrao} alt="icone" />
                        </div>
                        <div className="textAdvances">
                            <p>Total vendido no mês atual</p>
                            <h3>
                                R${" "}
                                {!isLoading && data?.totalSoldCurrentMonth.toFixed(2)}
                                {isLoading && "..."}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="containerGraphic">
                    <div className="leftBoxGraph">
                        <h3 id="textHigher">Vendas</h3>
                        {!isLoading ? (
                            <Graph
                                prevMonth={
                                    data?.totalSalesPreviousMonth
                                }
                                actMonth={
                                    data?.totalSalesCurrentMonth
                                }
                                max={
                                    data?.totalSalesPreviousMonth +
                                    data?.totalSalesCurrentMonth
                                }
                                colorPrev={lucreiView ? "#409900" : "#5c049c"}
                                colorAct={lucreiView ? "#00ee00" : "#9000f7"}
                            />
                        ) : <p className="mt-3 text-secondaryGreen">Estamos carregando as suas informações. Por favor, aguarde...</p>}


                    </div>
                    <div className="rightBoxGraph">
                        <h3 id="textHigher">Esse mês</h3>
                        <div className="statisticGraphic">
                            <div className="BoxDataGraph">
                                <div
                                    className={`caption ${lucreiView
                                        ? "captionLucreiCurr"
                                        : "captionAvulseCurr"
                                        }`}
                                ></div>
                                <div className="groupInfor">
                                    <p>Vendas - Mês atual</p>
                                    <h3>
                                        {!isLoading && data?.totalSalesCurrentMonth}
                                        {isLoading && "..."}
                                    </h3>
                                </div>
                            </div>
                            <div className="BoxDataGraph">
                                <div
                                    className={`caption ${lucreiView
                                        ? "captionLucreiPrev"
                                        : "captionAvulsePrev"
                                        }`}
                                ></div>
                                <div className="groupInfor">
                                    <p>Vendas - Mês Anterior</p>
                                    <h3>
                                        {!isLoading && data?.totalSalesPreviousMonth}
                                        {isLoading && "..."}
                                    </h3>
                                </div>
                            </div>

                            {/* <button
                                className="py-2 px-3 bg-secondaryGreen text-white font-semibold rounded-lg shadow-md duration-150 hover:scale-105"
                                onClick={() => history.push("/empresa/controle-financeiro")}
                            >
                                Acessar Controle Financeiro
                            </button> */}

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}
export default Chart
