import { useState, useEffect } from "react";

import useSWR from "swr";

import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import BannerTool from "../../components/BannerTool";
import DefaultRoot from "../../components/DeafultRoot";
import MenuCliente from "../../components/MenuCliente";
import MainContent from "../../components/MainContent";
import NewTable from "../../components/NewTable/NewTable";

import fetcher from "../../services/fetcher";


export default function ReceiptsScreen() {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);


    const { data, isLoading } = useSWR(
        `api/v1/customers/me/receipts?sortBy=-createdAt&pageSize=10`,
        fetcher,
        {
            revalidateOnFocus: false,
        },
    );

    useEffect(() => {
        data && setTotalPages(data.pages);
    }, [data]);


    return (
        <DefaultRoot>
            <MenuCliente />

            <BannerTool
                title={"Minhas notas fiscais"}
            />

            <MainContent>
                {!isLoading ? (
                    <NewTable
                        data={data}
                        name={""}
                        fields={[
                            {
                                type: "date",
                                title: "Data do envio",
                                name: "createdAt",
                            },
                            {
                                type: "default",
                                title: "Estabelecimento",
                                name: "store",
                            },
                            {
                                type: "money",
                                title: "Valor da nota",
                                name: "value",
                            },
                            {
                                type: "money",
                                title: "Cashback",
                                name: "cashback",
                            },
                            {
                                type: "default",
                                title: "Status",
                                name: "status",
                            },
                            {
                                type: "default",
                                title: "Detalhes",
                                name: "denyDetails",
                            },
                            {
                                type: "button",
                                title: "Anexo",
                                name: "Foto",
                                disabled: (item) => !item,
                                action: (item) => window.open(item.receiptPhoto, "_blank")
                            }
                        ]}
                    />
                ) : <Loading />}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </MainContent>

        </DefaultRoot>
    );
}
