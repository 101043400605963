import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./Modal.scss";

import arrowLeft from "./left.png";
import { useHistory } from "react-router-dom";
import MaskedInputCpf from "../../utils/maskInput_cpf";
import MaskedInputCnpj from "../../utils/maskInput_cnpj";
import MaskedInputPhone from "../../utils/maskInput_phone";
import InputMaskRandomKeyPix from "../../utils/InputMaskRandomKeyPix";
import api from "../../services/api";
import { logout } from "../../services/auth";
import CurrencyInput from "react-currency-masked-input";
import useForm from "../../hooks/useForm";
import formatCurrency from "../../utils/format-currency";
import CloseIcon from "@material-ui/icons/Close";
import InfoMessageForm from "./InfoMessageForm/InfoMessageForm";
import X from "../../assets/X.svg";

const WithdrawModal = ({
    balance,
    token,
    setShowWithdrawModal,
    showWithdrawModal,
    user,
}) => {
    const [typePix, setTypePix] = useState("phone");
    const pixInput = useForm(typePix);
    const nameInput = useForm();
    const valueInput = useForm();
    const cpfUserInput = useForm("cpf");
    const passwordInput = useForm("password");
    const [value, setValue] = useState();
    const [valueError, setValueError] = useState(false);
    const [valueErrorMessage, setValueErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMessageInfo, setShowMessageInfo] = useState(false);
    const history = useHistory();

    const [step, setStep] = useState(1);

    const nextToInfoPix = () => {
        if (!pixInput.validate()) return;

        setStep(2);
    };

    const nextToFinalStep = () => {
        if (!nameInput.validate() || !value) return;

        if (value < 5 * 1) {
            toast.info("O valor mínimo de saque agora é de R$5,00.", {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        setStep(3);
    };

    const checkTokenValidation = async () => {
        try {
            const response = await api.post(`/api/v1/auth/token-validate`, {
                headers: { Authorization: token },
            });
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (error.response.status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!cpfUserInput.validate() || !passwordInput.validate()) return;

        const request = {
            pix: pixInput.value,
            accountOwnerName: nameInput.value,
            value: value,
            cpf: cpfUserInput.value,
            password: passwordInput.value,
        };

        try {
            setLoading(true);
            const response = await api.post("api/v1/withdrawals", request, {
                headers: { Authorization: token },
            });

            setLoading(false);
            setStep(4);
            //setShowWithdrawModal((prev) => !prev);

            // if (response.status === 201) {
            //   toast.success(
            //     "Solicitação de saque criada com sucesso! Em breve estaremos fazendo o seu PIX :)",
            //     {
            //       position: toast.POSITION.TOP_RIGHT,
            //       autoClose: 8000,
            //     }
            //   );
            // }
            setShowMessageInfo(true);
        } catch (error) {
            setLoading(false);

            const { status } = error.response;

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (!window.navigator.onLine) {
                toast.error(
                    "Não foi possível se conectar a Lucrei. Verifique sua conexão de internet.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (error.message === "Network Error") {
                setShowWithdrawModal((prev) => !prev);
                toast.error(
                    "Os servidores da Lucrei encontram-se indisponíveis no momento. Prometemos retornar o mais breve possível.",
                    {
                        position: "top-right",
                        autoClose: 7500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }

            if (
                status === 400 &&
                error.response.data.message ===
                "Unable to withdraw! You have pending withdrawal request(s)."
            ) {
                setShowWithdrawModal((show) => !show);

                toast.error(
                    "Caro usuário, você já possui uma solicitação de saque em espera. Entre em contato com a Kobe para maiores informações.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (
                status === 400 &&
                error.response.data.message ===
                "Provide a value less or equals than your balance."
            ) {
                setShowWithdrawModal((show) => !show);

                toast.error(
                    "O valor informado é maior que o seu valor de saldo disponível.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (
                status === 400 &&
                error.response.data.message === "Check your CPF number."
            ) {
                toast.error(
                    "Caro usuário, o número de CPF deve ser o mesmo que foi usado no seu cadastro.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 403) {
                toast.error(
                    "Senha incorreta! Verifique seus dados e tente novamente.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (error.response.status === 429) {
                history.push("/login");
                logout();
                toast.info(
                    "Nossos servidores se encontram em manutenção nesse momento. Por favor, tente novamente em alguns minutos.",
                    {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
                return;
            }
        }
    };

    const onChangeCurrencyValue = ({ target }) => {
        const d = target.value.replace(/\D/, "");
        setValueError(false);
        const e = d / 100;

        setValue(e);
    };

    const handleChangeSelect = ({ target }) => {
        setTypePix(target.value);
        pixInput.setValue("");
        pixInput.setError(null);
    };

    const handleCurrencyInputBlur = ({ target }) => {
        if (target.value === "" || target.value === "0.00") {
            setValueError("Preencha um valor.");
        } else if (value > balance) {
            setValueError("O valor de saque deve ser menor que o saldo.");
        }
    };

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && showWithdrawModal) {
                setShowWithdrawModal(false);
            }
        },
        [showWithdrawModal, setShowWithdrawModal]
    );

    useEffect(() => {
        checkTokenValidation();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (step !== 1) checkTokenValidation();
        // eslint-disable-next-line
    }, [step]);

    useEffect(() => {
        document.addEventListener("keydown", keyPress);

        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);

    return (
        <div className="ModalWithdraw">
            {!showMessageInfo && (
                <form className="WithdrawForm" onSubmit={handleSubmit}>
                    <button
                        className="closeButton"
                        onClick={() => setShowWithdrawModal(false)}
                    >
                        <img src={X} alt="fechar" />
                    </button>
                    {step > 1 && (
                        <button className="return" onClick={() => setStep(step - 1)}>
                            <img src={arrowLeft} alt="" />
                        </button>
                    )}
                    <p id="StepInd">PASSO {step}</p>
                    <div className="groupText">
                        <h3>Solicitar Saque</h3>
                        <p>
                            Insira os dados solicitados para realizar o saque
                            dos cashbacks adquiridos na Lucrei
                        </p>
                    </div>
                    <div className="balanceInfo">
                        <p>SALDO DISPONÍVEL</p>
                        {<h1>R$ {formatCurrency(balance)}</h1>}
                        {/* {<h1>{balance.toFixed(2).replace(".", ",")}</h1>} */}
                    </div>
                    <div className="FormWrapper">
                        {step === 1 && (
                            <div className="step-1-form">
                                <div className="form-group">
                                    <label htmlFor="pix-type">
                                        Escolha o tipo da chave PIX:
                                    </label>
                                    <select
                                        id="pix-type"
                                        className="campo"
                                        onChange={handleChangeSelect}
                                        value={typePix}
                                    >
                                        <option value="phone">Telefone</option>
                                        <option value="cpf">CPF</option>
                                        <option value="email">Email</option>
                                        <option value="cnpj">CNPJ</option>
                                        <option value="random">
                                            Chave Aleatória
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    {typePix === "phone" && (
                                        <>
                                            <MaskedInputPhone
                                                id="campo_phone"
                                                className="campo"
                                                mask="(99) 99999-9999"
                                                maxLength="16"
                                                type="text"
                                                name="phone"
                                                placeholder="(99) 99999-9999"
                                                required
                                                value={pixInput.value}
                                                onChange={pixInput.onChange}
                                                onBlur={pixInput.onBlur}
                                            />
                                            {pixInput.error && (
                                                <span className="error-message">
                                                    {pixInput.error}
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {typePix === "cpf" && (
                                        <>
                                            <MaskedInputCpf
                                                className="campo"
                                                onChange={pixInput.onChange}
                                                value={pixInput.value}
                                                onBlur={pixInput.onBlur}
                                                mask="999.999.999-99"
                                                maxLength="16"
                                                type="text"
                                                placeholder="Ex: 000.000.000-00"
                                                required
                                            />
                                            {pixInput.error && (
                                                <span className="error-message">
                                                    {pixInput.error}
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {typePix === "email" && (
                                        <>
                                            <input
                                                className="campo"
                                                type="email"
                                                placeholder="mariasilva@gmail.com"
                                                value={pixInput.value}
                                                onChange={pixInput.onChange}
                                                onBlur={pixInput.onBlur}
                                            />
                                            {pixInput.error && (
                                                <span className="error-message">
                                                    {pixInput.error}
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {typePix === "cnpj" && (
                                        <>
                                            <MaskedInputCnpj
                                                className="campo"
                                                mask="99.999.999/9999-99"
                                                maxLength="18"
                                                type="text"
                                                name="cnpj"
                                                id="cnpj"
                                                value={pixInput.value}
                                                onChange={pixInput.onChange}
                                                onBlur={pixInput.onBlur}
                                                placeholder="Ex: 69.165.660/0001-28"
                                            />
                                            {pixInput.error && (
                                                <span className="error-message">
                                                    {pixInput.error}
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {typePix === "random" && (
                                        <>
                                            <InputMaskRandomKeyPix
                                                value={pixInput.value}
                                                onChange={pixInput.onChange}
                                                onBlur={pixInput.onBlur}
                                            />
                                            {pixInput.error && (
                                                <span className="error-message">
                                                    {pixInput.error}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn-next"
                                        type="button"
                                        onClick={() => nextToInfoPix()}
                                        disabled={pixInput.error}
                                    >
                                        Avançar
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* STEP 2 */}
                        {step === 2 && (
                            <div className="step-2-form">
                                <div className="form-group">
                                    <label htmlFor="name">
                                        Nome do titular:
                                    </label>
                                    <input
                                        className="campo"
                                        type="text"
                                        id="name"
                                        onChange={nameInput.onChange}
                                        onBlur={nameInput.onBlur}
                                        value={nameInput.value}
                                        placeholder="João da Silva"
                                    />
                                    {nameInput.error && (
                                        <span className="error-message">
                                            {nameInput.error}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Valor:</label>
                                    <CurrencyInput
                                        className="campo"
                                        onChange={onChangeCurrencyValue}
                                        onBlur={handleCurrencyInputBlur}
                                        type="number"
                                    />
                                    {valueError && (
                                        <span className="error-message">
                                            {valueError}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn-next btn-next-value"
                                        type="button"
                                        onClick={nextToFinalStep}
                                        disabled={
                                            nameInput.error ||
                                            !value ||
                                            value > balance
                                        }
                                    >
                                        Avançar
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* STEP 3 */}
                        {step === 3 && (
                            <div className="step-2-form">
                                <div className="form-group">
                                    <label htmlFor="cpf">
                                        Informe o seu CPF:
                                    </label>
                                    <MaskedInputCpf
                                        className="campo"
                                        onChange={cpfUserInput.onChange}
                                        value={cpfUserInput.value}
                                        onBlur={cpfUserInput.onBlur}
                                        mask="999.999.999-99"
                                        maxLength="16"
                                        type="text"
                                        placeholder="000.000.000-00"
                                        required
                                    />
                                    {cpfUserInput.error && (
                                        <span className="error-message">
                                            {cpfUserInput.error}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Senha:</label>
                                    <input
                                        className="campo"
                                        type="password"
                                        id="password"
                                        value={passwordInput.value}
                                        onChange={passwordInput.onChange}
                                        onBlur={passwordInput.onBlur}
                                        placeholder="Senha da Lucrei"
                                    />
                                    {passwordInput.error && (
                                        <span className="error-message">
                                            {passwordInput.error}
                                        </span>
                                    )}
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn-next"
                                        type="submit"
                                        disabled={
                                            passwordInput.error ||
                                            cpfUserInput.error
                                        }
                                    >
                                        Concluir
                                        {loading && (
                                            <CircularProgress
                                                className="progress"
                                                size={16}
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            )}
            {showMessageInfo && (
                <InfoMessageForm
                    user={user}
                    setShowWithdrawModal={setShowWithdrawModal}
                />
            )}
        </div>
    );
};

export default WithdrawModal;
