import React from "react";
/* Hooks */
import { useState, useEffect } from "react";
import useSWR from "swr";
import useLink from "../../../../hooks/useLink";

/* Services */
import api from "../../../../services/api";

/* Components */
import MenuCliente from "../../../../components/MenuCliente";
import BannerTool from "../../../../components/BannerTool";
import Loading from "../../../../components/Loading";
import CouponsGrid from "../../components/CouponsGrid";
import CouponOffer from "../../components/CouponOffer";
import Pagination from "../../../../components/Pagination";
import { toast } from "react-toastify";
import DefaultRoot from "../../../../components/DeafultRoot";

export default function ListCoupons() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [generating, setGenerating] = useState(null);

    const history = useLink();

    /* Fetch */
    const fetcher = async (url) => {
        const response = await api.get(url);
        return response.data;
    };

    const { data: couponsOffer, isLoading: loadingCouponsOffer } = useSWR(
        `api/v1/coupons?pageNumber=${currentPage}&status=ATIVO`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const GenerateCoupon = async (id) => {
        setGenerating(id);
        try {
            await api.post("api/v1/customer-coupons", { parentCoupon: id });

            toast.success(
                "Cupom gerado com sucesso! Clique em 'Meus Cupons' para acessá-lo.",
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
        } catch (error) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setGenerating(null);
        }
    };

    /* Effects */

    useEffect(() => {
        if (!loadingCouponsOffer) setTotalPages(couponsOffer.pages);
    }, [couponsOffer, loadingCouponsOffer]);

    return (
        <DefaultRoot>

            <MenuCliente />

            <main>
                <BannerTool
                    title="Cupons Disponíveis"
                    action={() => history.push("/cliente/meus-cupons")}
                    actionTitle="Ver Meus Cupons"
                />

                {loadingCouponsOffer ? (
                    <Loading />
                ) : (
                    <CouponsGrid totalItems={couponsOffer.totalItems}>
                        {couponsOffer.data.length !== 0 ? (
                            couponsOffer.data.map((coupon) => {
                                return (
                                    <CouponOffer
                                        key={coupon._id}
                                        id={coupon._id}
                                        status={coupon.status}
                                        store={coupon.store?.name}
                                        avatar={coupon.store?.avatar}
                                        discount={Number(coupon?.discountPercentage * 100).toFixed(0)}
                                        description={coupon.description}
                                        expiration={coupon.expirationDate}
                                        originalPrice={Number(coupon?.originalPrice).toFixed(2)}
                                        loading={generating}
                                        action={() =>
                                            GenerateCoupon(coupon._id)
                                        }
                                    />
                                );
                            })
                        ) : (
                            <span className="mt-40 text-center text-xl w-full">
                                Não temos cupons disponíveis no momento.
                            </span>
                        )}
                    </CouponsGrid>
                )}

                {!loadingCouponsOffer && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={setCurrentPage}
                    />
                )}
            </main>
        </DefaultRoot>
    );
}
