
import { useEffect, useState } from "react";


import api from "../../../services/api";
import './style.scss'


export default function Profile(props) {


  const HandleHelp = () => {
    window.location.href =
      'https://api.whatsapp.com/send?phone=5585992429308&text=Ol%C3%A1%20quero%20alterar%20meus%20dados';
  };
  const [user, setUser] = useState({});

  useEffect(() => {

    async function getLoggedUser() {
      const response = await api.get("api/v1/auth/me", {
        headers: { Authorization: props.token },
      });

      const { email, name, role, cnpj, managerCpf, phone, city, managerName } = response.data;

      setUser({ email, name, role, cnpj, managerCpf, phone, city, managerName });
    };
    getLoggedUser()
  }, []);



  return (
    <div class="card shadow-lg">
      <div class="card-body p-4">
        <h1 class="fs-4 card-title fw-bold mb-4 text-start">Dados Pessoais</h1>
        <button className="alterDados btn btn-sm btn-outline-dark mb-4" onClick={HandleHelp}>
          Alterar dados
        </button>
        <div className="master-container">
          <form>
            <div className="row">
              <div class="col mb-3">
                <label class="mb-2 text-muted">Nome da Empresa</label>
                <input type="text" class="form-control" value={user.name} disabled />
              </div>
              <div class="col mb-3">
                <label class="col mb-2 text-muted">CNPJ</label>
                <input type="text" class="form-control" value={user.cnpj} disabled />
              </div>
            </div>
            <div className="row">
              <div class="col mb-3">
                <label class="mb-2 text-muted">Nome do Responsável</label>
                <input type="text" class="form-control" value={user.managerName} disabled />
              </div>
              <div class="col mb-3">
                <label class="mb-2 text-muted">CPF do Responsável</label>
                <input type="text" class="form-control" value={user.managerCpf} disabled />
              </div>
            </div>
            <div className="row">
              <div class="col mb-3">
                <label class="mb-2 text-muted">Cidade</label>
                <input type="text" class="form-control" value={user.city} disabled />
              </div>
              <div class="col mb-3">
                <label class="mb-2 text-muted">E-mail</label>
                <input type="text" class="form-control" value={user.email} disabled />
              </div>
              <div class="col mb-3">
                <label class="mb-2 text-muted">Telefone</label>
                <input type="text" class="form-control" value={user.phone} disabled />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    /*
    <div className="containerDados">

      <div className="1">
        <h3>Dados Pessoais</h3>
        <button className="alterDados btn btn-sm btn-outline-dark" onClick={HandleHelp}>
          Alterar dados.

        </button>

        <div className="dados1" maxWidth="sm">
          <InputLabel htmlFor="input-with-icon-adornment">Nome Completo</InputLabel>
          <TextField

            id="outlined-disabled"
            disabled
            value={user.name}
            variant="standard"
          />
          <InputLabel htmlFor="input-with-icon-adornment">CPF</InputLabel>
          <TextField
            disabled
            id="outlined-disabled"
            value={user.cpf}
            variant="standard"
          />
          <InputLabel htmlFor="input-with-icon-adornment">Cidade</InputLabel>
          <TextField
            disabled
            id="outlined-disabled"
            value={user.city}
            variant="standard"
          />
        </div>
      </div>
      <div className="1">
        <div className='dados2'>
          <InputLabel htmlFor="input-with-icon-adornment">Email</InputLabel>
          <TextField
            disabled
            id="outlined-disabled"

            value={user.email}
            variant="standard"
          />



          <InputLabel htmlFor="input-with-icon-adornment">Telefone</InputLabel>
          <TextField
            disabled

            id="standard-disabled"
            value={user.phone}
            variant="standard"
          />



        </div>



      </div>



    </div >
    */
  );
}



