import './index.scss'

export default function Depoiments() {
    return (
        <section className="depoimentsSection mt-5">
            <div className="title d-flex justify-content-center text-center mb-3">
                <h2 className="me-2">O que nossos clientes dizem</h2>
            </div>

            <div className="opinionCards">
                <article className='opinionCard'>
                    "Aderi a ideia da Lucrei através de um amigo, e hoje vejo que faz muita diferença para mim em todas as compras que faço.
                    Procuro sempre as lojas parceiras e já sou cliente fiel da plataforma, indico para todos!"
                </article>
                <article className='opinionCard'>
                    "Em todas as compras que eu faço  nos parceiros sempre peço para cadastrar na Lucrei. Em 1 mês, já consegui mais de 40 reais.
                    A Lucrei me proporciona juntar dinheiro comprando, algo que nunca imaginei que conseguiria, mas que se torna possível na plataforma!"

                </article>
                <article className='opinionCard'>
                    "Antes eu costumava fazer compras pela internet mesmo sabendo que o produto que eu precisa estava disponível em lojas da minha cidade, mas,
                    depois da Lucrei, eu sempre pesquiso as empresas parceiras para economizar e garantir meu cashback, o que tem me ajudado a fortalecer o comércio local."

                </article>
                <article className='opinionCard'>
                    "Sempre indico a Lucrei, e quando falo da plataforma as pessoas costumam me perguntar sobre o saque do cashback que ganho, e é ótimo poder falar sobre isso,
                    pois é algo que, além do atendimento ser ótimo, não demanda nenhuma complicação e cai rapidinho na conta. A Lucrei realmente me faz lucrar de todos os jeitos!"
                </article>
            </div>
            <div className="end m-5">
                <div className="title d-flex justify-content-center text-center">
                    <div className="titleContent">
                        <h2>Tá esperando o quê? Vem lucrar com a gente!</h2>
                    </div>

                </div>
            </div>
        </section>
    )
}