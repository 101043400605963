/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from 'react'
import LogoPurple from "../../assets/logoCircularRoxo.png"
import NewLogo from "./img/LUCREI.svg";
import { Dialog, Menu as HeadlessMenu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ArrowRightStartOnRectangleIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import useLink from '../../hooks/useLink';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { logout } from '../../services/auth';
import { FcOnlineSupport } from "react-icons/fc"
import { FaWhatsapp } from "react-icons/fa"
import { useSWRConfig } from 'swr';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Menu({ home, navigation }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const history = useLink()
  const local = useLocation()

  const { cache } = useSWRConfig()

  const HandleLogout = () => {
    cache.delete("api/v1/auth/me")
    localStorage.clear()
    history.push("/login")
  }

  const userNavigation = [
    { name: 'Seu Perfil', action: () => history.push("/profile"), icon: UserIcon },
    { name: 'Sair', action: () => HandleLogout(), icon: ArrowRightStartOnRectangleIcon },
  ]

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-1 p-0.5 bg-primaryPurple rounded-full hover:scale-105 duration-100" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex  grow flex-col gap-y-5 overflow-y-auto bg-white px-6 py-4">
                    <div className="flex h-16 shrink-0 items-center mb-3">
                      <img
                        src={NewLogo}
                        alt="logo lucrei"
                        width={80}
                      />
                    </div>
                    <nav className="flex items-start justify-start">
                      <ul role="list" className="flex flex-1 flex-col m-0 p-0">
                        <li>
                          <ul role="list" className="flex flex-col gap-3 p-0">
                            <li className="flex gap-3 rounded-md ring-1 p-1 justify-center ring-secondaryGreen">
                              <img
                                className="h-8 w-8 rounded-full bg-gray-50"
                                src={LogoPurple}
                                alt="logo lucrei"
                              />
                              <span className="items-center flex gap-2">
                                <span className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                  {window.localStorage.getItem("NameUser")}
                                </span>
                              </span>
                            </li>

                            <div className="flex justify-end mb-5 text-primaryPurple cursor-pointer hover:scale-105 duration-100"
                              onClick={() => HandleLogout()}
                            >
                              <span className="text-sm">Sair</span>
                              <ArrowRightStartOnRectangleIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                            </div>

                            {navigation?.map((item) => {
                              const current = local.pathname === item.href
                              return (
                                <li key={item.name}>
                                  <button
                                    onClick={() => history.push(item.href)}
                                    className={classNames(
                                      current
                                        ? 'bg-secondaryGreen text-white'
                                        : 'text-zinc-950 hover:text-white hover:bg-primaryPurple',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        current ? 'text-white' : 'text-zinc-950 group-hover:text-white',
                                        'h-6 w-6 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </button>
                                </li>
                              )

                            })}

                            <button
                              type="button"
                              className="mt-5 inline-flex items-center justify-center gap-x-3 rounded-md bg-white border-2 border-primaryYellow w-full px-3.5 py-1.5 text-sm font-semibold text-black shadow-sm hover:scale-105 duration-100"
                              onClick={() => window.open("https://api.whatsapp.com/send?phone=5585991949020", "_blank")}
                            >
                              <FcOnlineSupport className="w-7 h-7" />
                              Precisa de Ajuda?
                            </button>
                          </ul>
                        </li>

                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10" aria-hidden="true" />

            <div className="flex justify-between flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <img
                className="cursor-pointer"
                src={NewLogo}
                alt="logo lucrei"
                width={80}
                onClick={() => history.push(home)}
              />
              <div className="flex items-center gap-x-4 lg:gap-x-6">


                {/* Profile dropdown */}
                <HeadlessMenu as="div" className="relative">
                  <HeadlessMenu.Button className="-m-1.5 flex items-center p-1.5">
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={LogoPurple}
                      alt="logo lucrei"
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {window.localStorage.getItem("NameUser")}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </HeadlessMenu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <HeadlessMenu.Items className="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <HeadlessMenu.Item key={item.name}>
                          {() => (
                            <button
                              onClick={() => item.action()}
                              className="px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:bg-secondaryGreen hover:text-white duration-100 w-full flex gap-2 whitespace-nowrap"
                            >
                              <item.icon className="ml-2 h-5 w-5" aria-hidden="true" />
                              {item.name}
                            </button>
                          )}
                        </HeadlessMenu.Item>
                      ))}
                    </HeadlessMenu.Items>
                  </Transition>
                </HeadlessMenu>
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      </div>
    </>
  )
}
