import { useState } from "react";
import api from "../../services/api";
import { logout } from "../../services/auth";
import "./style.scss";

import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

toast.configure();

export default function Profile(props) {
    const history = useHistory();

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    async function ChangePass(e) {
        try {
            e.preventDefault();
            const data = {
                password,
                newPassword,
                newPasswordConfirmation,
            };
            if (newPassword !== newPasswordConfirmation) {
                toast.error("Novas senhas não conferem", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                });
            }

            const response = await api.patch(
                "api/v1/auth/me/change-password",
                data,
                {
                    headers: { Authorization: props.token },
                }
            );
            setNewPassword("");
            setNewPasswordConfirmation("");
            setPassword("");

            if (response.status === 200) {
                toast.success("Senha Alterada com sucesso :)", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                });
            }
        } catch (error) {
            const { status } = error.response;

            if (status === 400) {
                toast.error("Senha atual não confere", {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }

            console.log("error", status);
            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    }

    return (
        <div className="mb-5">
            <div class="card shadow-lg mt-5">
                <div class="card-body p-4">
                    <h1 class="fs-4 card-title fw-bold mb-4 text-start">Nova Senha</h1>
                    <div className="master-container">
                        <form onSubmit={ChangePass}>
                            <div className="row pass-control">
                                <div class="col mb-3">
                                    <label class="mb-2 text-muted">Senha Atual</label>
                                    <input type={values.showPassword ? "text" : "password"} class="form-control" value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <div class="col mb-3">
                                    <label class="col mb-2 text-muted">Nova Senha</label>
                                    <input type={"password"}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} class="form-control" />
                                </div>
                                <div class="col mb-3">
                                    <label class="col mb-2 text-muted">Confirmar</label>
                                    <input type={"password"}
                                        value={newPasswordConfirmation}
                                        onChange={(e) =>
                                            setNewPasswordConfirmation(e.target.value)
                                        } class="form-control" />

                                </div>
                                <button className="col-auto text-white bg-secondaryGreen rounded-md hover:scale-105 duration-100 changePass-btn !mt-[2rem] !w-fit !mx-auto !px-5" type="submit">
                                    Trocar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
        /*
        <div className="container_senha">
            <div className="Inputs">
                <div className="title">
                    <h3>Nova Senha</h3>
                </div>
                <form className={classes.newPass} onSubmit={ChangePass}>
                    <div className="inputsCamp">
                        <InputLabel htmlFor="standard-adornment-oldPassword">
                            Senha Atual
                        </InputLabel>
                        <Input
                            id="standard-adornment-oldPassword"
                            type={values.showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className="inputsCamp">
                        <InputLabel htmlFor="standard-adornment-newPassword">
                            Nova Senha
                        </InputLabel>
                        <Input
                            id="standard-adornment-newPassword"
                            type={"password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <div className="inputsCamp">
                        <InputLabel htmlFor="standard-adornment-verificationPassword">
                            Confirmar Nova Senha
                        </InputLabel>
                        <Input
                            id="standard-adornment-verificationPassword"
                            type={"password"}
                            value={newPasswordConfirmation}
                            onChange={(e) =>
                                setNewPasswordConfirmation(e.target.value)
                            }
                        />
                    </div>

                    <Button variant="outlined" color="primary" type="submit">
                        Trocar
                    </Button>
                </form>
            </div>
        </div>*/
    );
}
