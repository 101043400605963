import Add from '../assets/AddCash.svg'
import NoteSearc from '../assets/noteSearcCash.svg'
import Note from '../assets/noteCash.svg'


export default function CashNotas(props) {
    return (
        <div className="cash-boxNotas"
            onClick={props.userInfo?.ableToSendReceipts === false ? props.limit : props.handleClick}
            style={{ opacity: props.userInfo?.ableToSendReceipts === false ? '.6' : '' }}
        >
            <div className="cash-headerBox">
                <div>
                    <img
                        src={props.NoteSearc ? NoteSearc : Note}
                    />
                    <span>{props.NoteSearc ? 'Acompanhe suas notas' : 'Envie suas notas'}</span>
                </div>
                <p>
                    {props.NoteSearc ? 'Visualize seu histórico de notas fiscais enviadas'
                        : props.userInfo?.ableToSendReceipts === false ?
                            <span style={{ color: '#EEE', fontWeight: '700' }}>Limite diário atingido</span>
                            : "Envie a foto e informações de notas fiscais para receber cashback"
                    }
                </p>
            </div>
            <button type="button">
                <img
                    src={Add}
                />
            </button>
        </div>
    )
}