import React from "react";

import useLink from "../../../../hooks/useLink";
import fetcher from "../../../../services/fetcher";
import BannerTool from "../../../../components/BannerTool";
import useSWR from "swr";

import { useState, useEffect } from "react";
import Pagination from "../../../../components/Pagination";
import Loading from "../../../../components/Loading";

import DefaultRoot from "../../../../components/DeafultRoot";
import PromoCard from "../components/PromoCard";
import MenuCliente from "../../../../components/MenuCliente";

export default function PromoCards() {
    const history = useLink();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data: cards, isLoading: loadingCards } = useSWR(
        `api/v1/loyalty-card-promotions?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        if (!loadingCards) {
            setTotalPages(cards.pages);
        }
    }, [cards, loadingCards]);

    return (
        <DefaultRoot>
            <MenuCliente />

            <main>
                <BannerTool title="Promoções de Cartão Fidelidade disponíveis" />

                {loadingCards ? (
                    <Loading />
                ) : cards.totalItems > 0 ? (
                    <section className="w-11/12 mx-auto grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                        {cards.data.map((card) => {
                            return (
                                <PromoCard
                                    store={card.store.name}
                                    avatar={card.store.avatar}
                                    photo={card.photo}
                                    title={card.description}
                                    promoPurchases={card.salesNumber}
                                    id={card.id}
                                    key={card.id}
                                />
                            );
                        })}
                    </section>
                ) : (
                    <h2 className="text-2xl text-center max-w-2xl mx-auto mt-5">
                        Você não possui cartões de fidelidade disponíveis no
                        momento.
                    </h2>
                )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </main>
        </DefaultRoot>
    );
}
