import useLink from "../../../../hooks/useLink"
import lucreiImg from "../../../../assets/logoAmarelaCircular.png"

export default function CustomerCard({ store, avatar, photo, title, isComplete, isRedeemed, totalPurchases, promoPurchases, code, id, object }) {

    const history = useLink()

    const handleClick = () => {
        window.sessionStorage.setItem('LucreiLoyaltyInfo', JSON.stringify(object))

        history.push(`/cliente/detalhes-do-cartao/${code}/${id}`)
    }

    return (
        <article className="bg-white shadow-md rounded-xl p-3 flex flex-col gap-3 items-center justify-center">
            <div className="flex text-center justify-center items-center gap-2">
                <span className="text-black text-sm w-[100px]">{store}</span>
                <img
                    src={avatar}
                    alt="logo"
                    width={80}
                    className="rounded-md"
                />
            </div>
            <div className="w-64 h-64 bg-zinc-100">
                <img src={photo || lucreiImg} alt="banner da promoção" className="h-full w-full object-cover" />
            </div>
            <h2 className="text-2xl font-semibold text-center">{title}</h2>
            <span className={isComplete ? 'text-secondaryGreen' : 'text-red-600'}>
                {isComplete ? 'COMPLETO' : `Nº de compras: ${totalPurchases}/${promoPurchases}`}
            </span>
            {isRedeemed && (
                <span className="text-primaryPurple text-center">Você já resgatou o brinde desta promoção.</span>
            )}
            <button
                className="bg-secondaryGreen text-white px-5 py-2 rounded-xl shadow-md hover:scale-105 duration-150"
                onClick={() => handleClick()}
            >
                Ver Detalhes
            </button>
        </article>
    )
}