import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";

import api from "../../../services/api";

import visivelOne from '../CadVenda/IconsCadVenda/visivel1.png'
import visivelTwo from '../CadVenda/IconsCadVenda/visivel2.png'
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import formatCurrency from "../../../utils/format-currency";
import CloseIcon from "@material-ui/icons/Close";
import { logout } from "../../../services/auth";
import x from "../CadVenda/IconsCadVenda/x.svg"

toast.configure();

const Deposit = ({
    token,
    setShowDepositModal,
    showDepositModal,
    updateUser,
}) => {
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({});
    const history = useHistory();

    useEffect(() => {
        async function getLoggedUser() {
            try {
                const response = await api.get("api/v1/auth/me", {
                    headers: { Authorization: token },
                });

                const { email, name, role, unableToSell } = response.data;

                setUser({ email, name, role, unableToSell });
            } catch (error) {
                const { status } = error.response;
                console.log("error", status);
                if (status === 401) {
                    logout();
                    history.push("/login");
                    toast.error(
                        "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        }
                    );
                }
            }
        }
        getLoggedUser();
    }, []);

    const ID_EMPRESA = "id";
    const id = localStorage.getItem(ID_EMPRESA);

    async function CreateDeposit(e) {
        try {
            e.preventDefault();
            const data = {
                password,
            };
            const response = "@response";
            if (user.role === "store") {
                const response = await api.post("/api/v1/deposits", data, {
                    headers: { Authorization: token },
                });
            } else if (user.role == "admin") {
                const response = await api.post(
                    `/api/v1/stores/${id}/deposits`,
                    data,
                    {
                        headers: { Authorization: token },
                    }
                );
            }

            setShowDepositModal((prev) => !prev);
            updateUser();
            toast.success(
                "Solicitação de Deposito criada com sucesso! Em breve entraremos em contato :)",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 6000,
                }
            );
        } catch (error) {
            const { status } = error.response;

            if (status === 401) {
                logout();
                history.push("/login");
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (
                error.response.status === 400 &&
                error.response.data.message ===
                "Unable to create a new deposit. You have a pending deposit."
            ) {
                toast.error(
                    "Caro usuário, você já possui uma solicitação de Deposito Pedente. Entre em contato com a Kobe para maiores informações.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 403) {
                toast.error(
                    "Senha incorreta! Verifique seus dados e tente novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }

            if (status === 500) {
                toast.error("Erro de servidor! Entre em contato com a Kobe", {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
    }

    const keyPress = useCallback(
        (e) => {
            if (e.key === "Escape" && showDepositModal) {
                setShowDepositModal(false);
            }
        },
        [showDepositModal, setShowDepositModal]
    );

    useEffect(() => {
        document.addEventListener("keydown", keyPress);

        return () => document.removeEventListener("keydown", keyPress);
    }, [keyPress]);

    const { innerHeight, innerWidth } = window;

    const DEPOSIT = "@DEPOSIT";
    const deposit = JSON.parse(localStorage.getItem(DEPOSIT));


    const [CheckType, setCheckType] = useState('password')
    const [showhidetext, setshowhidetext] = useState("show")
    const [Control, setControl] = useState(0)
    const [imgShow, setimgShow] = useState(visivelOne)

    const handleShowHide = (e) => {
        if (Control == 0) {
            setCheckType('text')
            setControl(1)
            setimgShow(visivelTwo)
        }
        else {
            setCheckType('password')
            setControl(0)
            setimgShow(visivelOne)
        }
    }
    return (
        <div className="ModalDeposito">
            <div className="ModalWrapper">
                <div className="headerModal">
                    <div className="CadInfo">
                        <h2>Depositar Comissão</h2>
                        <p className="labelCad">Esse dinheiro será investido na fidelização de clientes por meio do Cashback</p>
                    </div>
                </div>
                <form onSubmit={CreateDeposit} className="DepositForm">
                    <button
                        className="closeButton"
                        onClick={() => setShowDepositModal(false)}
                    >
                        <img
                            src={x}
                            width='20px'
                            alt="close"
                        />
                    </button>

                    <div className="groupForms">
                        <div className="valueInfo">
                            <label>VALOR DE DEPÓSITO</label>
                            <h2>{`R$ ${formatCurrency(deposit.amountToPay)}`}</h2>
                        </div>
                        <div className="group">
                            <label>
                                <span>Confirme sua senha:</span>
                                <div className="agroupInput">
                                    <input
                                        className="formInput"
                                        type={CheckType}
                                        placeholder="Digite sua senha"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        value={password}
                                    />
                                    <img onClick={() => handleShowHide()} src={imgShow} height="27px" alt="senha" />
                                </div>
                            </label>
                        </div>
                        <p id="TextBotton">Entraremos em contato para realizar o procedimento</p>
                        <div className="buttonGroup">
                            <button type="submit" className="buttonConfirm">
                                Depositar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Deposit;
