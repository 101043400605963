import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import api from "../../../../services/api";
import LoadingForButton from "../../../../components/LoadingForButton";
import PromoSelect from "./PromoSelect";
import InputMask from "react-input-mask";
import Loading from "../../../../components/Loading";

export default function RegistSellForm() {
    const [loading, setLoading] = useState(false);
    const [customersResult, setCustomersResult] = useState([]);
    const [search, setSearch] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [showCustomersResult, setShowCustomersResult] = useState(false);
    const [phone, setPhone] = useState("");
    const [selectedPromo, setSelectedPromo] = useState({
        title: "Selecione uma Promoção",
    });
    const initialValues = {
        value: "",
        promotionId: "",
    };
    const searchDebouncerTimeout = useRef(null);

    const validate = (values) => {
        const errors = {};

        if (!(Number(values.value) > 0))
            errors.value = "O valor da compra deve ser maior que 0";

        if (!selectedPromo._id) errors.promotionId = "Selecione uma promoção";

        return errors;
    };

    const handleSubmit = async (values, { resetForm }) => {
        const dataToSubmit = {
            value: Number(values.value),
            customerPhone: phone.replace(/\D/g, ""),
            promotionId: selectedPromo.id,
        };

        try {
            setLoading(true);

            const response = await api.post(
                "/api/v1/loyalty-cards",
                dataToSubmit
            );

            resetForm(); // wipe formik values
            setSelectedPromo({ title: "Selecione uma Promoção" }); // wipe the select promo input

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (error) {
            const { data } = error.response;

            console.log("error", { error });
            toast.error(data?.message, {
                position: "top-right",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoading(false);
        }
    };

    function searchForCustomers(e) {
        const value = e.target.value;
        if (searchLoading) {
            return;
        }
        clearTimeout(searchDebouncerTimeout.current);

        setShowCustomersResult(false);
        setSearch(value);
        setCustomersResult([]);
        if (value.length >= 2) {
            searchDebouncerTimeout.current = setTimeout(async () => {
                setSearchLoading(true);
                setShowCustomersResult(true);
                const searchResponse = await api.get(
                    `api/v1/dashboard/stores/me/customers-by-name?name=${value}`
                );

                setSearchLoading(false);

                const results = searchResponse.data.result;

                setCustomersResult(
                    results.filter((result) =>
                        result.name.toLowerCase().includes(value.toLowerCase())
                    )
                );
            }, 500);
        } else {
            setSearchLoading(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}
        >
            <Form className="flex flex-col items-center justify-center gap-3 w-full">
                <div className="bg-white rounded-xl shadow-lg p-4 w-full max-w-screen-md mx-3 flex flex-col gap-3">
                    <div>
                        <label htmlFor="promotionId">Promoção</label>
                        <PromoSelect
                            selectedPromo={selectedPromo}
                            setSelectedPromo={setSelectedPromo}
                        />
                        <ErrorMessage
                            name="promotionId"
                            component="div"
                            className="text-red-500"
                        />
                    </div>

                    <div>
                        <label htmlFor="value">Valor em Reais</label>
                        <Field
                            className="form-control"
                            type="number"
                            name="value"
                            placeholder="Valor da Compra"
                            required
                        />
                        <ErrorMessage
                            name="value"
                            component="div"
                            className="text-red-500"
                        />
                    </div>

                    <div>
                        <label htmlFor="customer">Cliente</label>
                        <div className="relative w-full">
                            <input
                                type="text"
                                name="customer"
                                id="customer"
                                onChange={searchForCustomers}
                                className="form-control w-full"
                                placeholder="Buscar por cliente..."
                                value={search}
                            />
                            {showCustomersResult && (
                                <div className="absolute left-0 top-full z-10 mt-2 w-full pb-8 bg-transparent">
                                    <div className="w-full overflow-hidden rounded border border-black bg-white shadow">
                                        {searchLoading && (
                                            <div className="py-3 flex justify-center w-full">
                                                <Loading />
                                            </div>
                                        )}
                                        {customersResult.length ? (
                                            <ul className="m-0 flex max-h-[50vh] flex-col overflow-y-auto p-0">
                                                {customersResult.map(
                                                    (client) => (
                                                        <li
                                                            key={client.phone}
                                                            onClick={() => {
                                                                setSearch(
                                                                    client.name
                                                                );
                                                                setPhone(
                                                                    client.phone
                                                                );
                                                                setShowCustomersResult(
                                                                    false
                                                                );
                                                            }}
                                                            className="block cursor-pointer p-3 text-base text-black no-underline hover:bg-black/5"
                                                        >
                                                            {client.name}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        ) : (
                                            <>
                                                {search && !searchLoading ? (
                                                    <p className="m-0 py-3 text-center font-normal">
                                                        Nenhum cliente
                                                        encontrado para{" "}
                                                        <strong>
                                                            {search}
                                                        </strong>
                                                        .
                                                    </p>
                                                ) : (
                                                    <p></p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div>
                        <label htmlFor="customerPhone">
                            Telefone do Cliente
                        </label>
                        <InputMask
                            className="form-control"
                            as={InputMask}
                            mask="(99) 99999-9999"
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            name="customerPhone"
                            placeholder="Ex: (99) 9 9999-9999"
                            required
                        />
                    </div> */}

                    <button
                        className="
                        px-5 py-2.5 mx-auto 
                        text-md font-semibold
                        rounded-xl bg-secondaryGreen text-white
                        w-fit
                        hover:scale-105 duration-300"
                        type="submit"
                    >
                        {loading ? <LoadingForButton /> : "Cadastrar"}
                    </button>
                </div>
            </Form>
        </Formik>
    );
}
