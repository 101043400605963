import React from "react";
/* Hooks */
import { useState, useEffect } from "react";
import useSWR from "swr";
import useLink from "../../../../hooks/useLink";
import useSWRMutation from 'swr/mutation'

/* Services */
import api from "../../../../services/api";
import fetcher from "../../../../services/fetcher"

/* Components */
import MenuCliente from "../../../../components/MenuCliente";
import BannerTool from "../../../../components/BannerTool";
import Loading from "../../../../components/Loading";
import CouponsGrid from "../../components/CouponsGrid";
import Pagination from "../../../../components/Pagination";
import CustomerCoupon from "../../components/CustomerCoupon";
import DefaultRoot from "../../../../components/DeafultRoot";
import { toast } from "react-toastify";

export default function CustomerCoupons() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loadingAction, setLoadingAction] = useState(null)

    const history = useLink();

    const { data: customerCoupons, isLoading: loadingCustomerCoupons } = useSWR(
        `api/v1/customer-coupons?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    const { trigger } = useSWRMutation(`api/v1/customer-coupons?pageNumber=${currentPage}`, fetcher)

    /* Functions */

    const deleteCoupon = async (id) => {
        try {
            setLoadingAction(id)

            await api.delete(`api/v1/customer-coupons/${id}`)

            toast.success(
                "Cupom excluído com sucesso!",
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                }
            );
        } catch (error) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } finally {
            setLoadingAction(null)
        }
    }

    /* Effects */

    useEffect(() => {
        if (!loadingCustomerCoupons) setTotalPages(customerCoupons.pages);
    }, [customerCoupons, loadingCustomerCoupons]);

    return (
        <DefaultRoot>
            <MenuCliente />

            <main>
                <BannerTool
                    title="Meus Cupons"
                    action={() => history.push("/cliente/cupons")}
                    actionTitle="Ver Cupons Disponíveis"
                />

                {loadingCustomerCoupons ? (
                    <Loading />
                ) : (
                    customerCoupons.data && (
                        <CouponsGrid totalItems={customerCoupons.totalItems}>
                            {customerCoupons.data.length !== 0 ? (
                                customerCoupons.data.map((coupon) => {
                                    return (
                                        <CustomerCoupon
                                            key={coupon._id}
                                            status={coupon.status}
                                            code={coupon.code}
                                            store={coupon.store?.name}
                                            avatar={coupon.parentCoupon?.storeLogo}
                                            discount={Number(coupon.parentCoupon?.discountPercentage * 100).toFixed(0)}
                                            description={coupon.parentCoupon?.description}
                                            expiration={coupon.parentCoupon?.expirationDate}
                                            originalPrice={Number(coupon.parentCoupon?.originalPrice).toFixed(2)}
                                            statusChangedAt={coupon?.statusChangedAt}
                                            id={coupon._id}
                                            action={deleteCoupon}
                                            loading={loadingAction}
                                            update={trigger}
                                        />
                                    );
                                })
                            ) : (
                                <span className="mt-40 text-center text-xl w-full">
                                    Não temos cupons disponíveis no momento.
                                </span>
                            )}
                        </CouponsGrid>
                    )

                )}

                {!loadingCustomerCoupons && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={setCurrentPage}
                    />
                )}
            </main>
        </DefaultRoot>
    );
}