import { useEffect, useState } from "react";

import useSWR from "swr";
import moment from "moment/moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Loading from "../../../../components/Loading";
import BannerTool from "../../../../components/BannerTool";
import DefaultRoot from "../../../../components/DeafultRoot";
import MenuCliente from "../../../../components/MenuCliente";
import NewTable from "../../../../components/NewTable/NewTable";

import fetcher from "../../../../services/fetcher";

export default function CardDetails() {
    const params = useParams();
    const [cardInfo, setCardInfo] = useState({});
    const [dataReady, setDataReady] = useState(false);

    const { data: details, isLoading: loadingDetails } = useSWR(
        `api/v1/customers/me/loyalty-cards/${params.cardId}/purchases`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        const data = window.sessionStorage.getItem("LucreiLoyaltyInfo");

        setCardInfo(JSON.parse(data));

        if (data) setDataReady(true);
    }, []);

    return (
        <DefaultRoot>
            <MenuCliente />

            {loadingDetails ? (
                <Loading />
            ) : (
                <main>
                    <BannerTool title={"Detalhes do Cartão"} />

                    <section className="flex flex-col w-full mx-auto px-3 max-w-screen-2xl gap-4 justify-center items-center mt-3">
                        {dataReady && (
                            <div className="flex flex-col w-full bg-white border border-gray-300 rounded-2xl overflow-hidden">
                                <div className="bg-primaryPurple uppercase p-3 text-center text-white text-xl font-semibold">
                                    {cardInfo.store.name}
                                </div>

                                <div className="flex gap-3 justify-center flex-wrap p-3">
                                    {Array.from({
                                        length: cardInfo.totalPurchasesDone,
                                    }).map((_, index) => (
                                        <div
                                            key={index}
                                            className="rounded-xl overflow-hidden w-28 h-28"
                                        >
                                            <img
                                                src={cardInfo.store.avatar}
                                                alt=""
                                                className="w-full"
                                            />
                                        </div>
                                    ))}
                                    {Array.from({
                                        length:
                                            cardInfo.promotion.salesNumber -
                                            cardInfo.totalPurchasesDone,
                                    }).map((_, index) => (
                                        <div
                                            key={index}
                                            className="rounded-xl overflow-hidden bg-gray-300 w-28 h-28"
                                        ></div>
                                    ))}
                                </div>

                                <div className="p-3 font-semibold uppercase flex w-full justify-between text-sm">
                                    <span>CÓD CARTÃO: {cardInfo.code}</span>
                                    <span>{`Gerado em: ${moment(
                                        cardInfo.createdAt
                                    ).format("DD/MM/YYYY - HH:mm")}`}</span>
                                </div>
                            </div>
                        )}

                        <NewTable
                            data={details}
                            name={"Histórico de Compras"}
                            fields={[
                                {
                                    type: "date",
                                    title: "Data da compra",
                                    name: "createdAt",
                                },
                                {
                                    type: "money",
                                    title: "Valor",
                                    name: "value",
                                },
                            ]}
                        />
                    </section>
                </main>
            )}
        </DefaultRoot>
    );
}
