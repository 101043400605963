import { useFormik } from "formik";
import api from "../../services/api";
import { toast } from "react-toastify";
import { cidades } from "../../utils/optionsSelect";
import { useState } from "react";
import LoadingForButton from "../LoadingForButton";
import InputMask from "react-input-mask";
import Modal from "../Tailwind/Modal";

export default function RegistClient({
    setShowRegistClient,
    showRegistClient,
}) {
    const [isRegistered, setIsRegistered] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [registeredData, setRegisteredData] = useState({
        email: "",
        phone: "",
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            email: "",
            city: "",
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values) => {
            const phoneRegex =
                /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;
            const errors = {};
            if (!phoneRegex.test(values.phone))
                errors.phone = "Informe um telefone válido!";

            return errors;
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmiting(true);

        const cleanPhone = formik.values.phone.replace(/\D/g, "");

        const formValues = {
            ...formik.values,
            phone: cleanPhone,
        };

        if (!formik.values.phone || !formik.values.name) {
            toast.error(
                "Por favor, informe nome e o telefone do novo cliente.",
                {
                    position: "top-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                },
            );
            return;
        }

        try {
            await api.post("/api/v1/auth/customers/quick-signup", formValues);
            toast.success("Seu cliente foi cadastrado com sucesso!", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setIsRegistered(true);
            setRegisteredData({
                email: formik.values.email,
                phone: formik.values.phone,
            });
        } catch (error) {
            const { status, data } = error.response;

            console.log("error", { error });
            if (status === 400) {
                toast.error(data?.message, {
                    position: "top-right",
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } finally {
            setIsSubmiting(false);
        }
    };

    return (
        <Modal
            openModal={showRegistClient}
            setOpenModal={setShowRegistClient}
            title="Cadastrar Cliente"
            subtitle="Faça um cadastro rápido de seus clientes na Lucrei"
        >

            {!isRegistered ? (
                <form
                    className="w-3/4 mx-auto my-8 grid gap-4"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <label htmlFor="name">Nome</label>
                        <input
                            className="form-control"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            type="text"
                            maxLength={50}
                            name="name"
                            placeholder="Ex: João da Silva"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="email">E-mail</label>
                        <input
                            className="form-control"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            type="email"
                            maxLength={50}
                            name="email"
                            placeholder="Ex: joao@email.com"
                            required
                        />
                    </div>

                    <div>
                        <label htmlFor="phone">Telefone</label>
                        <InputMask
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            type="text"
                            name="phone"
                            mask={"(99) 99999-9999"}
                            placeholder="Ex: 85988223344"
                            required
                        />
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Cidade</label>
                        <select
                            className="form-control"
                            onChange={formik.handleChange}
                            value={formik.values.cidade}
                            name="city"
                            id="city"
                            placeholder="Ex: Fortaleza"
                            onBlur={formik.handleBlur}
                            required
                        >
                            <option value="" disabled selected>
                                Selecione...
                            </option>
                            {cidades?.map((cidades) => (
                                <option value={cidades}>{cidades}</option>
                            ))}
                        </select>
                        <span className="text-red-600">
                            {formik.errors.city}
                        </span>
                    </div>
                    <button
                        className="
                        p-3 mx-auto mt-4
                        text-base font-semibold
                        rounded-3xl bg-primaryGreen
                        w-4/5
                        hover:scale-105 duration-300"
                        type="submit"
                    >
                        {isSubmiting ? <LoadingForButton /> : "Cadastrar"}
                    </button>
                </form>
            ) : (
                <div className="flex flex-col pt-5">
                    <div className="mb-2 flex flex-col">
                        <span>
                            O cliente poderá agora acessar a Lucrei usando o
                            e-mail:
                        </span>
                        <span className="font-semibold">
                            {registeredData.email}
                        </span>

                        <span>ou o telefone:</span>

                        <span className="font-semibold">
                            {registeredData.phone} (*apenas numeros)
                        </span>
                    </div>

                    <div className="mb-5 flex flex-col">
                        <span>Senha: </span>
                        <span className="font-semibold">lucrei123</span>
                    </div>

                    <span className="text-zinc-500">
                        *Caro cliente, ao acessar a plataforma, recomendamos
                        que troque a sua senha, indo em Meu Perfil / Nova
                        Senha.
                    </span>

                    <button
                        className="p-3 mx-auto mt-4 text-base font-semibold rounded-3xl bg-primaryGreen w-4/5 hover:scale-105 duration-300"
                        type="button"
                        onClick={() => setShowRegistClient(false)}
                    >
                        Finalizar
                    </button>
                </div>
            )}
        </Modal>
    );
}
