import useSWR from 'swr'
import { useHistory } from "react-router-dom"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import DashboardIcon from "../../assets/svg/clipboard-data.svg"
import MinhasVendas from "../../assets/GeneralLogo/Icones/Minhas vendas.png"
import IconeComissoes from "../../assets/GeneralLogo/Icones/Icones SVG/10.svg"

import Infor from './componentes/Infor'
import Historico from './componentes/HistoricoCompr.jsx'

import Menu from "../../components/Menu/Menu"
import Footer from "../../components/Footer/index"

import Vector from "./img/vector.png"
import perfil from "./img/perfil.svg"

import './stylePerfil.scss'

import fetcher from '../../services/fetcher.js'
import MenuEmpresa from '../../components/MenuEmpresa/index.jsx'



function PerfilClientes() {
    const history = useHistory()
    const params = useParams()
    const customers = JSON.parse(localStorage.getItem('customersList'))
    //const actCustomer = customers.filter(x => x._id === params.customerId)
    console.log(params.customerId)

    const { data: actCustomer, isLoading: loadingCustomer } = useSWR(
        `api/v1/dashboard/stores/me/customers/${params.customerId}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )
    //const actCustomer = customers.filter(x => x._id === JSON.parse(localStorage.getItem('customerKey')))


    return (
        <div className='Body'>
            <MenuEmpresa />
            <section className='Perfil'>
                <div className='left'>
                    <div id='aling'>
                        <h2>Análise de Perfil</h2>
                        <hr id='barra' />
                        <h5>Monitore e crie estratégias para fidelizar seus clientes.</h5>
                    </div>
                    <img
                        id='vector'
                        src={Vector}
                        alt='vector'
                    />
                </div>
                <div className='right'>
                    <div className='alingRight'>
                        {/* Esta mesma DIV (containerOne) está presente no componente Histórico, então, a mesma função que será utilizada nesta, deverá estar presente na outra também */}

                        <div className='containerOne'>
                            <div className='boxPerfil'>
                                <img
                                    width="40px"
                                    src={perfil}
                                    alt='perfil'
                                />
                                <h2>Perfil</h2>
                            </div>
                            <button className='button' onClick={() => history.push("/empresa/clientes")}>Voltar</button>
                        </div>
                        {!loadingCustomer && (
                            <>
                                <Historico customer={params.customerId} phone={actCustomer.phone} />
                                <Infor customer={actCustomer} />
                            </>
                        )}

                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PerfilClientes