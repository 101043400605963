import { useFormik } from "formik";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";
import LoadingForButton from "../../../LoadingForButton";
import Modal from "../../../Tailwind/Modal";
import ctl from "@netlify/classnames-template-literals";
import CurrencyInput from "react-currency-masked-input";

export default function AddProduct({
  setOpenModal,
  openModal,
  onAdd
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  // const [priceValue, setPriceValue] = useState(null)
  const [price, setPrice] = useState()

  const formik = useFormik({
    initialValues: {
      name: "",
      inStock: undefined,
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmiting(true);

    const formValues = {
      ...{ name: formik.values.name },
      price: Number(price),
      ...(formik.values.inStock !== undefined && { inStock: formik.values.inStock }),
    };

    try {
      await api.post("/api/v1/products", formValues);
      toast.success("Produto cadastrado com sucesso!", {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (error) {
      const { data } = error.response;

      console.log("error", { error });
      toast.error(data?.message, {
        position: "top-right",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

    } finally {
      formik.resetForm()
      setPrice("")
      setIsSubmiting(false);
      if (onAdd) onAdd()
    }
  };


  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      title="Cadastrar Produto"
      subtitle="Informe os dados do produto"
    >
      <form
        className="md:min-w-[400px] mx-auto my-8 grid gap-4"
        onSubmit={handleSubmit}
      >
        <div>
          <label htmlFor="name">Nome do produto</label>
          <input
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.name}
            type="text"
            maxLength={50}
            name="name"
            placeholder="Produto"
            required
          />
        </div>

        <div>
          <label htmlFor="price">Preço</label>
          <CurrencyInput
            className="form-control"
            onChange={(_e, maskedValue) => setPrice(maskedValue)}
            value={price}
            name="price"
            type="number"
            placeholder="Preço"
            required
          />
        </div>

        <div>
          <label htmlFor="inStock">Estoque</label>
          <input
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.inStock}
            key={isSubmiting}
            type="number"
            name="inStock"
            placeholder="Quantidade em estoque"
          />
        </div>

        <button
          className={ctl(`
              p-3 mx-auto mt-4
              text-base font-semibold
              rounded-3xl bg-primaryGreen
              w-4/5
              hover:scale-105 duration-300
            `)}
          type="submit"
        >
          {isSubmiting ? <LoadingForButton /> : "Cadastrar"}
        </button>
      </form>
    </Modal>
  );
}
