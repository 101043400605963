import React from "react";
import "./style.scss";

const Pagination = ({ currentPage, totalPages, paginate }) => {
    return (
        totalPages > 1 && (
            <div className="mx-auto mt-5">
                <div>
                    <div
                        className={totalPages > 1 ? "Pagination" : "Pagination center-text"}
                    >
                        <div>
                            <button
                                className="btn-pagination"
                                disabled={currentPage <= 1}
                                onClick={() => paginate(currentPage - 1)}
                            >
                                Anterior
                            </button>
                            <span>{` Página ${currentPage} de ${totalPages} `}</span>
                            <button
                                className="btn-pagination"
                                disabled={currentPage >= totalPages}
                                onClick={() => paginate(currentPage + 1)}
                            >
                                Próxima
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )

    );
};

export default Pagination;
