import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function UpdateToastModal({
    updateToastModal,
    setUpdateToastModal,
}) {
    return (
        <>
            <Transition.Root show={updateToastModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    onClose={setUpdateToastModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto mt-16">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-4 sm:p-6">
                                        <div className="flex w-full">
                                            <div className="mt-3 text-center w-full">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-semibold leading-6 text-center w-full mx-auto text-gray-900 mb-5"
                                                >
                                                    Agrupamento das Ferramentas
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <ul className="list-disc text-start mb-3">
                                                        <h5>Vendas</h5>
                                                        <li>Cadastrar Venda</li>
                                                        <li>Minhas Vendas</li>
                                                        <li>
                                                            Cadastrar Venda
                                                            Avulsa
                                                        </li>
                                                        <li>
                                                            Minhas Vendas
                                                            Avulsas
                                                        </li>
                                                        <li>
                                                            Depositar Comissões
                                                        </li>
                                                        <li>
                                                            Minhas Estatíticas
                                                        </li>
                                                    </ul>

                                                    <ul className="list-disc text-start">
                                                        <h5>Categorias</h5>
                                                        <li>
                                                            Cadastrar Categoria
                                                        </li>
                                                        <li>
                                                            Minhas Vendas por
                                                            Categoria
                                                        </li>
                                                    </ul>

                                                    <ul className="list-disc text-start">
                                                        <h5>Clientes</h5>
                                                        <li>
                                                            Cadastrar Cliente
                                                        </li>
                                                        <li>Meus Clientes</li>
                                                    </ul>

                                                    <ul className="list-disc text-start mb-3">
                                                        <h5>Promoções</h5>
                                                        <li>
                                                            Validar Cupom de
                                                            Desconto
                                                        </li>
                                                        <li>
                                                            Meus Cupons de
                                                            Desconto
                                                        </li>
                                                        <li>
                                                            Criar Promoção de
                                                            Cartão Fidelidade
                                                        </li>
                                                        <li>
                                                            Cadastrar Venda em
                                                            Cartão Fidelidade
                                                        </li>
                                                        <li>
                                                            Minhas Promoções de
                                                            Cartões fidelidade
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center border-none rounded-md bg-secondaryGreen px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-150 sm:ml-3 sm:w-auto"
                                            onClick={() =>
                                                setUpdateToastModal(false)
                                            }
                                        >
                                            Entendi
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
