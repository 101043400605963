export const cidades = [
    "Aracoiaba",
    "Baturité",
    "Capistrano",
    "Acarape",
    "Aratuba",
    "Barreira",
    "Guaramiranga",
    "Itapiúna",
    "Mulungu",
    "Pacoti",
    "Palmácia",
    "Ocara",
    "Redenção",
];

export const estados = ["Ceará"];
