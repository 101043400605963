import { PlusIcon, FolderPlusIcon } from '@heroicons/react/20/solid'

export default function RegistButtons({ transaction, category }) {
    return (
        <section className="flex gap-3 w-full justify-start items-center">
            <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-secondaryGreen px-3 py-3 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                onClick={() => transaction(true)}
            >
                <PlusIcon className="-ml-0.5 h-7 w-7 md:h-5 md:w-5" aria-hidden="true" />
                Criar novo registro
            </button>

            <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-primaryPurple px-3 py-3 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100"
                onClick={() => category(true)}
            >
                <FolderPlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Criar nova categoria
            </button>
        </section>
    )
}