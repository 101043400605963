import BannerTool from "../../components/BannerTool"
import { useState } from "react"
import StatisticsDataIntegration from "./integration/statistics"
import StatisticInfo from "./components/StatisticInfo"
import useSWRImutable from 'swr'
import DefaultRoot from "../../components/DeafultRoot"
import MenuEmpresa from "../../components/MenuEmpresa"
import MainContent from "../../components/MainContent"

export default function EstatisticasEmpresa() {
    const date = new Date()
    const [period, setPeriod] = useState({ month: date.getMonth() + 1, year: date.getFullYear() })
    const { data, isLoading } = useSWRImutable(period, StatisticsDataIntegration)


    return (
        <DefaultRoot>
            <MenuEmpresa />

            <BannerTool
                title={"Estatísticas"}
            />

            <MainContent>

                <section className="d-flex flex-column align-items-center justify-content-center">
                    <StatisticInfo data={data} isLoading={isLoading} period={period} setPeriod={setPeriod} />
                </section>

            </MainContent>

        </DefaultRoot>

    );
}
