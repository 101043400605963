import React from "react";

function CustomerProfileInfoCard({ icon, title, data }) {
    return (
        <div className="boxApont">
            <div className="ImageeCircle">
                <img height="40px" width="40px" src={icon} alt="icon" />
            </div>
            <div className="groupArticle">
                <h3>{data}</h3>
                <p>{title}</p>
            </div>
        </div>
    );
}

export default CustomerProfileInfoCard;
