import { useState } from 'react'
import { useContext } from 'react'
import { Fragment, useRef } from 'react'

import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { TransactionsContext } from '../context/context'

import api from '../../../services/api'


export default function NewCategory({ updateCategories }) {

    const cancelButtonRef = useRef(null)
    const [submitting, setSubmitting] = useState(false)
    const { categoryModalOpen, setCategoryModalOpen, registCallback, setTransactionModalOpen } = useContext(TransactionsContext)


    const formik = useFormik({
        initialValues: {
            name: "",
        }
    })

    const handleSubmit = async (e) => {

        e.preventDefault()
        setSubmitting(true)

        try {
            await api.post(
                "/api/v1/transactions-categories",
                formik.values
            )
            toast.success("Categoria criada com sucesso!", {
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })

            formik.resetForm()

            updateCategories()

            setCategoryModalOpen(false)

            if (registCallback)
                setTransactionModalOpen(true)

        } catch (error) {
            if (error.response) {
                const { data } = error.response

                console.log("error", { error })

                toast.error(data?.message, {
                    position: "top-right",
                    autoClose: 9000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Transition.Root show={categoryModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setCategoryModalOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg w-full bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={() => setCategoryModalOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>


                                <div className="flex">

                                    <div className="mt-2 text-center w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Nova Categoria
                                        </Dialog.Title>
                                        <form
                                            className="w-full mx-auto my-8 grid gap-4"
                                            onSubmit={handleSubmit}
                                        >

                                            <div className='flex flex-col items-start'>
                                                <label htmlFor="name">Nome da Categoria</label>
                                                <input
                                                    className="form-control uppercase"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Ex: PAGAMENTO"
                                                    required
                                                />
                                            </div>

                                            <div className="mt-3 flex gap-2 justify-end">

                                                <button
                                                    type="button"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto"
                                                    onClick={() => setCategoryModalOpen(false)}
                                                    ref={cancelButtonRef}
                                                >
                                                    Cancelar
                                                </button>

                                                <button
                                                    type="submit"
                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-secondaryGreen px-3 py-2 text-sm font-semibold text-white shadow-sm hover:scale-105 duration-100 sm:mt-0 sm:w-auto"
                                                >
                                                    {submitting ? 'Aguarde...' : 'Concluir'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
