import { toast } from "react-toastify"
import api from "./api"

export default async function fetcher(url) {
    try {
        const response = await api.get(url)
        return response.data
    }
    catch (error) {
        console.log(error)
        toast.error(
            "Ocorreu um erro interno em nosso sistema. Caso isso persista, por favor, entre em contato com o nosso suporte.",
            {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            }
        );
    }
}