import { QrReader } from "react-qr-reader";
import DefaultButton from "../../../../components/DefaultButton";
import { useEffect, useState } from "react";

export default function QrCouponReader({ qrReaderOpen, setQrReaderOpen, action }) {

    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);

    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value);
    };

    useEffect(() => {
        // Lista todas as câmeras disponíveis
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setCameras(videoDevices);
            if (videoDevices.length > 0) {
                setSelectedCamera(videoDevices[0].deviceId);
            }
        });
    }, []);



    /*     useEffect(() => {
            setQrReaderOpen(false)
            setQrReaderOpen(true)
        }, [selectedCamera, setQrReaderOpen]) */

    return (

        <div className="fixed overflow-y-auto mt-10 top-0 w-screen h-screen bg-black bg-opacity-50">
            <div className="mt-10 w-5/6 lg:w-1/3 mx-auto">
                <div className="w-full flex justify-between items-center gap-3">
                    <DefaultButton
                        type="button"
                        content={"Fechar"}
                        className={"bg-red-600 text-white font-semibold h-fit"}
                        onClick={() => setQrReaderOpen(false)}
                    />
                    <span className="bg-primaryPurple text-white p-3 rounded-lg md:mt-0">Por favor, aponte o QR Code para a câmera.</span>
                </div>

                {/*  <div className="flex flex-col mt-3">
                    <label htmlFor="camera-select" className="text-white">Escolha uma câmera:</label>
                    <select
                        id="camera-select"
                        onChange={(e) => {
                            handleCameraChange(e)
                        }}
                        value={selectedCamera}>
                        {cameras.map(camera => (
                            <option key={camera.deviceId} value={camera.deviceId}>
                                {camera.label}
                            </option>
                        ))}
                    </select>
                </div> */}

                <QrReader
                    onResult={(result) => {
                        if (!!result) {
                            action(result?.text);
                            setQrReaderOpen(false)
                        }
                    }}
                    facingMode={'rear'}
                    chooseDeviceId={selectedCamera}
                    style={{ width: '90%' }}
                />

            </div>
        </div>

    )
}