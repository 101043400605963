import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import ctl from "@netlify/classnames-template-literals";
import CurrencyInput from "react-currency-masked-input";

import LoadingForButton from "../../../LoadingForButton";

import Modal from "../../../Tailwind/Modal";

import api from "../../../../services/api";


export default function EditProduct({
  setOpenModal,
  openModal,
  onAdd,
  product
}) {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [price, setPrice] = useState(product?.price)

  const formik = useFormik({
    initialValues: {
      name: product?.name,
      inStock: product?.inStock,
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmiting(true);

    const formValues = {
      ...(formik.values.name !== product.name && { name: formik.values.name }),
      price: Number(price),
      ...(formik.values.inStock !== undefined && { inStock: formik.values.inStock }),
    };

    try {
      await api.patch(`/api/v1/products/${product.id}`, formValues);
      toast.success("Produto atualizado com sucesso!", {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setOpenModal(false)
    } catch (error) {
      const { data } = error.response;

      console.log("error", { error });
      toast.error(data?.message, {
        position: "top-right",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

    } finally {
      formik.resetForm()
      setPrice("")
      setIsSubmiting(false);
      if (onAdd) onAdd()
    }
  };

  console.log(product)
  console.log(price)

  useEffect(() => {
    if (product) {
      formik.setValues({
        name: product?.name,
        inStock: product?.inStock,
      });
      if (product.price)
        setPrice(product?.price.toFixed(2))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // useEffect(() => {
  //   formik.resetForm()
  // }, [product])


  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      title="Atualizar Produto"
    >
      <form
        className="md:min-w-[400px] mx-auto my-8 grid gap-4"
        onSubmit={handleSubmit}
        key={product}
      >
        <div>
          <label htmlFor="name">Nome do produto</label>
          <input
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.name}
            defaultValue={product?.name}
            type="text"
            maxLength={50}
            name="name"
            placeholder="Produto"
            required
          />
        </div>

        <div>
          <label htmlFor="price">Preço</label>
          <CurrencyInput
            className="form-control"
            onChange={(_e, maskedValue) => setPrice(maskedValue)}
            value={price}
            name="price"
            type="number"
            placeholder="Preço"
            required
          />
        </div>

        <div>
          <label htmlFor="inStock">Estoque</label>
          <input
            className="form-control"
            onChange={formik.handleChange}
            value={formik.values.inStock}
            defaultValue={product?.inStock}
            key={isSubmiting}
            type="number"
            name="inStock"
            placeholder="Quantidade em estoque"
          />
        </div>

        <button
          className={ctl(`
              p-3 mx-auto mt-4
              text-base font-semibold
              rounded-3xl bg-primaryGreen
              w-4/5
              hover:scale-105 duration-300
            `)}
          type="submit"
        >
          {isSubmiting ? <LoadingForButton /> : "Cadastrar"}
        </button>
      </form>
    </Modal>
  );
}
