import React from "react";
import InputMask from "react-input-mask";
import "./style.scss";

const InputMaskRandomKeyPix = ({ value, name, onChange, onBlur }) => {
  return (
    <InputMask
      className="campo"
      placeholder="ab12cdef-12ab-cd34-56ef-abc123def456"
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default InputMaskRandomKeyPix;
