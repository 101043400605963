import { useState, useEffect } from "react"

import useSWR from "swr"

import Loading from "../../components/Loading"
import Pagination from "../../components/Pagination"
import BannerTool from "../../components/BannerTool"
import MenuEmpresa from "../../components/MenuEmpresa"
import DefaultRoot from "../../components/DeafultRoot"
import MainContent from "../../components/MainContent"
import NewTable from "../../components/NewTable/NewTable"

import fetcher from "../../services/fetcher"
import SimpleCard from "../../components/SimpleCard/SimpleCard"
import formatCurrency from "../../utils/format-currency"
import { FcMoneyTransfer, FcShop } from "react-icons/fc";

export default function SalesScreen() {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { data, isLoading } = useSWR(
        `api/v1/stores/me/sales?sortBy=-createdAt&pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const { data: sales } = useSWR(
        `api/v1/dashboard/stores/me/sales/daily`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    useEffect(() => {
        if (!isLoading) {
            setTotalPages(data?.pages)
        }
    }, [data, isLoading])

    return (
        <DefaultRoot>

            <MenuEmpresa />

            <BannerTool
                title="Minhas Vendas"
            />

            <MainContent>

                <div className="table-responsive w-full max-w-full mx-auto py-2 mb-3 flex justify-start gap-4">
                    <SimpleCard
                        title={
                            <div className="flex flex-col md:flex-row items-center gap-1">
                                <FcShop className="w-5 h-5" />
                                <span>Nº de vendas hoje</span>
                            </div>
                        }
                        content={sales?.data?.totalSales ?? "..."}
                        custom={"whitespace-nowrap"}
                    />
                    <SimpleCard
                        title={
                            <div className="flex flex-col md:flex-row items-center gap-1">
                                <FcMoneyTransfer className="w-5 h-5" />
                                <span>Valor total vendido hoje</span>
                            </div>
                        }
                        content={`R$ ${formatCurrency(sales?.data?.totalSold)}` ?? "..."}
                        custom={"whitespace-nowrap"}
                    />
                </div>

                {isLoading ? <Loading />
                    : (
                        <NewTable
                            data={data}
                            name={""}
                            fields={[
                                {
                                    type: "default",
                                    title: "Cod. Lucrei",
                                    name: "lucreiCode",
                                },
                                {
                                    type: "default",
                                    title: "Usuário",
                                    name: "customerName",
                                },
                                {
                                    type: "date",
                                    title: "Data de Compra",
                                    name: "createdAt",
                                },
                                {
                                    type: "money",
                                    title: "Preço",
                                    name: "value",
                                },
                                {
                                    type: "money",
                                    title: "Cashback",
                                    name: "cashback",
                                }
                            ]}
                        />
                    )}

                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={setCurrentPage}
                />
            </MainContent>
        </DefaultRoot>

    )
}
