import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MenuCliente from "../../../../components/MenuCliente";
import api from "../../../../services/api"
import useSWR from "swr";
import CouponsGrid from "../../components/CouponsGrid";
import Loading from "../../../../components/Loading";
import CustomerCoupon from "../../components/CustomerCoupon";
import DefaultRoot from "../../../../components/DeafultRoot";
import moment from "moment/moment";

export default function CouponPage() {
    const params = useParams()

    /* Fetch */
    const fetcher = async (url) => {
        const response = await api.get(url)
        return response.data.data
    }

    const { data: coupon, isLoading: loadingCoupon } = useSWR(`api/v1/customer-coupons/${params.id}`,
        fetcher, {
        revalidateOnFocus: false,
    })


    return (
        <DefaultRoot>

            <MenuCliente />

            <div className="mainContentController"></div>

            <main>

                {loadingCoupon ? <Loading /> : (
                    <div className="flex flex-col w-full h-screen justify-center items-center">
                        {coupon.status === "USADO" ? (
                            <h1 className="font-semibold text-base text-center mx-auto max-w-lg mb-12 text-red-600">
                                {`Ops! Parece que esse cupom já foi utilizado em ${moment(coupon.statusChangedAt).format("DD/MM/YYYY")}. Tente usar outro.`}
                            </h1>
                        ) : (
                            <h1 className="font-semibold text-base text-center mx-auto max-w-lg mb-12">
                                Apresente este cupom no ato da compra para receber o desconto. A validação
                                pode ser feita pelo QR Code ou pelo ID do cupom.
                            </h1>
                        )}

                        <CouponsGrid>
                            <CustomerCoupon
                                status={coupon.status}
                                code={coupon.code}
                                store={coupon.store.name}
                                avatar={coupon.store.avatar}
                                discount={coupon.parentCoupon.discountPercentage}
                                description={coupon.parentCoupon.description}
                                expiration={coupon.parentCoupon.expirationDate}
                            />

                        </CouponsGrid>


                    </div>

                )}

            </main>

        </DefaultRoot>
    )
}