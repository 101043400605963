import React, { useEffect, useRef, useState } from "react";
import { months } from "../../../utils/months";
import rightArrowIcon from "../img/right_arrow.svg"
import leftArrowIcon from "../img/left_arrow.svg"


export default function PeriodControls({ isLoading, period, setPeriod }) {
    // const { period, setPeriod, loading } = useContext(StatisticContext)
    const [yearsActive, setYearsActive] = useState([])
    const date = new Date()
    const year = date.getFullYear()
    const monthRef = useRef(null)
    const yearRef = useRef(null)


    const ChangePeriodUp = () => {
        if (parseInt(period.month) === 12)
            setPeriod({ month: 1, year: period.year + 1 })
        else
            setPeriod({ month: parseInt(period.month) + 1, year: period.year })
    }

    const ChangePeriodDown = () => {
        if (period.month === 1)
            setPeriod({ month: 12, year: period.year - 1 })
        else
            setPeriod({ month: period.month - 1, year: period.year })
    }

    const HandleSelectMonth = (event) => {
        setPeriod({ month: event.target.value, year: period.year })
    }

    const HandleSelectYear = (event) => {
        setPeriod({ month: period.month, year: event.target.value })
    }

    const CalcYearsActive = () => {
        const years = []
        for (let i = 2022; i <= year; i++) {
            years.push(i)
        }
        setYearsActive(years)
    }

    useEffect(() => {
        CalcYearsActive()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (monthRef.current.value !== period.month || yearRef.current.value !== period.year) {
            monthRef.current.value = period.month
            yearRef.current.value = period.year
        }
    }, [period])


    return (

        <div className="flex gap-3 items-center">
            {!isLoading && (
                <button onClick={ChangePeriodDown}>
                    <img src={leftArrowIcon} alt="retroceder um mês" />
                </button>
            )}


            <select disabled={isLoading} ref={monthRef} className="appearance-none text-center w-32 rounded-md" name="month" id="month" value={period.month} onChange={HandleSelectMonth}>

                {months.map((monthOption, index) => {
                    return <option key={monthOption} value={index + 1}>{monthOption}</option>
                })}

            </select>

            <span>de</span>

            <select disabled={isLoading} ref={yearRef} className="appearance-none text-center w-24 rounded-md" name="year" id="year" value={period.year} onChange={HandleSelectYear}>
                {yearsActive.map((yearOption) => {
                    return <option key={yearOption} value={yearOption}>{yearOption}</option>
                })}

            </select>

            {!isLoading && (
                <button onClick={ChangePeriodUp}>
                    <img src={rightArrowIcon} alt="avançar um mês" />
                </button>
            )}

        </div>
    )
}