import React from "react";

import MenuEmpresa from "../../../../components/MenuEmpresa";
import useLink from "../../../../hooks/useLink";
import fetcher from "../../../../services/fetcher";
import BannerTool from "../../../../components/BannerTool/index";
import useSWR from "swr";

import { useState, useEffect } from "react";
import Pagination from "../../../../components/Pagination";
import Loading from "../../../../components/Loading";

import DefaultRoot from "../../../../components/DeafultRoot";
import PromoCard from "../components/PromoCard";

export default function PromoList() {
    const history = useLink();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data: promos, isLoading: loadingPromos } = useSWR(
        `api/v1/stores/me/loyalty-card-promotions?pageNumber=${currentPage}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    );

    /* Effects */
    useEffect(() => {
        if (!loadingPromos) {
            setTotalPages(promos.pages);
        }
    }, [promos, loadingPromos]);

    return (
        <DefaultRoot>
            <MenuEmpresa />

            <main>
                {promos?.totalItems > 0 ? (
                    <BannerTool
                        title="Minhas Promoções de Cartão Fidelidade"
                        action={() =>
                            history.push("/empresa/nova-venda-promocional")
                        }
                        actionTitle="Cadastrar Nova Venda em Cartão Fidelidade"
                    />
                ) : (
                    <BannerTool
                        title="Minhas Promoções de Cartão Fidelidade"
                        action={() => history.push("/empresa/criar-cartao")}
                        actionTitle="Criar Promoção de Cartão Fidelidade"
                    />
                )}

                {loadingPromos ? (
                    <Loading />
                ) : promos?.totalItems > 0 ? (
                    <section className="w-11/12 mx-auto grid gap-3 md:grid-cols-2 lg:grid-cols-3">
                        {promos.data.map((promo) => {
                            return (
                                <PromoCard
                                    photo={promo?.photo}
                                    title={promo?.title}
                                    isActive={promo?.isActive}
                                    id={promo?._id}
                                    key={promo?._id}
                                />
                            );
                        })}
                    </section>
                ) : (
                    <h2 className="text-2xl text-center max-w-2xl mx-auto mt-5">
                        Você ainda não cadastrou nenhuma promoção de Cartão
                        Fidelidade. Para criar uma, clique em "Cadastrar
                        Promoção de Cartão Fidelidade".
                    </h2>
                )}

                {!loadingPromos && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={setCurrentPage}
                    />
                )}
            </main>
        </DefaultRoot>
    );
}
