import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import useForm from "../../hooks/useForm";
import "./style.scss";
import api from "../../services/api";

export default function ResponsiveDialog({ setOpen, open, alterImg = () => { }, alterText = () => { } }) {
  const inputEmail = useForm("email");
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!inputEmail.validate()) return;

      setLoading(true);

      await api.post("api/v1/auth/forgot-password", {
        credential: inputEmail.value,
      });

      setLoading(false);
      setShowMessage(true);
    } catch (error) {
      setLoading(false);
      setShowMessage(true);
      console.log(error);
    }
  };

  if (showMessage) {
    alterImg()
    alterText()
  }
  return (
    <div>
      {showMessage && (
        <div className="response-message">
          <h2 className="Dnone">Email Enviado</h2>
          <p>O email foi enviado para <span>{inputEmail.value}</span> com um link para definir nova senha.</p>
          <p>Caso não tenha recebido, verifique a pasta Lixo Eletrônico/Spam.</p>
        </div>
      )}
      {!showMessage && (
        <div className="content">
          <h6 className="Dnone">Esqueceu a sua senha?</h6>
          <p className="Dnone">Sem problemas, a gente te ajuda a recuperar :)</p>
        </div>
      )}
      {!showMessage &&
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Digite seu e-mail:</label>
            <input
              id="email"
              className="campo input-email"
              type="email"
              value={inputEmail.value}
              onChange={inputEmail.onChange}
              onBlur={inputEmail.onBlur}
              placeholder="maria@gmail.com"
              disabled={showMessage}
            />
            {inputEmail.error && (
              <span className="error-message">{inputEmail.error}</span>
            )}
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn-reset-password"
              disabled={loading}
            >
              {loading ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </form>}
      {/* <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Esqueceu sua senha? Sem problemas, iremos te ajudar! :)
          </DialogContentText>
          <InputLabel htmlFor="input-with-icon-adornment">
            Digite seu email:
          </InputLabel>
          <TextField id="outlined" variant="standard" />
        </DialogContent>
        <DialogActions>
          <button className="btnTerms" onClick={handleClose}>
            Fechar
          </button>

          <button className="btnTerms" onClick={handleClose}>
            Redefinir Senha
          </button>
        </DialogActions> */}

    </div>
  );
}
