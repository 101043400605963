import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import PurchaseDetail from "../ClientManagement/components/PurchaseDetail";

import ClienteAvulsoInfo from "./componentes/ClienteAvulsoInfo";

import Footer from "../../components/Footer/index";
import Modal from "../../components/Tailwind/Modal";
import MenuEmpresa from "../../components/MenuEmpresa";
import CustomerInfoCards from "../../components/CustomerInfoCards";

import useLink from "../../hooks/useLink";

import Vector from "./img/vector.png";
import perfil from "./img/perfil.svg";

import "./stylePerfil.scss";

import api from "../../services/api";
import { getToken, logout } from "../../services/auth";

import formatDateToBrazilianFormat from "../../utils/format-date-and-time";


function PerfilClientesAvulsos() {
    const history = useLink();
    const params = useParams();
    const customerPhone = params.customerPhone;
    const token = getToken();

    const [averageTicket, setAverageTicket] = useState(0);
    const [separateSalesFromThisMonth, setSeparateSalesFromThisMonth] =
        useState(0);
    // const [dateFilter, setDateFilter] = useState();
    // const [visibilityButton, setVisibilityButton] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const [selectedCustomer, setSelectedCustomer] = useState();

    const [vendasAvulsas, setVendasAvulsas] = useState([]);
    const [comprasEfetuadas, setComprasEfetuadas] = useState(0);
    const [clienteAvulsoInfo, setClienteAvulsoInfo] = useState({});

    const [separatedPurchases, setSeparatedPurchases] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [purchaseDetails, setPurchaseDetails] = useState()

    useEffect(() => {
        // TODO! Tratar erro no catch
        // getHistory();
        // getHistoryFromSeparateSales();
        // getSeparateSalesFromCurrentMonth();
        // getAverageTicket();
        // getPurchasesPerMonth();
        // getSelectedCustomerInfo();
        getHistoryFromSeparateSales();
        getTicketMedioVendasAvulsasPeloTelefone();
        getSeparateSalesFromCurrentMonthByCustomerPhone();
        getTodasVendasAvulsasPeloTelefone();
        getClienteAvulsoInfoPeloTelefone();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClienteAvulsoInfoPeloTelefone = async () => {
        try {
            const response = await api.get(
                `api/v1/dashboard/stores/me/separated-customers/${customerPhone}/info`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log("getClienteAvulsoInfoPeloTelefone => ", response);
            const customerInfo = response?.data?.data;
            setClienteAvulsoInfo({
                name:
                    customerInfo?.customerName ||
                    customerInfo?.leadCustomerName,

                phone:
                    customerInfo?.customerPhone ||
                    customerInfo?.leadCustomerPhone,

                email: customerInfo?.customerEmail,
                gender: customerInfo?.customerGender,
                customerId: customerInfo?.customerId
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getTicketMedioVendasAvulsasPeloTelefone = async () => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerPhone}/average-ticket-from-customer-phone`,
            {
                headers: { Authorization: token },
            }
        );
        console.log("getAverageTicketFromCustomerPhone", response);
        setAverageTicket(response?.data[0]?.averageTicket.toFixed(2));
    };

    const getTodasVendasAvulsasPeloTelefone = async () => {
        try {
            const response = await api.get(
                `api/v1/dashboard/stores/me/${customerPhone}/all-separate-sales-from-customer-phone`,
                {
                    headers: { Authorization: token },
                }
            );

            console.log(
                "getAllSeparateSalesFromCurrentMonthByCustomerPhone",
                response
            );

            setVendasAvulsas(response.data.data);
            setComprasEfetuadas(response.data.totalItems);
            setIsLoading(false);
        } catch (error) {
            const { status } = error.response;
            console.log({ error });

            if (status === 401) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
            if (status === 403) {
                history.push("/login");
                logout();
                toast.error(
                    "Seu tempo de sessão expirou! Por favor, faça login novamente.",
                    {
                        position: "top-right",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    }
                );
            }
        }
    };

    const getHistoryFromSeparateSales = async () => {
        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerPhone}/separated-purchases`,
            {
                headers: { Authorization: token },
            }
        );
        setSeparatedPurchases(response.data.data)
        console.log("getHistoryFromSeparateSales", response);
    };

    /** PRECISO BUSCAR AS COMPRAS DESSE CARA NO MES POR TELEFONE */
    const getSeparateSalesFromCurrentMonthByCustomerPhone = async () => {
        const now = new Date();

        const response = await api.get(
            `api/v1/dashboard/stores/me/${customerPhone}/separate-sales-from-customer-phone?month=${now.getMonth() + 1
            }&year=${now.getFullYear()}`,
            {
                headers: { Authorization: token },
            }
        );

        console.log(
            "getSeparateSalesFromCurrentMonthByCustomerPhone",
            response
        );
        setSeparateSalesFromThisMonth(response.data.totalItems);
    };

    const totalPurchasesFromThisMonth = separateSalesFromThisMonth;
    const totalVendasAvulsas = vendasAvulsas.length;

    function Detail() {
        return <PurchaseDetail
            customerName={clienteAvulsoInfo?.name}
            createdAt={purchaseDetails?.createdAt}
            value={purchaseDetails?.value}
            products={purchaseDetails?.products} />
    }
    return (
        <React.Fragment>
            <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                children={<Detail />}
                unlimitedSize
            />
            <div className="Body">
                <MenuEmpresa />
                <section className="Perfil">
                    <div className="left">
                        <div id="aling">
                            <h2>Análise de Perfil</h2>
                            <hr id="barra" />
                            <h5>
                                Monitore e crie estratégias para fidelizar seus
                                clientes.
                            </h5>
                        </div>
                        <img id="vector" src={Vector} alt="" />
                    </div>
                    <div className="right">
                        <div className="alingRight">
                            <div className="containerOne">
                                <div className="boxPerfil">
                                    <img width="40px" src={perfil} alt="" />
                                    <h2>Perfil</h2>
                                </div>
                                <button
                                    className="button"
                                    onClick={() =>
                                        history.push(
                                            "/empresa/clientes"
                                        )
                                    }
                                >
                                    Voltar
                                </button>
                            </div>
                            <div className="agroupHistoric">
                                <div className="boxLeft">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div className="boxOnee mb-3 PurchaseHistory">
                                            <div className="w-full flex justify-start">
                                                <button
                                                    className="bg-secondaryGreen text-white rounded-lg py-1 px-2 duration-100 hover:scale-105"
                                                    onClick={() => history.push(`/empresa/produtos-por-cliente/mais-vendidos/${clienteAvulsoInfo?.customerId}/${clienteAvulsoInfo?.name}`)}
                                                    disabled={!clienteAvulsoInfo?.customerId}
                                                >
                                                    Ver produtos mais comprados pelo cliente
                                                </button>
                                            </div>
                                        </div>
                                        {separatedPurchases.length > 0 && (
                                            <strong
                                                style={{
                                                    textAlign: "right",
                                                    margin: "0 .5rem .5rem 0",
                                                }}
                                            >
                                                Total: {totalVendasAvulsas}
                                            </strong>
                                        )}
                                        {isLoading ? <p>Carregando...</p> : null}
                                        <div className="historyBox-container">
                                            {!(
                                                isLoading &&
                                                separatedPurchases.length !== 0
                                            )
                                                ? separatedPurchases.map((purchase) => {
                                                    const datetime = formatDateToBrazilianFormat(purchase.createdAt);
                                                    return (
                                                        <div
                                                            key={purchase._id}
                                                            className="historyBox"
                                                        >
                                                            <div className="contHistory">
                                                                <img
                                                                    id="set"
                                                                    src={perfil}
                                                                    height="30px"
                                                                    alt="Income icon"
                                                                />
                                                                <h3>
                                                                    R${" "}
                                                                    {
                                                                        purchase.value
                                                                    }
                                                                </h3>
                                                            </div>
                                                            <p>{datetime}</p>
                                                            <button
                                                                className="bg-secondaryGreen text-white rounded-lg py-1 px-2 duration-100 hover:scale-105"
                                                                onClick={() => {
                                                                    setPurchaseDetails(purchase)
                                                                    setOpenModal(true)
                                                                }}
                                                            >
                                                                Ver detalhes
                                                            </button>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                            {vendasAvulsas.length === 0 ? (
                                                <p className="no-purchases-info">
                                                    Nenhuma venda avulsa encontrada
                                                    para o cliente selecionado.
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/** REFATORAR ISSO AQUI */}

                                {clienteAvulsoInfo?.phone && (
                                    <CustomerInfoCards
                                        averageTicket={averageTicket}
                                        totalPurchases={comprasEfetuadas}
                                        totalPurchasesFromThisMonth={
                                            totalPurchasesFromThisMonth
                                        }
                                    />
                                )}
                            </div>
                            {clienteAvulsoInfo?.phone && (
                                <ClienteAvulsoInfo customer={clienteAvulsoInfo} />
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </React.Fragment>
    );
}

export default PerfilClientesAvulsos;
